<template>
    <div id="thankYouModalDefaultContent" class="text-center p-4 px-5">
        <h1>
            Thank You.
        </h1>
        <p>
            <strong>
                Your price has been updated.
            </strong>
        </p>
        <p>
            A representative from <strong>{{ dealerName }}</strong> will be
            contacting you to confirm availability and answer any questions you
            may have about this vehicle.
        </p>
        <div class="actions mx-auto my-2">
            <b-btn variant="orange" block @click="$emit('see-pricing')">
                Okay. See Pricing
            </b-btn>
        </div>
        <p>
            <b-link @click="$emit('still-shopping')">
                I'm still shopping. I'm not ready to talk to a dealership yet.
            </b-link>
        </p>
    </div>
</template>

<style lang="scss">
#thankYouModalDefaultContent {
    color: $medium-gray;

    h1 {
        color: $primary-blue;
        font-size: 36px;
        font-weight: 300;
    }

    strong {
        font-family: $helvetica-65-medium;
        font-weight: normal;
    }

    .actions {
        max-width: 200px;
    }
}
</style>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";

export default {
    computed: {
        dealer: get("dealerLink/selectedDealer"),
        dealerName() {
            return _.get(this.dealer, "name", "a CarSaver Certified Dealer");
        }
    }
};
</script>
