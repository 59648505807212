<template>
    <div>
        <vdp-slider v-if="inventoryVehicle" />

        <vdp-hero-image v-else />
    </div>
</template>

<script>
import { get } from "vuex-pathify";
import VdpSlider from "./components/VdpSlider";
import VdpHeroImage from "./components/VdpHeroImage";

export default {
    components: { VdpSlider, VdpHeroImage },

    computed: {
        inventoryVehicle: get("vehicleDetails/inventoryVehicle")
    }
};
</script>
