<template>
    <div v-if="loading" class="loading-wrapper">
        <loading-spinner />
    </div>

    <div v-else-if="noIncentives" class="mt-3">
        <no-incentives-found />
    </div>

    <div v-else class="mt-3">
        <div class="mb-2">
            The following incentive(s) are available for this vehicle.
        </div>

        <cash-programs :consumer-cash="cash.standard.consumerCash" />

        <special-programs
            id="cash-special-incentives"
            :special-incentives="cash.consumerSpecials"
        />
    </div>
</template>

<script>
import api from "@/api";
import CashPrograms from "./CashPrograms";
import SpecialPrograms from "./SpecialPrograms";
import LoadingSpinner from "Components/LoadingSpinner";
import NoIncentivesFound from "./NoIncentivesFound";

export default {
    name: "CashTab",

    components: {
        NoIncentivesFound,
        LoadingSpinner,
        SpecialPrograms,
        CashPrograms
    },

    props: {
        zipCode: {
            type: [String, Number],
            required: true
        },
        styleId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            loading: true,
            cash: {
                consumerSpecials: [],
                standard: {
                    consumerCash: {
                        totalCash: null,
                        programs: []
                    }
                }
            }
        };
    },

    computed: {
        noIncentives() {
            return !this.cash.standard;
        }
    },

    mounted() {
        api.get(
            `/zipcodes/${this.zipCode}/styles/${this.styleId}/incentives/CASH`
        )
            .then(response => {
                this.cash = response.data;
                this.loading = false;
                this.complete();
            })
            .catch(error => {
                this.loading = false;
                this.complete();
                console.error(error);
            });
    },

    methods: {
        complete() {
            this.$emit("complete");
        }
    }
};
</script>
