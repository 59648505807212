import Vue from "vue";
import _ from "lodash";
import "@/lib/polyfills";
import Affix from "vue-affix";
import * as VueGoogleMaps from "vue2-google-maps";
import VueLodashFilters from "vue-lodash-filters";
import money from "v-money";

import "../../sass/vendor.scss";
import "../../sass/core.scss";
import "../../sass/vdp.scss";

import PickPriceDrive from "@/components/PickPriceDrive/VdpDataProvider";
import VdpNavigation from "Modules/Vehicle/Navigation";
import VehicleDetails from "Modules/Vehicle";
import PricingCard from "Modules/Vehicle/PricingCard";
import SignInRequiredModal from "Components/SignInRequiredModal";
import NewSearchLink from "Modules/Vehicle/NewSearchLink";
import FuelConsumptionBlock from "Modules/Vehicle/FuelConsumptionBlock";
import MobileStickyFooter from "Modules/Vehicle/MobileStickyFooter";
import VdpHero from "Modules/Vehicle/VdpHero";
import VehicleDealerStickyFooter from "Modules/Vehicle/DealerStickyFooter";
import DealerStickyFooter from "Components/DealerStickyFooter";
import PrivacyProtectionModal from "Components/PrivacyProtectionModal";
import ExpressPass from "Modules/Vehicle/ExpressPass";
import VueI18n from "vue-i18n";
import Vuex from "vuex";
import pathify from "@/store/pathify";
import user from "@/store/modules/user";
import dealerLink from "@/store/modules/dealerLink";
import translated from "@/store/modules/translated";
import location from "@/store/modules/location";
import campaign from "@/store/modules/campaign";
import sessionReset from "@/store/modules/sessionReset";
import vehicleDetails from "@/store/modules/vehicleDetails";
import paymentMatrix from "@/store/modules/paymentMatrix";
import contactDealer from "@/store/modules/contactDealer";
import supplierPricing from "@/store/modules/supplierPricing";
import scheduleAppointment from "@/store/modules/scheduleAppointment";
import CarSaverPlugin from "@/lib/CarSaverPlugin";
import headerTransitionStore from "@/store/modules/headerTransitionStore";
import VehicleReviewModal from "Modules/Vehicle/VehicleReviewModal";
import DistanceNotification from "Modules/Vehicle/DistanceNotification";
import GetSalesPriceBlock from "Modules/Vehicle/PricingCard/components/GetSalesPriceBlock";
import DealerLinkRequiredModal from "Components/DealerLinkRequiredModal";
import LifetimeWarrantyBlock from "Modules/Vehicle/LifetimeWarrantyBlock";
import LeavingCarSaverModal from "Components/LeavingCarSaverModal";
import GeoLocation from "Components/GeoLocation";

const environment = _.get(window, "appConfig.env[0]", "local") || "local";

Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(VueLodashFilters);
Vue.use(money);
Vue.use(Affix);

const debug = environment !== "production";

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAmeMzc4Bx3ogH6OT4oNZ_hy2ufznNTcwY"
    }
});

const store = new Vuex.Store({
    plugins: [pathify.plugin],

    modules: {
        user,
        translated,
        location,
        sessionReset,
        campaign,
        vehicleDetails,
        paymentMatrix,
        contactDealer,
        scheduleAppointment,
        dealerLink,
        supplierPricing,
        headerTransitionStore
    },
    strict: debug
});

const defaultMessages = _.get(store, "state.translated.text", {});
const messages = {
    default: defaultMessages,
    en: {},
    es: {}
};
const i18n = new VueI18n({
    locale: "default",
    fallbackLocale: "default",
    messages
});

Vue.use(CarSaverPlugin);

if (document.getElementById("root") != null) {
    // eslint-disable-next-line no-new
    new Vue({
        el: "#root",

        store,

        i18n,

        components: {
            PickPriceDrive,
            VdpNavigation,
            ExpressPass,
            PricingCard,
            SignInRequiredModal,
            DealerLinkRequiredModal,
            NewSearchLink,
            MobileStickyFooter,
            DealerStickyFooter,
            PrivacyProtectionModal,
            FuelConsumptionBlock,
            VehicleDealerStickyFooter,
            VehicleReviewModal,
            DistanceNotification,
            GetSalesPriceBlock,
            VdpHero,
            VehicleDetails,
            LifetimeWarrantyBlock,
            "leaving-carsaver-modal": LeavingCarSaverModal,
            GeoLocation
        },

        data: {
            page: _.get(window, "page", null)
        }
    });
}
