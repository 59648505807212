var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "view-details-modal",
    { staticClass: "insure-view-details-modal", attrs: { id: _vm.id } },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("\n        Congratulations!\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "summary" }, slot: "summary" },
        [
          _c("h4", [
            _vm._v(
              "\n            You received an insurance offer from Liberty Mutual Insurance.\n        "
            )
          ]),
          _vm._v(" "),
          _c("quote-info", { attrs: { quote: _vm.quote } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "offerTab" }, slot: "offerTab" },
        [
          _c("coverage-details", {
            attrs: {
              coverages: _vm.quote.policyCoverage,
              title: "Policy Coverages"
            }
          }),
          _vm._v(" "),
          _vm._l(_vm.quote.vehicleCoverage, function(vehicleCoverage, key) {
            return _c("coverage-details", {
              key: key,
              attrs: {
                title: _vm.vehicleTitle(vehicleCoverage.vehicle),
                coverages: vehicleCoverage.coverages
              }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "detailsTab" }, slot: "detailsTab" },
        [
          _c("insurance-vehicle-panel", {
            attrs: { quote: _vm.quote, vehicle: _vm.vehicle }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "call-action" }, [
            _vm._v("\n            Call\n            "),
            _c("a", { attrs: { href: "tel:8887272146" } }, [
              _vm._v("\n                888-727-2146\n            ")
            ]),
            _vm._v("\n            to accept this offer.\n        ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "quote-id" }, [
            _vm._v("Quote ID: " + _vm._s(_vm.quote.quoteId))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "disclaimer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("p", [
            _vm._v(
              "\n            This example of some of the available coverages and limits is\n            not a contract, binder, or recommendation of coverage. All\n            coverages are subject to the term and conditions contained in\n            the policy and endorsements. Because the rate charged must be in\n            compliance with the Company's rules and rates, rate quotes are\n            subject to revision if different rates are effective at the time\n            of the policy insurance. This rate quote may be revised if any\n            of the information used for rating is changed. Coverage provided\n            and underwritten by Liberty Mutual Insurance and its affiliates,\n            175 Berkeley Street, Boston, MA, 02116 USA. Equal Housing\n            Insurer. (c) 2018 Liberty Mutual Insurance.\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.quote.disclaimer))])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }