var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      staticClass: "leaving-carsaver",
      attrs: { "hide-footer": true }
    },
    [
      _c(
        "div",
        { attrs: { slot: "modal-header-close" }, slot: "modal-header-close" },
        [
          _c(
            "i",
            {
              staticClass: "ion-ios-close-outline",
              attrs: { "aria-hidden": "true" },
              on: { click: _vm.hide }
            },
            [_vm._v("\n             \n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "px-3 text-center" }, [
        _c("h5", [_vm._v("You are leaving " + _vm._s(_vm.domain) + "!")]),
        _vm._v(" "),
        _c("p", { staticClass: "px-5" }, [
          _vm._v(
            "\n            CarSaver is not responsible for any content, terms or conditions\n            on this third-party website.\n        "
          )
        ]),
        _vm._v(" "),
        _vm.url
          ? _c(
              "div",
              { staticClass: "mt-5 text-center" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mx-auto",
                    attrs: {
                      variant: "primary",
                      target: "_blank",
                      rel: "noopener noreferrer"
                    },
                    on: { click: _vm.leave }
                  },
                  [_vm._v("\n                Continue\n            ")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }