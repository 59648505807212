<template>
    <b-modal
        id="carvana-modal"
        ref="modal"
        v-model="showModal"
        hide-footer
        @show="onShown"
    >
        <div slot="modal-header-close">
            <i aria-hidden="true" class="ion ion-close" @click="hide">
                &nbsp;
            </i>
        </div>

        <div class="text-center">
            <b-alert v-if="errorMessage !== null" show variant="danger">
                {{ errorMessage }}
            </b-alert>

            <div
                class="logo-block d-flex justify-content-center align-items-center"
            >
                <div class="carsaver-logo mx-2">
                    <img
                        :alt="carsaverLogo.altText"
                        :src="carsaverLogo.src"
                        width="150"
                        height="20.23"
                    />
                </div>
                <div class="vertical mx-3" />

                <div class="carvana-logo mx-2">
                    <img
                        :alt="carvanaLogo.altText"
                        :src="carvanaLogo.src"
                        width="150"
                    />
                </div>
            </div>

            <div class="d-md-none" style="margin-bottom: 32px;">
                <b-img
                    v-if="splashImage"
                    :src="splashImage"
                    :alt="vehicleName"
                    fluid
                    @error="imageUnavailable($event)"
                />
                <div
                    v-else-if="vehicle"
                    class="body-style-icon d-flex justify-content-center py-5"
                >
                    <body-style-icon :id="vehicle.bodyStyle" />
                </div>
            </div>

            <h3>Good News!</h3>

            <div class="msg-block d-flex flex-column px-2 px-md-0">
                <span class="main-span"
                    >Carvana has additional information available for this
                    vehicle.</span
                >
                <span class="sub-span"
                    >Enhanced Media, Online Financing options, Home Delivery
                    options and more…</span
                >
            </div>
            <div class="button-wrapper">
                <b-button
                    v-if="url !== null && campaignType !== 'kiosk'"
                    variant="orange"
                    target="_blank"
                    class="mx-auto"
                    rel="noopener noreferrer"
                    @click="go"
                >
                    Go
                </b-button>
                <b-button
                    v-else-if="
                        errorMessage === null && campaignType !== 'kiosk'
                    "
                    variant="orange"
                    class="mx-auto"
                    disabled
                >
                    <span
                        class="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                    />
                    Sending to Carvana...
                </b-button>
                <b-button
                    v-else
                    variant="primary"
                    class="mx-auto"
                    @click="hide"
                >
                    Close
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import _ from "lodash";
import api from "@/api";
import { get, sync } from "vuex-pathify";
import imageUnavailable from "@/mixins/imageUnavailable";

export default {
    name: "CarvanaModal",

    mixins: [imageUnavailable],

    data() {
        return {
            url: null,
            carsaverLogo: {
                src: require("./images/carsaver_logoLightBlue.svg"),
                altText: "CarSaver at Walmart"
            },
            carvanaLogo: {
                src: require("./images/carvana-logo.svg"),
                altText: "CARVANA"
            },
            interval: null,
            errorMessage: null
        };
    },

    computed: {
        campaignType: get("campaign/type"),
        leadId: get("dealerLink/leadId"),
        vehicle: get("dealerLink/vehicle"),
        splashImage: get("dealerLink/vehicle@imageUrl"),
        showModal: sync("dealerLink/carvanaModalOpen"),
        vehicleName() {
            return `${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model} ${this.vehicle.trim}`;
        }
    },

    beforeDestroy() {
        if (!_.isNil(this.interval)) {
            clearInterval(this.interval);
        }
    },

    methods: {
        show() {
            this.showModal = true;
        },

        onShown() {
            this.interval = setInterval(this.fetchLeadTransaction, 1000);
        },
        go() {
            this.hide();

            window.open(this.url, "_blank").opener = null;
        },

        hide() {
            this.showModal = false;

            if (!_.isNil(this.interval)) {
                clearInterval(this.interval);
            }
        },

        fetchLeadTransaction() {
            if (_.isNil(this.leadId)) {
                return;
            }

            api.get(`/leads/${this.leadId}/transaction`)
                .then(response => {
                    if (_.get(response, "status") === 200) {
                        clearInterval(this.interval);

                        this.url = _.get(response, "data");
                    }
                })
                .catch(error => {
                    clearInterval(this.interval);

                    this.errorMessage =
                        "Unable to send lead to Carvana.  Please contact support.";
                    console.error(error);
                });
        }
    }
};
</script>
<style lang="scss">
#carvana-modal {
    .modal-header {
        .close {
            padding: 0 15px 0 0 !important;

            i {
                font-size: 20px !important;
                color: #888 !important;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .modal-body {
            padding: 0 !important;

            .logo-block {
                .carsaver-logo {
                    img {
                        width: 130px !important;
                    }
                }
                .carvana-logo {
                    img {
                        width: 130px !important;
                    }
                }
            }
        }
    }
    .modal-body {
        padding-top: 0;

        .logo-block {
            height: 40px;
            margin-bottom: 32px;
            .carsaver-logo {
                img {
                    width: 150px;
                    height: 20.23px;
                }
            }

            .vertical {
                border-left: 1px solid #dddddd;
                height: 32px;
            }

            .carvana-logo {
                img {
                    width: 150px;
                }
            }
        }

        h3 {
            font-family: $helvetica-45-light;
            font-size: 24px;
            color: $primary-blue;
            line-height: 29px;
            margin-bottom: 16px;
        }

        .msg-block {
            text-align: center;

            .main-span {
                font-family: $helvetica-55-Roman;
                color: #888888;
                font-size: 12px;
            }

            .sub-span {
                color: #444444;
                font-size: 12px;
                font-family: $helvetica-65-medium;
            }
        }

        .button-wrapper {
            margin-top: 16px;
            margin-bottom: 28px;

            button {
                height: 40px !important;
                width: 206px !important;
            }
        }
    }
}
</style>
