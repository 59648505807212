<template>
    <div class="price-block">
        <div v-if="type" class="type">
            {{ type }}
        </div>
        <div :class="{ 'black-text': paymentIsHigher }" class="currency">
            $
        </div>
        <div :class="{ 'black-text': paymentIsHigher }" class="value">
            {{ payment | abs | round }}
        </div>
        <div class="per-month">
            /mo
        </div>
    </div>
</template>
<script>
export default {
    name: "PriceBlock",

    props: {
        type: {
            type: String,
            required: false,
            default: null
        },
        payment: {
            type: Number,
            required: true
        },
        paymentIsHigher: {
            type: Boolean,
            required: false,
            defaultValue: false
        }
    }
};
</script>

<style lang="scss" scoped>
.price-block {
    display: flex;
    flex-direction: row;
    font-style: italic;

    .type {
        display: flex;
        align-items: flex-end;
        color: $muted-dark-gray;
        font-size: 12px;
        margin-right: 5px;
        padding-bottom: 4px;
    }

    .currency {
        color: $primary-blue;
        font-weight: bold;
        line-height: 18px;
        padding-top: 6px;
    }

    .value {
        color: $primary-blue;
        font-size: 36px;
        font-weight: bold;
        line-height: 42px;
        font-family: "Times New Roman", sans-serif;
    }

    .per-month {
        color: $muted-dark-gray;
        padding-bottom: 6px;
    }
    .black-text {
        color: $dark-gray;
    }
}
</style>
