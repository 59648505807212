<template>
    <detail-block :title="$t('component.tradeIn.title')" name="tradeIn">
        <div id="trade-in-card" class="card-body pb-4">
            <b-row>
                <div
                    class="col-12 col-md-2 mb-3 mb-md-0 d-flex justify-content-center"
                >
                    <i
                        aria-hidden="true"
                        class="icon icon-bumper_to_bumper p-3"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex flex-column">
                        <span class="text font-weight-bold">
                            {{ $t("component.tradeIn.text") }}
                        </span>
                        <span class="sub-text">
                            {{ $t("component.tradeIn.subText") }}
                        </span>
                    </div>
                </div>
                <div
                    class="col-12 col-md-4 d-flex justify-content-center align-items-center button-block py-3 py-md-0"
                >
                    <b-button variant="primary" @click="gotoTradeIn">
                        {{ $t("component.tradeIn.buttonText") }}
                    </b-button>
                </div>
            </b-row>
        </div>
        <sign-in-required-modal ref="signInRequiredModal" />
    </detail-block>
</template>

<script>
import DetailBlock from "Modules/Vehicle/DetailBlock";
import { get, dispatch } from "vuex-pathify";
import SignInRequiredModal from "Components/SignInRequiredModal";
import _ from "lodash";

export default {
    name: "TradeIn",
    components: {
        DetailBlock,
        "sign-in-required-modal": SignInRequiredModal
    },
    computed: {
        isSignedIn: get("user/isSignedIn"),
        certificate: get("vehicleDetails/certificate"),
        dealer: get("vehicleDetails/dealer"),
        certificateId() {
            return _.get(this.certificate, "id", null);
        }
    },
    methods: {
        gotoTradeIn() {
            if (!this.isSignedIn) {
                this.showSignInRequiredModal();
            } else if (!this.dealer) {
                dispatch("dealerLink/openModal", this.certificateId);
            } else {
                window.location = `/certificate/${this.certificateId}/tradein`;
            }
        },
        showSignInRequiredModal() {
            this.$refs.signInRequiredModal.show();
        }
    }
};
</script>
<style lang="scss">
#trade-in-card {
    .icon-bumper_to_bumper {
        font-size: px2rem(70);
        color: $primary-blue;
    }
    .text {
        font-size: px2rem(16);
    }
    .btn-primary {
        height: 40px;
        width: 200px;
    }
}
</style>
