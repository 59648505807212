<template>
    <div id="cashTab" class="w-100">
        <div class="container-fluid">
            <div class="block border-bottom py-1">
                <div class="d-flex justify-content-between line-item">
                    <span class="label">
                        {{ $t("component.pricingCard.retailPrice") }}
                    </span>
                    <span class="value">
                        {{ retailPrice | emptyDash | numeral("$0,0") }}
                    </span>
                </div>
                <div
                    v-if="vehicleIsNew"
                    class="d-flex justify-content-between line-item"
                >
                    <span class="label">
                        {{ $t("component.pricingCard.rebate") }}
                    </span>
                    <div class="d-flex value">
                        <span v-if="pricing.rebatesValue > 0">
                            -
                        </span>
                        {{
                            pricing
                                | emptyDash("rebatesValue")
                                | numeral("$0,0")
                        }}
                    </div>
                </div>
                <div
                    v-if="hasDealerDiscount"
                    class="d-flex justify-content-between line-item"
                >
                    <span class="label">
                        {{ $t("component.pricingCard.discount") }}
                    </span>
                    <span class="value">
                        - {{ dealerDiscount | emptyDash | numeral("$0,0") }}
                    </span>
                </div>
                <div
                    v-if="vehicle.warrantyEligible"
                    class="d-flex justify-content-between line-item"
                >
                    <div class="d-flex align-items-center">
                        <b-link
                            class="label mr-1"
                            @click="showLifetimeWarrantyModal()"
                        >
                            {{ $t("component.pricingCard.lifetimeWarranty") }}
                        </b-link>
                        <lifetime-warranty-tooltip-disclaimer />
                    </div>
                    <span class="value">
                        {{ $t("component.pricingCard.included") }}
                    </span>
                </div>
            </div>
            <div class="block border-bottom py-1">
                <div
                    class="d-flex justify-content-between align-items-center line-item"
                >
                    <div class="sale-label font-weight-bold">
                        {{ $t("component.pricingCard.salePrice") }}
                        <tooltip-disclaimer
                            v-if="salePriceDisclaimer !== null"
                            :title="salePriceDisclaimer"
                        />
                        <br />
                        <small class="font-italic excludes-taxes">
                            {{
                                $t("component.pricingCard.excludesTaxesAndFees")
                            }}
                        </small>
                    </div>
                    <div
                        v-if="showRestrictedMake"
                        class="restricted-make-price"
                    >
                        {{ $t("component.pricingCard.seeEmail") }}
                    </div>
                    <dealer-provided-price
                        v-else-if="showDealerProvidedPrice"
                    />
                    <div v-else class="blue-value">
                        {{
                            pricing
                                | emptyDash("salePrice.value")
                                | numeral("$0,0")
                        }}
                    </div>
                </div>
            </div>
            <div v-if="hasFinanceTaxes" class="block border-bottom py-1">
                <div class="d-flex justify-content-between line-item">
                    <span class="label">
                        {{ $t("component.pricingCard.tax") }}
                    </span>
                    <span class="value">
                        {{
                            taxes
                                | emptyDash("finance.salesTax")
                                | numeral("+ $0,0")
                        }}
                    </span>
                </div>
                <div class="d-flex justify-content-between line-item">
                    <span class="label">
                        {{ $t("component.pricingCard.licenseRegistration") }}
                    </span>
                    <span class="value">
                        {{
                            taxes
                                | emptyDash("finance.titleLicenseFees")
                                | numeral("+ $0,0")
                        }}
                    </span>
                </div>
                <div class="d-flex justify-content-between line-item">
                    <span class="label">
                        {{ $t("component.pricingCard.dealerFee") }}
                    </span>
                    <span class="value">
                        {{
                            pricing
                                | emptyDash("dealerFees")
                                | numeral("+ $0,0")
                        }}
                    </span>
                </div>
            </div>
            <div v-if="hasFinanceTaxes" class="block py-1">
                <div class="d-flex justify-content-between line-item">
                    <span class="label">
                        {{ $t("component.pricingCard.total") }}
                    </span>
                    <span class="value font-weight-bold">
                        {{ totalWithTaxes | numeral("$0,0") }}
                    </span>
                </div>
            </div>
            <div v-else class="block py-1 text-center taxes-link">
                <loading-spinner v-if="taxesLoading" :size="30" />
                <b-link v-else @click="loadFinanceTaxes">
                    View with Taxes &amp; Fees
                </b-link>
            </div>
        </div>
    </div>
</template>
<script>
import TooltipDisclaimer from "Components/TooltipDisclaimer";
import DealerProvidedPrice from "../../DealerProvidedPrice";
import _ from "lodash";
import { get } from "vuex-pathify";
import EventBus from "@/event-bus";
import taxesAndFeesMixin from "../../../mixins/taxesAndFees";
import LoadingSpinner from "Components/LoadingSpinner/index";
import LifetimeWarrantyTooltipDisclaimer from "Components/LifetimeWarrantyTooltipDisclaimer";

export default {
    name: "Cash",
    components: {LifetimeWarrantyTooltipDisclaimer, LoadingSpinner, TooltipDisclaimer, DealerProvidedPrice },
    mixins: [taxesAndFeesMixin],
    computed: {
        pricing: get("vehicleDetails/pricing"),
        vehicle: get("vehicleDetails/vehicle"),
        stockType: get("vehicleDetails/inventoryVehicle@stockType"),
        isUserSignedIn: get("user/isSignedIn"),
        restrictedMake: get("vehicleDetails/vehicle@restrictedMake"),
        dealerLinkExists: get("dealerLink/active"),
        showRestrictedMake() {
            return this.restrictedMake && this.dealerLinkExists;
        },
        showDealerProvidedPrice() {
            return (
                _.isNil(_.get(this.pricing, "salePrice.value")) &&
                this.dealerLinkExists
            );
        },
        vehicleIsNew() {
            return _.lowerCase(this.stockType) !== "used";
        },
        retailPrice() {
            return this.vehicleIsNew
                ? _.get(this.pricing, "msrp")
                : _.get(this.pricing, "dealerPrice.value");
        },
        dealerDiscount() {
            const salePrice = _.get(this.pricing, "salePrice.value", 0);
            return this.vehicleIsNew
                ? _.get(this.pricing, "dealerDiscount")
                : this.retailPrice - salePrice;
        },
        hasDealerDiscount() {
            return !_.isNil(this.dealerDiscount) && this.dealerDiscount > 0;
        },
        salePriceDisclaimer() {
            return _.get(this.pricing, "salePrice.disclaimer", null);
        },
        totalWithTaxes() {
            const salePrice = _.get(this.pricing, "salePrice.value", 0);
            const taxesAndFees = _.get(this.taxes, "finance.feeTotal", 0);
            const dealerFees = _.get(this.pricing, "dealerFees", 0);

            return salePrice + taxesAndFees + dealerFees;
        }
    },
    methods: {
        showLifetimeWarrantyModal() {
            EventBus.$emit("lifetime-warranty-modal-open");
        }
    }
};
</script>
<style lang="scss">
#cashTab {
    .excludes-taxes {
        font-size: 10px;
        color: $muted-dark-gray;
        line-height: 5px;
    }

    .taxes-link {
        .half-circle-spinner {
            margin: 0 auto;
        }
    }

    .container-fluid {
        padding: 0 20px;

        .line-item {
            color: $dark-gray;
            padding: 2px 0;

            .label {
                font-size: px2rem(12);
                color: $dark-gray;
            }
            .sale-label {
                font-weight: bold;
                font-size: 16px;
                line-height: 12px;
                color: $dark-gray;
            }
            .value {
                font-size: px2rem(12);
                color: $muted-dark-gray;
                line-height: 15px;
            }

            .blue-value {
                font-size: px2rem(26);
                font-weight: bold;
                font-style: italic;
                font-family: "Times New Roman", sans-serif;
                line-height: 30px;
                color: $primary-blue;
            }
        }
    }
}
</style>
