<template>
    <tooltip-disclaimer
        id="ltw-tooltip-disclaimer"
        :title="lifetimeWarrantyDisclaimer"
    />
</template>

<script>
import TooltipDisclaimer from "Components/TooltipDisclaimer";

export default {
    name: "LifetimeWarrantyTooltipDisclaimer",
    components: {
        TooltipDisclaimer
    },
    computed: {
        lifetimeWarrantyDisclaimer() {
            return this.$t('disclaimer.warranty');
        }
    }
}
</script>

<style lang="scss">
    #ltw-tooltip-disclaimer {
        color: $muted-dark-gray;
    }
</style>
