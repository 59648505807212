<template>
    <div
        class="section-box offer-info d-flex justify-content-around align-items-center"
    >
        <div class="logo">
            <img :src="carrierLogo" :alt="quote.carrier.name" />
        </div>

        <div class="section-item">
            <div class="value">
                {{ quote.paymentPlan.avgMonthlyPayment | numeral("$0,0.00") }}
            </div>
            <div class="label">
                Avg Monthly Payment
            </div>
        </div>

        <div class="section-item">
            <div class="value">
                {{ quote.paymentPlan.term | numeral("$0,0.00") }}
            </div>
            <div class="label">
                Month
            </div>
        </div>

        <div class="section-item">
            <div class="value">
                {{ quote.paymentPlan.premium | numeral("$0,0.00") }}
            </div>
            <div class="label">
                Premium
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.section-box {
    border: 1px solid $muted-gray;
    border-radius: 2px;
    background-color: $very-light-gray;
    min-height: 70px;
    padding: 5px;
    margin-top: 10px;

    .logo {
        > img {
            max-width: 100%;
            max-height: 50px;
        }
    }

    .section-item {
        text-align: center;

        .value {
            font-weight: bold;
        }
    }
}
</style>

<script>
import _ from "lodash";

export default {
    name: "QuoteInfo",

    props: {
        quote: {
            type: Object,
            required: true
        }
    },

    computed: {
        carrierLogo() {
            const carrierId = _.get(this.quote, "carrier.id", null);

            return _.isNil(carrierId)
                ? null
                : `/assets/images/insure/${carrierId}.png`;
        }
    }
};
</script>
