<template>
    <c-s-modal ref="modal" class="restricted-make-no-email-modal">
        <div slot="modal-title">
            {{ $t("component.pricingCard.noEmail") }}
        </div>

        <b-container fluid>
            <p>
                <strong>{{
                    $t("component.pricingCard.sorryYourHavingTrouble")
                }}</strong>
            </p>

            <ol>
                <li>
                    {{ $t("component.pricingCard.verifyYourContactInfo")
                    }}<br />
                    <email-updater />
                </li>
                <li>{{ $t("component.pricingCard.junkMail") }}</li>
                <li>{{ $t("component.pricingCard.clickTheResendButton") }}</li>
                <li>{{ $t("component.pricingCard.callCarSaverDealer") }}</li>
            </ol>

            <div
                class="questions d-flex flex-column flex-nowrap align-items-center justify-content-between"
            >
                <i
                    aria-hidden="true"
                    class="ion-android-call d-none d-md-inline"
                />
                <div class="phone-row text-center">
                    <strong>{{
                        $t("component.pricingCard.stillHaveQuestions")
                    }}</strong>
                    {{ $t("component.pricingCard.giveUsACall") }}
                    {{ phoneNumber }}.
                </div>
                <div class="note-row text-center">
                    {{ $t("component.pricingCard.note") }}
                </div>
            </div>
        </b-container>

        <div slot="modal-footer" class="w-100 d-flex justify-content-center">
            <b-button variant="primary" class="resend-btn" @click="resendEmail">
                {{ $t("component.pricingCard.resend") }}
            </b-button>
        </div>
    </c-s-modal>
</template>

<style lang="scss">
.restricted-make-no-email-modal {
    .questions {
        padding: 10px 0;
        border: 1px solid $muted-gray;
        background-color: $very-light-gray;

        > .phone-row {
            line-height: 20px;
        }

        .note-row {
            display: inline-block;
            font-style: italic;
            line-height: 20px;
            color: $muted-dark-gray;
        }

        > i {
            position: absolute;
            left: 55px;
            color: $primary-blue;
            font-size: 36px;
            line-height: 40px;
        }
    }

    .resend-btn {
        width: 200px;
    }
}
</style>

<script>
import api from "@/api";
import CSModal from "Components/CSModal";
import EmailUpdater from "./EmailUpdater";
import { get } from "vuex-pathify";

export default {
    components: { EmailUpdater, CSModal },
    props: {
        certificateId: {
            required: true,
            type: Number
        }
    },
    computed: {
        phoneNumber: get("campaign/phoneNumberFormatted")
    },
    methods: {
        show() {
            this.$refs.modal.show();
        },
        resendEmail() {
            api.post(`/certificates/${this.certificateId}/vehicle-price`)
                .catch(error => {
                    console.error(error);
                });
            this.$refs.modal.hide();
        }
    }
};
</script>
