import Vue from "vue";
import api from "../../api";
import _ from "lodash";
import { make } from "vuex-pathify";

const initialState = {
    best: [],
    bestDealsPricingCard: [],
    all: [],
    loadingStep: 0,
    changingDownPayment: false,
    taxesLoading: false
};

const getters = {
    ...make.getters(initialState),
    bestLeaseDeal: state => {
        if (!_.isNil(state.best) && state.best.length > 0) {
            return _.find(state.best, ["quote.type", "Closed End"]);
        }

        return null;
    },
    bestFinanceDeal: state => {
        if (!_.isNil(state.best) && state.best.length > 0) {
            return _.find(state.best, ["quote.type", "Loan"]);
        }

        return null;
    }
};

const mutations = {
    ...make.mutations(initialState),

    SET_QUOTE_WITH_TAXES(state, payload) {
        const quoteWithTaxes = payload;

        // Find if the request was for a best quote
        const matchingBestQuoteIndex = _.findIndex(state.best, deal => {
            return (
                deal.quote.term === quoteWithTaxes.quote.term &&
                deal.quote.interestRate === quoteWithTaxes.quote.interestRate &&
                deal.quote.lenderName === quoteWithTaxes.quote.lenderName &&
                deal.quote.type === quoteWithTaxes.quote.type
            );
        });
        if (matchingBestQuoteIndex !== -1) {
            const newQuoteObject = state.best[matchingBestQuoteIndex];
            Vue.set(newQuoteObject, "quoteWithTaxes", quoteWithTaxes);
            Vue.set(state.best, matchingBestQuoteIndex, newQuoteObject);
        }

        // Find in all quotes
        const matchingAllQuoteIndex = _.findIndex(state.all, deal => {
            return (
                deal.quote.term === quoteWithTaxes.quote.term &&
                deal.quote.interestRate === quoteWithTaxes.quote.interestRate &&
                deal.quote.lenderName === quoteWithTaxes.quote.lenderName &&
                deal.quote.type === quoteWithTaxes.quote.type
            );
        });
        if (matchingAllQuoteIndex !== -1) {
            const newQuoteObject = state.all[matchingAllQuoteIndex];
            Vue.set(newQuoteObject, "quoteWithTaxes", quoteWithTaxes);
            Vue.set(state.all, matchingAllQuoteIndex, newQuoteObject);
        }

        state.taxesLoading = false;
    }
};

const actions = {
    ...make.actions(initialState),

    fetchQuoteTaxes: ({ commit }, { certificateId, quote }) => {
        commit("SET_TAXES_LOADING", true);

        return api
            .post(
                `/certificates/${certificateId}/payment-matrix/with-taxes`,
                quote
            )
            .then(response => {
                const quoteWithTaxes = response.data;
                commit("SET_QUOTE_WITH_TAXES", quoteWithTaxes);

                return response.data;
            })
            .catch(() => {
                commit("SET_TAXES_LOADING", false);
            });
    },

    loadBest: ({ commit }, { certificateId }) => {
        if (_.isNil(certificateId)) {
            return;
        }

        api.get(
            `/certificates/${certificateId}/payment-matrix/best`,
            {},
            { ignore404: true }
        )
            .then(response => {
                const quotes = _.get(response, "data.quotes", []);
                const bestQuotes = _.chain(quotes)
                    .sortBy(["quote.monthlyPayment"])
                    .map(q => {
                        return {
                            ...q,
                            quoteWithTaxes: null
                        };
                    })
                    .value();
                commit("SET_BEST", bestQuotes);
                commit("SET_BEST_DEALS_PRICING_CARD", bestQuotes);
                commit("SET_LOADING_STEP", 1);
            })
            .catch(error => {
                console.error(error);
                const statusCode = _.get(error, "response.status");
                if (statusCode !== 404) {
                    throw error;
                }
            });
    },

    loadAll: ({ commit }, { certificateId }) => {
        if (_.isNil(certificateId)) {
            return;
        }

        api.get(
            `/certificates/${certificateId}/payment-matrix`,
            {},
            { ignore404: true }
        )
            .then(response => {
                const quotes = _.get(response, "data.quotes", []);
                const allQuotes = _.chain(quotes)
                    .sortBy(["quote.monthlyPayment"])
                    .map(q => {
                        return {
                            ...q,
                            quoteWithTaxes: null
                        };
                    })
                    .value();
                commit("SET_ALL", allQuotes);
                commit("SET_LOADING_STEP", 2);
            })
            .catch(error => {
                console.error(error);
                const statusCode = _.get(error, "response.status");
                if (statusCode !== 404) {
                    throw error;
                }
            });
    },

    changeDownPayment: ({ commit }, { certificateId, downPayment }) => {
        commit("SET_CHANGING_DOWN_PAYMENT", true);

        api.get(
            `/certificates/${certificateId}/payment-matrix`,
            { downPayment },
            { ignore404: true }
        )
            .then(response => {
                const quotes = _.get(response, "data.quotes", []);
                if (quotes.length === 0) {
                    commit("SET_CHANGING_DOWN_PAYMENT", false);
                    return;
                }

                const allQuotes = _.sortBy(quotes, ["quote.monthlyPayment"]);

                const bestQuotes = [];
                const bestFinance = _.chain(allQuotes)
                    .filter(["quote.type", "Loan"])
                    .minBy("quote.monthlyPayment")
                    .value();

                if (!_.isNil(bestFinance)) {
                    bestQuotes.push(bestFinance);
                }

                const bestLease = _.chain(allQuotes)
                    .filter(["quote.type", "Closed End"])
                    .minBy("quote.monthlyPayment")
                    .value();

                if (!_.isNil(bestLease)) {
                    bestQuotes.push(bestLease);
                }

                commit("SET_BEST", bestQuotes);
                commit("SET_ALL", allQuotes);
                commit("SET_CHANGING_DOWN_PAYMENT", false);
            })
            .catch(error => {
                console.error(error);
                const statusCode = _.get(error, "response.status");
                if (statusCode !== 404) {
                    throw error;
                }
            });
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    getters,
    actions
};
