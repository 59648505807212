var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { id: "carvana-modal", "hide-footer": "" },
      on: { show: _vm.onShown },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "div",
        { attrs: { slot: "modal-header-close" }, slot: "modal-header-close" },
        [
          _c(
            "i",
            {
              staticClass: "ion ion-close",
              attrs: { "aria-hidden": "true" },
              on: { click: _vm.hide }
            },
            [_vm._v("\n             \n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _vm.errorMessage !== null
            ? _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.errorMessage) + "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "logo-block d-flex justify-content-center align-items-center"
            },
            [
              _c("div", { staticClass: "carsaver-logo mx-2" }, [
                _c("img", {
                  attrs: {
                    alt: _vm.carsaverLogo.altText,
                    src: _vm.carsaverLogo.src,
                    width: "150",
                    height: "20.23"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vertical mx-3" }),
              _vm._v(" "),
              _c("div", { staticClass: "carvana-logo mx-2" }, [
                _c("img", {
                  attrs: {
                    alt: _vm.carvanaLogo.altText,
                    src: _vm.carvanaLogo.src,
                    width: "150"
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-md-none",
              staticStyle: { "margin-bottom": "32px" }
            },
            [
              _vm.splashImage
                ? _c("b-img", {
                    attrs: {
                      src: _vm.splashImage,
                      alt: _vm.vehicleName,
                      fluid: ""
                    },
                    on: {
                      error: function($event) {
                        return _vm.imageUnavailable($event)
                      }
                    }
                  })
                : _vm.vehicle
                ? _c(
                    "div",
                    {
                      staticClass:
                        "body-style-icon d-flex justify-content-center py-5"
                    },
                    [
                      _c("body-style-icon", {
                        attrs: { id: _vm.vehicle.bodyStyle }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", [_vm._v("Good News!")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "msg-block d-flex flex-column px-2 px-md-0" },
            [
              _c("span", { staticClass: "main-span" }, [
                _vm._v(
                  "Carvana has additional information available for this\n                vehicle."
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "sub-span" }, [
                _vm._v(
                  "Enhanced Media, Online Financing options, Home Delivery\n                options and more…"
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button-wrapper" },
            [
              _vm.url !== null && _vm.campaignType !== "kiosk"
                ? _c(
                    "b-button",
                    {
                      staticClass: "mx-auto",
                      attrs: {
                        variant: "orange",
                        target: "_blank",
                        rel: "noopener noreferrer"
                      },
                      on: { click: _vm.go }
                    },
                    [_vm._v("\n                Go\n            ")]
                  )
                : _vm.errorMessage === null && _vm.campaignType !== "kiosk"
                ? _c(
                    "b-button",
                    {
                      staticClass: "mx-auto",
                      attrs: { variant: "orange", disabled: "" }
                    },
                    [
                      _c("span", {
                        staticClass: "spinner-border spinner-border-sm mr-2",
                        attrs: { role: "status", "aria-hidden": "true" }
                      }),
                      _vm._v(
                        "\n                Sending to Carvana...\n            "
                      )
                    ]
                  )
                : _c(
                    "b-button",
                    {
                      staticClass: "mx-auto",
                      attrs: { variant: "primary" },
                      on: { click: _vm.hide }
                    },
                    [_vm._v("\n                Close\n            ")]
                  )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }