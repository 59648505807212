<template>
    <div
        id="price"
        class="tab-pane active pb-3"
        role="tabpanel"
        aria-labelledby="price-tab"
    >
        <div class="container-fluid">
            <b-row class="mb-2">
                <b-col
                    cols="12"
                    class="d-flex justify-content-between align-items-center line-item"
                >
                    <div class="d-flex flex-column">
                        <span v-if="canUnlockPricing && isUsed">
                            <span class="title blue font-weight-bold">
                                {{ $t("component.pricingCard.salePrice") }}
                                <tooltip-disclaimer
                                    v-if="salePriceDisclaimer !== null"
                                    :title="salePriceDisclaimer"
                                />
                            </span>
                            <small class="font-italic d-block">
                                {{
                                    $t(
                                        "component.pricingCard.excludesTaxesAndFees"
                                    )
                                }}
                            </small>
                        </span>
                        <span v-else-if="isNew" class="title font-weight-bold">
                            {{ $t("component.pricingCard.msrp") }}
                            <tooltip-disclaimer
                                :title="retailPriceDisclaimer"
                            />
                        </span>
                        <span v-else class="title font-weight-bold">
                            {{ $t("component.pricingCard.retailPrice") }}
                            <tooltip-disclaimer
                                :title="retailPriceDisclaimer"
                            />
                        </span>
                    </div>

                    <span v-if="canUnlockPricing && isUsed" class="value blue">
                        {{
                            pricing
                                | emptyDash("salePrice.value")
                                | numeral("$0,0")
                        }}
                    </span>
                    <span
                        v-else-if="
                            (isUsed && !canUnlockPricing) ||
                                isNew ||
                                isConfigured
                        "
                        class="value"
                    >
                        {{ retailPrice | emptyDash | numeral("$0,0") }}
                    </span>
                </b-col>
            </b-row>

            <b-row v-if="shouldAverageMarketPriceShow" class="mb-2">
                <b-col
                    cols="12"
                    class="d-flex justify-content-between align-items-center line-item"
                >
                    <span class="title font-weight-bold">
                        {{ $t("component.pricingCard.marketAverage") }}
                        <tooltip-disclaimer
                            v-if="averageMarketPrice"
                            :title="averageMarketPrice.disclaimer"
                        />
                    </span>
                    <span class="value">
                        {{ averageMarketPrice.value | numeral("$0,0") }}
                    </span>
                </b-col>
            </b-row>

            <b-row v-if="!certifiedDealer && !isConfigured">
                <b-col
                    cols="12"
                    class="mt-2 d-flex justify-content-between align-items-center line-item"
                >
                    <span class="title font-weight-bold">
                        {{ $t("component.pricingCard.dealerPrice") }}
                    </span>
                    <b-link class="font-italic" @click="contactDealer">
                        {{ $t("component.pricingCard.contactDealer") }}
                    </b-link>
                </b-col>
            </b-row>

            <b-row
                v-if="
                    (showLifetimeWarranty && !isUsed) ||
                        (showLifetimeWarranty && !canUnlockPricing && !isNew)
                "
                class="mb-2"
            >
                <b-col
                    cols="12"
                    class="d-flex justify-content-between align-items-center line-item"
                >
                    <span class="title font-weight-bold mr-1">
                        {{ $t("component.pricingCard.lifetimeWarranty") }}
                        <lifetime-warranty-tooltip-disclaimer />
                    </span>
                    <div class="d-flex align-items-center">
                        <b-link
                            class="warranty-value font-italic"
                            @click="showLifetimeWarrantyModal()"
                        >
                            {{ $t("component.pricingCard.included") }}
                        </b-link>
                    </div>
                </b-col>
            </b-row>

            <b-row v-if="isCertifiedDealer && isNew" class="mt-3 mb-2">
                <b-col
                    cols="12"
                    class="d-flex justify-content-between align-items-center line-item"
                >
                    <div class="d-flex flex-column">
                        <span class="title font-weight-bold">
                            {{ $t("component.pricingCard.salePrice") }}
                            <tooltip-disclaimer
                                v-if="salePriceDisclaimer !== null"
                                :title="salePriceDisclaimer"
                            />
                        </span>
                        <small v-if="canUnlockPricing" class="font-italic">
                            {{
                                $t("component.pricingCard.excludesTaxesAndFees")
                            }}
                        </small>
                    </div>
                    <div
                        v-if="
                            canUnlockPricing &&
                                (!showRestrictedMake || sentPriceByEmail)
                        "
                    >
                        <div
                            v-if="showRestrictedMake"
                            class="restricted-make-price"
                        >
                            {{ $t("component.pricingCard.seeEmail") }}
                        </div>
                        <dealer-provided-price
                            v-else-if="showDealerProvidedPrice"
                        />
                        <div v-else class="blue-value">
                            {{
                                pricing
                                    | emptyDash("salePrice.value")
                                    | numeral("$0,0")
                            }}
                        </div>
                    </div>
                    <div v-else>
                        <b-link
                            v-if="isUserSignedIn"
                            @click="requestDealerLink"
                        >
                            {{ $t("component.pricingCard.getSalePrice") }}
                        </b-link>
                        <b-link
                            v-else
                            v-href-return-url="{ modal: 'dealer-link' }"
                            :href="signUpHref"
                        >
                            {{ $t("component.pricingCard.getSalePrice") }}
                        </b-link>
                    </div>
                </b-col>
            </b-row>
            <b-row
                v-else-if="
                    !showSupplierPricing &&
                        isCertifiedDealer &&
                        canUnlockPricing &&
                        hasIncentives
                "
            >
                <b-col>
                    <div class="text-center additional-incentives mt-3">
                        Additional Incentives
                    </div>

                    <div class="incentives-box p-3">
                        <b-row
                            v-for="(incentive,
                            key) in pricing.purchaseIncentives"
                            :key="key"
                        >
                            <b-col cols="8">
                                {{ incentive.name }}
                                <small v-if="incentive.providedBy"
                                    >from {{ incentive.providedBy }}</small
                                >
                            </b-col>
                            <b-col
                                v-if="incentive.cashValue"
                                cols="4"
                                class="text-right value"
                            >
                                {{ incentive.cashValue | numeral("$0,0") }}
                            </b-col>
                            <b-col v-else cols="4" class="text-right value">
                                included
                            </b-col>
                        </b-row>
                        <b-row v-if="showLifetimeWarranty">
                            <b-col cols="8">
                                Lifetime Warranty
                                <small>from CarSaver</small>
                            </b-col>
                            <b-col cols="4" class="text-right value">
                                included
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
            <b-row v-else-if="canUnlockPricing && isUsed && isCertifiedDealer">
                <b-col>
                    <div class="text-center additional-incentives mt-3">
                        Valid only at:
                    </div>

                    <div class="certified-box px-3 py-2 text-center">
                        <strong class="blue">{{ dealerName }}</strong>
                        <br />
                        Please ask for your dedicated CarSaver Program Manager
                        when you visit this dealership.
                    </div>
                </b-col>
            </b-row>
        </div>
        <get-supplier-pricing-block v-if="showSupplierPricing" />
        <get-sales-price-block v-if="isCertifiedDealer" />
        <contact-dealer-block v-else />
        <search-local-listings v-if="isConfigured" />
    </div>
</template>
<script>
import TooltipDisclaimer from "Components/TooltipDisclaimer";
import URL from "url-parse";
import { get, call, sync } from "vuex-pathify";
import _ from "lodash";
import EventBus from "@/event-bus";
import GetSalesPriceBlock from "./GetSalesPriceBlock";
import DealerProvidedPrice from "./DealerProvidedPrice";
import GetSupplierPricingBlock from "./GetSupplierPricingBlock";
import ContactDealerBlock from "./ContactDealerBlock";
import SearchLocalListings from "./SearchLocalListings";
import LifetimeWarrantyTooltipDisclaimer from "Components/LifetimeWarrantyTooltipDisclaimer";

export default {
    name: "PriceTab",
    components: {
        LifetimeWarrantyTooltipDisclaimer,
        SearchLocalListings,
        ContactDealerBlock,
        GetSupplierPricingBlock,
        GetSalesPriceBlock,
        TooltipDisclaimer,
        DealerProvidedPrice
    },
    data() {
        return {
            signUpHref: "/sign-up",
            showGetSalePrice: false
        };
    },
    computed: {
        pricing: get("vehicleDetails/pricing"),
        vehicle: get("vehicleDetails/vehicle"),
        inventoryVehicle: get("vehicleDetails/inventoryVehicle"),
        isUserSignedIn: get("user/isSignedIn"),
        restrictedMake: get("vehicleDetails/vehicle@restrictedMake"),
        canUnlockPricing: get("dealerLink/canUnlockPricing"),
        certificateId: get("vehicleDetails/certificate@id"),
        certificateShownPricing: get("vehicleDetails/certificate@shownPricing"),
        sentPriceByEmail: get("vehicleDetails/certificate@sentPriceByEmail"),
        showLifetimeWarranty: get("vehicleDetails/vehicle@warrantyEligible"),
        certifiedDealer: get("vehicleDetails/dealer@certified"),
        dealerName: get("vehicleDetails/dealer@name"),
        dealer: get("vehicleDetails/dealer"),
        vin: sync("vehicleDetails/inventoryVehicle@vin"),

        hasIncentives() {
            return (
                (this.pricing.purchaseIncentives != null &&
                    this.pricing.purchaseIncentives.length > 0) ||
                this.showLifetimeWarranty
            );
        },

        shouldAverageMarketPriceShow() {
            const ampValue = _.get(
                this.pricing,
                "averageMarketPrice.value",
                null
            );
            const isNew = _.get(this.inventoryVehicle, "stockType") !== "used";
            const dealerIsCertified = this.certifiedDealer;
            return !!(ampValue && isNew && dealerIsCertified);
        },
        averageMarketPrice() {
            return _.get(this.pricing, "averageMarketPrice", null);
        },
        isCertifiedDealer() {
            return this.certifiedDealer || this.isConfigured;
        },
        showSupplierPricing() {
            return _.get(this.pricing, "supplierPricingAvailable", false);
        },
        showRestrictedMake() {
            return this.restrictedMake && this.canUnlockPricing;
        },

        showDealerProvidedPrice() {
            return (
                _.isNil(_.get(this.pricing, "salePrice.value")) &&
                this.canUnlockPricing
            );
        },

        isConfigured() {
            return _.isNil(this.inventoryVehicle);
        },

        isNew() {
            return _.get(this.inventoryVehicle, "stockType") !== "used";
        },

        isUsed() {
            return _.get(this.inventoryVehicle, "stockType") === "used";
        },

        retailPrice() {
            return this.isNew
                ? _.get(this.pricing, "msrp")
                : _.get(this.pricing, "dealerPrice.value");
        },

        retailPriceDisclaimer() {
            return this.isNew
                ? this.$t("disclaimer.msrp")
                : this.$t("disclaimer.retailPrice");
        },

        salePriceDisclaimer() {
            return _.get(this.pricing, "salePrice.disclaimer", null);
        }
    },
    methods: {
        openModal: call("dealerLink/openModal"),
        getSalePrice() {
            if (this.isUserSignedIn) {
                this.openModal(this.certificateId);
            } else {
                EventBus.$emit("sign-in-modal-open", {
                    modalToOpen: "dealer-link"
                });
            }
        },
        requestDealerLink() {
            this.openModal(this.certificateId);
        },
        showLifetimeWarrantyModal() {
            EventBus.$emit("lifetime-warranty-modal-open");
        },
        openContactModal: call("contactDealer/openModal"),
        contactDealer() {
            if (this.isUserSignedIn) {
                return this.openContactModal({
                    certificateId: this.certificateId
                });
            }

            const returnUrl = new URL(window.location.href, true);
            returnUrl.query.modal = "contact-dealer";

            document.location = "/sign-up?returnUrl=" + returnUrl;
        }
    }
};
</script>
<style lang="scss">
#price {
    width: 100%;

    .container-fluid {
        padding: 7px 20px 0 20px;

        .line-item {
            color: $dark-gray;

            .title {
                font-size: px2rem(16);
                color: $dark-gray;

                &.blue {
                    color: $primary-blue;
                }

                .tooltip-disclaimer {
                    a {
                        color: $muted-dark-gray;
                    }
                }
            }
            .value {
                font-size: px2rem(26);
                font-family: $helvetica-45-light, sans-serif;
                color: $dark-gray;
                line-height: 30px;

                &.blue {
                    color: $primary-blue;
                }
            }
            .warranty-value {
                font-size: px2rem(14);
                color: $primary-blue;
            }
            .blue-value {
                font-size: px2rem(26);
                font-family: $helvetica-45-light, sans-serif;
                line-height: 30px;
                color: $primary-blue;
            }
            small {
                font-size: 10px;
                color: $muted-dark-gray;
                line-height: 5px;
            }
            .restricted-make-price {
                font-style: italic;
                font-size: 16px;
                color: $muted-dark-gray;
            }
        }
    }

    .additional-incentives {
        font-family: $helvetica-65-medium;
        font-size: 10px;
        color: $muted-dark-gray;
    }

    .incentives-box {
        background-color: $light-gray;
        color: $primary-blue;
        border-radius: 6px;
        font-family: $helvetica-65-medium;

        .value {
            font-family: $helvetica-55-Roman;
        }

        small {
            font-family: $helvetica-46-light-italic;
            font-style: italic;
            font-size: 10px;
            line-height: 5px;
            margin-bottom: 5px;
            display: block;
        }
    }

    .certified-box {
        background-color: $light-gray;
        border-radius: 6px;
        font-size: 12px;

        strong {
            font-size: 14px;
            color: $primary-blue;
        }
    }
}
</style>
