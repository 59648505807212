<template>
    <div class="mt-2 mt-md-0">
        <flickity
            ref="mainCarousel"
            :options="mainOptions"
            class="carousel-main"
        >
            <div
                v-for="(image, key) in marketingImages"
                :key="'main-' + key"
                class="carousel-cell"
            >
                <b-img :src="image" fluid />
            </div>
        </flickity>
        <flickity
            ref="thumbnailCarousel"
            :options="thumbnailOptions"
            class="carousel-nav mt-1"
        >
            <div
                v-for="(image, key) in marketingImages"
                :key="'thumbnail-' + key"
                class="carousel-cell"
            >
                <b-img :src="image" fluid />
            </div>
        </flickity>
    </div>
</template>
<script>
import Flickity from "vue-flickity";
import "flickity-sync";
import "flickity-as-nav-for";
export default {
    name: "MarketingImageSlider",
    components: { Flickity },
    props: {
        marketingImages: {
            type: Array,
            required: false,
            default: null
        }
    },
    data() {
        return {
            mainOptions: {
                initialIndex: 0,
                pageDots: false,
                prevNextButtons: false,
                contain: true
            },
            thumbnailOptions: {
                sync: ".carousel-main",
                asNavFor: ".carousel-main",
                initialIndex: 0,
                pageDots: false,
                prevNextButtons: true,
                contain: true,
                draggable: false
            }
        };
    }
};
</script>
<style lang="scss">
.carousel {
    margin-bottom: 5px;
}

.carousel-cell {
    width: 100%;
    height: 340px;
    margin-right: 2.5px;
    background: transparent;
}

.carousel-nav .carousel-cell {
    height: 50px;
    width: 65px;
}

.carousel-nav .carousel-cell.is-selected {
    border: 2px solid $primary-blue;
}
</style>
