var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column justify-content-center align-items-center estimated-payment"
    },
    [
      _c("span", { staticClass: "starting-at" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("component.pricingCard.startingAt")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-row justify-content-between" }, [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "d-flex flex-row flex-nowrap my-2" },
              [_c("loading-spinner")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.loading
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row align-items-center flex-nowrap dollar-value-block"
              },
              [
                _c(
                  "span",
                  {
                    class: {
                      "blue-value": !_vm.strikeout,
                      value: _vm.strikeout,
                      strikeout: _vm.strikeout
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("numeral")(
                            _vm._f("emptyDash")(_vm.monthlyPayment),
                            "$0,0"
                          )
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "align-self-start month" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("component.pricingCard.perMonth")) +
                        "\n                "
                    ),
                    _vm.disclaimer
                      ? _c("tooltip-disclaimer", {
                          attrs: { title: _vm.disclaimer }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("small", { staticClass: "sub-text" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("component.pricingCard.excludesTaxesAndFees")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _vm.showPaymentsAvailable && !_vm.strikeout
        ? _c(
            "div",
            { staticClass: "text-center w-100 payment-options" },
            [
              _c("payment-matrix", {
                ref: "paymentMatrix",
                attrs: { "show-lease": _vm.showLease }
              }),
              _vm._v(" "),
              _c(
                "b-link",
                {
                  staticClass: "color-payment-options",
                  on: { click: _vm.viewPaymentOptions }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("component.pricingCard.viewPaymentOptions")
                      ) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              !_vm.canUnlockPricing
                ? _c("i", {
                    staticClass: "ion-locked",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }