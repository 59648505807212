<template>
    <div
        id="details"
        class="tab-pane pb-3"
        role="tabpanel"
        aria-labelledby="details-tab"
    >
        <div
            v-if="!canUnlockPricing"
            class="not-signed-in-overlay d-flex justify-content-center align-items-center w-100"
        >
            <div class="d-flex flex-column">
                <h4 class="text-center font-weight-bold mt-3">
                    {{
                        $t(
                            "component.pricingCard.detailsTab.knowWhatYourPaying"
                        )
                    }}
                </h4>

                <p class="text-center">
                    {{ $t("component.pricingCard.detailsTab.seeDetails") }}
                </p>

                <div
                    class="d-flex flex-column justify-content-center align-items-center"
                >
                    <b-img
                        src="/assets/images/lock.png"
                        alt="lock_icon"
                        class="mb-3"
                    />
                    <span>{{
                        $t("component.pricingCard.detailsTab.clickBelow")
                    }}</span>
                </div>
            </div>
        </div>

        <div v-if="showRestrictedMake" class="container-fluid pt-2">
            <div class="row">
                <div class="col-12">
                    <restricted-make-alert
                        :dealer-name="dealerName"
                        :vehicle-name="vehicleName"
                        :savings="savings"
                    />
                </div>
            </div>
        </div>

        <div v-if="canUnlockPricing" class="container-fluid py-0">
            <div class="row mb-2">
                <b-tabs
                    ref="tabs"
                    v-model="tabIndex"
                    class="payment-type-tabs w-100"
                >
                    <b-tab
                        v-if="hasSalePrice && !restrictedMake"
                        :title="$t('component.pricingCard.cash')"
                    >
                        <cash />
                    </b-tab>
                    <b-tab
                        v-if="hasSalePrice && hasFinance && !restrictedMake"
                        :title="$t('component.pricingCard.finance')"
                    >
                        <finance />
                    </b-tab>
                    <b-tab
                        v-if="
                            isNew && hasSalePrice && hasLease && !restrictedMake
                        "
                        :title="$t('component.pricingCard.lease')"
                    >
                        <lease />
                    </b-tab>
                </b-tabs>
            </div>
        </div>
        <get-supplier-pricing-block v-if="showSupplierPricing" />
        <get-sales-price-block v-if="isCertifiedDealer" :text="unlockDetails" />
        <contact-dealer-block v-else />
    </div>
</template>

<script>
import _ from "lodash";
import Cash from "./components/Cash";
import Finance from "./components/Finance";
import Lease from "./components/Lease";
import { get } from "vuex-pathify";
import RestrictedMakeAlert from "../RestrictedMakeAlert";
import GetSalesPriceBlock from "../GetSalesPriceBlock";
import GetSupplierPricingBlock from "../GetSupplierPricingBlock/index";
import ContactDealerBlock from "../ContactDealerBlock";

const DEAL_TYPE_MAPPING = ["CASH", "FINANCE", "LEASE"];

export default {
    name: "DetailsTab",
    components: {
        ContactDealerBlock,
        GetSupplierPricingBlock,
        GetSalesPriceBlock,
        RestrictedMakeAlert,
        Cash,
        Finance,
        Lease
    },

    props: {
        canLease: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            unlockDetails: this.$i18n.t("component.pricingCard.unlockDetails")
        };
    },

    computed: {
        dealerLinkExists: get("dealerLink/active"),
        canUnlockPricing: get("dealerLink/canUnlockPricing"),
        restrictedMake: get("vehicleDetails/vehicle@restrictedMake"),
        stockType: get("vehicleDetails/inventoryVehicle@stockType"),
        dealerName: get("vehicleDetails/dealer@name"),
        vehicle: get("vehicleDetails/vehicle"),
        pricing: get("vehicleDetails/pricing"),
        bestLeaseDeal: get("paymentMatrix/bestLeaseDeal"),
        bestFinanceDeal: get("paymentMatrix/bestFinanceDeal"),
        certifiedDealer: get("vehicleDetails/dealer@certified"),
        inventoryVehicle: get("vehicleDetails/inventoryVehicle"),
        isCertifiedDealer() {
            return this.certifiedDealer || this.isConfigured;
        },
        isConfigured() {
            return _.isNil(this.inventoryVehicle);
        },
        showSupplierPricing() {
            return _.get(this.pricing, "supplierPricingAvailable", false);
        },

        hasSalePrice() {
            return !_.isNil(_.get(this.pricing, "salePrice.value"));
        },
        tabIndex: {
            get() {
                const tabIndex = DEAL_TYPE_MAPPING.indexOf(
                    this.$store.state.vehicleDetails.selectedDealType
                );
                const numberOfTabs =
                    _.get(this.$refs, "tabs.$children.length", 2) / 2;

                if (tabIndex > numberOfTabs - 1) {
                    return numberOfTabs - 1;
                }

                return DEAL_TYPE_MAPPING.indexOf(
                    this.$store.state.vehicleDetails.selectedDealType
                );
            },
            set(value) {
                this.$store.commit(
                    "vehicleDetails/SET_SELECTED_DEAL_TYPE",
                    DEAL_TYPE_MAPPING[value]
                );
            }
        },

        isNew() {
            return _.toLower(this.stockType) !== "used";
        },

        vehicleName() {
            const year = _.get(this.vehicle, "year");
            const make = _.get(this.vehicle, "make");
            const model = _.get(this.vehicle, "model");
            return `${year} ${make} ${model}`;
        },
        savings() {
            const salePrice = _.get(this.pricing, "salePrice.value");
            const msrp = _.get(this.pricing, "msrp");
            return msrp - salePrice;
        },
        showRestrictedMake() {
            return this.restrictedMake && this.canUnlockPricing;
        },
        hasLease() {
            return !_.isNil(this.bestLeaseDeal);
        },
        hasFinance() {
            return !_.isNil(this.bestFinanceDeal);
        }
    }
};
</script>

<style lang="scss">
#details {
    width: 100%;

    .not-signed-in-overlay {
        padding: 0 10px;

        > div {
            max-width: 265px;

            > h4 {
                color: $primary-blue;
                margin-bottom: 5px;
            }

            > p {
                line-height: 15px;
                margin-bottom: 15px;
            }
        }
    }

    .nav-tabs {
        border: none;
        width: 90%;
        justify-content: center;
        margin: 0 auto;

        .nav-item {
            border: none;
            cursor: pointer;
            font-size: px2em(10);
            border-right: solid 1px $muted-dark-gray;
            height: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 10px 0;

            &:last-child {
                border-right: none;
            }

            .disabled {
                cursor: not-allowed;
            }

            .nav-link {
                color: $muted-dark-gray;
                font-style: italic;
                font-weight: normal;
                line-height: 13px;
                text-align: center;
                border: none;

                &.active {
                    color: $dark-gray;
                    font-weight: bold;
                    font-style: normal;
                    text-decoration: underline;
                }
            }
        }
    }

    .payment-type-tabs {
        li:only-of-type {
            a {
                text-decoration: none !important;
                cursor: text !important;
            }
        }
    }
}
</style>
