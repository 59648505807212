<template>
    <div id="get-supplier-pricing-block" class="pt-3 row">
        <div class="col-12 text-center d-flex flex-column align-items-center">
            <h6>
                Supplier Pricing Available
                <b-link ref="icon" tabindex="0" style="outline: none">
                    <i
                        aria-hidden="true"
                        class="icon ion-ios-information-outline"
                    />
                </b-link>
                <b-popover :target="() => $refs.icon" triggers="focus">
                    <strong>Supplier Pricing</strong><br />
                    Only available at participating franchise dealerships.
                    Pricing eligibility of specific models is subject to change
                    at any time without notice.
                </b-popover>
            </h6>
            <p>from this manufacturer</p>
        </div>
    </div>
</template>
<script>
import { get, dispatch, call } from "vuex-pathify";

export default {
    name: "GetSupplierPricingBlock",
    props: {
        text: {
            type: String,
            default: null
        }
    },
    data: function() {
        return {
            defaultText: this.$i18n.t(
                "component.pricingCard.getSupplierPricing"
            )
        };
    },
    computed: {
        isUserSignedIn: get("user/isSignedIn"),
        dealerLinkExists: get("dealerLink/active"),
        certificateId: get("vehicleDetails/certificate@id"),
        vehicle: get("vehicleDetails/vehicle"),
        splashImage: get("vehicleDetails/media@splashImage")
    },
    methods: {
        openModal: call("dealerLink/openModal"),
        scheduleTestDrive() {
            dispatch("scheduleAppointment/openScheduleModal", {
                certificateId: this.certificateId
            });
        },
        requestDealerLink() {
            this.openModal(this.certificateId);
        },
        openSupplierPricingModal() {
            dispatch("supplierPricing/openSupplierPricingModal", {
                vehicle: this.vehicle,
                splashImage: this.splashImage
            });
        }
    }
};
</script>
<style lang="scss" scoped>
#get-supplier-pricing-block {
    h6,
    p {
        color: $primary-blue;
    }

    h6 {
        font-size: px2rem(14);
        font-weight: bold;
        margin: 0;
    }

    p {
        font-size: px2rem(12);
        font-style: italic;
    }

    button,
    .btn {
        max-width: 270px;
    }
}
</style>
