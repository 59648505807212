<template>
    <div class="row quote-row">
        <div class="carrier-logo col">
            <img :src="carrierLogo" class="img-fluid" alt="Carrier Logo" />
        </div>

        <div class="quote-detail col">
            <div class="detail">
                <strong>{{
                    quote.paymentPlan.avgMonthlyPayment | numeral("$0,0.00")
                }}</strong
                >/mo
            </div>
            <div class="label">
                Avg. Monthly Payment
            </div>
        </div>

        <div class="quote-detail col">
            <div class="detail">
                {{ quote.paymentPlan.term }}
            </div>
            <div class="label">
                Months
            </div>
        </div>

        <div class="quote-detail col">
            <div class="detail">
                {{ quote.paymentPlan.premium | numeral("$0,0") }}
            </div>
            <div class="label">
                Premium
            </div>
        </div>

        <div class="offer-actions col-md mt-3 mt-md-0">
            <b-btn
                v-b-modal="'insureViewDetailsModal'"
                variant="secondary"
                block
            >
                View Details
            </b-btn>
            <p class="call-details">
                Call
                <a href="tel:8887272146">
                    888-727-2146
                </a>
                to accept this offer
            </p>
        </div>

        <insurance-view-details-modal
            id="insureViewDetailsModal"
            :quote="quote"
        />
    </div>
</template>

<style lang="scss">
.quote-row {
    padding-top: 15px;

    .carrier-logo {
        img {
            max-height: 48px;
        }
    }

    .quote-detail {
        text-align: center;

        > .detail {
            color: $primary-blue;
            font-size: 18px;
            line-height: 21px;
        }

        > .label {
            margin-top: 7px;
        }
    }

    .call-details {
        color: $muted-dark-gray;
        font-style: italic;
        font-size: 12px;
        text-align: center;

        a {
            font-weight: bold;
        }
    }
}
</style>

<script>
import _ from "lodash";
import InsuranceViewDetailsModal from "./ViewDetailsModal";

export default {
    name: "InsuranceQuoteRow",
    components: { InsuranceViewDetailsModal },
    props: {
        quote: {
            required: true,
            type: Object
        }
    },

    computed: {
        carrierLogo() {
            const carrierId = _.get(this.quote, "carrier.id", null);

            return _.isNil(carrierId)
                ? null
                : `/assets/images/insure/${carrierId}.png`;
        }
    }
};
</script>
