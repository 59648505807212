var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "detail-block",
    { attrs: { title: _vm.$t("component.tradeIn.title"), name: "tradeIn" } },
    [
      _c(
        "div",
        { staticClass: "card-body pb-4", attrs: { id: "trade-in-card" } },
        [
          _c("b-row", [
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-md-2 mb-3 mb-md-0 d-flex justify-content-center"
              },
              [
                _c("i", {
                  staticClass: "icon icon-bumper_to_bumper p-3",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("span", { staticClass: "text font-weight-bold" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("component.tradeIn.text")) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-text" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("component.tradeIn.subText")) +
                      "\n                    "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-md-4 d-flex justify-content-center align-items-center button-block py-3 py-md-0"
              },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: { click: _vm.gotoTradeIn }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("component.tradeIn.buttonText")) +
                        "\n                "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("sign-in-required-modal", { ref: "signInRequiredModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }