var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tab-pane pb-3",
      attrs: {
        id: "payment",
        role: "tabpanel",
        "aria-labelledby": "payment-tab"
      }
    },
    [
      _c("div", { staticClass: "container-fluid pt-2" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _vm.showRestrictedMake
                ? _c("restricted-make-alert", {
                    attrs: {
                      "dealer-name": _vm.dealerName,
                      "vehicle-name": _vm.vehicleName,
                      savings: _vm.savings
                    }
                  })
                : _c("estimated-payment", {
                    attrs: {
                      "show-lease": _vm.canLease,
                      loading: _vm.isLoading,
                      "monthly-payment": _vm.monthlyPayment,
                      disclaimer: _vm.disclaimer,
                      strikeout: _vm.strikeoutPrice
                    }
                  })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.showSupplierPricing ? _c("get-supplier-pricing-block") : _vm._e(),
      _vm._v(" "),
      _vm.isCertifiedDealer
        ? _c("get-sales-price-block")
        : _c("contact-dealer-block")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }