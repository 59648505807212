var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row quote-row" },
    [
      _c("div", { staticClass: "carrier-logo col" }, [
        _c("img", {
          staticClass: "img-fluid",
          attrs: { src: _vm.carrierLogo, alt: "Carrier Logo" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "quote-detail col" }, [
        _c("div", { staticClass: "detail" }, [
          _c("strong", [
            _vm._v(
              _vm._s(
                _vm._f("numeral")(
                  _vm.quote.paymentPlan.avgMonthlyPayment,
                  "$0,0.00"
                )
              )
            )
          ]),
          _vm._v("/mo\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label" }, [
          _vm._v("\n            Avg. Monthly Payment\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "quote-detail col" }, [
        _c("div", { staticClass: "detail" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.quote.paymentPlan.term) + "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label" }, [
          _vm._v("\n            Months\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "quote-detail col" }, [
        _c("div", { staticClass: "detail" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("numeral")(_vm.quote.paymentPlan.premium, "$0,0")) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label" }, [
          _vm._v("\n            Premium\n        ")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "offer-actions col-md mt-3 mt-md-0" },
        [
          _c(
            "b-btn",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: "insureViewDetailsModal",
                  expression: "'insureViewDetailsModal'"
                }
              ],
              attrs: { variant: "secondary", block: "" }
            },
            [_vm._v("\n            View Details\n        ")]
          ),
          _vm._v(" "),
          _vm._m(0)
        ],
        1
      ),
      _vm._v(" "),
      _c("insurance-view-details-modal", {
        attrs: { id: "insureViewDetailsModal", quote: _vm.quote }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "call-details" }, [
      _vm._v("\n            Call\n            "),
      _c("a", { attrs: { href: "tel:8887272146" } }, [
        _vm._v("\n                888-727-2146\n            ")
      ]),
      _vm._v("\n            to accept this offer\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }