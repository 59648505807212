<template>
    <div v-if="activeDealerLink" id="expressPass" class="mb-3">
        <div class="express-pass-block py-3">
            <div
                class="d-flex align-items-center justify-content-between express-pass-header"
            >
                <div class="d-flex align-items-center">
                    <img
                        class="cs-logo-black"
                        src="/assets/images/black_CRSV_logo.svg"
                        alt="CarSaver_logo_express_pass"
                    />
                    <span class="font-weight-bold font-italic ml-2">
                        {{ $t("component.expressPass.title") }}
                    </span>
                </div>
                <div class="d-flex justify-content-between align-self-start">
                    <div class="d-flex mr-2">
                        <b-link
                            class="print-link"
                            href="javascript:window.print()"
                        >
                            <i
                                class="ion ion-printer mr-1"
                                aria-hidden="true"
                            />
                            {{ $t("component.expressPass.print") }}
                        </b-link>
                    </div>
                </div>
            </div>

            <div class="container-fluid mb-3 mb-md-0">
                <div class="row">
                    <div class="col-12 col-md-4 border-right qr-block">
                        <div
                            class="d-flex flex-column justify-content-center align-items-center"
                        >
                            <div class="mb-1 qr-code">
                                <qrcode-vue
                                    :value="qrCodeUrl"
                                    :size="115"
                                    level="H"
                                />
                            </div>
                            <span class="savings-code">
                                {{ $t("component.expressPass.savingsCode") }}
                                {{ certificate.id }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-8 user-vehicle-block">
                        <div class="d-flex flex-column">
                            <span class="user">
                                {{ user.lastName }}, {{ user.firstName }}
                            </span>

                            <div
                                v-if="inventoryVehicle"
                                class="d-flex flex-row flex-wrap justify-content-between vehicle"
                            >
                                <div class="d-flex flex-column">
                                    <span class="express-label">
                                        {{ $t("component.expressPass.vin") }}
                                    </span>
                                    <span class="express-value">
                                        {{ inventoryVehicle.vin }}
                                    </span>
                                </div>

                                <div class="d-flex flex-column">
                                    <span class="express-label">
                                        {{
                                            $t(
                                                "component.expressPass.stockNumber"
                                            )
                                        }}
                                    </span>
                                    <span class="express-value">
                                        {{ inventoryVehicle.stockNumber }}
                                    </span>
                                </div>

                                <div class="d-flex flex-column">
                                    <span class="express-label">
                                        {{ $t("component.expressPass.miles") }}
                                    </span>
                                    <span class="express-value">
                                        {{ inventoryVehicle.miles | numeral }}
                                    </span>
                                </div>

                                <div class="d-flex flex-column">
                                    <span class="express-label">
                                        {{
                                            $t(
                                                "component.expressPass.expiration"
                                            )
                                        }}
                                    </span>
                                    <span class="express-value">
                                        {{ certificate.expirationDate }}
                                    </span>
                                </div>
                            </div>

                            <div
                                v-else
                                class="d-flex flex-row flex-wrap justify-content-between vehicle"
                            >
                                <div class="d-flex flex-column">
                                    <span class="express-label">
                                        {{ $t("component.expressPass.vin") }}
                                    </span>
                                    <span class="express-value">
                                        Configured Vehicle*
                                    </span>
                                </div>

                                <div class="d-flex flex-column">
                                    <span class="express-label">
                                        {{
                                            $t(
                                                "component.expressPass.expiration"
                                            )
                                        }}
                                    </span>
                                    <span class="express-value">
                                        {{ certificate.expirationDate }}
                                    </span>
                                </div>
                            </div>

                            <div
                                class="d-flex flex-column align-items-center p-2 dealer-block"
                            >
                                <span class="valid">
                                    {{ $t("component.expressPass.validOnly") }}
                                </span>
                                <span class="dealer">
                                    {{ dealer.name }}
                                </span>
                                <span class="msg">
                                    {{ $t("component.expressPass.msg") }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import QrcodeVue from "qrcode.vue";

export default {
    name: "ExpressPass",

    components: {
        QrcodeVue
    },

    computed: {
        activeDealerLink: get("dealerLink/active"),
        user: get("user/user"),
        inventoryVehicle: get("vehicleDetails/inventoryVehicle"),
        vehicle: get("vehicleDetails/vehicle"),
        dealer: get("vehicleDetails/dealer"),
        certificate: get("vehicleDetails/certificate"),
        domain: get("campaign/domain"),
        qrCodeUrl() {
            const protocol = location.protocol;
            if (_.isNil(this.inventoryVehicle)) {
                const basePath = `${protocol}//${this.domain}/build/`;
                const styleId = _.get(this.vehicle, "styleId");
                const certificateParam = `?cid=${this.certificate.id}`;
                const utmSource = `&utm_source=carsaver&utm_medium=qrcode&utm_campaign=Certificate%20QR%20Code&utm_term=${this.certificate.id}`;
                return basePath + styleId + certificateParam + utmSource;
            }

            const basePath = `${protocol}//${this.domain}/shop/${_.toLower(
                this.inventoryVehicle.stockType
            )}/`;
            const inventoryId = _.get(this.inventoryVehicle, "id");
            const certificateParam = `?cid=${this.certificate.id}`;
            const utmSource = `&utm_source=carsaver&utm_medium=qrcode&utm_campaign=Certificate%20QR%20Code&utm_term=${this.certificate.id}`;
            return basePath + inventoryId + certificateParam + utmSource;
        }
    }
};
</script>

<style lang="scss" scoped>
#expressPass {
    background-color: $white;
    border: none;
    opacity: 0.99;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

    .express-pass-block {
        min-height: 215px;
        height: auto;
        width: 100%;
        border-radius: 2px;

        .express-pass-header {
            padding: 0px 15px 15px 15px;

            .cs-logo-black {
                margin-bottom: 1px;
            }
            span {
                color: $primary-blue;
                font-size: px2rem(22);
                line-height: 27px;
                margin-top: 3px;
                white-space: nowrap;
            }
            .print-link {
                color: $muted-dark-gray;
                font-size: px2rem(12);
                line-height: 15px;
            }
        }

        .qr-block {
            .qr-code {
                height: 121px;
                width: 119px;
            }
            .savings-code {
                font-size: px2rem(10);
                color: $muted-dark-gray;
                line-height: 15px;
            }
        }

        .user-vehicle-block {
            .user {
                font-weight: bold;
                font-size: px2rem(16);
                color: $muted-dark-gray;
                line-height: 15px;
                margin-bottom: 5px;
            }
            .vehicle {
                margin-bottom: 10px;
                color: $muted-dark-gray;

                .express-label {
                    font-size: px2rem(9);
                    line-height: 15px;
                }
                .express-value {
                    font-style: italic;
                    line-height: 15px;
                    font-size: px2rem(12);
                }
            }
            .dealer-block {
                background-color: $very-light-gray;
                border-radius: 2px;
                border: 1px solid $muted-gray;
                width: 100%;
                height: auto;

                .valid {
                    line-height: 13px;
                    font-size: px2rem(10);
                    color: $muted-dark-gray;
                }
                .dealer {
                    line-height: 20px;
                    font-weight: bold;
                    font-size: px2rem(16);
                    color: $primary-blue;
                }
                .msg {
                    text-align: center;
                    line-height: 15px;
                    font-size: px2rem(10);
                    color: $dark-gray;
                    height: 30px;
                    max-width: 245px;
                }
            }
        }
    }
}
</style>
