<template>
    <cs-modal id="vehicleReviewModal" ref="modal" :hide-footer="true">
        <div slot="modal-title">
            Reviews
        </div>
        <b-container>
            <div class="my-2 w-100 text-center">
                <h3 class="vehicle-title">
                    {{ vehicleFullName }}
                </h3>
            </div>
            <div class="review-wrapper">
                <div class="review">
                    <div class="review-image">
                        <fixed-vehicle-image
                            :image-url="vehicleSplashImage"
                            :backup-image-url="vehicleBackUpImage"
                        />
                    </div>
                    <p
                        v-for="(review, key) in reviews"
                        :key="key"
                        class="review-text"
                    >
                        {{ review }}
                    </p>
                </div>
            </div>
        </b-container>
    </cs-modal>
</template>

<style lang="scss" scoped>
#vehicleReviewModal {
    .vehicle-title {
        font-size: 18px;
        font-weight: 700;
        color: #444;
    }

    .review-wrapper {
        position: relative;
        overflow: hidden;
        height: 510px;

        .review-image {
            width: 230px;
            height: 172px;
            float: left;
            margin-right: 1em;
        }

        .review {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: scroll;
            .left {
                float: left;
                margin-right: 1em;

                img {
                    width: 230px;
                }
            }
            .review-text {
                font-size: 12px;
                line-height: 20px;
                color: #888;
                font-weight: normal;
            }
        }
    }

    .modal-btn {
        height: 30px;
        width: 180px;
        border-radius: 2px;
        line-height: 20px;
    }
}
</style>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import CSModal from "../../../components/CSModal/index";
import FixedVehicleImage from "../FixedVehicleImage/index";
import EventBus from "../../../event-bus";

export default {
    name: "VehicleReviewModal",

    components: {
        "cs-modal": CSModal,
        "fixed-vehicle-image": FixedVehicleImage
    },

    computed: {
        reviews() {
            if (_.isNil(this.vehicleReview)) {
                return [];
            }

            const delimiter = "<p><p>";
            return _.split(this.vehicleReview, delimiter);
        },
        vehicleReview: get("vehicleDetails/reviewText"),
        vehicleFullName: get("vehicleDetails/vehicle@fullName"),
        vehicleSplashImage: get("vehicleDetails/media@splashImage"),
        vehicleBackUpImage: get("vehicleDetails/media@front34.small")
    },

    created() {
        const component = this;
        EventBus.$on("review-modal-open", () => {
            component.show();
        });
    },
    methods: {
        show() {
            this.$refs.modal.show();
        }
    }
};
</script>
