<template>
    <div v-if="installedOptions" id="installed-options-block">
        <div class="d-flex justify-content-between">
            <span class="font-weight-bold">
                Options
            </span>
        </div>
        <ul class="d-flex flex-column flex-md-row flex-wrap mb-0">
            <li v-for="(option, index) in installedOptions" :key="index">
                <div class="constraints collapsed">
                    {{ option | capitalize }}
                    <div
                        v-if="option.length > 90"
                        class="overlay d-none d-md-flex w-100"
                    >
                        <b-link @click="expandOption($event)">
                            more
                        </b-link>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
    name: "InstalledOptionsBlock",

    filters: {
        capitalize(input) {
            if (input !== null) {
                return input.toLowerCase().replace(/^\b./g, function(input) {
                    return input.toUpperCase();
                });
            } else {
                return input;
            }
        }
    },

    computed: {
        installedOptions: get("vehicleDetails/vehicle@specs.INSTALLED")
    },

    methods: {
        expandOption(event) {
            const expandableElement = event.target.parentElement.parentElement;

            if (expandableElement.classList.contains("expanded")) {
                expandableElement.classList.remove("expanded");
                expandableElement.classList.add("collapsed");
                event.target.innerHTML = "more";
            } else {
                expandableElement.classList.remove("collapsed");
                expandableElement.classList.add("expanded");
                event.target.innerHTML = "less";
            }
        }
    }
};
</script>

<style lang="scss">
#installed-options-block {
    background-color: $white;
    margin: 0 0 25px 0;
    height: auto;

    span {
        font-size: 16px;
    }

    ul li {
        flex: 0 0 calc(33% - 20px);
        margin: 10px 10px 0 10px;
    }

    .constraints {
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 73px;
        width: auto;
        position: relative;
    }

    .constraints.expanded {
        max-height: none;
        margin-bottom: 5px;
        padding-bottom: 20px;

        .overlay {
            bottom: 0px;
        }
    }
    .constraints.collapsed {
        max-height: 73px;

        .overlay {
            top: 50px;
        }
    }

    .overlay {
        position: absolute;
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.55) 0%,
            rgba(255, 255, 255, 1) 100%
        );
        height: 23px;
        justify-content: center;
        align-items: flex-end;

        a {
            line-height: 14px;
            vertical-align: bottom;
            display: table-cell;
        }
    }
}

@include media-breakpoint-down(sm) {
    #installed-options-block {
        height: auto !important;
        padding: 10px 15px 0 15px !important;

        .constraints {
            max-height: none !important;
        }

        ul {
            padding: 10px;
        }

        ul li {
            flex: 1 0 0%;
        }
    }
}
</style>
