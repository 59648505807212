var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "section-box offer-info d-flex justify-content-around align-items-center"
    },
    [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { src: _vm.carrierLogo, alt: _vm.quote.carrier.name }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section-item" }, [
        _c("div", { staticClass: "value" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("numeral")(
                  _vm.quote.paymentPlan.avgMonthlyPayment,
                  "$0,0.00"
                )
              ) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label" }, [
          _vm._v("\n            Avg Monthly Payment\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section-item" }, [
        _c("div", { staticClass: "value" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("numeral")(_vm.quote.paymentPlan.term, "$0,0.00")) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label" }, [
          _vm._v("\n            Month\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section-item" }, [
        _c("div", { staticClass: "value" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("numeral")(_vm.quote.paymentPlan.premium, "$0,0.00")
              ) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label" }, [
          _vm._v("\n            Premium\n        ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }