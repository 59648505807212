var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-3 row", attrs: { id: "get-supplier-pricing-block" } },
    [
      _c(
        "div",
        {
          staticClass:
            "col-12 text-center d-flex flex-column align-items-center"
        },
        [
          _c(
            "h6",
            [
              _vm._v("\n            Supplier Pricing Available\n            "),
              _c(
                "b-link",
                {
                  ref: "icon",
                  staticStyle: { outline: "none" },
                  attrs: { tabindex: "0" }
                },
                [
                  _c("i", {
                    staticClass: "icon ion-ios-information-outline",
                    attrs: { "aria-hidden": "true" }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "b-popover",
                { attrs: { target: () => _vm.$refs.icon, triggers: "focus" } },
                [
                  _c("strong", [_vm._v("Supplier Pricing")]),
                  _c("br"),
                  _vm._v(
                    "\n                Only available at participating franchise dealerships.\n                Pricing eligibility of specific models is subject to change\n                at any time without notice.\n            "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("p", [_vm._v("from this manufacturer")])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }