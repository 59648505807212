<template>
    <b-list-group-item
        class="expandable-list-item"
        @click="showCollapse = !showCollapse"
    >
        <div class="d-flex justify-content-between align-items-center">
            <div
                class="d-flex justify-content-between align-items-center w-100 pr-1"
            >
                <slot name="list-item" />
            </div>
            <i
                v-if="expandable"
                aria-hidden="true"
                :class="showCollapse ? 'ion-arrow-up-b' : 'ion-arrow-down-b'"
            />
        </div>
        <b-collapse :id="id + '-details'" v-model="showCollapse">
            <slot name="details" />
        </b-collapse>
    </b-list-group-item>
</template>

<style>
.expandable-list-item {
    cursor: pointer;
}
</style>

<script>
export default {
    name: "ExpandableListItem",

    props: {
        id: {
            type: [String, Number],
            required: true
        },
        expandable: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data() {
        return {
            showCollapse: false
        };
    }
};
</script>
