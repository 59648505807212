<template>
    <b-modal
        :id="id"
        ref="detailsModal"
        class="view-details-modal"
        size="lg"
        lazy
    >
        <div slot="modal-header-close">
            <i aria-hidden="true" class="ion-ios-close-outline">
                &nbsp;
            </i>
        </div>
        <slot slot="modal-title" name="title" />

        <b-container>
            <b-row>
                <div class="col-md-8">
                    <slot name="summary" />

                    <div :class="offerTabClasses">
                        <slot name="offerTab" />
                    </div>
                </div>

                <div :class="detailsTabClasses">
                    <slot name="detailsTab" />
                </div>

                <slot name="footer" />
            </b-row>
        </b-container>

        <div slot="modal-footer">
            <b-button-group class="d-sm-none">
                <b-button
                    :variant="getButtonVariant('offer')"
                    @click="showOffer()"
                >
                    Offer
                </b-button>
                <b-button
                    :variant="getButtonVariant('details')"
                    @click="showDetails()"
                >
                    Details
                </b-button>
            </b-button-group>
        </div>
    </b-modal>
</template>

<style lang="scss">
.view-details-modal {
    @include media-breakpoint-up(md) {
        .modal-footer {
            display: none;
        }
    }

    .modal-footer {
        button {
            border-radius: 0;
        }
    }
}
</style>

<script>
export default {
    name: "ViewDetailsModal",

    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        offerTabLabel: {
            type: String,
            required: false,
            default: "Offer"
        },
        detailsTabLabel: {
            type: String,
            required: false,
            default: "Details"
        }
    },

    data() {
        return {
            selectedTab: "offer"
        };
    },

    computed: {
        offerTabClasses() {
            return {
                "d-none": this.isMobile() && this.isSelectedTab("details")
            };
        },
        detailsTabClasses() {
            return {
                "col-md-4": true,
                "d-none": this.isMobile() && this.isSelectedTab("offer"),
                "d-sm-none": this.isMobile() && this.isSelectedTab("offer"),
                "d-md-flex": true,
                "flex-column": true
            };
        }
    },

    methods: {
        isSelectedTab(tabName) {
            return this.selectedTab === tabName;
        },

        getButtonVariant(tabName) {
            return this.isSelectedTab(tabName) ? "primary" : "";
        },

        isMobile() {
            return window.innerWidth < 768;
        },

        showOffer() {
            this.selectedTab = "offer";
        },

        showDetails() {
            this.selectedTab = "details";
        }
    }
};
</script>
