var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "restricted-make-alert" }, [
    _c("div", { staticClass: "title" }, [
      _c("i", {
        staticClass: "ion ion-android-warning",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v(
        "\n        " +
          _vm._s(_vm.$t("component.pricingCard.displayRestricted")) +
          "\n    "
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "body" },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("component.pricingCard.checkYourEmail")) +
            "\n        "
        ),
        _c("b-link", { ref: "icon", attrs: { tabindex: "0" } }, [
          _c("i", {
            staticClass: "icon ion-ios-information-outline",
            attrs: { "aria-hidden": "true" }
          })
        ]),
        _vm._v(" "),
        _c(
          "b-popover",
          { attrs: { target: () => _vm.$refs.icon, triggers: "focus" } },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t("component.pricingCard.minimumAdvertisedPricePolicy")
                ) +
                "\n            "
            ),
            _c("br"),
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("component.pricingCard.yourGauranteedSavings")) +
                "\n            " +
                _vm._s(_vm._f("numeral")(_vm.savings, "$0,0")) +
                "\n            " +
                _vm._s(_vm.$t("component.pricingCard.onAnyInStock")) +
                "\n            " +
                _vm._s(_vm.vehicleName) +
                " " +
                _vm._s(_vm.$t("component.pricingCard.at")) +
                "\n            " +
                _vm._s(_vm.dealerName) +
                ".\n            "
            ),
            _c("br"),
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("component.pricingCard.eachCertifiedDealer")) +
                "\n        "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }