var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "detail-block",
    { attrs: { id: "overview", title: "Overview", name: "overview" } },
    [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("div", { staticClass: "color-block d-flex flex-row" }, [
            _vm.vehicle.exteriorColor
              ? _c("div", { staticClass: "d-flex flex-column mr-5" }, [
                  _vm.isConfigured
                    ? _c(
                        "span",
                        { staticClass: "label mb-1" },
                        [
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t("page.vdp.extColor")) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-link",
                            {
                              staticClass: "d-sm-none",
                              on: { click: _vm.onExteriorEditClick }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("page.vdp.extColor")) +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c("span", { staticClass: "label mb-1" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("page.vdp.extColor")) +
                            "\n                "
                        )
                      ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "value d-flex flex-row align-items-center" },
                    [
                      _c("span", {
                        staticClass: "color-swatch mx-2",
                        style: {
                          "background-color":
                            _vm.vehicle.exteriorColorHexCode != null
                              ? "#" + _vm.vehicle.exteriorColorHexCode
                              : ""
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "color-text" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.vehicle.exteriorColor) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.isConfigured
                        ? _c(
                            "span",
                            {
                              staticClass: "d-none d-sm-inline color-edit pl-2"
                            },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass: "font-italic",
                                  on: { click: _vm.onExteriorEditClick }
                                },
                                [
                                  _vm._v(
                                    "\n                            edit\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.vehicle.interiorColor
              ? _c("div", { staticClass: "d-flex flex-column" }, [
                  _vm.isConfigured
                    ? _c(
                        "span",
                        { staticClass: "label mb-1" },
                        [
                          _c("span", { staticClass: "d-none d-sm-inline" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t("page.vdp.intColor")) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-link",
                            {
                              staticClass: "d-sm-none",
                              on: { click: _vm.onInteriorEditClick }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("page.vdp.intColor")) +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c("span", { staticClass: "label mb-1" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("page.vdp.intColor")) +
                            "\n                "
                        )
                      ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "value d-flex flex-row align-items-center" },
                    [
                      _c("span", { staticClass: "color-text" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.vehicle.interiorColor) +
                            "\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.isConfigured
                        ? _c(
                            "span",
                            {
                              staticClass: "d-none d-sm-inline color-edit pl-2"
                            },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass: "font-italic",
                                  on: { click: _vm.onInteriorEditClick }
                                },
                                [
                                  _vm._v(
                                    "\n                            edit\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("hr", { staticClass: "divider" }),
          _vm._v(" "),
          _c("div", { staticClass: "overview-icon-wrapper" }, [
            _vm.vehicle.passengerCapacity
              ? _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "icon-wrapper" }, [
                    _c("i", {
                      staticClass: "icon icon-seat",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.vehicle.passengerCapacity) + " Seats")
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.vehicle.bodyStyle
              ? _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "icon-wrapper" }, [
                    _c("i", {
                      staticClass: "icon icon-bodystyle",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("capitalize")(_vm.vehicle.bodyStyle))
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.vehicle.transmission
              ? _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "icon-wrapper" }, [
                    _c("i", {
                      staticClass: "icon icon-automatic",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c("span", [_vm._v(_vm._s(_vm.vehicle.transmission))])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.vehicle.drivetrain
              ? _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "icon-wrapper" }, [
                    _c("i", {
                      staticClass: "icon icon-front_wheel",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c("span", [_vm._v(_vm._s(_vm.vehicle.drivetrain))])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.vehicle.fuelType
              ? _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "icon-wrapper" }, [
                    _c("i", {
                      staticClass: "icon icon-gas_pump",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c("span", [_vm._v(_vm._s(_vm.vehicle.fuelType))])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.vehicle.horsePower
              ? _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "icon-wrapper" }, [
                    _c("i", {
                      staticClass: "icon icon-lightning_engine",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c("span", [_vm._v(_vm._s(_vm.vehicle.horsePower) + " hp")])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.vehicle.engineDisplacement
              ? _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "icon-wrapper" }, [
                    _c("i", {
                      staticClass: "icon icon-E_engine",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c("span", [_vm._v(_vm._s(_vm.vehicle.engineDisplacement))])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.vehicle.features.fuelType
              ? _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "icon-wrapper" }, [
                    _c("i", {
                      staticClass: "icon icon-fuel_can",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label" }, [
                    _c("span", [_vm._v(_vm._s(_vm.vehicle.features.fuelType))])
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.vehicle.specs ? _c("hr", { staticClass: "divider" }) : _vm._e(),
          _vm._v(" "),
          _c("installed-options-block"),
          _vm._v(" "),
          _vm.vehicle.specs
            ? _c(
                "div",
                {
                  staticClass:
                    "show-all-block d-flex justify-content-center align-items-center mb-3"
                },
                [
                  _c(
                    "b-link",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: {
                        href: "#specsAndFeatures",
                        "smooth-scrolling": ""
                      }
                    },
                    [
                      _vm._v(
                        "\n                show all specs & features\n            "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasSellerNotes
            ? _c("div", { staticClass: "seller-notes mb-3" }, [
                _c("h3", [_vm._v("Seller's Notes")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "notes" },
                  [
                    _vm.truncateSellerNotes
                      ? _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.truncatedSellerNotes)
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.truncateSellerNotes
                      ? _c(
                          "b-link",
                          { on: { click: _vm.sellerNotesReadMore } },
                          [
                            _vm._v(
                              "\n                    Read More\n                "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.truncateSellerNotes
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.sellerNotes) }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }