<template>
    <div id="thankYouModalRestrictedMakeContent" class="text-center p-2 px-3">
        <h1>
            Thank You.
        </h1>

        <p>
            <strong>
                The sale price for this vehicle is lower than the manufacturer
                allows us to show online.
            </strong>
        </p>

        <p>Your pre-set price for this vehicle has been sent to {{ email }}.</p>

        <div v-if="canSendLead">
            <div class="grey-bg p-2 py-3 mb-4">
                A representative from <strong>{{ dealerName }}</strong> will be
                contacting you to confirm availability and answer any questions
                you may have about this vehicle.
            </div>

            <div class="actions mx-auto my-2">
                <b-btn variant="orange" block @click="$emit('see-pricing')">
                    Okay. See Pricing
                </b-btn>
            </div>
            <p>
                <b-link @click="$emit('still-shopping')">
                    I'm still shopping. I'm not ready to talk to a dealership
                    yet.
                </b-link>
            </p>
        </div>
        <div v-else>
            <div class="actions mx-auto my-2">
                <b-btn variant="primary" block @click="$emit('close')">
                    Close
                </b-btn>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
#thankYouModalRestrictedMakeContent {
    color: $medium-gray;

    h1 {
        color: $primary-blue;
        font-size: 36px;
        font-weight: 300;
    }

    strong {
        font-family: $helvetica-65-medium;
        font-weight: normal;
    }

    .actions {
        max-width: 200px;
    }

    .grey-bg {
        background-color: $card-background;
        border-radius: 9px;

        strong {
            color: $primary-blue;
        }
    }
}
</style>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";

export default {
    computed: {
        dealer: get("dealerLink/selectedDealer"),
        email: get("user/user@email"),
        canSendLead: get("dealerLink/canSendLead"),

        dealerName() {
            return _.get(this.dealer, "name", "a CarSaver Certified Dealer");
        }
    }
};
</script>
