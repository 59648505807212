var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.validCrashTestRating
    ? _c(
        "detail-block",
        { attrs: { id: "safety", title: "Safety Rating", name: "safety" } },
        [
          _c("div", { staticClass: "card-body pb-4" }, [
            _c(
              "div",
              { staticClass: "block d-flex flex-column align-items-center" },
              [
                _c(
                  "div",
                  { staticClass: "star-rating d-flex" },
                  _vm._l(_vm.stars, function(star) {
                    return _c("i", {
                      key: star,
                      staticClass: "ion-android-star mx-2",
                      class: { highlight: _vm.crashTestRating > star },
                      attrs: { "aria-hidden": "true" }
                    })
                  }),
                  0
                ),
                _vm._v(" "),
                _c("span", { attrs: { id: "ratings" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.crashTestRating) +
                      " Star Crash Test Rating\n            "
                  )
                ])
              ]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }