var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selectedOptions
    ? _c(
        "detail-block",
        { attrs: { title: "Packages & Options", name: "options" } },
        [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "b-container",
                { staticClass: "px-0 pb-3", attrs: { fluid: "" } },
                [
                  _c(
                    "b-list-group",
                    [
                      _vm._l(_vm.selectedOptions, function(option, key) {
                        return _c(
                          "expandable-list-item",
                          {
                            key: key + option.code,
                            attrs: {
                              id: option.code,
                              expandable: option.details !== null
                            }
                          },
                          [
                            _c("template", { slot: "list-item" }, [
                              _c("span", [_vm._v(_vm._s(option.name))]),
                              _vm._v(" "),
                              option.msrp > 0
                                ? _c("span", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("numeral")(option.msrp, "$0,0")
                                        ) +
                                        "\n                        "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                            Inc.\n                        "
                                    )
                                  ])
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "details" }, [
                              _c("span", { staticClass: "font-italic" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(option.details) +
                                    "\n                        "
                                )
                              ])
                            ])
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "b-list-group-item",
                        { staticClass: "expandable-list-item" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between align-items-center w-100 pr-1"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        "\n                                Total Packages & Options\n                            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm._f("numeral")(_vm.total, "$0,0")
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "w-100 pt-3" },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: { variant: "secondary mx-auto" },
                          on: { click: _vm.addEditClick }
                        },
                        [
                          _vm._v(
                            "\n                    add/edit\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }