var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column header",
      class: { "-with-background": _vm.splashImage },
      attrs: { id: "vdp-hero" }
    },
    [
      _c("vehicle-title-bar"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "card-image order-2 order-md-1 position-relative pt-5 pt-sm-0"
        },
        [
          _c(
            "div",
            { staticClass: "container-fluid" },
            [_c("new-search-link")],
            1
          ),
          _vm._v(" "),
          _vm.splashImage
            ? _c("b-img", { attrs: { src: _vm.splashImage, fluid: "" } })
            : _c("missing-image", { attrs: { "body-style": _vm.bodyStyle } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }