<template>
    <div
        v-if="hasLocalListingsAvailable"
        class="search-local-listings text-center"
    >
        <b-link :href="url">
            Search Local Listings&nbsp;
            <b-badge pill variant="danger">
                {{ vehicle.localListingCount }}
            </b-badge>
        </b-link>
    </div>
</template>

<style lang="scss">
.search-local-listings {
    font-size: 12px;
}
</style>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";

export default {
    computed: {
        vehicle: get("vehicleDetails/vehicle"),

        hasLocalListingsAvailable() {
            const numberOfLocalListings = _.get(
                this.vehicle,
                "localListingCount",
                0
            );
            return numberOfLocalListings > 0;
        },

        url() {
            const make = _.get(this.vehicle, "make");
            const model = _.get(this.vehicle, "model");
            const year = _.get(this.vehicle, "year");
            return `/shop/new?makes=${make}&models=${model}&minYear=${year}&maxYear=${year}&distance=100`;
        }
    }
};
</script>
