var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "detail-block",
    {
      attrs: {
        title: _vm.$t("component.betterWayToBuy.title"),
        name: "betterWayToBuy"
      }
    },
    [
      _c(
        "div",
        { staticClass: "card-body p-0", attrs: { id: "better-way-to-buy" } },
        [
          _c("div", { staticClass: "position-relative" }, [
            _c("img", {
              staticClass: "img-fluid",
              attrs: {
                src: "/assets/images/made_easy.jpg",
                alt: "a better way to buy cars"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "position-absolute d-flex flex-column logo-block"
              },
              [
                _c("img", {
                  staticClass: "crsv-logo",
                  attrs: {
                    src: "/assets/images/white_crsv_logo.svg",
                    alt: "a better way to buy cars"
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "align-self-end mr-2" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.betterWayToBuy.enjoyTheRide")) +
                      "\n                "
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "our-goal d-flex flex-column justify-content-center align-items-center text-center"
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("component.betterWayToBuy.ourGoal")))
              ]),
              _vm._v(" "),
              _c(
                "b-link",
                {
                  staticClass: "phone",
                  attrs: { href: "tel:" + _vm.phoneNumber }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.phoneNumberFormatted) +
                      "\n            "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }