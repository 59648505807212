<template>
    <detail-block
        v-if="hasVehicleSpecs"
        id="features-container"
        title="Specs & Features"
        name="specsAndFeatures"
    >
        <div id="specsAndFeatures" class="card-body specs">
            <fuel-consumption-block />
            <hr class="fuel-divider" />
            <b-container fluid class="p-0">
                <b-tabs>
                    <b-tab
                        v-for="(options, category, index) in vehicleSpecs"
                        :key="index"
                        :title="category"
                    >
                        <b-list-group>
                            <b-list-group-item
                                v-for="(option, optionIndex) in options"
                                :key="optionIndex"
                                class="spec-row"
                            >
                                {{ option }}
                            </b-list-group-item>
                        </b-list-group>
                    </b-tab>
                </b-tabs>
            </b-container>
        </div>
    </detail-block>
</template>

<style lang="scss">
#specsAndFeatures {
    .fuel-divider {
        margin: 14px 0 30px 0;
    }

    .container-fluid {
        margin-bottom: 30px;

        .nav-tabs {
            .nav-link {
                color: $muted-dark-gray;
                font-style: italic;

                .title-link {
                    font-size: px2em(12);
                }
            }

            .nav-link.active {
                font-style: normal;
                color: $primary-blue;
            }
        }

        .list-group {
            max-height: 510px;
            overflow: scroll;
        }

        .spec-row {
            padding: 15px;
        }

        .spec-row:nth-child(1) {
            border-top: none;
        }

        .spec-row:nth-child(even) {
            background: #f6f6f6;
        }
    }

    @include media-breakpoint-down(sm) {
        .fuel-divider {
            margin: 0 0 30px 0 !important;
        }
    }
}
</style>

<script>
import { get } from "vuex-pathify";
import FuelConsumptionBlock from "../FuelConsumptionBlock";
import DetailBlock from "Modules/Vehicle/DetailBlock";
import _ from "lodash";

export default {
    name: "SpecsAndFeatures",
    components: { DetailBlock, FuelConsumptionBlock },
    computed: {
        vehicleSpecs: get("vehicleDetails/vehicle@specs"),
        hasVehicleSpecs() {
            return !_.isEmpty(this.vehicleSpecs);
        }
    }
};
</script>
