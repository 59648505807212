var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.vehicleReview
    ? _c(
        "detail-block",
        {
          attrs: {
            title: _vm.$t("page.vdp.aboutThisVehicle"),
            name: "aboutThisVehicle"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "card-body p-0",
              attrs: { id: "about-this-vehicle" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "image-block",
                  style: [
                    _vm.vehicleSplashImage !== null
                      ? {
                          "background-image":
                            "url(" + _vm.vehicleSplashImage + ")"
                        }
                      : ""
                  ],
                  attrs: { "aria-label": "Splash Image of vehicle" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "critics-text d-flex flex-column justify-content-center align-items-center"
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "quote quote-start",
                          attrs: { "aria-hidden": "true" }
                        },
                        [_vm._v("\n                    “\n                ")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "w-100" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("truncatedReview")(_vm.vehicleReview)
                            ) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "i",
                        {
                          staticClass: "quote quote-end",
                          attrs: { "aria-hidden": "true" }
                        },
                        [_vm._v("\n                    ”\n                ")]
                      ),
                      _vm._v(" "),
                      _c("b-link", { on: { click: _vm.onShowMoreClick } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("page.vdp.viewMore")) +
                            "\n                "
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }