<template>
    <div class="coverage-row d-flex flex-row justify-content-between">
        <span class="coverage-label">
            {{ label }}
        </span>
        <span class="coverage-value">
            {{ value }}
        </span>
    </div>
</template>

<style lang="scss">
.coverage-row {
    padding: 5px 10px;
    border-top: 1px solid $muted-gray;
}
</style>

<script>
export default {
    name: "CoverageRow",

    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        }
    }
};
</script>
