var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vehicle-panel",
    {
      staticClass: "finance-offers-vehicle-panel",
      attrs: { vehicle: _vm.vehicle }
    },
    [
      _c(
        "title-with-price",
        [
          _c(
            "template",
            { slot: "title" },
            [
              _vm._v("\n            Market Average\n            "),
              _c("tooltip-disclaimer", {
                attrs: { title: _vm.pricing.averageMarketPrice.disclaimer }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("template", { slot: "price" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("numeral")(
                    _vm._f("emptyDash")(
                      _vm.pricing,
                      "averageMarketPrice.value"
                    ),
                    "$0,0"
                  )
                ) +
                "\n        "
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "title-with-price",
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("strong", [_vm._v("Sale Price")]),
              _vm._v(" "),
              _c("tooltip-disclaimer", {
                attrs: { title: _vm.pricing.salePrice.disclaimer }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "blue-price",
              attrs: { slot: "price" },
              slot: "price"
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("numeral")(
                      _vm._f("emptyDash")(_vm.pricing, "salePrice.value"),
                      "$0,0"
                    )
                  ) +
                  "\n        "
              )
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "title-with-price",
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n            Taxes\n        ")
          ]),
          _vm._v(" "),
          _c("template", { slot: "price" }, [
            _vm._v(
              "\n            +" +
                _vm._s(
                  _vm._f("numeral")(
                    _vm._f("emptyDash")(_vm.request, "salesTax"),
                    "$0,0"
                  )
                ) +
                "\n        "
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "title-with-price",
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n            Title/Registration\n        ")
          ]),
          _vm._v(" "),
          _c("template", { slot: "price" }, [
            _vm._v(
              "\n            +" +
                _vm._s(
                  _vm._f("numeral")(
                    _vm._f("emptyDash")(_vm.request, "titleLicense"),
                    "$0,0"
                  )
                ) +
                "\n        "
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "title-with-price",
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n            Dealer Fees\n        ")
          ]),
          _vm._v(" "),
          _c("template", { slot: "price" }, [
            _vm._v(
              "\n            +" +
                _vm._s(
                  _vm._f("numeral")(
                    _vm._f("emptyDash")(_vm.request, "dealerFees"),
                    "$0,0"
                  )
                ) +
                "\n        "
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "title-with-price",
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n            Subtotal\n        ")
          ]),
          _vm._v(" "),
          _c("template", { slot: "price" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("numeral")(
                    _vm._f("emptyDash")(_vm.request, "requestedAmountFinanced"),
                    "$0,0.00"
                  )
                ) +
                "\n        "
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "title-with-price",
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n            Down Payment\n        ")
          ]),
          _vm._v(" "),
          _c("template", { slot: "price" }, [
            _vm._v(
              "\n            -" +
                _vm._s(
                  _vm._f("numeral")(
                    _vm._f("emptyDash")(_vm.offer, "downPayment"),
                    "$0,0"
                  )
                ) +
                "\n        "
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "title-with-price",
        [
          _c("template", { slot: "title" }, [
            _c("strong", [_vm._v("Total Financed")])
          ]),
          _vm._v(" "),
          _c("template", { slot: "price" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm._f("numeral")(_vm.totalFinanced, "$0,0.00")))
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }