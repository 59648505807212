var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-alert",
    {
      staticClass: "mb-2 d-flex align-items-center",
      attrs: { show: "", variant: "primary" }
    },
    [
      _c("i", {
        staticClass: "icon ion-ios-heart mr-1",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v("\n     \n    "),
      _c("strong", [_vm._v("Dealer Delivery Available.")]),
      _vm._v(" \n    "),
      _c("span", [
        _vm._v("CarSaver cares about your well being. Stay Home. Stay Safe.")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }