<template>
    <div
        :class="{ selected: selected }"
        class="payment-card"
        @click="onPaymentCardClick"
    >
        <div class="term">{{ offer.quote.term }} months</div>
        <div class="payment">
            {{ offer.quote.monthlyPayment | numeral("$0") }}
        </div>
        <div class="type">
            {{ offer.quote.type === "Loan" ? "Finance" : "Lease" }}
        </div>
    </div>
</template>

<script>
export default {
    name: "PaymentCard",

    props: {
        offer: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            required: false,
            defaultValue: false
        },
        index: {
            type: Number,
            required: true
        }
    },

    methods: {
        onPaymentCardClick: function() {
            this.$emit("click", this.index, this.offer);
        }
    }
};
</script>

<style lang="scss" scoped>
.payment-card {
    margin: 0 5px;
    border: 1px solid $light-medium-gray;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    width: 85px;
    height: 85px;
    justify-content: center;
    cursor: pointer;

    &:hover {
        border-color: $primary-blue;
    }

    > .term {
        white-space: nowrap;
    }

    > .payment {
        font-family: "Times New Roman", serif;
        color: $muted-dark-gray;
        font-size: 28px;
        font-style: italic;
        font-weight: bold;
        line-height: 32px;
    }

    &.selected {
        border: 1px solid rgba(136, 136, 136, 0.35);
        border-radius: 2px;
        background-color: $primary-blue;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        color: #fff;

        > .payment {
            color: #fff;
        }
    }
}
</style>
