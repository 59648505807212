var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "view-details-modal",
    { staticClass: "finance-view-details-modal", attrs: { id: _vm.id } },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("\n        Congratulations!\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "summary" }, slot: "summary" },
        [
          _c("h4", [
            _vm._v(
              "\n            You received a financing offer from\n            "
            ),
            _c("strong", [_vm._v(_vm._s(_vm.offer.lenderName))])
          ]),
          _vm._v(" "),
          _c("offer-info", { attrs: { offer: _vm.offer } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "offerTab" }, slot: "offerTab" },
        [
          _c("down-payment-matrix", { attrs: { offer: _vm.offer } }),
          _vm._v(" "),
          _c("div", { staticClass: "disclaimer" }, [
            _vm._v(
              "\n            *Payments shown above are based on an estimated purchase price\n            of\n            " +
                _vm._s(
                  _vm._f("numeral")(
                    _vm.request.requestedAmountFinanced,
                    "$0,0.00"
                  )
                ) +
                "\n            (CarSaver Price of\n            " +
                _vm._s(_vm._f("numeral")(_vm.request.carsaverPrice, "$0,0")) +
                ", plus Dealer Fees\n            of " +
                _vm._s(_vm._f("numeral")(_vm.request.dealerFees, "$0,0")) +
                ", plus taxes and\n            fees totaling " +
                _vm._s(_vm._f("numeral")(_vm.taxTitleAndLicense, "$0,0.00")) +
                ").\n            Down Payment of " +
                _vm._s(_vm._f("numeral")(_vm.offer.downPayment, "$0,0")) +
                ". APR\n            of " +
                _vm._s(_vm.offer.rate) +
                "% and " +
                _vm._s(_vm.offer.term) +
                " month term. Approval\n            and terms are subject to credit approval and collateral\n            verification. All loan program rates, terms and conditions are\n            subject to change at any time without notice. CarSaver is not\n            responsible for information provided by third parties. Please\n            see Terms of Service for more information."
            ),
            _c("br"),
            _vm._v(
              "\n            **Additional Stipulations, Terms and Conditions: Approval is\n            specific to vehicle identified and is subject to proof of\n            income, verification of employment, proof of residence and proof\n            of phone.\n        "
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "detailsTab" }, slot: "detailsTab" },
        [
          _c("finance-vehicle-panel", {
            attrs: {
              offer: _vm.offer,
              request: _vm.request,
              pricing: _vm.pricing,
              vehicle: _vm.vehicle
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }