<template>
    <section-box class="coverage-details">
        <div class="header">
            {{ title }}
        </div>

        <coverage-row
            v-for="coverage in coverages"
            :key="coverage.name"
            :label="coverage.name"
            :value="coverage.value"
        />
    </section-box>
</template>

<style lang="scss">
.coverage-details {
    padding: 0;

    .header {
        background-color: $light-gray;
        color: $medium-gray;
        padding: 5px 10px;
        font-weight: bold;
    }
}
</style>

<script>
import CoverageRow from "./CoverageRow";
import SectionBox from "../../../ViewDetailsModal/components/SectionBox";

export default {
    name: "CoverageDetails",
    components: { SectionBox, CoverageRow },
    props: {
        coverages: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    }
};
</script>
