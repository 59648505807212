<template>
    <div v-if="loading" class="loading-wrapper">
        <loading-spinner />
    </div>

    <div v-else-if="noIncentives" class="mt-3">
        <no-incentives-found />
    </div>

    <div v-else class="mt-3">
        <div class="mb-2">
            The following incentive(s) are available for this vehicle.
        </div>

        <b-list-group>
            <b-list-group-item>
                <div class="d-flex justify-content-between align-items-center">
                    <div
                        class="d-flex justify-content-between align-items-center w-100 pr-1"
                    >
                        <span class="font-weight-bold">
                            Term
                        </span>
                        <span class="font-weight-bold">
                            Rate
                        </span>
                        <span class="font-weight-bold">
                            Total Cash
                        </span>
                    </div>
                </div>
            </b-list-group-item>

            <div v-if="hasSpecial">
                <b-list-group-item
                    v-for="term in finance.special.aprTerms"
                    :id="term.length"
                    :key="term.length"
                >
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center w-100 pr-1"
                        >
                            <span>{{ term.length }} Months</span>
                            <span>{{ term.adjRate | numeral("0.00") }}%</span>
                            <span :id="'finance-term-' + term.length">
                                -
                                {{
                                    finance.special.consumerCash.totalCash
                                        | numeral("$0,0")
                                }}
                                <i
                                    v-if="finance.special.consumerCash.programs"
                                    aria-hidden="true"
                                    class="ion-information-circled"
                                />
                            </span>
                        </div>
                    </div>

                    <b-popover
                        :target="'finance-term-' + term.length"
                        title="Consumer Cash"
                        triggers="hover focus"
                    >
                        <div
                            v-if="finance.special.consumerCash.programs.length"
                        >
                            <span
                                v-for="program in finance.special.consumerCash
                                    .programs"
                                :key="program.id"
                            >
                                {{ program.name }}<br />
                            </span>
                        </div>
                    </b-popover>
                </b-list-group-item>
            </div>

            <b-list-group-item>
                <div class="d-flex justify-content-between align-items-center">
                    <div
                        class="d-flex justify-content-between align-items-center w-100 pr-1"
                    >
                        <span>All Others</span>
                        <span>Standard Rate</span>
                        <span id="finance-term-other">
                            -
                            {{
                                finance.standard.consumerCash.totalCash
                                    | numeral("$0,0")
                            }}
                            <i
                                v-if="finance.standard.consumerCash.programs"
                                aria-hidden="true"
                                class="ion-information-circled"
                            />
                        </span>
                    </div>
                </div>

                <b-popover
                    v-if="finance.standard.consumerCash.programs"
                    target="finance-term-other"
                    title="Consumer Cash"
                    triggers="hover focus"
                >
                    <span
                        v-for="program in finance.standard.consumerCash
                            .programs"
                        :key="program.id"
                    >
                        {{ program.name }}<br />
                    </span>
                </b-popover>
            </b-list-group-item>
        </b-list-group>

        <special-programs
            id="finance-special-incentives"
            :special-incentives="finance.consumerSpecials"
        />
    </div>
</template>

<script>
import api from "@/api";
import SpecialPrograms from "./SpecialPrograms";
import LoadingSpinner from "Components/LoadingSpinner";
import NoIncentivesFound from "./NoIncentivesFound";

export default {
    name: "FinanceTab",

    components: { NoIncentivesFound, LoadingSpinner, SpecialPrograms },

    props: {
        zipCode: {
            type: [String, Number],
            required: true
        },
        styleId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            loading: true,
            finance: {
                consumerSpecials: [],
                special: {
                    aprTerms: [],
                    consumerCash: {
                        programs: [],
                        totalCash: null
                    },
                    leaseTerms: []
                },
                standard: {
                    consumerCash: {
                        totalCash: null,
                        programs: []
                    }
                }
            }
        };
    },

    computed: {
        noIncentives() {
            return !this.finance.standard;
        },
        hasSpecial() {
            return this.finance.special;
        }
    },

    methods: {
        load() {
            const component = this;
            api.get(
                `/zipcodes/${this.zipCode}/styles/${this.styleId}/incentives/FINANCE`
            )
                .then(response => {
                    component.finance = response.data;
                    component.loading = false;
                })
                .catch(error => {
                    component.loading = false;
                    console.error(error);
                });
        }
    }
};
</script>
