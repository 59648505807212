<template>
    <div class="pt-3 w-100 get-sales-price-outer-block">
        <div
            class="col-12 text-center d-flex flex-column align-items-center get-sales-price-block"
        >
            <hr class="divider">
            <b-button
                v-if="
                    canUnlockPricing &&
                        supportsTestDrive &&
                        (!restrictedMake || sentPriceByEmail)
                "
                variant="orange"
                block
                class="mb-2"
                @click="scheduleTestDrive"
            >
                {{ $t("component.pricingCard.testDrive") }}
            </b-button>
            <b-button
                v-else-if="
                    canUnlockPricing && (!restrictedMake || sentPriceByEmail)
                "
                variant="orange"
                block
                class="mb-2"
                @click="contactDealer"
            >
                Contact Dealer
            </b-button>
            <div v-else class="d-flex justify-content-center w-100">
                <b-button
                    v-if="isUserSignedIn"
                    variant="orange"
                    block
                    class="mb-2"
                    @click="requestDealerLink"
                >
                    {{ text || defaultText }}
                </b-button>
                <b-button
                    v-else
                    variant="orange"
                    block
                    class="mb-2"
                    @click="showSignInRequiredModal()"
                >
                    {{ text || defaultText }}
                </b-button>
                <sign-in-required-modal ref="signInRequiredModal" />
            </div>

            <span v-if="!canUnlockPricing" class="font-italic">
                {{ $t("component.pricingCard.additionalSavings") }}
            </span>
        </div>
    </div>
</template>
<script>
import { get, call, sync } from "vuex-pathify";
import _ from "lodash";
import SignInRequiredModal from "Components/SignInRequiredModal";
import { trackGAEvent } from "@/lib/GoogleTagManager";

export default {
    name: "GetSalesPriceBlock",
    components: {
        "sign-in-required-modal": SignInRequiredModal
    },
    props: {
        text: {
            type: String,
            default: null
        }
    },
    data: function() {
        return {
            defaultText: this.$i18n.t("component.pricingCard.getSalePrice"),
            signUpHref: "/sign-up"
        };
    },
    computed: {
        isUserSignedIn: get("user/isSignedIn"),
        dealerLinkExists: get("dealerLink/active"),
        canUnlockPricing: get("dealerLink/canUnlockPricing"),
        certificateId: get("vehicleDetails/certificate@id"),
        certificateShownPricing: get("vehicleDetails/certificate@shownPricing"),
        dealer: get("vehicleDetails/dealer"),
        vehicle: get("vehicleDetails/vehicle"),
        sentPriceByEmail: get("vehicleDetails/certificate@sentPriceByEmail"),
        restrictedMake: get("vehicleDetails/vehicle@restrictedMake"),
        selectedDealer: sync("dealerLink/selectedDealer"),
        dealerLinkCertificateId: sync("dealerLink/certificateId"),
        dealerLinkVehicle: sync("dealerLink/vehicle"),
        vin: get("vehicleDetails/inventoryVehicle@vin"),
        supportsTestDrive() {
            return _.get(this.dealer, "supportsTestDrive", true);
        }
    },
    methods: {
        openModal: call("dealerLink/openModal"),
        createLink: call("dealerLink/createLink"),
        openContactModal: call("contactDealer/openModal"),
        openScheduleModal: call("scheduleAppointment/openScheduleModal"),

        contactDealer() {
            if (this.isUserSignedIn) {
                return this.openContactModal({
                    certificateId: this.certificateId
                });
            }

            const returnUrl = new URL(window.location.href, true);
            returnUrl.query.modal = "contact-dealer";

            document.location = "/sign-up?returnUrl=" + returnUrl;
        },
        scheduleTestDrive() {
            this.openScheduleModal({ certificateId: this.certificateId });
        },
        requestDealerLink() {
            if (this.canUnlockPricing) {
                this.selectedDealer = this.dealer;
                this.dealerLinkCertificateId = this.certificateId;
                this.dealerLinkVehicle = this.vehicle;
                this.createLink(this.certificateId);
            } else {
                this.openModal(this.certificateId);
            }
        },
        showSignInRequiredModal() {
            this.$refs.signInRequiredModal.show();
        }
    }
};
</script>
<style lang="scss" scoped>
.get-sales-price-outer-block {
    padding-top: 0;
}
.get-sales-price-block {
    button,
    .btn {
        width: 100%;
    }
    span {
        font-size: px2em(12);
        color: $dark-gray;
        padding: 5px 0;
    }
    .divider {
        margin-top: 0;
        width: 100%;
        height: 2px;
    }
}
</style>
