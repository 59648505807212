<template>
    <detail-block
        v-if="showRebates"
        :title="$t('page.vdp.rebatesAndIncentives')"
        name="rebates"
    >
        <div class="card-body">
            <b-container fluid class="px-0 pb-3">
                <b-tabs ref="tabs" v-model="tabIndex">
                    <b-tab title="Cash">
                        <cash-tab
                            :zip-code="zipCode"
                            :style-id="styleId"
                            @complete="loadOtherTabs"
                        />
                    </b-tab>
                    <b-tab title="Finance">
                        <finance-tab
                            ref="financeTab"
                            :zip-code="zipCode"
                            :style-id="styleId"
                        />
                    </b-tab>
                    <b-tab title="Lease">
                        <lease-tab
                            ref="leaseTab"
                            :zip-code="zipCode"
                            :style-id="styleId"
                        />
                    </b-tab>
                </b-tabs>
            </b-container>
        </div>
    </detail-block>
</template>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import DetailBlock from "Modules/Vehicle/DetailBlock";
import CashTab from "Modules/Vehicle/RebatesAndIncentives/components/CashTab";
import FinanceTab from "Modules/Vehicle/RebatesAndIncentives/components/FinanceTab";
import LeaseTab from "Modules/Vehicle/RebatesAndIncentives/components/LeaseTab";

const DEAL_TYPE_MAPPING = ["CASH", "FINANCE", "LEASE"];

export default {
    name: "RebatesAndIncentives",
    components: { LeaseTab, FinanceTab, CashTab, DetailBlock },

    computed: {
        styleId: get("vehicleDetails/vehicle@styleId"),
        stockType: get("vehicleDetails/inventoryVehicle@stockType"),
        certificateZipCode: get("vehicleDetails/certificate@zipCode"),
        userZipCode: get("user/user@zipCode"),
        dealerZipCode: get("vehicleDetails/dealer@zipCode"),
        locationZipCode: get("location/selectedLocation@zipCode"),
        tabIndex: {
            get() {
                const tabIndex = DEAL_TYPE_MAPPING.indexOf(
                    this.$store.state.vehicleDetails.selectedDealType
                );
                const numberOfTabs =
                    _.get(this.$refs, "tabs.$children.length", 2) / 2;

                if (tabIndex > numberOfTabs - 1) {
                    return numberOfTabs - 1;
                }

                return DEAL_TYPE_MAPPING.indexOf(
                    this.$store.state.vehicleDetails.selectedDealType
                );
            },
            set(value) {
                this.$store.commit(
                    "vehicleDetails/SET_SELECTED_DEAL_TYPE",
                    DEAL_TYPE_MAPPING[value]
                );
            }
        },
        showRebates() {
            return this.stockType !== "used" && !_.isNil(this.zipCode);
        },
        zipCode() {
            return (
                this.certificateZipCode ||
                this.userZipCode ||
                this.dealerZipCode ||
                this.locationZipCode
            );
        }
    },

    methods: {
        loadOtherTabs() {
            this.$refs.financeTab.load();
            this.$refs.leaseTab.load();
        }
    }
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}
</style>
