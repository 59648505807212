var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "expiration", class: { pastDue: _vm.pastDue } },
    [
      _vm._v(
        "\n    expires " +
          _vm._s(_vm._f("moment")(_vm.expirationDate, "L")) +
          "\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }