var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section-box",
    { staticClass: "coverage-details" },
    [
      _c("div", { staticClass: "header" }, [
        _vm._v("\n        " + _vm._s(_vm.title) + "\n    ")
      ]),
      _vm._v(" "),
      _vm._l(_vm.coverages, function(coverage) {
        return _c("coverage-row", {
          key: coverage.name,
          attrs: { label: coverage.name, value: coverage.value }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }