<template>
    <div
        v-if="warrantyEligible"
        id="lifetimeWarrantyBlock"
        class="d-flex flex-column flex-md-row justify-content-around align-items-center pt-4 py-md-2 px-md-3 position-relative"
    >
        <div class="d-flex flex-column logo-block">
            <span class="text-center">This vehicle comes with…</span>
            <div class="d-flex flex-column lifetime-warranty-logo">
                <b-img
                    src="./images/lifetime-warranty-logo.png"
                    alt="Lifetime Warranty - powered by CarSaver"
                />
                <div class="d-flex align-items-center align-self-end mr-3">
                    <span class="font-italic powered-by">powered by</span>
                    <small class="font-weight-bold">CarSaver</small>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row">
            <div
                class="d-flex flex-column flex-md-row align-items-center p-3 p-md-0 icon-block"
            >
                <svg-fallback class="icon m-2" :src="ShieldImage" />
                <span class="d-flex align-items-center"
                    >No additional cost to you.</span
                >
            </div>
            <div
                class="d-flex flex-column flex-md-row align-items-center p-3 p-md-0 icon-block"
            >
                <svg-fallback class="icon m-2" :src="EngineImage" />
                <span class="d-flex align-items-center"
                    >Covers engine & drivetrain.</span
                >
            </div>
            <div
                class="d-flex flex-column flex-md-row align-items-center p-3 p-md-0 icon-block"
            >
                <svg-fallback class="icon m-2" :src="TimeImage" />
                <span class="d-flex align-items-center"
                    >As long as you own the vehicle.</span
                >
            </div>
        </div>
    </div>
</template>

<script>
import ShieldImage from "./images/shield.svg";
import EngineImage from "./images/engine.svg";
import TimeImage from "./images/time.svg";
import { get } from "vuex-pathify";
import SvgFallback from "Components/SvgFallback/index";

export default {
    components: { SvgFallback },

    data() {
        return {
            ShieldImage,
            EngineImage,
            TimeImage
        };
    },

    computed: {
        warrantyEligible: get("vehicleDetails/vehicle@warrantyEligible")
    }
};
</script>

<style lang="scss">
#lifetimeWarrantyBlock {
    width: 100%;
    height: auto;
    min-height: 85px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 10px;

    .logo-block {
        > span {
            font-size: 10px;
            padding-bottom: 3px;
        }

        .lifetime-warranty-logo {
            height: 30px;
            max-width: 185px;
            color: $muted-dark-gray;

            img {
                max-width: 185px;
                height: 30px;
            }

            .powered-by {
                line-height: 15px;
                margin-right: 5px;
                font-size: px2em(10);
            }
        }
    }

    .icon-block {
        .icon {
            font-size: 25px;
            color: $primary-blue;
        }

        span {
            font-family: $helvetica-46-light-italic;
            max-width: 110px;
            color: $dark-gray;
            font-size: px2em(12);
            line-height: 15px;
            height: 30px;
        }
    }

    .asterisk {
        top: 7px;
        right: 10px;
    }
}

@include media-breakpoint-down(sm) {
    #lifetimeWarrantyBlock {
        .logo-block {
            > span {
                padding-bottom: 15px !important;
            }
        }

        .icon-block {
            text-align: center;

            span {
                max-width: 175px;
            }
        }
    }
}
</style>
