<template>
    <b-list-group>
        <b-list-group-item
            v-for="cashIncentive in consumerCash.programs"
            :key="cashIncentive.name"
            class="d-flex justify-content-between align-items-center"
        >
            <span class="text-capitalize incentive-name">
                {{ cashIncentive.name }}
                <expiration :expiration-date="cashIncentive.stopDate" />
            </span>
            - ${{ cashIncentive.cash | numeral }}
        </b-list-group-item>
        <b-list-group-item
            class="d-flex justify-content-between align-items-center"
        >
            <span class="font-weight-bold text-capitalize">
                Total Rebates & Incentives
            </span>
            <span v-if="greaterThanZero" class="font-weight-bold">
                ${{ consumerCash.totalCash | numeral }}
            </span>
        </b-list-group-item>
    </b-list-group>
</template>

<style lang="scss" scoped>
.incentive-name {
    max-width: 600px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>

<script>
import Expiration from "./Expiration";

export default {
    name: "CashPrograms",
    components: { Expiration },
    props: {
        consumerCash: {
            type: Object,
            required: true
        }
    },
    computed: {
        greaterThanZero() {
            return this.consumerCash.totalCash > 0;
        }
    }
};
</script>
