<template>
    <div class="special-programs mt-3">
        <div v-if="sortedSpecialIncentives.length > 0" class="mb-2 mt-2">
            You may also qualify for one or more of the following special
            rebates & incentives. Please speak with your CarSaver Certified
            Dealer for complete details.
        </div>

        <div v-if="!specialProgramsOpen && sortedSpecialIncentives.length > 0">
            <b-button class="mx-auto px-4" @click="specialProgramsOpen = true">
                show special incentives
            </b-button>
        </div>

        <b-collapse :id="id" v-model="specialProgramsOpen">
            <div class="sub-card my-1">
                <b-list-group>
                    <expandable-list-item
                        v-for="specialIncentive in sortedSpecialIncentives"
                        :id="specialIncentive.program.programID"
                        :key="specialIncentive.program.programID"
                    >
                        <template slot="list-item">
                            <span class="text-capitalize incentive-name">
                                {{ specialIncentive.program.title }}
                                <expiration
                                    :expiration-date="
                                        specialIncentive.program.stopDate
                                    "
                                />
                            </span>
                            <span v-if="specialIncentive.cash > 0">
                                - {{ specialIncentive.cash | numeral("$0,0") }}
                            </span>
                        </template>

                        <template slot="details">
                            <span class="font-italic">
                                {{ specialIncentive.program.consumer }}
                            </span>
                        </template>
                    </expandable-list-item>
                </b-list-group>
            </div>
        </b-collapse>
    </div>
</template>

<style lang="scss" scoped>
.incentive-name {
    max-width: 600px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.btn-secondary {
    color: $primary-blue;
    font-style: italic;
}
</style>

<script>
import ExpandableListItem from "../../ExpandableListItem";
import Expiration from "./Expiration";
import _ from "lodash";

export default {
    name: "SpecialPrograms",
    components: { Expiration, ExpandableListItem },
    props: {
        id: {
            type: String,
            required: true
        },
        specialIncentives: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            specialProgramsOpen: false
        };
    },

    computed: {
        sortedSpecialIncentives() {
            return _.sortBy(this.specialIncentives, "program.title");
        }
    }
};
</script>
