<template>
    <detail-block
        v-if="vehicleReview"
        :title="$t('page.vdp.aboutThisVehicle')"
        name="aboutThisVehicle"
    >
        <div id="about-this-vehicle" class="card-body p-0">
            <div
                class="image-block"
                aria-label="Splash Image of vehicle"
                :style="[
                    vehicleSplashImage !== null
                        ? {
                              'background-image':
                                  'url(' + vehicleSplashImage + ')'
                          }
                        : ''
                ]"
            >
                <div
                    class="critics-text d-flex flex-column justify-content-center align-items-center"
                >
                    <i aria-hidden="true" class="quote quote-start">
                        &ldquo;
                    </i>
                    <span class="w-100">
                        {{ vehicleReview | truncatedReview }}
                    </span>
                    <i aria-hidden="true" class="quote quote-end">
                        &rdquo;
                    </i>
                    <b-link @click="onShowMoreClick">
                        {{ $t("page.vdp.viewMore") }}
                    </b-link>
                </div>
            </div>
        </div>
    </detail-block>
</template>
<script>
import EventBus from "@/event-bus";
import { get, call } from "vuex-pathify";
import DetailBlock from "Modules/Vehicle/DetailBlock";

export default {
    name: "AboutThisVehicle",
    components: { DetailBlock },
    filters: {
        truncatedReview(review) {
            const formattedReview = review.replace("<p><p>", "");

            return formattedReview.substring(0, 230) + "...";
        }
    },

    computed: {
        vehicleSplashImage: get("vehicleDetails/media@splashImage"),
        vehicleReview: get("vehicleDetails/reviewText")
    },
    created() {
        this.loadReview();
    },
    methods: {
        loadReview: call("vehicleDetails/loadReview"),
        onShowMoreClick() {
            EventBus.$emit("review-modal-open");
        }
    }
};
</script>
<style lang="scss">
#about-this-vehicle {
    background-color: $white;
    padding: 0;

    .image-block {
        background-color: rgba(0, 125, 198, 0.75);
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        color: $white;
        height: 235px;
        margin-bottom: 35px;

        .critics-text {
            padding: 50px 90px 50px;
            background-color: rgba(0, 125, 198, 0.75);
            color: $white;
            font-size: px2rem(20);
            font-weight: bold;
            line-height: 1.5em;
            z-index: 1;
            height: 100%;

            .quote {
                font-size: px2rem(144);
                font-weight: normal;
                position: absolute;

                &.quote-start {
                    left: -5px;
                    top: 90px;
                }

                &.quote-end {
                    right: 35px;
                    bottom: -20px;
                }
            }

            > a {
                cursor: pointer;
                color: $white;
                font-size: px2rem(14);
                line-height: 18px;
                font-weight: normal;
                text-decoration: underline;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .critics-text {
            padding: 25px 45px 25px !important;
            font-size: px2rem(14) !important;

            .quote {
                font-size: px2rem(100) !important;

                &.quote-start {
                    left: -10px !important;
                    top: 55px !important;
                }

                &.quote-end {
                    right: 20px !important;
                    bottom: -20px !important;
                }
            }
        }
    }
}
</style>
