<template>
    <span class="expiration" :class="{ pastDue: pastDue }">
        expires {{ expirationDate | moment("L") }}
    </span>
</template>

<style scoped>
.expiration {
    font-size: 0.8rem;
    color: green;
}

.pastDue {
    font-size: 0.8rem;
    color: red;
}
</style>

<script>
import moment from "moment";

export default {
    props: {
        expirationDate: {
            required: true,
            type: String
        }
    },

    computed: {
        pastDue() {
            return moment(this.expirationDate).isBefore();
        }
    }
};
</script>
