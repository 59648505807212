<template>
    <b-link :disabled="disabled" @click="show">
        <slot />
    </b-link>
</template>

<script>
import EventBus from "../../event-bus";

export default {
    name: "EventLink",

    props: {
        eventName: {
            required: true,
            type: String
        },
        disabled: {
            required: false,
            type: Boolean,
            defaultValue: false
        }
    },

    methods: {
        show() {
            EventBus.$emit(this.eventName);
        }
    }
};
</script>
