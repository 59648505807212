var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "price-block" }, [
    _vm.type
      ? _c("div", { staticClass: "type" }, [
          _vm._v("\n        " + _vm._s(_vm.type) + "\n    ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "currency", class: { "black-text": _vm.paymentIsHigher } },
      [_vm._v("\n        $\n    ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "value", class: { "black-text": _vm.paymentIsHigher } },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm._f("round")(_vm._f("abs")(_vm.payment))) +
            "\n    "
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "per-month" }, [_vm._v("\n        /mo\n    ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }