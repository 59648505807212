<template>
    <detail-block title="Payment Options" name="paymentOptions">
        <div id="payment-options-card" class="card-body pb-4">
            <b-row>
                <div
                    class="col-12 col-md-2 mb-3 mb-md-0 d-flex justify-content-center"
                >
                    <i aria-hidden="true" class="icon icon-payment p-3" />
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex flex-column">
                        <span class="text font-weight-bold">
                            Planning to lease or finance this vehicle?
                        </span>
                        <span class="sub-text">
                            Seeing all your different payment options allows you
                            to be more informed about your payment
                            <strong>BEFORE</strong> you get to the dealership.
                        </span>
                    </div>
                </div>
                <div
                    class="col-12 col-md-4 d-flex justify-content-center align-items-center button-block py-3 py-md-0"
                >
                    <b-button variant="primary" @click="openPaymentOptions">
                        View Payment Options
                    </b-button>
                </div>
            </b-row>
        </div>
    </detail-block>
</template>
<script>
import URL from "url-parse";
import DetailBlock from "Modules/Vehicle/DetailBlock";
import { get, dispatch } from "vuex-pathify";
import EventBus from "@/event-bus";

export default {
    components: { DetailBlock },
    computed: {
        isSignedIn: get("user/isSignedIn"),
        certificateId: get("vehicleDetails/certificate@id"),
        canUnlockPricing: get("dealerLink/canUnlockPricing")
    },
    methods: {
        openPaymentOptions() {
            if (!this.isSignedIn) {
                const returnUrl = new URL(window.location.href, true);
                returnUrl.query.modal = "dealer-link";

                document.location = "/sign-up?returnUrl=" + returnUrl;
            } else if (!this.canUnlockPricing) {
                dispatch("dealerLink/openModal", this.certificateId);
            } else {
                EventBus.$emit("payment-matrix-modal-open");
            }
        }
    }
};
</script>
<style lang="scss">
#payment-options-card {
    .icon-payment {
        font-size: px2rem(70);
        color: $primary-blue;
    }
    .text {
        font-size: px2rem(16);
    }
    .btn-primary {
        height: 40px;
        width: 200px;
    }
}
</style>
