var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-body insure-quote-list" },
    [
      _vm._l(_vm.quotes, function(quote, key) {
        return _c("insurance-quote-row", { key: key, attrs: { quote: quote } })
      }),
      _vm._v(" "),
      _c("div", { staticClass: "disclaimer" }, [
        _vm._v(
          "\n        This is not a solicitation on behalf of Liberty Mutual or any other\n        carrier. CarSaver is not a licensed insurance provider representing\n        Liberty Mutual or any other carrier on this website. Please consult\n        with the carrier for coverage and policy details.\n    "
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }