<template>
    <div class="contact-button-wrapper wrapper">
        <c-s-modal
            ref="successModal"
            :ok-only="true"
            :hide-footer="true"
            modal-class="success-modal"
        >
            <div slot="modal-title">
                <span>{{
                    $t("component.contactDealer.successModal.title")
                }}</span>
            </div>
            <b-container>
                <b-row>
                    <b-col cols="12" class="px-4 pb-3 pt-0">
                        <div
                            class="d-flex flex-column text-center success-block p-3"
                        >
                            <span class="mb-1">
                                {{
                                    $t(
                                        "component.contactDealer.successModal.messageSent"
                                    )
                                }}
                            </span>
                            <div
                                class="certified-dealer d-flex flex-column p-2 mb-1"
                            >
                                <span v-if="dealerIsCertified">{{
                                    $t(
                                        "component.contactDealer.successModal.certifiedDealer"
                                    )
                                }}</span>
                                <strong v-if="dealer">
                                    {{ dealer.name }}
                                </strong>
                            </div>
                            <span>{{
                                $t(
                                    "component.contactDealer.successModal.contactingYouSoon"
                                )
                            }}</span>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <next-steps-buttons />
        </c-s-modal>

        <c-s-modal
            ref="formModal"
            modal-class="contact-dealer-modal"
            ok-only
            title="Contact Dealer"
            size="lg"
            lazy
            @hidden="onHidden"
            @shown="onShown"
        >
            <div slot="modal-title">
                <span v-if="dealerIsCertified">{{
                    $t("component.contactDealer.title")
                }}</span>
                <span v-else>Out of Network</span>
            </div>

            <contact-dealer-loader v-if="isLoading" />

            <b-container v-else>
                <p v-if="!dealerIsCertified">
                    This vehicle is a standard listing. This dealer is not part
                    of the CarSaver network.
                </p>
                <b-row>
                    <div class="col-md-8">
                        <form
                            id="contact-dealer-form"
                            class="horizontal-form"
                            @submit.prevent="onSubmit"
                            @keydown="form.errors.clear($event.target.name)"
                        >
                            <div class="inputs-wrapper">
                                <div class="form-group input-group-sm">
                                    <label
                                        class="question-text"
                                        for="questionType"
                                    >
                                        {{
                                            $t(
                                                "component.contactDealer.question"
                                            )
                                        }}
                                    </label>
                                    <select
                                        id="questionType"
                                        v-model="form.questionType"
                                        class="form-control d-sm-block d-md-inline d-lg-inline"
                                    >
                                        <option value="">
                                            {{
                                                $t(
                                                    "component.contactDealer.selectOption"
                                                )
                                            }}
                                        </option>
                                        <option value="Pricing">
                                            {{
                                                $t(
                                                    "component.contactDealer.pricingOption"
                                                )
                                            }}
                                        </option>
                                        <option value="Vehicle Availability">
                                            {{
                                                $t(
                                                    "component.contactDealer.availabilityOption"
                                                )
                                            }}
                                        </option>
                                        <option value="Financing">
                                            {{
                                                $t(
                                                    "component.contactDealer.financingOption"
                                                )
                                            }}
                                        </option>
                                        <option value="My Trade In">
                                            {{
                                                $t(
                                                    "component.contactDealer.tradeInOption"
                                                )
                                            }}
                                        </option>
                                        <option value="Other">
                                            {{
                                                $t(
                                                    "component.contactDealer.otherOption"
                                                )
                                            }}
                                        </option>
                                    </select>
                                    <div
                                        v-if="form.errors.has('questionType')"
                                        class="help-block with-errors text-danger"
                                    >
                                        <p
                                            class="has-error text-danger"
                                            v-text="
                                                form.errors.get('questionType')
                                            "
                                        >
                                            Error Text
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label
                                        class="comment-box-label"
                                        for="question"
                                    >
                                        {{
                                            $t(
                                                "component.contactDealer.questionOrComment"
                                            )
                                        }}
                                    </label>
                                    <textarea
                                        id="question"
                                        v-model="form.question"
                                        rows="4"
                                        class="form-control"
                                    />
                                    <div
                                        v-if="form.errors.has('question')"
                                        class="help-block with-errors text-danger"
                                    >
                                        <p
                                            class="has-error text-danger"
                                            v-text="form.errors.get('question')"
                                        >
                                            Error Text
                                        </p>
                                    </div>
                                </div>
                                <div
                                    v-if="dealer"
                                    class="dealership-info d-flex flex-column d-sm-flex d-md-none d-lg-none d-xl-none mt-0 mb-3"
                                >
                                    <div
                                        v-if="dealerIsCertified"
                                        class="certified-dealer"
                                    >
                                        <span>{{
                                            $t(
                                                "component.contactDealer.certifiedDealer"
                                            )
                                        }}</span>
                                    </div>
                                    <div class="dealership-name">
                                        <span>{{ dealer.name }}</span>
                                    </div>
                                    <div>
                                        <span class="distance">
                                            {{
                                                $t(
                                                    "component.contactDealer.distance"
                                                )
                                            }}
                                            {{ distance | numeral("0.0") }}
                                            {{
                                                $t(
                                                    "component.contactDealer.miles"
                                                )
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <input
                                id="ambassadorPin"
                                v-model="form.ambassadorPin"
                                type="hidden"
                                name="ambassadorPin"
                            />
                        </form>

                        <div
                            v-if="user"
                            class="d-none d-sm-none d-md-flex flex-column user-info"
                        >
                            <div class="mb-2">
                                <span>{{
                                    $t(
                                        "component.contactDealer.contactInfoCorrect"
                                    )
                                }}</span>
                            </div>
                            <edit-contact-info />
                        </div>
                    </div>
                    <div
                        class="col-md-4 d-none d-sm-none d-md-flex flex-column"
                    >
                        <div
                            v-if="vehicle"
                            class="vehicle-image flex-column text-center"
                        >
                            <b-img
                                v-if="imageUrl"
                                :src="imageUrl"
                                :alt="vehicleName"
                                fluid
                            />
                            <div v-else class="my-4">
                                <body-style-icon :id="vehicleBodyStyle" />
                            </div>
                            <div class="vehicle-title-box py-2">
                                <h4>{{ vehicleName }}</h4>
                                <span>{{ vehicle.trim }}</span>
                            </div>
                        </div>
                        <div
                            v-if="dealer"
                            class="dealership-info d-flex flex-column h-100 justify-content-end"
                        >
                            <span
                                v-if="dealerIsCertified"
                                class="certified-dealer"
                            >
                                {{
                                    $t(
                                        "component.contactDealer.certifiedDealer"
                                    )
                                }}
                            </span>
                            <span class="dealership-name">
                                {{ dealer.name }}
                            </span>
                            <span v-if="!isDelivery" class="distance">
                                {{ $t("component.contactDealer.distance") }}
                                {{ distance | numeral("0.0") }}
                                {{ $t("component.contactDealer.miles") }}
                            </span>
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-12">
                        <div class="terms-row mt-0 mt-lg-3 mb-3">
                            <div class="terms-and-condition-block">
                                <span class="terms font-weight-bold">
                                    {{
                                        $t("component.contactDealer.termsBold")
                                    }}
                                </span>

                                <span class="terms font-italic">
                                    {{ $t("component.contactDealer.terms") }}
                                </span>
                            </div>
                        </div>
                    </div>
                </b-row>
            </b-container>
            <template slot="modal-footer">
                <ladda-b-btn
                    class="px-5"
                    variant="orange"
                    :loading="submitting"
                    :disabled="isFormInvalid"
                    @click="onSubmit"
                >
                    {{ buttonTitle }}
                </ladda-b-btn>
            </template>
        </c-s-modal>

        <carvana-modal ref="carvanaModal" />
    </div>
</template>

<style lang="scss">
.success-modal {
    .success-block {
        background: $very-light-gray;
        border-radius: 2px;

        > .certified-dealer {
            > span {
                font-size: px2rem(12);
                color: $muted-dark-gray;
                line-height: 15px;
            }
            > strong {
                font-size: px2rem(16);
            }
        }
    }
}

.contact-dealer-modal {
    .modal-body {
        padding: 15px 20px;

        select {
            width: 195px;
            font-size: px2rem(14);
        }
        textarea {
            height: 60px;
            border-radius: 0;
        }
        .form-check-label {
            font-size: px2rem(14);
        }
        .question-text {
            font-size: px2rem(18);
        }
        .comment-box-label {
            font-size: px2rem(14);
        }
        .user-info {
            bottom: 0;

            .user-name {
                height: auto;
                max-width: 250px;

                > span {
                    font-size: px2rem(14);
                    font-weight: bold;
                    color: $medium-gray;
                    height: auto;
                    line-height: 20px;
                    display: block;
                }
            }
            .user-phone {
                height: auto;
                max-width: 250px;
                > span {
                    font-size: px2rem(14);
                    color: $dark-gray;
                    line-height: 20px;
                    height: auto;
                    display: block;
                }
            }
            .user-email {
                max-width: 250px;
                font-size: px2rem(14);
                color: $dark-gray;
                height: auto;
                line-height: 20px;
                display: block;
            }
        }

        .vehicle-image {
            i {
                font-size: px2rem(60);
            }

            .vehicle-title-box {
                text-align: center;
                color: $dark-gray;
                line-height: 10px;

                > h4 {
                    font-size: px2rem(16);
                    font-weight: bold;
                    margin-bottom: 0;
                }

                > span {
                    font-size: px2rem(14);
                    font-weight: normal;
                }
            }
        }

        .dealership-info {
            .certified-dealer {
                font-size: px2rem(12);
                color: $muted-dark-gray;
                line-height: 15px;
            }
            .dealership-name {
                font-size: 16px;
                color: $medium-gray;
                line-height: 20px;
                font-weight: bold;
                height: auto;
                display: block;
                max-width: 250px;
            }
            .distance {
                font-size: px2rem(12);
            }
        }
    }

    .terms-row {
        .terms-and-condition-block {
            line-height: 15px;
            .terms {
                font-size: px2rem(12);
                color: $dark-gray;
                margin-top: 5px;
            }
        }
    }

    .col-md-8 {
        border-right: 1px solid #d4d4d4;
    }

    .modal-footer {
        background-color: $light-gray;
        height: 65px;

        > .btn-orange {
            margin: 0;
            width: 200px;
            font-size: px2rem(16);
        }
    }

    @include media-breakpoint-down(sm) {
        .col-md-8 {
            border-right: none;
        }
    }
}
</style>

<script>
import { call, get, sync } from "vuex-pathify";
import LaddaBBtn from "../LaddaBBtn";
import CSModal from "../CSModal";
import EventBus from "../../event-bus";
import EditContactInfo from "../EditContactInfo/index";
import Form from "../../api/form";
import { trackGAEvent, trackPageView } from "@/lib/GoogleTagManager";
import _ from "lodash";
import ContactDealerLoader from "Components/ContactDealer/components/ContactDealerLoader/index";
import NextStepsButtons from "Components/NextStepsButtons/index";
import shouldAutoLoadModal from "@/mixins/shouldAutoLoadModal";
import BodyStyleIcon from "Modules/ShopBy/BodyStyle/components/BodyStyleIcon/index";
import CarvanaModal from "Modules/Vehicle/CarvanaModal/index";

export default {
    name: "ContactDealer",
    components: {
        CarvanaModal,
        BodyStyleIcon,
        NextStepsButtons,
        ContactDealerLoader,
        EditContactInfo,
        LaddaBBtn,
        CSModal
    },

    mixins: [shouldAutoLoadModal],

    data() {
        return {
            form: new Form({
                questionType: null,
                question: null,
                ambassadorPin: null
            }),
            submitting: false
        };
    },

    computed: {
        vehicleName() {
            const year = _.get(this.vehicle, "year");
            const make = _.get(this.vehicle, "make");
            const model = _.get(this.vehicle, "model");
            const trim = _.get(this.vehicle, "trim");

            return `${year} ${make} ${model} ${trim}`;
        },
        isFormInvalid() {
            return !this.form.questionType || this.form.errors.any();
        },
        buttonTitle() {
            return this.submitting ? "Submitting..." : "Submit";
        },
        ambassadorPin: get("sessionReset/ambassadorId"),
        vehicle: get("contactDealer/vehicle"),
        dealer: get("contactDealer/dealer"),
        user: get("contactDealer/user"),
        distance: get("contactDealer/distance"),
        imageUrl: get("contactDealer/imageUrl"),
        isOpen: sync("contactDealer/isOpen"),
        isLoading: get("contactDealer/isLoading"),
        certificateId: get("contactDealer/certificateId"),
        vdpCertificateId: get("vehicleDetails/certificate@id"),
        questionType: sync("contactDealer/questionType"),
        dealerLinkActive: sync("dealerLink/active"),
        leadId: sync("dealerLink/leadId"),
        deliveryAvailability: get(
            "vehicleDetails/dealer@preferences.deliveryAvailability"
        ),

        isDelivery: function() {
            return _.eq(this.deliveryAvailability, "DELIVERY_ONLY");
        },

        dealerIsCertified() {
            return _.get(this.dealer, "certified", false);
        },

        vehicleBodyStyle() {
            const bodyStyle = _.get(this.vehicle, "bodyStyle", "sedan");

            return _.isNil(bodyStyle) ? "sedan" : bodyStyle;
        },

        question: {
            get() {
                const questionType = this.form.questionType;

                switch (questionType) {
                    case "Pricing":
                        return "I have a question about the pricing on this vehicle. ";
                    case "Vehicle Availability":
                        return `I’d like to know if this ${this.vehicleName} listed on your site is still available.`;
                    case "Financing":
                        return "I have a question about financing.";
                    case "My Trade In":
                        return "I have a question about the car I plan to trade-in.";
                    case "Other":
                        return "I have a few questions. Will you please give me a call?";
                    default:
                        return "";
                }
            },
            set(question) {
                this.form.question = question;
            }
        }
    },

    watch: {
        ambassadorPin(newVal) {
            this.form.ambassadorPin = newVal;
        },
        isOpen(newVal) {
            if (newVal === true) {
                this.$refs.formModal.show();
            }
        },
        questionType(newVal) {
            if (!_.isNil(newVal)) {
                this.form.questionType = newVal;
            }
        },
        question(newVal) {
            if (!_.isNil(newVal)) {
                this.form.question = newVal;
            }
        }
    },
    created() {
        const component = this;
        EventBus.$on("success-modal.close", () => {
            component.closeSuccessModal();
        });
    },

    mounted() {
        this.shouldAutoLoadModal("contact-dealer", () => {
            this.openModal({ certificateId: this.vdpCertificateId });
        });

        this.form.ambassadorPin = this.ambassadorPin;
    },

    methods: {
        openModal: call("contactDealer/openModal"),
        onHidden() {
            this.form.reset();
            this.questionType = null;
            this.submitting = false;
            this.isOpen = false;
        },
        onShown() {
            trackPageView(this, `${window.location.pathname}/contact-dealer`);
        },
        closeSuccessModal() {
            this.$refs.successModal.hide();
        },
        onSubmit() {
            this.submitting = true;
            const component = this;
            this.form
                .submit(`/garage/${this.certificateId}/contact-dealer`)
                .then(response => {
                    this.leadId = _.get(response, "data.id");
                    component.dealerLinkActive = true;

                    trackGAEvent(component, {
                        category: "contact-dealer",
                        action: "submit",
                        label: "Submit"
                    });

                    trackPageView(
                        component,
                        `${window.location.pathname}/contact-dealer/thank-you`
                    );

                    this.submitting = false;
                    this.$refs.formModal.hide();

                    // If Carvana
                    if (_.get(this.vehicle, "source") === "CV") {
                        this.$refs.carvanaModal.show();
                        return;
                    }

                    this.$refs.successModal.show();
                })
                .catch(error => {
                    this.submitting = false;
                    console.log(error);
                });

            // Kiosk requires an explicit return of false - even with .prevent on the event.
            return false;
        }
    }
};
</script>
