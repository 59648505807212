<template>
    <div v-if="loading" class="loading-wrapper">
        <loading-spinner />
    </div>

    <div v-else-if="noIncentives" class="mt-3">
        <no-incentives-found />
    </div>

    <div v-else class="mt-3">
        <div class="mb-2">
            The following incentive(s) are available for this vehicle.
        </div>

        <b-list-group>
            <b-list-group-item>
                <div class="d-flex justify-content-between align-items-center">
                    <div
                        class="d-flex justify-content-between align-items-center w-100 pr-1"
                    >
                        <span class="font-weight-bold">
                            Term
                        </span>
                        <span class="font-weight-bold">
                            Total Cash
                        </span>
                    </div>
                </div>
            </b-list-group-item>

            <div v-if="hasSpecial">
                <b-list-group-item
                    v-for="term in lease.special.leaseTerms"
                    :key="term.length"
                >
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center w-100 pr-1"
                        >
                            <span>{{ term.length }} Months</span>
                            <span :id="'term-' + term.length">
                                -
                                {{
                                    (lease.special.consumerCash.totalCash +
                                        term.ccrCash.totalCCR)
                                        | numeral("$0,0")
                                }}
                                <i
                                    aria-hidden="true"
                                    class="ion-information-circled"
                                />
                            </span>
                        </div>
                    </div>

                    <b-popover
                        :target="'term-' + term.length"
                        title="Consumer Cash"
                        triggers="hover focus"
                    >
                        {{ term.ccrCash.displayName }}
                        <div v-if="lease.special.consumerCash.programs.length">
                            <span
                                v-for="program in lease.special.consumerCash
                                    .programs"
                                :key="program.id"
                            >
                                {{ program.name }}<br />
                            </span>
                        </div>
                    </b-popover>
                </b-list-group-item>
            </div>

            <b-list-group-item>
                <div class="d-flex justify-content-between align-items-center">
                    <div
                        class="d-flex justify-content-between align-items-center w-100 pr-1"
                    >
                        <span>Other</span>
                        <span id="term-other">
                            -
                            {{
                                lease.standard.consumerCash.totalCash
                                    | numeral("$0,0")
                            }}
                            <i
                                v-if="hasStandardPrograms"
                                class="ion-information-circled"
                                aria-hidden="true"
                            />
                        </span>
                    </div>
                </div>

                <b-popover
                    v-if="hasStandardPrograms"
                    :target="'term-other'"
                    title="Consumer Cash"
                    triggers="hover focus"
                >
                    <div v-if="lease.standard.consumerCash.programs">
                        <span
                            v-for="program in lease.standard.consumerCash
                                .programs"
                            :key="program.id"
                        >
                            {{ program.name }}<br />
                        </span>
                    </div>
                </b-popover>
            </b-list-group-item>
        </b-list-group>

        <special-programs
            id="lease-special-incentives"
            :special-incentives="lease.consumerSpecials"
        />
    </div>
</template>

<script>
import api from "@/api";
import _ from "lodash";
import SpecialPrograms from "./SpecialPrograms";
import LoadingSpinner from "Components/LoadingSpinner";
import NoIncentivesFound from "./NoIncentivesFound";

export default {
    name: "LeaseTab",

    components: { NoIncentivesFound, LoadingSpinner, SpecialPrograms },

    props: {
        zipCode: {
            type: [String, Number],
            required: true
        },
        styleId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            loading: true,
            lease: {
                consumerSpecials: [],
                special: {
                    aprTerms: [],
                    consumerCash: {
                        programs: [],
                        totalCash: null
                    },
                    leaseTerms: []
                },
                standard: {
                    consumerCash: {
                        totalCash: null,
                        programs: []
                    }
                }
            }
        };
    },

    computed: {
        hasStandardPrograms() {
            return (
                _.get(this.lease, "standard.consumerCash.programs.length") &&
                this.lease.standard.consumerCash.programs.length > 0
            );
        },

        noIncentives() {
            return !this.lease.standard;
        },

        hasSpecial() {
            return this.lease.special;
        }
    },

    methods: {
        load() {
            const component = this;
            api.get(
                `/zipcodes/${this.zipCode}/styles/${this.styleId}/incentives/LEASE`
            )
                .then(response => {
                    component.lease = response.data;
                    component.loading = false;
                })
                .catch(error => {
                    component.loading = false;
                    console.error(error);
                });
        }
    }
};
</script>
