var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSimilarBlock
    ? _c(
        "detail-block",
        {
          attrs: {
            name: "similar",
            title: "Similar Vehicles",
            loading: _vm.loading
          }
        },
        [
          _c(
            "div",
            { staticClass: "container-fluid mb-3 mx-3" },
            [
              _c("p", [
                _vm._v("\n            Customers looking at the\n            "),
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.vehicle.make) + " " + _vm._s(_vm.vehicle.model)
                  )
                ]),
                _vm._v(" also\n            looked at these vehicles.\n        ")
              ]),
              _vm._v(" "),
              _c(
                "slider",
                {
                  ref: "similarVehicles",
                  attrs: {
                    options: _vm.flickityOptions,
                    "css-class": "similarVehicles"
                  }
                },
                _vm._l(_vm.vehicles, function(similarVehicle) {
                  return _c(
                    "div",
                    {
                      key: similarVehicle.id,
                      staticClass: "mb-2 carousel-cell similarVehicle"
                    },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "similarVehicleLink",
                          attrs: { href: _vm.vehicleUrl(similarVehicle) },
                          on: {
                            click: function($event) {
                              return _vm.similarVehicleClick(similarVehicle)
                            }
                          }
                        },
                        [
                          _c("b-img", {
                            attrs: {
                              src: similarVehicle.image,
                              alt: `${similarVehicle.year} ${similarVehicle.make} ${similarVehicle.model}`,
                              fluid: ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("h3", { staticClass: "text-left ml-2" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(similarVehicle.year) +
                            " " +
                            _vm._s(similarVehicle.make) +
                            "\n                    " +
                            _vm._s(similarVehicle.model) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-left ml-2" }, [
                        _vm._v(
                          "\n                    " + _vm._s(similarVehicle.trim)
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("numeral")(
                                _vm.price(similarVehicle),
                                "$0,0"
                              )
                            ) +
                            "\n                "
                        )
                      ])
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }