var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-body planning-to-finance" }, [
    _c("div", { staticClass: "row py-md-4" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-12 col-md-4 d-flex justify-content-center align-items-center button-block pt-3 pb-4 py-md-0"
        },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "primary" },
              on: {
                click: function($event) {
                  return _vm.onGetPreApprovedClick()
                }
              }
            },
            [_vm._v("\n                Get Pre-approved\n            ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-md-2 mb-3 mb-md-0" }, [
      _c("div", { staticClass: "icon-block" }, [
        _c("i", {
          staticClass: "icon-calendar",
          attrs: { "aria-hidden": "true" }
        })
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-md-6" }, [
      _c("div", { staticClass: "d-flex flex-column question-block" }, [
        _c("h3", { staticClass: "mb-1 mb-md-0" }, [
          _vm._v(
            "\n                    Are you planning to finance this vehicle?\n                "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                    Financing through CarSaver allows you to lock-in your\n                    payment "
          ),
          _c("strong", [_vm._v("BEFORE")]),
          _vm._v(
            " you get to the\n                    dealership.\n                "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }