var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vehicle-panel",
    { staticClass: "insurance-vehicle-panel", attrs: { vehicle: _vm.vehicle } },
    [
      _c(
        "title-with-price",
        [
          _c("template", { slot: "title" }, [
            _c("strong", [_vm._v("Total Premium")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "blue-price",
              attrs: { slot: "price" },
              slot: "price"
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("numeral")(
                      _vm._f("emptyDash")(_vm.quote, "paymentPlan.premium"),
                      "$0,0"
                    )
                  ) +
                  "\n        "
              )
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("title-with-price", [
        _c("div", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("Average"),
          _c("br"),
          _c("strong", [_vm._v("Payment")])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blue-price",
            attrs: { slot: "price" },
            slot: "price"
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("numeral")(
                    _vm._f("emptyDash")(
                      _vm.quote,
                      "paymentPlan.avgMonthlyPayment"
                    ),
                    "$0,0.00"
                  )
                ) +
                "\n        "
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }