<template>
    <div ref="image" class="progressive-vehicle-image">
        <canvas
            v-if="!shouldImageRender"
            ref="canvas"
            width="1"
            height="1"
            class="canvas"
        />
        <div
            :style="wrapperStyle"
            class="vehicle-image-wrapper inventory-desktop"
        >
            <transition
                enter-class="vehicle-image-enter"
                enter-active-class="vehicle-image-before-enter"
            >
                <img
                    v-show="shouldImageRender"
                    ref="main"
                    :class="imageClassName"
                    :src="image"
                    :alt="alt"
                    :style="imageStyle"
                    class="inventory-desktop-image"
                />
            </transition>
            <transition
                enter-class="vehicle-image-enter"
                enter-active-class="vehicle-image-before-enter"
            >
                <div
                    v-if="shouldPlaceholderRender"
                    :class="{
                        'vehicle-image-placeholder-out': shouldImageRender
                    }"
                    :style="placeholderStyle"
                    class="vehicle-image-placeholder"
                />
            </transition>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.progressive-vehicle-image {
    .canvas {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }
    .vehicle-image-main {
        width: 100%;
        height: auto;
        z-index: 1;
        transition-duration: 0.3s;
        transition-property: all;
        transition-timing-function: ease-out;
        transform: translateZ(0);
    }
    .vehicle-image-before-enter {
        opacity: 1;
    }
    .vehicle-image-enter {
        opacity: 0;
    }
    .vehicle-image-placeholder {
        z-index: 0;
        overflow: hidden;
        transition-duration: 300ms;
        transition-property: all;
        transition-timing-function: ease-out;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1.1);
        width: 100%;
        height: 100%;
        background-size: cover;
    }
    .vehicle-image-placeholder-out {
        transition-duration: inherit;
        transition-property: all;
        transition-timing-function: ease-out;
        /**
             * the transitioon delay needs to be longer than the
             * .vehicle-image-main transition-duration, otherwise it will flick
             * because there won't be a background.
            */
        transition-delay: 0.4s;
        opacity: 0;
    }
}
</style>

<script>
import _ from "lodash";

export default {
    name: "VehicleImage",

    props: {
        imageUrl: {
            type: String,
            default: null
        },
        backupImageUrl: {
            type: String,
            default: null
        },
        alt: {
            type: String,
            default: null
        },
        imageClass: {
            type: String,
            default: null
        },
        fill: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            defaultBlur: 20,
            placeholder: "/assets/images/no_car_image.svg",
            fallback: "/assets/images/no_car_image.svg",
            choiceIndex: 0,
            isRendered: false,
            placeholderImage: null,
            image: null,
            isPollingKilled: false,
            aspectRatioDetect: 0.5625
        };
    },

    computed: {
        imageStyle() {
            if (!this.fill) {
                return {};
            }

            return {
                width: "100%",
                height: "auto"
            };
        },

        imageClassName() {
            let defaultClassName = "vehicle-image-main";

            if (!_.isNil(this.imageClass)) {
                defaultClassName += ` ${this.imageClass}`;
            }

            return defaultClassName;
        },

        wrapperStyle() {
            if (this.noRatio) {
                return {};
            }

            return {
                paddingBottom: `${this.calculatedRatio * 100}%`
            };
        },

        blurStyle() {
            const blur = this.defaultBlur;

            return this.getBlurStyle(blur);
        },

        placeholderStyle() {
            return {
                ...this.blurStyle,
                "background-image": `url(${this.placeholder})`
            };
        },

        shouldPlaceholderRender() {
            return !!this.placeholderImage;
        },

        shouldImageRender() {
            return this.isRendered;
        },

        imageChoices() {
            // ordered by preference
            return [this.imageUrl, this.backupImageUrl, this.fallback].filter(
                img => !_.isNil(img)
            );
        }
    },

    watch: {
        imageUrl() {
            this.handleImageLoading();
        }
    },

    mounted() {
        this.handleImageLoading();
    },

    methods: {
        getBlurStyle(amount) {
            if (amount === 0) {
                return;
            }

            return {
                filter: `blur(${amount}px)`
            };
        },

        defineAspectRatio(img) {
            const delay = 2500;
            const pollInterval = 80;

            const poll = setInterval(() => {
                if (!img.naturalWidth) {
                    return;
                }

                clearInterval(poll);

                const { naturalHeight, naturalWidth } = img;

                this.aspectRatioDetect = naturalHeight / naturalWidth;
            }, pollInterval);

            setTimeout(() => {
                if (img.naturalWidth) {
                    return;
                }

                clearInterval(poll);
                this.isPollingKilled = true;
            }, delay);
        },

        loadPlaceholder() {
            if (!this.placeholder) {
                return;
            }

            const image = new Image();
            const src = this.placeholder;

            image.onload = () => {
                this.placeholderImage = src;

                // Dispatches an event on placeholder image load
                this.$emit("load-placeholder", src);
            };

            image.onerror = error => {
                this.$emit("placeholder-error", error);

                if (process.env.NODE_ENV !== "production") {
                    console.warn(
                        "[vue-vehicle-image] An error occured during the placeholder image loading"
                    );
                }
            };

            image.src = src;
        },

        loadImage(imageSource) {
            const image = new Image();
            const delay = 0;

            // reset the image holder
            this.image = null;
            this.isRendered = false;

            if (!this.aspectRatio) {
                this.defineAspectRatio(image);
            }

            image.onload = () => {
                if (this.image) {
                    return;
                }

                if (this.isPollingKilled && !this.aspectRatio) {
                    this.defineAspectRatio(image);
                }

                // assign the image
                this.image = imageSource;

                let ctx;
                try {
                    // the drawImage it's a synchronous method, so when it's done
                    // the nextTick will notify the view that we're ready
                    // to fadeIn the main image
                    ctx = this.$refs.canvas.getContext("2d");
                    ctx.drawImage(this.$refs.main, 0, 0);
                } catch (e) {
                    // see https://github.com/MatteoGabriele/vue-vehicle-image/issues/30
                }

                // next tick to know when the image is rendered
                this.$nextTick(() => {
                    // timeout for a custom delay
                    setTimeout(() => {
                        this.isRendered = true;
                        // remove placeholder image
                        this.placeholderImage = null;
                    }, delay);
                });

                this.$emit("load", image.src);
            };

            image.onerror = error => {
                this.$emit("error", error);

                if (process.env.NODE_ENV !== "production" && !this.fallback) {
                    console.warn(
                        "[vue-vehicle-image] An error occured during the image loading"
                    );
                }

                this.choiceIndex++; // try next image

                this.handleImageLoading();
            };

            image.src = imageSource;
        },

        handleImageLoading() {
            this.loadPlaceholder();

            if (this.choiceIndex < this.imageChoices.length) {
                this.loadImage(this.imageChoices[this.choiceIndex]);
            }
        }
    }
};
</script>
