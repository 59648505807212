var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "overlay-icons-block" } }, [
    _c(
      "div",
      {
        staticClass:
          "icons d-none d-lg-flex flex-row justify-content-end position-absolute"
      },
      [
        _c("heart-icon"),
        _vm._v(" "),
        _c(
          "b-link",
          {
            class: { "disabled-link": !_vm.hasImages },
            attrs: { disabled: !_vm.hasImages },
            on: { click: _vm.goToPhotos }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-center block position-relative"
              },
              [
                _c("i", {
                  staticClass: "icon icon-camera_font",
                  attrs: { "aria-hidden": "true" }
                }),
                _vm._v(" "),
                _vm.hasImages
                  ? _c("span", {
                      staticClass: "position-absolute",
                      domProps: { textContent: _vm._s(_vm.numberOfImages) }
                    })
                  : _vm._e()
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "b-link",
          {
            class: { "disabled-link": _vm.hasNo360Images || !_vm.hasImages },
            attrs: { disabled: _vm.hasNo360Images || !_vm.hasImages },
            on: { click: _vm.goTo360 }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-center block"
              },
              [
                _c("i", {
                  staticClass: "icon icon-360_font",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "b-link",
          {
            class: { "disabled-link": !_vm.vrAvailable },
            attrs: { disabled: !_vm.vrAvailable },
            on: { click: _vm.goToVr }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-center block"
              },
              [
                _c("i", {
                  staticClass: "icon icon-vr_cardboard_solid",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }