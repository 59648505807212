<template>
    <b-modal
        id="get-insurance-modal"
        ref="getInsuranceModal"
        size="lg"
        body-class="m-0 p-0"
        hide-header
        hide-footer
    >
        <div class="m-0 p-0">
            <div class="insure-hero m-0 p-0 d-flex flex-column">
                <span class="align-self-end" @click="close">
                    <i aria-hidden="true" class="ion-ios-close-outline">
                        &nbsp;
                    </i>
                </span>
                <div class="container d-table">
                    <div class="lib-m-logo" />
                    <div class="special-discount">
                        <span>{{
                            $t("component.getInsuranceQuote.title")
                        }}</span>
                    </div>
                    <div class="btn-and-phone-block d-flex flex-column">
                        <b-link
                            class="d-flex justify-content-center align-items-center p-0 m-0 text-center btn btn-outline-white mb-2"
                            @click="gotoInsurance()"
                        >
                            {{ $t("component.getInsuranceQuote.getQuoteBtn") }}
                        </b-link>
                        <div class="phone-number">
                            {{ $t("component.getInsuranceQuote.orCall") }}
                            <a href="tel:888-727-2146">
                                888-727-2146
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<style lang="scss">
#get-insurance-modal {
    margin-bottom: 5px;

    .show-modal-btn {
        line-height: 29px;
        height: 30px;
        border-radius: 2px;
        border: 1px solid $muted-gray;
        padding: 0;
        margin-bottom: 5px;
        background-color: $very-light-gray;
        color: $muted-dark-gray;
        font-size: px2rem(12);
        font-weight: normal;
    }
    .insure-hero {
        height: 420px;
        background-image: url("Sass/images/insure/liberty_mutual_bg.png");
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;

        > span {
            font-size: 2.6rem;
            color: $white;
            cursor: pointer;
        }

        .d-table {
            padding: 0 30px;
        }
    }

    .lib-m-logo {
        width: 165px;
        height: 90px;
        background-image: url("Sass/images/insure/lm-masterbrand.png");
        background-size: cover;
    }

    .special-discount {
        font-size: px2rem(28);
        border-bottom: 5px solid #ecac00;
        line-height: 1.25;
        margin-top: 45px;
        padding: 10px 0;
        margin-bottom: 20px;
        max-width: 610px;
        color: $white;
        width: 425px;
    }

    .btn-and-phone-block {
        .btn-outline-white {
            font-size: px2rem(14);
            width: 185px;
        }

        .phone-number {
            font-size: px2rem(14);
            color: $white;

            > a {
                font-weight: 700;
                color: $white;
                text-decoration: none;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .insure-hero {
            height: calc(100vh - 50px) !important;
            background-position: unset !important;
            background-position-y: 35% !important;
            border-radius: 0;

            .d-table {
                padding: 0 30px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .insure-hero {
            .d-table {
                padding: 0 15px !important;
            }
        }
        .lib-m-logo {
            width: 150px !important;
            height: 81px !important;
        }
        .special-discount {
            line-height: 1.2 !important;
            font-size: px2rem(32) !important;
            text-align: center !important;
            margin-top: 25% !important;
            width: 100% !important;
        }

        .btn-and-phone-block {
            .get-quote-btn {
                width: 100%;
            }
            .phone-number {
                text-align: center;
            }
        }
    }
}
</style>

<script>
import EventBus from "../../event-bus";
import { get } from "vuex-pathify";
import { trackPageView } from "@/lib/GoogleTagManager";

export default {
    name: "GetInsuranceModal",
    computed: {
        user: get("user/user"),
        certificateId: get("vehicleDetails/certificate@id")
    },
    created() {
        if (this.user !== null) {
            EventBus.$on("insurance-modal-open", () => {
                this.show();
            });
        }
    },
    methods: {
        show() {
            trackPageView(this, `${window.location.pathname}/insurance/info`);
            this.$refs.getInsuranceModal.show();
        },
        close() {
            this.$refs.getInsuranceModal.hide();
        },
        gotoInsurance() {
            window.location = `/insurance?certificateId=${this.certificateId}`;
        }
    }
};
</script>
