<template>
    <div class="card-body insure-quote-list">
        <insurance-quote-row
            v-for="(quote, key) in quotes"
            :key="key"
            :quote="quote"
        />

        <div class="disclaimer">
            This is not a solicitation on behalf of Liberty Mutual or any other
            carrier. CarSaver is not a licensed insurance provider representing
            Liberty Mutual or any other carrier on this website. Please consult
            with the carrier for coverage and policy details.
        </div>
    </div>
</template>

<style lang="scss">
.insure-quote-list {
    .disclaimer {
        font-size: 12px;
        font-style: italic;
        line-height: 13px;
        color: $muted-dark-gray;
        padding: 10px;
    }
}
</style>

<script>
import InsuranceQuoteRow from "./QuoteRow";

export default {
    name: "InsuranceQuoteList",
    components: { InsuranceQuoteRow },
    props: {
        quotes: {
            required: true,
            type: Array
        }
    }
};
</script>
