var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dealerLinkExists
    ? _c("dealer-sticky-footer", { attrs: { dealer: _vm.dealer } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-between button-wrapper",
            attrs: { slot: "cta" },
            slot: "cta"
          },
          [
            _c(
              "div",
              { staticClass: "mx-2" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "px-4",
                    attrs: { variant: "primary", block: "" },
                    on: {
                      click: function($event) {
                        return _vm.onViewInventoryClick()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("component.dealerStickyFooter.view")) +
                        "\n            "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mx-2" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "px-4",
                    attrs: { variant: "dark-blue", block: "" },
                    on: {
                      click: function($event) {
                        return _vm.onConfirmAvailabilityClick()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("component.dealerStickyFooter.confirm")) +
                        "\n            "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.supportsTestDrive
              ? _c(
                  "div",
                  { staticClass: "mx-2" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "px-4",
                        attrs: { variant: "orange", block: "" },
                        on: {
                          click: function($event) {
                            return _vm.onScheduleTestDriveClick()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("component.dealerStickyFooter.schedule")
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }