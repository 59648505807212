<template>
    <div>
        <div class="gallery-icon-wrapper d-flex justify-content-center">
            <a
                :disabled="hasNoGalleryImages"
                :class="{ 'disabled-link': hasNoGalleryImages }"
                @click="!hasNoGalleryImages && showFeature('showImages')"
            >
                <div class="block">
                    <i aria-hidden="true" class="icon icon-camera_font" />
                </div>
            </a>

            <a
                :disabled="hasNo360Images"
                :class="{ 'disabled-link': hasNo360Images }"
                @click="!hasNo360Images && showFeature('show360')"
            >
                <div class="block">
                    <i aria-hidden="true" class="icon icon-360_font" />
                </div>
            </a>
        </div>
        <div
            v-if="hasNoGalleryImages && hasNo360Images"
            class="d-flex justify-content-center align-items-center no-images-block"
        >
            <div
                class="d-flex flex-column justify-content-center align-items-center"
            >
                <i aria-hidden="true" class="icon-car_front_view" />
                <h3>Sorry, no images available.</h3>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
#gallery-container {
    a {
        text-decoration: none;
        cursor: pointer;
    }

    .block {
        padding: 0 0 20px 0;
        margin: 0 20px;

        i {
            font-size: px2rem(25);
            opacity: 0.8;
            color: $primary-blue;
        }
    }

    .disabled-link {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .no-images-block {
        height: 150px;
        cursor: not-allowed;

        i {
            font-size: px2em(70);
            color: $primary-blue;
            opacity: 0.5;
            margin-bottom: 15px;
        }
        h3 {
            color: $primary-blue;
            opacity: 0.5;
        }
    }

    @include media-breakpoint-down(sm) {
        .block {
            i {
                font-size: px2rem(21);
            }
        }
    }
}
</style>

<script>
import { get, sync } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "GalleryIcons",

    data() {
        return {
            images360: {
                errorLoading: false
            }
        };
    },

    computed: {
        carouselImageUrls: get("vehicleDetails/media@carouselImageUrls"),
        galleryImageUrls: get("vehicleDetails/media@galleryImageUrls"),
        exteriorThreeSixtyBaseUrl: get(
            "vehicleDetails/media@exteriorThreeSixtyBaseUrl"
        ),
        hasNoGalleryImages() {
            return (
                _.isNil(_.get(this.carouselImageUrls, "[0]")) &&
                _.isNil(_.get(this.galleryImageUrls, "[0]"))
            );
        },
        showPhotos: sync("vehicleDetails/ui@gallery.photos.show"),
        show360: sync("vehicleDetails/ui@gallery.vehicle360.show"),
        hasNo360Images() {
            return (
                _.isNil(this.exteriorThreeSixtyBaseUrl) ||
                this.exteriorThreeSixtyBaseUrl === "" ||
                this.images360.errorLoading
            );
        }
    },

    mounted() {
        this.initFeatures();
    },

    created() {
        this.check360Images();
    },

    methods: {
        showFeature: function(feature) {
            const selected = feature.toUpperCase();

            this.hideAllFeatures();

            switch (selected) {
                case "SHOWIMAGES":
                    this.showPhotos = true;
                    break;
                case "SHOW360":
                    this.show360 = true;
                    break;
                default:
                    this.showPhotos = true;
            }
        },

        hideAllFeatures: function() {
            this.showPhotos = false;
            this.show360 = false;
        },

        initFeatures: function() {
            // resets
            this.hideAllFeatures();

            if (!this.hasNoGalleryImages) {
                this.showPhotos = true;
            } else if (!this.hasNo360Images) {
                this.show360 = true;
            }
        },

        check360Images: function() {
            if (
                _.isNil(this.exteriorThreeSixtyBaseUrl) ||
                this.exteriorThreeSixtyBaseUrl === ""
            ) {
                this.images360.errorLoading = true;
                return;
            }

            const url = this.exteriorThreeSixtyBaseUrl + "001.jpg";

            this.images360.errorLoading = false;
            const image = new Image();
            image.onerror = () => {
                this.images360.errorLoading = true;
            };
            image.src = url;
        }
    }
};
</script>
