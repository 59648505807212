var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-3", attrs: { id: "special-offers-block" } },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("component.specialOffersBlock.header")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", {
        staticClass: "m-0",
        domProps: {
          innerHTML: _vm._s(_vm.$t("component.specialOffersBlock.body"))
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }