var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-body insurance-block" },
    [
      _c("div", { staticClass: "row py-md-4" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-6" }, [
          _c("div", { staticClass: "d-flex flex-column question-block" }, [
            _c("h3", { staticClass: "mb-1 mb-md-0" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("component.getInsuranceQuote.doYou")) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("component.getInsuranceQuote.weHave")))
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-12 col-md-4 d-flex justify-content-center align-items-center button-block py-3 py-md-0"
          },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "primary" },
                on: {
                  click: function($event) {
                    return _vm.openInsuranceModal()
                  }
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("component.getInsuranceQuote.getQuotes")) +
                    "\n            "
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "disclaimer" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("component.getInsuranceQuote.disclaimer")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("sign-in-required-modal", { ref: "signInRequiredModal" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-md-2 mb-3 mb-md-0" }, [
      _c("div", { staticClass: "icon-block" }, [
        _c("i", {
          staticClass: "icon-shield_car",
          attrs: { "aria-hidden": "true" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }