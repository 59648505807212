<template>
    <div id="overlay-icons-block">
        <div
            class="icons d-none d-lg-flex flex-row justify-content-end position-absolute"
        >
            <heart-icon />
            <b-link
                :disabled="!hasImages"
                :class="{ 'disabled-link': !hasImages }"
                @click="goToPhotos"
            >
                <div
                    class="d-flex align-items-center justify-content-center block position-relative"
                >
                    <i aria-hidden="true" class="icon icon-camera_font" />
                    <span
                        v-if="hasImages"
                        class="position-absolute"
                        v-text="numberOfImages"
                    />
                </div>
            </b-link>
            <b-link
                :disabled="hasNo360Images || !hasImages"
                :class="{ 'disabled-link': hasNo360Images || !hasImages }"
                @click="goTo360"
            >
                <div
                    class="d-flex align-items-center justify-content-center block"
                >
                    <i aria-hidden="true" class="icon icon-360_font" />
                </div>
            </b-link>
            <b-link
                :disabled="!vrAvailable"
                :class="{ 'disabled-link': !vrAvailable }"
                @click="goToVr"
            >
                <div
                    class="d-flex align-items-center justify-content-center block"
                >
                    <i
                        aria-hidden="true"
                        class="icon icon-vr_cardboard_solid"
                    />
                </div>
            </b-link>
        </div>
    </div>
</template>
<script>
import { get, sync } from "vuex-pathify";
import axios from "axios";
import _ from "lodash";
import smoothScroll from "@/mixins/smoothScroll";
import HeartIcon from "Modules/Vehicle/OverlayIcons/HeartIcon";

export default {
    name: "OverlayIcons",
    components: { HeartIcon },
    mixins: [smoothScroll],

    data() {
        return {
            images360: {
                errorLoading: false
            },
            isMobile: false
        };
    },
    computed: {
        showPhotos: sync("vehicleDetails/ui@gallery.photos.show"),
        show360: sync("vehicleDetails/ui@gallery.vehicle360.show"),
        carouselImageUrls: get("vehicleDetails/media@carouselImageUrls"),
        galleryImageUrls: get("vehicleDetails/media@galleryImageUrls"),
        vrAvailable: get("vehicleDetails/media@interiorCubeMapExists"),
        exteriorThreeSixtyBaseUrl: get(
            "vehicleDetails/media@exteriorThreeSixtyBaseUrl"
        ),
        hasDealerImages() {
            return (
                this.carouselImageUrls !== null &&
                this.carouselImageUrls.length > 0
            );
        },
        hasGalleryImages() {
            return (
                this.galleryImageUrls !== null &&
                this.galleryImageUrls.length > 0
            );
        },
        numberOfImages() {
            return (
                _.get(this.carouselImageUrls, "length", 0) +
                _.get(this.galleryImageUrls, "length", 0)
            );
        },
        hasImages() {
            return this.hasDealerImages || this.hasGalleryImages;
        },
        hasNo360Images() {
            return (
                _.isNil(this.exteriorThreeSixtyBaseUrl) ||
                this.exteriorThreeSixtyBaseUrl === "" ||
                this.images360.errorLoading
            );
        },
        scrollOffset() {
            return this.isMobile ? 130 : 90;
        },
        scrollToOptions: function() {
            return {
                easing: "ease-in",
                offset: 0 - this.scrollOffset
            };
        }
    },

    created() {
        this.check360Images();
    },

    mounted() {
        const isMobile = window.matchMedia(
            "only screen and (max-width: 990px)"
        );
        this.isMobile = isMobile.matches;
    },
    methods: {
        goToVr() {
            this.showFeature("showVr");
            this.scrollTo("vr-banner-block");
        },

        goToPhotos() {
            this.showFeature("showImages");
            this.scrollTo("headingGallery");
        },
        goTo360() {
            this.showFeature("show360");
            this.scrollTo("headingGallery");
        },

        showFeature: function(feature) {
            const selected = feature.toUpperCase();

            this.hideAllFeatures();

            switch (selected) {
                case "SHOWIMAGES":
                    this.showPhotos = true;
                    break;
                case "SHOW360":
                    this.show360 = true;
                    break;
                default:
                    this.showPhotos = true;
            }
        },

        hideAllFeatures: function() {
            this.showPhotos = false;
            this.show360 = false;
        },

        check360Images: function() {
            if (
                _.isNil(this.exteriorThreeSixtyBaseUrl) ||
                this.exteriorThreeSixtyBaseUrl === ""
            ) {
                this.images360.errorLoading = true;
                return;
            }

            const url = this.exteriorThreeSixtyBaseUrl + "001.jpg";

            axios
                .head(url)
                .then(response => {
                    this.images360.errorLoading = response.status !== 200;
                })
                .catch(() => {
                    this.images360.errorLoading = true;
                });
        }
    }
};
</script>
<style lang="scss" scoped>
#overlay-icons-block {
    height: 0;

    .icons {
        right: 0;
        top: 10px;
        z-index: 1;

        a {
            text-decoration: none;
            line-height: 15px;

            .block {
                border-radius: 50px;
                background-color: rgba(0, 0, 0, 0.2);
                height: 28px;
                width: 28px;
                font-size: px2em(10);
                margin: 0 5px;
                border: 1px solid white;

                .icon {
                    color: $white;
                    font-size: px2rem(12);
                }

                > span {
                    left: 20px;
                    bottom: 20px;
                    border-radius: 50px;
                    background-color: $red;
                    color: white;
                    height: 15px;
                    justify-content: center;
                    display: flex;
                    width: 15px;
                    font-size: px2em(8);
                }
            }
        }

        .disabled-link {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    @include media-breakpoint-down(sm) {
        .overlay-icons-block {
            .icons {
                right: 20px !important;
            }
        }
    }
}
</style>
