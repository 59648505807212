<template>
    <is-user-signed-in>
        <b-link class="heart-icon" @click="toggleSavedVehicle">
            <div class="d-flex align-items-center justify-content-center block">
                <i
                    v-if="!saved"
                    class="ion-ios-heart white"
                    aria-hidden="true"
                />
                <i v-if="saved" class="ion-ios-heart red" aria-hidden="true" />
            </div>
        </b-link>
    </is-user-signed-in>
</template>

<script>
import { sync, get } from "vuex-pathify";
import IsUserSignedIn from "Components/IsUserSignedIn/index";
import api from "../../../api";

export default {
    name: "HeartIcon",
    components: { IsUserSignedIn },
    computed: {
        user: get("user/user"),
        certificateId: get("vehicleDetails/certificate@id"),
        saved: sync("vehicleDetails/certificate@saved")
    },
    methods: {
        toggleSavedVehicle() {
            if (this.user !== null) {
                if (this.saved) {
                    api.post(`/garage/${this.certificateId}/unsaved`)
                        .then(() => {
                            this.saved = false;
                        })
                        .catch(error => {
                            console.error(
                                "Error occurred unsaving a vehicle on VDP, error =",
                                error
                            );
                        });
                }
                if (!this.saved) {
                    api.post(`/garage/${this.certificateId}/saved`)
                        .then(() => {
                            this.saved = true;
                        })
                        .catch(error => {
                            console.error(
                                "Error occurred saving vehicle on VDP, error =",
                                error
                            );
                        });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.heart-icon {
    text-decoration: none;

    .block {
        border-radius: 50px;
        background-color: rgba(0, 0, 0, 0.2);
        height: 28px;
        width: 28px;
        font-size: px2em(10);
        margin: 0 5px;
        border: 1px solid white;

        .ion-ios-heart.white {
            color: $white;
            font-size: px2rem(14);
        }

        .ion-ios-heart.red {
            color: $red;
            font-size: px2rem(14);
        }
    }
}
</style>
