<template>
    <div id="financeTab" class="w-100">
        <div
            v-if="isLoading"
            class="loading-wrapper d-flex align-items-center flex-column"
        >
            <loading-spinner />
        </div>
        <div v-else class="container-fluid">
            <div class="block border-bottom py-1">
                <div class="d-flex justify-content-between line-item">
                    <span class="label">
                        {{ $t("component.pricingCard.retailPrice") }}
                    </span>
                    <span class="value">
                        {{ retailPrice | emptyDash | numeral("$0,0") }}
                    </span>
                </div>
                <div
                    v-if="vehicleIsNew"
                    class="d-flex justify-content-between line-item"
                >
                    <span class="label">
                        {{ $t("component.pricingCard.rebate") }}
                    </span>
                    <div class="d-flex value">
                        <span
                            v-if="
                                bestFinanceDeal &&
                                    bestFinanceDeal.quote.rebates > 0
                            "
                        >
                            -
                        </span>
                        {{
                            bestFinanceDeal
                                | emptyDash("quote.rebates")
                                | numeral("$0,0")
                        }}
                    </div>
                </div>
                <div
                    v-if="hasDealerDiscount"
                    class="d-flex justify-content-between line-item"
                >
                    <span class="label">
                        {{ $t("component.pricingCard.discount") }}
                    </span>
                    <span class="value">
                        -
                        {{
                            pricing
                                | emptyDash("dealerDiscount")
                                | numeral("$0,0")
                        }}
                    </span>
                </div>
                <div
                    v-if="vehicle.warrantyEligible"
                    class="d-flex justify-content-between line-item"
                >
                    <div class="d-flex align-items-center">
                        <b-link
                            class="label mr-1"
                            @click="showLifetimeWarrantyModal()"
                        >
                            {{ $t("component.pricingCard.lifetimeWarranty") }}
                        </b-link>
                        <lifetime-warranty-tooltip-disclaimer />
                    </div>
                    <span class="value">
                        {{ $t("component.pricingCard.included") }}
                    </span>
                </div>
            </div>
            <div class="block border-bottom py-1">
                <div
                    class="d-flex justify-content-between align-items-center line-item"
                >
                    <span class="sale-label font-weight-bold">
                        {{ $t("component.pricingCard.salePrice") }}
                    </span>
                    <div
                        v-if="showRestrictedMake"
                        class="restricted-make-price"
                    >
                        {{ $t("component.pricingCard.seeEmail") }}
                    </div>
                    <div v-else class="blue-value">
                        {{ salePrice | numeral("$0,0") }}
                    </div>
                </div>
            </div>
            <div class="block border-bottom py-1">
                <div
                    v-if="hasFinanceTaxes"
                    class="d-flex justify-content-between line-item"
                >
                    <span class="label">
                        {{ $t("component.pricingCard.tax") }}
                    </span>
                    <span class="value">
                        {{
                            taxes
                                | emptyDash("finance.salesTax")
                                | numeral("+ $0,0")
                        }}
                    </span>
                </div>
                <div
                    v-if="hasFinanceTaxes"
                    class="d-flex justify-content-between line-item"
                >
                    <span class="label">
                        {{ $t("component.pricingCard.licenseRegistration") }}
                    </span>
                    <span class="value">
                        {{
                            taxes
                                | emptyDash("finance.titleLicenseFees")
                                | numeral("+ $0,0")
                        }}
                    </span>
                </div>
                <div
                    v-if="hasFinanceTaxes"
                    class="d-flex justify-content-between line-item"
                >
                    <span class="label">
                        {{ $t("component.pricingCard.dealerFee") }}
                    </span>
                    <span class="value">
                        {{
                            pricing
                                | emptyDash("dealerFees")
                                | numeral("+ $0,0")
                        }}
                    </span>
                </div>
                <div v-else class="line-item text-center">
                    <div
                        v-if="taxesLoading"
                        class="d-flex justify-content-center"
                    >
                        <loading-spinner :size="30" />
                    </div>
                    <b-link v-else @click="loadFinanceTaxes">
                        View with Taxes &amp; Fees
                    </b-link>
                </div>
                <div class="d-flex justify-content-between line-item">
                    <span class="label">
                        {{ $t("component.pricingCard.downPayment") }}
                    </span>
                    <span class="value">
                        <span v-if="bestFinanceDeal">
                            -
                        </span>
                        {{
                            bestFinanceDeal
                                | emptyDash("quote.downPayment")
                                | numeral("$0,0")
                        }}
                    </span>
                </div>
            </div>
            <div class="block py-1">
                <div class="d-flex justify-content-between line-item">
                    <span class="label">
                        {{ $t("component.pricingCard.totalAmountFinanced") }}
                    </span>
                    <span class="value font-weight-bold">
                        {{ totalAmountFinanced | numeral("$0,0") }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import EventBus from "@/event-bus";
import LoadingSpinner from "Components/LoadingSpinner";
import taxesAndFeesMixin from "../../../mixins/taxesAndFees";
import LifetimeWarrantyTooltipDisclaimer from "Components/LifetimeWarrantyTooltipDisclaimer";

export default {
    name: "Finance",
    components: {LifetimeWarrantyTooltipDisclaimer, LoadingSpinner },
    mixins: [taxesAndFeesMixin],
    computed: {
        vehicle: get("vehicleDetails/vehicle"),
        pricing: get("vehicleDetails/pricing"),
        stockType: get("vehicleDetails/inventoryVehicle@stockType"),
        isUserSignedIn: get("user/isSignedIn"),
        restrictedMake: get("vehicleDetails/vehicle@restrictedMake"),
        dealerLinkExists: get("vehicleDetails/dealerLinkExists"),
        bestFinanceDeal: get("paymentMatrix/bestFinanceDeal"),
        bestDeals: get("paymentMatrix/best"),
        bestDealsLoadingStep: get("paymentMatrix/loadingStep"),

        dealerDiscount() {
            const salePrice = _.get(this.pricing, "salePrice.value", 0);
            return this.vehicleIsNew
                ? _.get(this.pricing, "dealerDiscount")
                : this.retailPrice - salePrice;
        },

        isLoading() {
            return this.bestDealsLoadingStep === 0;
        },

        showRestrictedMake() {
            return this.restrictedMake && this.dealerLinkExists;
        },
        vehicleIsNew() {
            return _.lowerCase(this.stockType) !== "used";
        },

        retailPrice() {
            return this.vehicleIsNew
                ? _.get(this.pricing, "msrp")
                : _.get(this.pricing, "salePrice.value");
        },

        salePrice() {
            const purchase = _.get(this.bestFinanceDeal, "quote.purchase", 0);
            const rebates = _.get(this.bestFinanceDeal, "quote.rebates", 0);
            return purchase - rebates;
        },

        hasDealerDiscount() {
            return !_.isNil(this.dealerDiscount) && this.dealerDiscount > 0;
        },

        totalAmountFinanced() {
            let totalAmountFinanced =
                this.salePrice - this.bestFinanceDeal.quote.downPayment;

            if (this.hasFinanceTaxes) {
                const taxesAndFees = _.get(this.taxes, "finance.feeTotal", 0);
                const dealerFees = _.get(this.pricing, "dealerFees", 0);

                totalAmountFinanced += taxesAndFees + dealerFees;
            }

            return totalAmountFinanced;
        }
    },
    methods: {
        showLifetimeWarrantyModal: function() {
            EventBus.$emit("lifetime-warranty-modal-open");
        }
    }
};
</script>

<style lang="scss">
#financeTab {
    .container-fluid {
        padding: 0 20px;

        .line-item {
            color: $dark-gray;
            padding: 2px 0;

            .label {
                font-size: px2rem(12);
                color: $dark-gray;
            }
            .sale-label {
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                color: $dark-gray;
            }
            .value {
                font-size: px2rem(12);
                color: $muted-dark-gray;
                line-height: 15px;
            }

            .blue-value {
                font-size: px2rem(26);
                font-weight: bold;
                font-style: italic;
                font-family: Times New Roman, sans-serif;
                line-height: 30px;
                color: $primary-blue;
            }
        }
    }
}
</style>
