var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-list-group",
    [
      _vm._l(_vm.consumerCash.programs, function(cashIncentive) {
        return _c(
          "b-list-group-item",
          {
            key: cashIncentive.name,
            staticClass: "d-flex justify-content-between align-items-center"
          },
          [
            _c(
              "span",
              { staticClass: "text-capitalize incentive-name" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(cashIncentive.name) +
                    "\n            "
                ),
                _c("expiration", {
                  attrs: { "expiration-date": cashIncentive.stopDate }
                })
              ],
              1
            ),
            _vm._v(
              "\n        - $" +
                _vm._s(_vm._f("numeral")(cashIncentive.cash)) +
                "\n    "
            )
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "b-list-group-item",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("span", { staticClass: "font-weight-bold text-capitalize" }, [
            _vm._v("\n            Total Rebates & Incentives\n        ")
          ]),
          _vm._v(" "),
          _vm.greaterThanZero
            ? _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n            $" +
                    _vm._s(_vm._f("numeral")(_vm.consumerCash.totalCash)) +
                    "\n        "
                )
              ])
            : _vm._e()
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }