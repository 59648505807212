var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-list-group-item",
    {
      staticClass: "expandable-list-item",
      on: {
        click: function($event) {
          _vm.showCollapse = !_vm.showCollapse
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center w-100 pr-1"
            },
            [_vm._t("list-item")],
            2
          ),
          _vm._v(" "),
          _vm.expandable
            ? _c("i", {
                class: _vm.showCollapse ? "ion-arrow-up-b" : "ion-arrow-down-b",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { id: _vm.id + "-details" },
          model: {
            value: _vm.showCollapse,
            callback: function($$v) {
              _vm.showCollapse = $$v
            },
            expression: "showCollapse"
          }
        },
        [_vm._t("details")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }