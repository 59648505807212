var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section-box",
    {
      staticClass:
        "offer-info d-flex justify-content-between align-items-center"
    },
    [
      _c("div", { staticClass: "logo" }, [
        _c("img", { attrs: { src: _vm.lenderLogo, alt: _vm.offer.lenderName } })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "status-offer-number text-right" }, [
        _c("div", { staticClass: "item status" }, [
          _vm._v("Status: "),
          _c("strong", [_vm._v("QUALIFIED")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item offer-number" }, [
          _vm._v(
            "\n            Offer Number: " +
              _vm._s(_vm.offer.lenderAppId) +
              "\n        "
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }