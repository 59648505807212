<template>
    <section-box
        class="offer-info d-flex justify-content-between align-items-center"
    >
        <div class="logo">
            <img :src="lenderLogo" :alt="offer.lenderName" />
        </div>

        <div class="status-offer-number text-right">
            <div class="item status">Status: <strong>QUALIFIED</strong></div>
            <div class="item offer-number">
                Offer Number: {{ offer.lenderAppId }}
            </div>
        </div>
    </section-box>
</template>

<style lang="scss" scoped>
.section-box {
    border: 1px solid $muted-gray;
    border-radius: 2px;
    background-color: $very-light-gray;
    min-height: 70px;
    padding: 5px;
    margin-top: 10px;

    .logo {
        > img {
            max-width: 100%;
            max-height: 50px;
        }
    }
}
</style>

<script>
import SectionBox from "../../../ViewDetailsModal/components/SectionBox";
export default {
    name: "OfferInfo",
    components: { SectionBox },
    props: {
        offer: {
            type: Object,
            required: true
        }
    },

    computed: {
        lenderLogo() {
            return `https://app.myautoloan.com${this.offer.lenderLogoUrl}`;
        }
    }
};
</script>
