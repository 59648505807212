<template>
    <div v-show="showPhotos">
        <div
            v-if="images"
            class="row d-flex flex-column flex-sm-row images-container no-gutters"
        >
            <div
                v-if="isExpanded"
                class="fullscreen-icon-wrapper exit-button"
                @click="exitFullScreen"
            >
                <i
                    aria-hidden="true"
                    class="ion-arrow-shrink fullscreen-icon"
                />
            </div>

            <div id="main-image-container" class="col-12 col-sm-8 pr-sm-3">
                <flickity
                    id="main-image"
                    ref="flickity"
                    :options="flickityOptions"
                >
                    <div
                        v-for="image in images"
                        :id="`#vehicle${image.index}`"
                        :key="image.index"
                        class="carousel-cell"
                    >
                        <b-img-lazy
                            :src="image.url"
                            :alt="vehicleFullName"
                            fluid
                            @error="imageUnavailable"
                        />
                    </div>
                </flickity>
                <div
                    class="fullscreen-icon-wrapper"
                    @click="activateFullScreen"
                >
                    <i
                        aria-hidden="true"
                        class="ion-arrow-expand fullscreen-icon"
                    />
                </div>
            </div>

            <div
                v-if="hasImages"
                id="image-nav"
                class="col-12 col-sm-4 d-flex flex-wrap align-content-start"
            >
                <div
                    v-for="image in inventoryImages"
                    :key="image.index"
                    class="thumbnail"
                    :class="{ 'thumbnail-selected': image === selected }"
                >
                    <div
                        class="selectable"
                        :href="`#vehicle${image.index}`"
                        @click="thumbNailClickHandler(image)"
                    >
                        <b-img-lazy
                            :src="image.url"
                            :alt="vehicleFullName"
                            fluid
                            @error="imageUnavailable"
                        />
                    </div>
                </div>

                <h4 v-if="hasStockImages" class="w-100">
                    Stock Images
                </h4>
                <div
                    v-for="image in stockImages"
                    :key="image.index"
                    class="thumbnail"
                    :class="{ 'thumbnail-selected': image === selected }"
                >
                    <div
                        class="selectable"
                        :href="`#vehicle${image.index}`"
                        @click="thumbNailClickHandler(image)"
                    >
                        <b-img-lazy
                            :src="image.url"
                            :alt="vehicleFullName"
                            fluid
                            @error="imageUnavailable"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import Flickity from "vue-flickity";
import FullScreen from "flickity-fullscreen";
import imageUnavailable from "@/mixins/imageUnavailable";

export default {
    name: "PhotoGallery",
    components: { Flickity },
    mixins: [imageUnavailable],

    data() {
        return {
            selected: null,
            index: 0,
            focus: false,
            flickityOptions: {
                initialIndex: 0,
                pageDots: false,
                wrapAround: true,
                imagesLoaded: true,
                prevNextButtons: true,
                cellAlign: "center"
            },
            isExpanded: false,
            images: null
        };
    },

    computed: {
        imageList: get("vehicleDetails/media@carouselImageUrls"),
        gallery: get("vehicleDetails/media@galleryImageUrls"),
        vehicleFullName: get("vehicleDetails/vehicleModelFullName"),
        showPhotos: get("vehicleDetails/ui@gallery.photos.show"),
        inventoryImages() {
            return _.filter(this.images, ["type", "inventory"]);
        },
        stockImages() {
            return _.filter(this.images, ["type", "stock"]);
        },
        imagesQty: function() {
            return this.images ? this.images.length : 0;
        },
        flktyFS: function() {
            return new FullScreen("#main-image", {
                fullscreen: true
            });
        },
        hasImages() {
            return this.hasDealerImages || this.hasStockImages;
        },
        hasDealerImages() {
            return _.size(this.inventoryImages) > 0;
        },
        hasStockImages() {
            return _.size(this.stockImages) > 0;
        }
    },

    mounted: function() {
        const isWrapped = true;
        const isInstant = false;
        let index = 0;
        this.images = [];
        _.forEach(this.imageList, image => {
            this.images.push({
                index,
                url: image,
                type: "inventory"
            });
            index++;
        });

        _.forEach(this.gallery, image => {
            this.images.push({
                index,
                url: image,
                type: "stock"
            });
            index++;
        });

        this.selected = this.images[0];

        if (this.$refs.flickity) {
            this.$refs.flickity.select(this.index, isWrapped, isInstant);
        }

        document.addEventListener("click", event => {
            this.focus = !!event.target.closest("#gallery-container");
        });

        document.addEventListener("keyup", event => {
            switch (event.keyCode) {
                case 37:
                    // left arrow
                    this.previous();
                    break;
                case 39:
                    // right arrow
                    this.next();
                    break;
            }
        });
    },

    methods: {
        thumbNailClickHandler(image) {
            this.selected = image;
            this.index = image.index;
            const isWrapped = true;
            const isInstant = false;

            this.$refs.flickity.select(this.index, isWrapped, isInstant);
        },
        next() {
            const isWrapped = true;
            const isInstant = false;

            if (!this.focus) {
                return;
            }

            if (this.imagesQty === 0) {
                return;
            }

            if (this.imagesQty === this.index + 1) {
                this.index = 0;
                this.selected = this.images[this.index];
                this.$refs.flickity.select(this.index, isWrapped, isInstant);
                return;
            }

            this.index++;
            this.selected = this.images[this.index];
            this.$refs.flickity.select(this.index, isWrapped, isInstant);
        },

        previous() {
            const isWrapped = true;
            const isInstant = false;

            if (!this.focus) {
                return;
            }

            if (this.imagesQty === 0) {
                return;
            }

            if (this.index === 0) {
                this.index = this.imagesQty - 1;
                this.selected = this.images[this.index];
                this.$refs.flickity.select(this.index, isWrapped, isInstant);
                return;
            }

            this.index--;
            this.selected = this.images[this.index];
            this.$refs.flickity.select(this.index, isWrapped, isInstant);
        },

        activateFullScreen() {
            this.flktyFS.viewFullscreen();
            this.isExpanded = true;
        },

        exitFullScreen(event) {
            event.stopPropagation();
            this.isExpanded = false;
            this.flktyFS.exitFullscreen();
        }
    }
};
</script>
<style lang="scss">
@import "~flickity-fullscreen/fullscreen";

#gallery-container {
    #main-image-container {
        height: 300px;
    }

    #main-image {
        height: 100%;
        overflow-y: hidden;

        .flickity-viewport {
            touch-action: pan-y pinch-zoom !important;
        }

        .carousel-cell {
            width: 100%; /* full width */
            height: 100%;
            /* center images in cells with flexbox */
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }

        img.vehicle-image-main {
            object-fit: cover;
        }

        &.is-fullscreen {
            z-index: 9999;

            .flickity-slider {
                .vehicle-image-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    * {
                    }

                    img {
                        min-width: 100%;

                        &.vehicle-image-main {
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }

    #image-nav {
        height: 100%;
        max-height: 300px;
        overflow: scroll;

        .thumbnail {
            width: 33.33%;
            cursor: pointer;
            padding: 3px;

            &-selected {
                opacity: 0.5;

                img {
                    box-shadow: 0 0 5px 2px rgb(22, 141, 243);
                }
            }
        }
    }

    .fullscreen-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        height: 26px;
        width: 26px;
        opacity: 0.85;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 10px;
        right: 25px;
        border: 1px solid white;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }

        &.exit-button {
            top: 5%;
            right: 5%;
            position: fixed;
            z-index: 99999;
        }
    }

    .fullscreen-icon {
        height: 16px;
        color: #ffffff;
        font-family: Ionicons;
        font-size: 16px;
        font-weight: 500;
        line-height: 17px;
        text-align: center;
    }

    @include media-breakpoint-down(xs) {
        #main-image {
            overflow-y: visible;
            padding-bottom: 20px;
        }

        #image-nav {
            height: 300px !important;
        }

        .fullscreen-icon-wrapper {
            bottom: 30px;
            right: 10px;
        }

        .flickity-page-dots {
            display: none;
        }

        .is-fullscreen {
            .flickity-page-dots {
                display: block;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        button.flickity-button,
        .flickity-page-dots {
            display: none;
        }

        .is-fullscreen {
            button.flickity-button,
            .flickity-page-dots {
                display: block;
            }
        }
    }
}
</style>
