<template>
    <div>
        <mobile-contact-block />

        <p
            v-if="!certifiedDealer && !isConfiguredVehicle"
            class="non-certified-warning py-2 px-3"
        >
            The information on this page is provided for informational purposes
            only. This vehicle does not include any benefits that are
            exclusively available at CarSaver Certified Dealerships. Upfront,
            sales price is not available online from non-certified dealers.
            Contact dealer using the Contact Dealer button on site to obtain
            sales price.
        </p>

        <dealer-delivery-response
            v-if="deliveryEnabled && userIsInsideDeliveryDistance"
        />

        <special-offers-block v-if="showSpecialOffers" />

        <lifetime-warranty-block />

        <certified-dealer-block v-if="showCertifiedDealer" />

        <vin-stock-miles-block />

        <div id="accordion" role="tablist">
            <dealer-map />

            <overview />

            <recommended-vehicles />

            <options />

            <rebates-and-incentives />

            <payment-matrix-block v-if="showPaymentMatrixBlock" />

            <trade-in />

            <finance-block v-if="!supplierPricingAvailable" />

            <vehicle-insurance-block />

            <div v-if="showMainGallery" id="main-gallery">
                <gallery v-if="mediaAvailable" />
                <vr-banner v-if="showVrBlock" />
            </div>

            <about-this-vehicle />

            <specs-and-features />

            <safety-rating />

            <better-way-to-buy />

            <lifetime-warranty-disclaimer />
        </div>

        <schedule-call v-if="supplierPricingAvailable" />
        <call-me-now-modal v-if="supplierPricingAvailable" />
        <supplier-pricing-modal v-if="supplierPricingAvailable" />
        <call-in-queue-modal v-if="supplierPricingAvailable" />
        <vr-download-modal />
        <vr-tour-modal />
    </div>
</template>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import VrBanner from "Modules/Vehicle/VrBanner";
import MobileContactBlock from "Modules/Vehicle/MobileContactBlock";
import VinStockMilesBlock from "Modules/Vehicle/VinStockMilesBlock";
import Options from "Modules/Vehicle/Options";
import DealerMap from "Modules/Vehicle/DealerMap";
import Overview from "Modules/Vehicle/Overview";
import RebatesAndIncentives from "Modules/Vehicle/RebatesAndIncentives";
import TradeIn from "Modules/Vehicle/TradeIn";
import VehicleInsuranceBlock from "Modules/Vehicle/InsuranceBlock";
import Gallery from "Modules/Vehicle/Gallery";
import AboutThisVehicle from "Modules/Vehicle/AboutThisVehicle";
import SpecsAndFeatures from "Modules/Vehicle/SpecsAndFeatures";
import SafetyRating from "Modules/Vehicle/SafetyRating";
import SpecialOffersBlock from "Modules/Vehicle/SpecialOffersBlock";
import FinanceBlock from "Modules/Vehicle/FinanceBlock";
import { trackEvent } from "@/lib/GoogleTagManager";
import CertifiedDealerBlock from "Modules/Vehicle/CertifiedDealerBlock";
import ScheduleCall from "Modules/Wavpp/ScheduleCall";
import CallMeNowModal from "Modules/Wavpp/CallMeNowModal";
import SupplierPricingModal from "Modules/Wavpp/SupplierPricingModal";
import CallInQueueModal from "Modules/Wavpp/CallInQueue";
import BetterWayToBuy from "Modules/Vehicle/BetterWayToBuy";
import VrDownloadModal from "Modules/Vehicle/VrBanner/DownloadModal";
import VrTourModal from "Modules/Vehicle/VrBanner/TourModal";
import LifetimeWarrantyBlock from "Modules/Vehicle/LifetimeWarrantyBlock";
import LifetimeWarrantyDisclaimer from "Modules/Vehicle/LifetimeWarrantyDisclaimer";
import PaymentMatrixBlock from "Modules/Vehicle/PaymentMatrixBlock";
import RecommendedVehicles from "Modules/Vehicle/RecommendedVehicles";
import DealerDeliveryResponse from "Modules/Vehicle/DealerDeliveryResponse";

export default {
    components: {
        DealerDeliveryResponse,
        RecommendedVehicles,
        PaymentMatrixBlock,
        LifetimeWarrantyDisclaimer,
        LifetimeWarrantyBlock,
        VrDownloadModal,
        VrBanner,
        VrTourModal,
        BetterWayToBuy,
        CallInQueueModal,
        SupplierPricingModal,
        CallMeNowModal,
        ScheduleCall,
        CertifiedDealerBlock,
        SpecialOffersBlock,
        SafetyRating,
        SpecsAndFeatures,
        AboutThisVehicle,
        Gallery,
        VehicleInsuranceBlock,
        TradeIn,
        RebatesAndIncentives,
        Overview,
        DealerMap,
        VinStockMilesBlock,
        MobileContactBlock,
        Options,
        FinanceBlock
    },

    computed: {
        dealerId: get("vehicleDetails/dealer@id"),
        distanceToDealer: get("vehicleDetails/dealer@distance"),
        deliveryEnabled: get("vehicleDetails/dealer@deliveryEnabled"),
        deliveryDistance: get("vehicleDetails/dealer@deliveryDistance"),
        deliveryAvailability: get(
            "vehicleDetails/dealer@preferences.deliveryAvailability"
        ),
        warrantyEligible: get("vehicleDetails/vehicle@warrantyEligible"),
        vehicleName: get("vehicleDetails/vehicleModelFullName"),
        financingAvailable: get("vehicleDetails/vehicle@financingAvailable"),
        inventoryVehicle: get("vehicleDetails/inventoryVehicle"),
        imageList: get("vehicleDetails/media@carouselImageUrls"),
        galleryImageUrls: get("vehicleDetails/media@galleryImageUrls"),
        vehicle: get("vehicleDetails/vehicle"),
        vehicleYear() {
            return _.get(this.vehicle, "year");
        },
        vehicleMake() {
            return _.get(this.vehicle, "make");
        },
        vehicleModel() {
            return _.get(this.vehicle, "model");
        },
        supplierPricingAvailable: get(
            "vehicleDetails/pricing@supplierPricingAvailable"
        ),
        campaignType: get("campaign/type"),
        certifiedDealer: get("vehicleDetails/dealer@certified"),
        vrAvailable: get("vehicleDetails/media@interiorCubeMapExists"),
        userIsInsideDeliveryDistance() {
            if (this.distanceToDealer != null) {
                return _.lte(this.distanceToDealer, this.deliveryDistance);
            } else {
                return false;
            }
        },
        isKiosk() {
            return this.campaignType === "kiosk";
        },
        showMainGallery() {
            return this.showVrBlock || this.mediaAvailable;
        },
        showVrBlock() {
            return !this.isKiosk && this.vrAvailable;
        },
        isWalmartEmployeePurchase() {
            const program = "wavpp";

            if (_.isString(this.campaignType)) {
                return program === _.lowerCase(this.campaignType);
            }

            return false;
        },
        isConfiguredVehicle() {
            return this.inventoryVehicle === null;
        },
        showSpecialOffers() {
            return this.isWalmartEmployeePurchase && this.certifiedDealer;
        },
        showCertifiedDealer() {
            return (
                (this.certifiedDealer || this.isConfiguredVehicle) &&
                this.deliveryAvailability !== "DELIVERY_ONLY"
            );
        },
        mediaAvailable() {
            return (
                !_.isNil(_.get(this.imageList, "[0]")) ||
                !_.isNil(_.get(this.galleryImageUrls, "[0]"))
            );
        },
        showPaymentMatrixBlock() {
            return (
                this.certifiedDealer &&
                !this.supplierPricingAvailable &&
                !this.vehicle.restrictedMake
            );
        }
    },

    mounted() {
        trackEvent(this, "vdp-visit", {
            dealerId: this.dealerId,
            vehicleBodyStyle: _.get(this.vehicle, "bodyStyle"),
            certifiedDealer: _.get(this.vehicle, "certified"),
            vehicleYear: _.get(this.vehicle, "year"),
            vehicleMake: _.get(this.vehicle, "make"),
            vehicleModel: _.get(this.vehicle, "model"),
            vehicleTrim: _.get(this.vehicle, "trim")
        });

        this.checkUrlParamsToScroll();
    },

    methods: {
        checkUrlParamsToScroll() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const hasUtmCampaign = urlParams.has("utm_campaign");

            if (hasUtmCampaign) {
                const utmCampaignValue = urlParams.get("utm_campaign");
                if (utmCampaignValue === "deal-finance-offers") {
                    document
                        .getElementById("headingFinance")
                        .scrollIntoView({ behavior: "smooth" });
                }
            }
        }
    }
};
</script>

<style lang="scss">
.non-certified-warning {
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    min-height: 30px;
    font-size: px2rem(14);
}
</style>
