var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasLocalListingsAvailable
    ? _c(
        "div",
        { staticClass: "search-local-listings text-center" },
        [
          _c(
            "b-link",
            { attrs: { href: _vm.url } },
            [
              _vm._v("\n        Search Local Listings \n        "),
              _c("b-badge", { attrs: { pill: "", variant: "danger" } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.vehicle.localListingCount) +
                    "\n        "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }