<template>
    <div class="card-body unable-to-process p-2">
        <div
            class="d-flex flex-column justify-content-center align-content-center"
        >
            <h3>Application In Progress...</h3>
            <p>
                Your finance application is being processed. This may take a few
                minutes. We will notify you when your finance offers are ready.
            </p>
        </div>
    </div>
</template>

<style lang="scss">
.unable-to-process {
    > div {
        width: 100%;
    }

    h3 {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0 auto;
    }

    p {
        width: 375px;
        margin: 9px auto;
        font-size: 0.8rem;
        color: $muted-dark-gray;
    }
}
</style>

<script>
export default {
    name: "ApplicationInProgress"
};
</script>
