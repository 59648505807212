var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "text-center p-2 px-3",
      attrs: { id: "thankYouModalRestrictedMakeContent" }
    },
    [
      _c("h1", [_vm._v("\n        Thank You.\n    ")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Your pre-set price for this vehicle has been sent to " +
            _vm._s(_vm.email) +
            "."
        )
      ]),
      _vm._v(" "),
      _vm.canSendLead
        ? _c("div", [
            _c("div", { staticClass: "grey-bg p-2 py-3 mb-4" }, [
              _vm._v("\n            A representative from "),
              _c("strong", [_vm._v(_vm._s(_vm.dealerName))]),
              _vm._v(
                " will be\n            contacting you to confirm availability and answer any questions\n            you may have about this vehicle.\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "actions mx-auto my-2" },
              [
                _c(
                  "b-btn",
                  {
                    attrs: { variant: "orange", block: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("see-pricing")
                      }
                    }
                  },
                  [_vm._v("\n                Okay. See Pricing\n            ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c(
                  "b-link",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$emit("still-shopping")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                I'm still shopping. I'm not ready to talk to a dealership\n                yet.\n            "
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _c("div", [
            _c(
              "div",
              { staticClass: "actions mx-auto my-2" },
              [
                _c(
                  "b-btn",
                  {
                    attrs: { variant: "primary", block: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [_vm._v("\n                Close\n            ")]
                )
              ],
              1
            )
          ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("strong", [
        _vm._v(
          "\n            The sale price for this vehicle is lower than the manufacturer\n            allows us to show online.\n        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }