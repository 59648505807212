var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showPhotos,
          expression: "showPhotos"
        }
      ]
    },
    [
      _vm.images
        ? _c(
            "div",
            {
              staticClass:
                "row d-flex flex-column flex-sm-row images-container no-gutters"
            },
            [
              _vm.isExpanded
                ? _c(
                    "div",
                    {
                      staticClass: "fullscreen-icon-wrapper exit-button",
                      on: { click: _vm.exitFullScreen }
                    },
                    [
                      _c("i", {
                        staticClass: "ion-arrow-shrink fullscreen-icon",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-12 col-sm-8 pr-sm-3",
                  attrs: { id: "main-image-container" }
                },
                [
                  _c(
                    "flickity",
                    {
                      ref: "flickity",
                      attrs: { id: "main-image", options: _vm.flickityOptions }
                    },
                    _vm._l(_vm.images, function(image) {
                      return _c(
                        "div",
                        {
                          key: image.index,
                          staticClass: "carousel-cell",
                          attrs: { id: `#vehicle${image.index}` }
                        },
                        [
                          _c("b-img-lazy", {
                            attrs: {
                              src: image.url,
                              alt: _vm.vehicleFullName,
                              fluid: ""
                            },
                            on: { error: _vm.imageUnavailable }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "fullscreen-icon-wrapper",
                      on: { click: _vm.activateFullScreen }
                    },
                    [
                      _c("i", {
                        staticClass: "ion-arrow-expand fullscreen-icon",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasImages
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-4 d-flex flex-wrap align-content-start",
                      attrs: { id: "image-nav" }
                    },
                    [
                      _vm._l(_vm.inventoryImages, function(image) {
                        return _c(
                          "div",
                          {
                            key: image.index,
                            staticClass: "thumbnail",
                            class: {
                              "thumbnail-selected": image === _vm.selected
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "selectable",
                                attrs: { href: `#vehicle${image.index}` },
                                on: {
                                  click: function($event) {
                                    return _vm.thumbNailClickHandler(image)
                                  }
                                }
                              },
                              [
                                _c("b-img-lazy", {
                                  attrs: {
                                    src: image.url,
                                    alt: _vm.vehicleFullName,
                                    fluid: ""
                                  },
                                  on: { error: _vm.imageUnavailable }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.hasStockImages
                        ? _c("h4", { staticClass: "w-100" }, [
                            _vm._v(
                              "\n                Stock Images\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.stockImages, function(image) {
                        return _c(
                          "div",
                          {
                            key: image.index,
                            staticClass: "thumbnail",
                            class: {
                              "thumbnail-selected": image === _vm.selected
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "selectable",
                                attrs: { href: `#vehicle${image.index}` },
                                on: {
                                  click: function($event) {
                                    return _vm.thumbNailClickHandler(image)
                                  }
                                }
                              },
                              [
                                _c("b-img-lazy", {
                                  attrs: {
                                    src: image.url,
                                    alt: _vm.vehicleFullName,
                                    fluid: ""
                                  },
                                  on: { error: _vm.imageUnavailable }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }