<template>
    <b-modal ref="modal" :hide-footer="true" class="leaving-carsaver">
        <div slot="modal-header-close">
            <i class="ion-ios-close-outline" aria-hidden="true" @click="hide">
                &nbsp;
            </i>
        </div>

        <div class="px-3 text-center">
            <h5>You are leaving {{ domain }}!</h5>
            <p class="px-5">
                CarSaver is not responsible for any content, terms or conditions
                on this third-party website.
            </p>
            <div v-if="url" class="mt-5 text-center">
                <b-button
                    variant="primary"
                    target="_blank"
                    class="mx-auto"
                    rel="noopener noreferrer"
                    @click="leave"
                >
                    Continue
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<style lang="scss">
.leaving-carsaver {
    .modal-body {
        padding-top: 0;
    }

    h5 {
        font-size: 24px;
        color: $primary-blue;
    }
}
</style>

<script>
import { get } from "vuex-pathify";
import EventBus from "@/event-bus";

export default {
    data() {
        return {
            url: null
        };
    },

    computed: {
        domain: get("campaign/domain")
    },

    created() {
        EventBus.$on("leaving-modal", url => {
            this.url = url;

            this.$refs.modal.show();
        });
    },

    methods: {
        leave() {
            this.hide();

            window.open(this.url, "_blank").opener = null;
        },

        hide() {
            this.$refs.modal.hide();
        }
    }
};
</script>
