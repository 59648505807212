<template>
    <div class="card-body unable-to-process p-2">
        <div
            class="d-flex flex-column justify-content-center align-content-center"
        >
            <h3>Sorry, we were unable to process your request at this time.</h3>
            <p>
                For more information about available financing options, please
                speak with a Certified CarSaver Dealer or call your AutoAdvisor
                at (800) 217-4800
            </p>
            <p v-if="errorCode">Error Code: {{ errorCode }}</p>
        </div>
    </div>
</template>

<style lang="scss">
.unable-to-process {
    > div {
        width: 100%;
    }

    h3 {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0 auto;
    }

    p {
        width: 375px;
        margin: 9px auto;
        font-size: 0.8rem;
        color: $muted-dark-gray;
    }
}
</style>

<script>
export default {
    name: "UnableToProcess",

    props: {
        errorCode: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>
