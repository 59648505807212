var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column flex-sm-row p-4 align-items-center",
      attrs: { id: "certified-dealer-block" }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "p",
          { staticClass: "font-weight-bold mb-2 text-center text-sm-left" },
          [
            _vm._v(
              "\n            This vehicle is located at a CarSaver Certified Dealership\n        "
            )
          ]
        ),
        _vm._v(" "),
        _c("ul", { staticClass: "list d-flex flex-wrap m-0" }, [
          _c("li", [_vm._v("Everyday Low Pricing")]),
          _vm._v(" "),
          _c("li", [_vm._v("Express Delivery")]),
          _vm._v(" "),
          _vm.warrantyEligible
            ? _c("li", [
                _vm._v("\n                Lifetime Warranty *\n            ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("li", [_vm._v("Dedicated Program Manager")])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "logo-wrapper d-flex justify-content-center align-items-center m-0 mb-3 mb-sm-0 mr-sm-5"
      },
      [
        _c("img", {
          attrs: {
            src: require("./images/CRSV_Certified_Dealer_Logo.png"),
            alt: "Certified Dealer"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }