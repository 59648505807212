var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "gallery-icon-wrapper d-flex justify-content-center" },
      [
        _c(
          "a",
          {
            class: { "disabled-link": _vm.hasNoGalleryImages },
            attrs: { disabled: _vm.hasNoGalleryImages },
            on: {
              click: function($event) {
                !_vm.hasNoGalleryImages && _vm.showFeature("showImages")
              }
            }
          },
          [_vm._m(0)]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            class: { "disabled-link": _vm.hasNo360Images },
            attrs: { disabled: _vm.hasNo360Images },
            on: {
              click: function($event) {
                !_vm.hasNo360Images && _vm.showFeature("show360")
              }
            }
          },
          [_vm._m(1)]
        )
      ]
    ),
    _vm._v(" "),
    _vm.hasNoGalleryImages && _vm.hasNo360Images
      ? _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center align-items-center no-images-block"
          },
          [_vm._m(2)]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block" }, [
      _c("i", {
        staticClass: "icon icon-camera_font",
        attrs: { "aria-hidden": "true" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block" }, [
      _c("i", {
        staticClass: "icon icon-360_font",
        attrs: { "aria-hidden": "true" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex flex-column justify-content-center align-items-center"
      },
      [
        _c("i", {
          staticClass: "icon-car_front_view",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v(" "),
        _c("h3", [_vm._v("Sorry, no images available.")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }