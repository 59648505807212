<template>
    <div
        v-if="!fuelDataIsNull"
        class="fuel-consumption d-flex flex-row flex-nowrap align-items-center justify-content-around"
    >
        <div
            class="gas-icon-wrapper d-flex justify-content-start align-items-center"
        >
            <i aria-hidden="true" class="fa icon-gas_pump " />
        </div>
        <div class="mpg-block d-flex flex-column align-items-center">
            <span class="label text-uppercase">
                {{ $t("page.vdp.city") }}
            </span>
            <span class="value">
                {{ vehicle.mpgCity | emptyDash }}
            </span>
        </div>
        <div class="mpg-block d-flex flex-column align-items-center">
            <span class="label text-uppercase">
                {{ $t("page.vdp.hwy") }}
            </span>
            <span class="value">
                {{ vehicle.mpgHighway | emptyDash }}
            </span>
        </div>
        <div class="mpg-block d-flex flex-column align-items-center">
            <span class="label text-uppercase">
                {{ $t("page.vdp.avg") }}
            </span>
            <span class="value">
                {{ vehicle.averageMpg | emptyDash }}
            </span>
        </div>
    </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
    name: "FuelConsumptionBlock",

    computed: {
        vehicle: get("vehicleDetails/vehicle"),
        fuelDataIsNull() {
            return (
                this.vehicle.mpgCity === null &&
                this.vehicle.mpgHighway === null &&
                this.vehicle.averageMpg === null
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.fuel-consumption {
    width: 100%;

    .gas-icon-wrapper {
        height: auto;

        i {
            font-size: px2rem(55);
            color: $primary-blue;
        }
    }

    .mpg-block {
        .label {
            font-weight: 300;
            color: $dark-gray;
            font-size: px2rem(12);
            line-height: 10px;
        }
        .value {
            font-size: px2rem(64);
            color: $dark-gray;
            letter-spacing: -2.5px;
        }
    }
}
@include media-breakpoint-down(sm) {
    .mpg-block {
        .label {
            font-size: px2rem(10) !important;
            line-height: 10px !important;
        }
        .value {
            font-size: px2rem(45) !important;
        }
    }
}
</style>
