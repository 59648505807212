var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "cs-modal",
    { ref: "modal", attrs: { id: "lifetimeWarrantyModal" } },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _vm._v("\n        This Vehicle Comes With...\n    ")
      ]),
      _vm._v(" "),
      _c("b-container", { staticClass: "py-sm-3 py-5" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column justify-content-center align-items-center"
          },
          [
            _c("div", { staticClass: "d-flex flex-column mb-md-4 mb-0" }, [
              _c("img", {
                attrs: {
                  src: "/assets/images/lifetime-warranty-logo.png",
                  alt: "lifetime_warranty_logo"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center align-self-end mr-3 powered-block"
                },
                [
                  _c("small", { staticClass: "font-italic powered-by mr-1" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t("component.lifetimeWarrantyModal.poweredBy")
                        ) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "\n                        CarSaver\n                    "
                    )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex flex-column flex-md-row py-4 mb-0 mb-md-4"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-items-center text-center p-3 p-md-0 iconblock mx-2"
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: "/assets/images/shield.svg",
                        alt: "shield_icon_no_additional_cost"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("component.lifetimeWarrantyModal.itemOne")
                          ) +
                          "\n                    "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-items-center text-center p-3 p-md-0 iconblock mx-2"
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: "/assets/images/engine.svg",
                        alt: "engine_icon_covers_drivetrain"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("component.lifetimeWarrantyModal.itemTwo")
                          ) +
                          "\n                    "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-items-center text-center p-3 p-md-0 iconblock mx-2"
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: "/assets/images/time.svg",
                        alt: "clock_icon_covers_for_life"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("component.lifetimeWarrantyModal.itemThree")
                          ) +
                          "\n                    "
                      )
                    ])
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "disclaimer",
          domProps: { innerHTML: _vm._s(_vm.warrantyDisclaimer) }
        })
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
        _c(
          "a",
          { staticClass: "btn btn-primary", attrs: { href: "/warranty" } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("component.lifetimeWarrantyModal.btn")) +
                "\n        "
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }