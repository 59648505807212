<template>
    <div
        id="certified-dealer-block"
        class="d-flex flex-column flex-sm-row p-4 align-items-center"
    >
        <div
            class="logo-wrapper d-flex justify-content-center align-items-center m-0 mb-3 mb-sm-0 mr-sm-5"
        >
            <img
                src="./images/CRSV_Certified_Dealer_Logo.png"
                alt="Certified Dealer"
            />
        </div>
        <div class="content">
            <p class="font-weight-bold mb-2 text-center text-sm-left">
                This vehicle is located at a CarSaver Certified Dealership
            </p>
            <ul class="list d-flex flex-wrap m-0">
                <li>Everyday Low Pricing</li>
                <li>Express Delivery</li>
                <li v-if="warrantyEligible">
                    Lifetime Warranty *
                </li>
                <li>Dedicated Program Manager</li>
            </ul>
        </div>
    </div>
</template>
<script>
import { get } from "vuex-pathify";
export default {
    name: "CertifiedDealerBlock",

    computed: {
        warrantyEligible: get("vehicleDetails/vehicle@warrantyEligible")
    }
};
</script>
<style lang="scss" scoped>
#certified-dealer-block {
    margin-bottom: 10px;

    // .content-box
    border-radius: 2px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: px2rem(14);

    .logo-wrapper {
        img {
            max-width: 215px;
            width: 53vw;
        }
    }

    .content {
        max-width: 385px;
        p {
            font-size: px2rem(12);
        }
        ul.list {
            font-size: px2rem(14);
            padding-inline-start: 30px;

            li {
                min-width: 160px;
                padding-left: 0;
            }
        }
    }
}
</style>
