<template>
    <b-alert
        v-if="showNotification"
        show
        variant="warning"
        class="distance-notification text-center"
    >
        This vehicle is {{ distance | numeral("0,0") }} miles away from
        {{ userCity | capitalize }}, {{ userStateCode | upperCase }}
        <i
            v-b-tooltip.hover
            class="ion-ios-information-outline"
            :title="locationTooltipMsg"
            aria-hidden="true"
        />.&nbsp;

        <b-link @click="searchLocalListingsHandler">
            Search Local Listings
        </b-link>
    </b-alert>
</template>

<style>
.distance-notification {
    font-size: 0.9rem;
}
</style>

<script>
import _ from "lodash";
import { get, dispatch } from "vuex-pathify";

export default {
    name: "DistanceNotification",

    props: {
        dealerLinkRequired: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        dealer: get("vehicleDetails/dealer"),
        distance: get("vehicleDetails/dealer@distance"),
        location: get("location/selectedLocation"),
        city: get("location/selectedLocation@city"),
        stateCode: get("location/selectedLocation@state"),
        zipCode: get("location/selectedLocation@zipCode"),
        vehicle: get("vehicleDetails/vehicle"),
        userCity: get("user/user@city"),
        userStateCode: get("user/user@stateCode"),
        userZipCode: get("user/user@zipCode"),
        activeDealerLink: get("dealerLink/active"),
        selectedDealer: get("dealerLink/selectedDealer"),
        deliveryAvailability: get(
            "vehicleDetails/dealer@preferences.deliveryAvailability"
        ),

        isDelivery: function() {
            return _.eq(this.deliveryAvailability, "DELIVERY_ONLY");
        },
        showNotification() {
            if (this.isDelivery) {
                return false;
            }
            return (
                this.isFarAway &&
                (this.activeDealerLink || !this.dealerLinkRequired)
            );
        },
        locationTooltipMsg() {
            return "Based on your home address. To update, please visit your profile";
        },
        isFarAway: function() {
            return _.gt(this.distance, 250);
        },
        stockType: get("vehicleDetails/inventoryVehicle@stockType"),
        normStockType() {
            let type = this.stockType;
            let result;

            if (typeof type === "string") {
                type = type.toLowerCase();
            }

            switch (type) {
                case "used":
                    result = "used";
                    break;
                case "new":
                    result = "new";
                    break;
                default:
                    result = "new";
            }

            return result;
        },

        url() {
            const make = _.get(this.vehicle, "make");
            const model = _.get(this.vehicle, "model");
            const year = _.get(this.vehicle, "year");
            return `/shop/${this.normStockType}?makes=${make}&models=${model}&minYear=${year}&maxYear=${year}&distance=100`;
        }
    },

    watch: {
        location(newState) {
            if (!_.isNil(this.dealer)) {
                dispatch("vehicleDetails/loadDistance", {
                    zipCode: newState.zipCode,
                    lat: this.dealer.lat,
                    lng: this.dealer.lng
                });
            }
        }
    },

    methods: {
        async updateLocationZip(zip) {
            const newZip = zip;

            dispatch("location/updateZipCode", newZip);

            return newZip;
        },

        searchLocalListingsHandler() {
            this.updateLocationZip(this.userZipCode).then(
                (window.location = this.url)
            );
        }
    }
};
</script>
