var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-body" },
    _vm._l(_vm.offers, function(offer, key) {
      return _c("offer-row", {
        key: key,
        attrs: {
          offer: offer,
          accepted: _vm.isAccepted(offer),
          request: _vm.request,
          "resubmit-required": _vm.isResubmitRequired(offer)
        },
        on: { accept: _vm.acceptOffer, resubmit: _vm.resubmitOffer }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }