<template>
    <div class="card-body">
        <offer-row
            v-for="(offer, key) in offers"
            :key="key"
            :offer="offer"
            :accepted="isAccepted(offer)"
            :request="request"
            :resubmit-required="isResubmitRequired(offer)"
            @accept="acceptOffer"
            @resubmit="resubmitOffer"
        />
    </div>
</template>

<script>
import _ from "lodash";
import OfferRow from "./OfferRow";

export default {
    name: "OffersList",
    components: { OfferRow },
    props: {
        offers: {
            required: true,
            type: Array
        },
        request: {
            required: true,
            type: Object
        },
        acceptedResponseId: {
            type: Number,
            default: 0
        }
    },
    methods: {
        acceptOffer(data) {
            this.$emit("accept", data);
        },
        resubmitOffer(data) {
            this.$emit("resubmit", data);
        },
        isAccepted(offer) {
            return _.get(offer, "responseId", null) === this.acceptedResponseId;
        },
        isResubmitRequired(offer) {
            return _.get(offer, "status", null) === "RESUBMIT_REQUIRED";
        }
    }
};
</script>
