<template>
    <div class="vehicle-panel">
        <div class="panel-header">
            <b-img
                v-if="splashImage"
                :src="splashImage"
                :alt="carTitle"
                class="image"
                fluid
            />
            <body-style-icon v-else :id="vehicle.bodyStyle" />
            <div class="heading">
                {{ carTitle }}
            </div>
            <div class="subheading">
                {{ carSubtitle }}
            </div>
        </div>
        <div class="panel-content">
            <slot />
        </div>
    </div>
</template>

<style lang="scss">
.vehicle-panel {
    margin-top: 50px;
    border-radius: 2px;
    border: 1px solid $muted-gray;

    @include media-breakpoint-down(sm) {
        margin-top: 10px !important;

        > .panel-header {
            margin-top: 0 !important;
            background-color: $light-gray;
            padding: 5px 0;
            margin-bottom: 5px;

            > .image {
                display: none !important;
            }

            > .heading {
                margin-top: 0 !important;
            }
        }
    }

    > .panel-header {
        margin-top: -60px;
        text-align: center;

        > .image {
            display: block;
            height: 100px;
            margin: 0 auto;
            border-radius: 8px;
            border: 1px solid $line-gray;
        }

        > .heading {
            font-size: 18px;
            font-weight: bold;
            line-height: 21px;
            margin-top: 10px;
        }

        > .subheading {
            line-height: 20px;
        }
    }

    .panel-content {
        padding: 0 20px 20px;

        hr {
            border-top: 1px solid $muted-gray;
            width: 100%;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}
</style>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import BodyStyleIcon from "Modules/ShopBy/BodyStyle/components/BodyStyleIcon/index";

export default {
    name: "VehiclePanel",
    components: { BodyStyleIcon },
    props: {
        vehicle: {
            required: true,
            type: Object
        }
    },

    computed: {
        splashImage: get("vehicleDetails/media@splashImage"),
        mainImage() {
            return _.get(this.vehicle, "images[0]", null);
        },
        backupImage() {
            return _.get(this.vehicle, "stockPhoto.medium", null);
        },
        carTitle() {
            return `${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}`;
        },
        carSubtitle() {
            return this.vehicle.trim;
        }
    }
};
</script>
