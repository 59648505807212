<template>
    <detail-block
        v-if="selectedOptions"
        title="Packages &amp; Options"
        name="options"
    >
        <div class="card-body">
            <b-container fluid class="px-0 pb-3">
                <b-list-group>
                    <expandable-list-item
                        v-for="(option, key) in selectedOptions"
                        :id="option.code"
                        :key="key + option.code"
                        :expandable="option.details !== null"
                    >
                        <template slot="list-item">
                            <span>{{ option.name }}</span>
                            <span v-if="option.msrp > 0">
                                {{ option.msrp | numeral("$0,0") }}
                            </span>
                            <span v-else>
                                Inc.
                            </span>
                        </template>

                        <template slot="details">
                            <span class="font-italic">
                                {{ option.details }}
                            </span>
                        </template>
                    </expandable-list-item>
                    <b-list-group-item class="expandable-list-item">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div
                                class="d-flex justify-content-between align-items-center w-100 pr-1"
                            >
                                <span class="font-weight-bold">
                                    Total Packages &amp; Options
                                </span>
                                <span class="font-weight-bold">
                                    {{ total | numeral("$0,0") }}
                                </span>
                            </div>
                        </div>
                    </b-list-group-item>
                </b-list-group>

                <div class="w-100 pt-3">
                    <b-btn variant="secondary mx-auto" @click="addEditClick">
                        add/edit
                    </b-btn>
                </div>
            </b-container>
        </div>
    </detail-block>
</template>
<script>
import DetailBlock from "Modules/Vehicle/DetailBlock";
import ExpandableListItem from "Modules/Vehicle/ExpandableListItem";
import _ from "lodash";
import { get } from "vuex-pathify";

export default {
    components: { ExpandableListItem, DetailBlock },

    computed: {
        certificateId: get("vehicleDetails/certificate@id"),
        selectedOptions: get("vehicleDetails/vehicle@selectedOptions"),
        configurationState: get("vehicleDetails/vehicle@configurationState"),
        styleId: get("vehicleDetails/vehicle@styleId"),
        total() {
            return _.chain(this.selectedOptions)
                .filter(
                    option =>
                        _.toUpper(option.selectionState) === "REQUIRED" ||
                        _.toUpper(option.selectionState) === "SELECTED"
                )
                .sumBy("msrp")
                .value();
        }
    },

    methods: {
        addEditClick() {
            document.location = `/configure/style/${
                this.styleId
            }/options?configuration_state=${encodeURIComponent(
                this.configurationState
            )}&cid=${this.certificateId}`;
        }
    }
};
</script>
