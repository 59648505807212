<template>
    <div
        v-if="!signedIn"
        id="mobileContactBlock"
        class="d-flex d-md-none d-lg-none flex-column text-center"
    >
        <span class="title">
            {{ $t("component.pricingCard.contactUs.questions") }}
        </span>
        <span class="sub-title">
            {{ $t("component.pricingCard.contactUs.speakToAutoAdvisor") }}
        </span>
        <div class="d-flex justify-content-center">
            <b-link
                class="btn btn-secondary d-flex justify-content-center align-items-center"
                :href="'tel:' + phoneNumber"
            >
                <i aria-hidden="true" class="ion-ios7-telephone" />
                {{ phoneNumber }}
            </b-link>
        </div>
    </div>
</template>
<script>
import { get } from "vuex-pathify";
export default {
    name: "MobileContactBlock",

    computed: {
        phoneNumber: get("campaign/phoneNumberFormatted"),
        signedIn: get("user/user")
    }
};
</script>
<style lang="scss">
#mobileContactBlock {
    padding: 16px 18px 21px 18px;
    height: 145px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-color: $white;

    .title {
        font-size: 28px;
        color: #444;
        margin-bottom: 0;
    }
    .sub-title {
        font-size: 14px;
        margin-bottom: 10px;
    }
    a {
        color: $primary-blue;
        border-radius: 22.5px;
        border: 1px solid #dddddd;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        height: 40px;
        width: 150px;
        font-size: 14px;
    }
}
</style>
