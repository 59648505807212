<template>
    <div id="vdp-slider" class="d-flex flex-column header">
        <vehicle-title-bar />

        <div class="card-image order-2 order-md-1 position-relative">
            <div class="container-fluid">
                <new-search-link />
            </div>

            <vehicle-image-slider v-if="vehicleGallery || vehicleMediumImage" />

            <missing-image v-else :body-style="bodyStyle" />
        </div>
    </div>
</template>

<style lang="scss">
#vdp-slider {
    padding: 0;
    background-color: $very-light-gray;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
</style>

<script>
import VehicleTitleBar from "Modules/Vehicle/VehicleTitleBar";
import NewSearchLink from "Modules/Vehicle/NewSearchLink";
import VehicleImageSlider from "Modules/Vehicle/VehicleImageSlider";
import { get } from "vuex-pathify";
import MissingImage from "Modules/Vehicle/VdpHero/components/MissingImage";

export default {
    components: {
        MissingImage,
        VehicleImageSlider,
        NewSearchLink,
        VehicleTitleBar
    },

    computed: {
        vehicleGallery: get("vehicleDetails/media@carouselImageUrls"),
        vehicleMediumImage: get("vehicleDetails/media@front34/medium"),
        bodyStyle: get("vehicleDetails/vehicle@bodyStyle")
    }
};
</script>
