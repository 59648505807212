<template>
    <div class="card">
        <div
            class="card-header d-flex justify-content-between"
            :class="showCollapse ? 'collapsed' : null"
            :aria-controls="`${name}-collapse`"
            :aria-expanded="showCollapse ? 'true' : 'false'"
            @click="showCollapse = !showCollapse"
        >
            <h2 class="mb-0">
                {{ title }}
            </h2>
            <i
                aria-hidden="true"
                :class="showCollapse ? 'ion-chevron-up' : 'ion-chevron-down'"
            />
        </div>
        <b-collapse :id="`${name}-collapse`" v-model="showCollapse">
            <loading-spinner v-if="loading" class="my-5 mx-auto" />
            <slot v-else />
        </b-collapse>
    </div>
</template>

<script>
import LoadingSpinner from "Components/LoadingSpinner/index";
export default {
    name: "DetailBlock",
    components: {
        LoadingSpinner
    },
    props: {
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            showCollapse: true
        };
    }
};
</script>
