var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "c-s-modal",
    { ref: "modal", staticClass: "restricted-make-no-email-modal" },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("component.pricingCard.noEmail")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("b-container", { attrs: { fluid: "" } }, [
        _c("p", [
          _c("strong", [
            _vm._v(
              _vm._s(_vm.$t("component.pricingCard.sorryYourHavingTrouble"))
            )
          ])
        ]),
        _vm._v(" "),
        _c("ol", [
          _c(
            "li",
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("component.pricingCard.verifyYourContactInfo"))
              ),
              _c("br"),
              _vm._v(" "),
              _c("email-updater")
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [_vm._v(_vm._s(_vm.$t("component.pricingCard.junkMail")))]),
          _vm._v(" "),
          _c("li", [
            _vm._v(_vm._s(_vm.$t("component.pricingCard.clickTheResendButton")))
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(_vm._s(_vm.$t("component.pricingCard.callCarSaverDealer")))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "questions d-flex flex-column flex-nowrap align-items-center justify-content-between"
          },
          [
            _c("i", {
              staticClass: "ion-android-call d-none d-md-inline",
              attrs: { "aria-hidden": "true" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "phone-row text-center" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.$t("component.pricingCard.stillHaveQuestions"))
                )
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("component.pricingCard.giveUsACall")) +
                  "\n                " +
                  _vm._s(_vm.phoneNumber) +
                  ".\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "note-row text-center" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("component.pricingCard.note")) +
                  "\n            "
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "w-100 d-flex justify-content-center",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer"
        },
        [
          _c(
            "b-button",
            {
              staticClass: "resend-btn",
              attrs: { variant: "primary" },
              on: { click: _vm.resendEmail }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("component.pricingCard.resend")) +
                  "\n        "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }