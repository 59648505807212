var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "detailsModal",
      staticClass: "view-details-modal",
      attrs: { id: _vm.id, size: "lg", lazy: "" }
    },
    [
      _c(
        "div",
        { attrs: { slot: "modal-header-close" }, slot: "modal-header-close" },
        [
          _c(
            "i",
            {
              staticClass: "ion-ios-close-outline",
              attrs: { "aria-hidden": "true" }
            },
            [_vm._v("\n             \n        ")]
          )
        ]
      ),
      _vm._v(" "),
      _vm._t("title", null, { slot: "modal-title" }),
      _vm._v(" "),
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _vm._t("summary"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.offerTabClasses },
                    [_vm._t("offerTab")],
                    2
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.detailsTabClasses },
                [_vm._t("detailsTab")],
                2
              ),
              _vm._v(" "),
              _vm._t("footer")
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
        [
          _c(
            "b-button-group",
            { staticClass: "d-sm-none" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: _vm.getButtonVariant("offer") },
                  on: {
                    click: function($event) {
                      return _vm.showOffer()
                    }
                  }
                },
                [_vm._v("\n                Offer\n            ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: _vm.getButtonVariant("details") },
                  on: {
                    click: function($event) {
                      return _vm.showDetails()
                    }
                  }
                },
                [_vm._v("\n                Details\n            ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }