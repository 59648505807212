var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "vinStockMilesWrapper" } }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-wrap justify-content-between align-items-center content-box px-3",
        attrs: { id: "vinStockMiles" }
      },
      [
        _c("div", { staticClass: "text-nowrap" }, [
          _c("span", { staticClass: "label" }, [
            _vm._v("\n                VIN:\n            ")
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "value" },
            [
              _vm.showCarfax
                ? _c(
                    "b-link",
                    {
                      attrs: {
                        href: _vm.inventoryVehicle.carfaxUrl,
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("emptyDash")(_vm.vin)) +
                          "\n                    "
                      ),
                      _c("img", {
                        attrs: {
                          src: "/assets/images/carfax.png",
                          alt: "error_car"
                        }
                      })
                    ]
                  )
                : _c("span", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("emptyDash")(_vm.vin)) +
                        "\n                "
                    )
                  ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.inventoryVehicle
          ? _c("div", [
              _c("span", { staticClass: "label" }, [
                _vm._v(" " + _vm._s(_vm.$t("page.vdp.stock")) + ": ")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("emptyDash")(_vm.inventoryVehicle.stockNumber)
                    ) +
                    "\n            "
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.vehicleIsUsed
          ? _c("div", [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("page.vdp.miles")) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("emptyDash")(
                        _vm._f("numeral")(_vm.inventoryVehicle.miles)
                      )
                    ) +
                    "\n            "
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showDistanceAway
          ? _c("div", [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("page.vdp.distance")) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("numeral")(_vm.distance, "0,0")) +
                    " miles\n            "
                )
              ])
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    !_vm.inventoryVehicle
      ? _c(
          "div",
          {
            staticClass:
              "d-flex flex-wrap justify-content-between align-items-center content-box pb-3 px-3",
            attrs: { id: "configuredDisclaimer" }
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("page.vdp.configuredMsg")) + "\n    "
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }