var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-loader",
    {
      attrs: {
        height: 250,
        width: 800,
        speed: 2,
        "primary-color": "#f3f3f3",
        "secondary-color": "#ecebeb"
      }
    },
    [
      _c("rect", {
        attrs: {
          x: "470",
          y: "20",
          rx: "0",
          ry: "0",
          width: "320",
          height: "200"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "10",
          y: "20",
          rx: "0",
          ry: "0",
          width: "360",
          height: "20"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "10",
          y: "50",
          rx: "0",
          ry: "0",
          width: "200",
          height: "20"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "230",
          y: "50",
          rx: "0",
          ry: "0",
          width: "220",
          height: "20"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "10",
          y: "80",
          rx: "0",
          ry: "0",
          width: "200",
          height: "20"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "10",
          y: "110",
          rx: "0",
          ry: "0",
          width: "440",
          height: "110"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }