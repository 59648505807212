var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "detail-block",
    {
      attrs: {
        id: "insurance-tab",
        title: _vm.$t("component.insuranceBlock.title"),
        name: "insurance"
      }
    },
    [
      _vm.quotes.length === 0
        ? _c("get-insurance-quote")
        : _c("insurance-quote-list", { attrs: { quotes: _vm.quotes } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }