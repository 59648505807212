<template>
    <div
        id="payment"
        class="tab-pane pb-3"
        role="tabpanel"
        aria-labelledby="payment-tab"
    >
        <div class="container-fluid pt-2">
            <div class="row">
                <div class="col-12">
                    <restricted-make-alert
                        v-if="showRestrictedMake"
                        :dealer-name="dealerName"
                        :vehicle-name="vehicleName"
                        :savings="savings"
                    />
                    <estimated-payment
                        v-else
                        :show-lease="canLease"
                        :loading="isLoading"
                        :monthly-payment="monthlyPayment"
                        :disclaimer="disclaimer"
                        :strikeout="strikeoutPrice"
                    />
                </div>
            </div>
        </div>
        <get-supplier-pricing-block v-if="showSupplierPricing" />
        <get-sales-price-block v-if="isCertifiedDealer" />
        <contact-dealer-block v-else />
    </div>
</template>
<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import EstimatedPayment from "./components/EstimatedPayment";
import RestrictedMakeAlert from "../RestrictedMakeAlert";
import GetSalesPriceBlock from "Modules/Vehicle/PricingCard/components/GetSalesPriceBlock";
import GetSupplierPricingBlock from "Modules/Vehicle/PricingCard/components/GetSupplierPricingBlock";
import ContactDealerBlock from "Modules/Vehicle/PricingCard/components/ContactDealerBlock";

export default {
    name: "PaymentTab",
    components: {
        ContactDealerBlock,
        GetSupplierPricingBlock,
        GetSalesPriceBlock,
        RestrictedMakeAlert,
        EstimatedPayment
    },

    props: {
        canFinance: {
            type: Boolean,
            default: true
        },

        canLease: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        certificateBaseQuote: get("vehicleDetails/certificate@baseQuote"),
        certificateShownPricing: get("vehicleDetails/certificate@shownPricing"),
        bestDealsPricingCard: get("paymentMatrix/bestDealsPricingCard"),
        bestDealsLoadingStep: get("paymentMatrix/loadingStep"),
        pricing: get("vehicleDetails/pricing"),
        estimatedPayment: get("vehicleDetails/estimatedPayment"),
        restrictedMake: get("vehicleDetails/vehicle@restrictedMake"),
        canUnlockPricing: get("dealerLink/canUnlockPricing"),
        dealerName: get("vehicleDetails/dealer@name"),
        vehicle: get("vehicleDetails/vehicle"),
        certifiedDealer: get("vehicleDetails/dealer@certified"),
        inventoryVehicle: get("vehicleDetails/inventoryVehicle"),
        isCertifiedDealer() {
            return this.certifiedDealer || this.isConfigured;
        },
        isConfigured() {
            return _.isNil(this.inventoryVehicle);
        },
        showSupplierPricing() {
            return _.get(this.pricing, "supplierPricingAvailable", false);
        },

        strikeoutPrice() {
            return (
                (this.monthlyPayment && !this.canUnlockPricing) ||
                _.get(this.bestDeal, "baseQuote.purchase") ===
                    _.get(this.pricing, "msrp")
            );
        },

        isLoading() {
            if (this.certificateBaseQuote != null) {
                return false;
            }

            if (!this.canUnlockPricing) {
                return this.estimatedPayment.loading;
            }

            return this.bestDealsLoadingStep === 0;
        },

        bestDeal() {
            return _.minBy(this.bestDealsPricingCard, "quote.monthlyPayment");
        },

        monthlyPayment() {
            if (this.certificateBaseQuote != null) {
                return this.certificateBaseQuote.monthlyPayment;
            }

            if (!this.canUnlockPricing) {
                return this.estimatedPayment.monthlyPayment;
            }

            return _.get(this.bestDeal, "quote.monthlyPayment");
        },

        disclaimer() {
            if (this.certificateBaseQuote != null) {
                return this.certificateBaseQuote.disclaimer;
            }

            if (!this.canUnlockPricing) {
                return this.estimatedPayment.disclaimer;
            }

            const bestDeal = _.minBy(
                this.bestDealsPricingCard,
                "quote.monthlyPayment"
            );
            return _.get(bestDeal, "disclaimer");
        },

        showRestrictedMake() {
            return this.restrictedMake && this.canUnlockPricing;
        },
        vehicleName() {
            const year = _.get(this.vehicle, "year");
            const make = _.get(this.vehicle, "make");
            const model = _.get(this.vehicle, "model");
            return `${year} ${make} ${model}`;
        },
        savings() {
            const salePrice = _.get(this.pricing, "salePrice.value");
            const msrp = _.get(this.pricing, "msrp");
            return msrp - salePrice;
        }
    }
};
</script>
<style lang="scss">
#payment {
    width: 100%;

    .container-fluid {
        padding: 7px 20px 0 20px;

        .line-item {
            color: $dark-gray;

            .title {
                font-size: px2rem(16);
                color: $dark-gray;
            }
            .value {
                font-size: px2rem(26);
                font-weight: bold;
                font-style: italic;
                font-family: Times New Roman, sans-serif;
                color: $muted-dark-gray;
                line-height: 30px;
            }
            .blue-value {
                font-size: px2rem(26);
                font-weight: bold;
                font-style: italic;
                font-family: Times New Roman, sans-serif;
                line-height: 30px;
                color: $primary-blue;
            }
            small {
                font-size: 10px;
                color: $muted-dark-gray;
                line-height: 5px;
            }
        }
    }
}
</style>
