var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.installedOptions
    ? _c("div", { attrs: { id: "installed-options-block" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "d-flex flex-column flex-md-row flex-wrap mb-0" },
          _vm._l(_vm.installedOptions, function(option, index) {
            return _c("li", { key: index }, [
              _c("div", { staticClass: "constraints collapsed" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("capitalize")(option)) +
                    "\n                "
                ),
                option.length > 90
                  ? _c(
                      "div",
                      { staticClass: "overlay d-none d-md-flex w-100" },
                      [
                        _c(
                          "b-link",
                          {
                            on: {
                              click: function($event) {
                                return _vm.expandOption($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                        more\n                    "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex justify-content-between" }, [
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v("\n            Options\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }