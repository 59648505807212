<template>
    <pick-price-drive :selected-index="selectedIndex" />
</template>

<script>
import PickPriceDrive from "./index";
import { get } from "vuex-pathify";

export default {
    components: { PickPriceDrive },

    computed: {
        dealerLinkActive: get("dealerLink/active"),
        appointmentScheduled: get(
            "vehicleDetails/certificate@appointmentScheduled"
        ),

        selectedIndex() {
            if (this.appointmentScheduled) {
                return 3;
            }

            if (this.dealerLinkActive) {
                return 2;
            }

            return 1;
        }
    }
};
</script>
