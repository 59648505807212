<template>
    <b-modal
        id="payment-matrix-wrapper"
        ref="modal"
        :busy="isLoading"
        :hide-footer="true"
        size="lg"
        title="Payment Options"
        @show="onShow"
    >
        <div slot="modal-header-close">
            <i aria-hidden="true" class="ion-ios-close-outline" />
        </div>
        <div v-if="isLoading" class="loading pt-2 text-center">
            <loading-spinner :size="60" />
            <h3 v-if="changingDownPayment" class="mt-2">
                Updating quotes with new down payment.
            </h3>
        </div>

        <b-container v-else fluid>
            <b-row>
                <b-col>
                    <h3>
                        <strong>{{ vehicle.fullName }}</strong>
                    </h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="8" class="d-flex">
                    <div
                        class="selected-payment-info d-flex flex-row flex-md-column flex-lg-row"
                    >
                        <div
                            class="vehicle-image-wrapper d-flex justify-content-center w-100"
                        >
                            <vehicle-image
                                :image-url="vehicleImageUrl"
                                :backup-image-url="vehicleBackUpImageUrl"
                                :fill="true"
                            />
                        </div>
                        <selected-payment-card
                            :selected-offer="selectedOffer"
                            @fetch-taxes="fetchQuoteTaxes"
                        />
                    </div>
                </b-col>
                <b-col cols="12" lg="4" class="offer-details-column pl-lg-0">
                    <div class="offer-details-wrapper">
                        <h4 id="lender-name" tabindex="0" class="text-center">
                            {{
                                selectedOffer.quote.lenderName
                                    | truncate({
                                        length: 30,
                                        separator: /\-| /
                                    })
                            }}
                        </h4>
                        <b-popover
                            :content="selectedOffer.quote.lenderName"
                            target="lender-name"
                            triggers="hover focus"
                            placement="left"
                        />
                        <div
                            v-if="selectedOffer.quote.type === 'Loan'"
                            class="offer-details"
                        >
                            <div class="offer-row">
                                <div class="offer-label">
                                    Term
                                </div>
                                <div class="offer-value text-right">
                                    {{ selectedOffer.quote.term }} Months
                                </div>
                            </div>
                            <div class="offer-row">
                                <div class="offer-label">
                                    Annual Percentage Rate
                                </div>
                                <div class="offer-value text-right">
                                    {{
                                        selectedOffer.quote.interestRate
                                            | numeral("0.0")
                                    }}%
                                </div>
                            </div>
                            <div class="offer-row">
                                <div class="offer-label">
                                    Purchase Price
                                </div>
                                <div class="offer-value text-right">
                                    {{
                                        selectedOffer.quote.purchase
                                            | numeral("$0,0")
                                    }}
                                </div>
                            </div>

                            <div class="offer-row">
                                <div class="offer-label">
                                    Down Payment
                                </div>
                                <div class="offer-value text-right">
                                    {{
                                        selectedOffer.quote.downPayment
                                            | numeral("$0,0")
                                    }}
                                </div>
                            </div>
                            <div
                                v-if="
                                    selectedOffer.quote.upfront -
                                        selectedOffer.quote.downPayment >
                                        0
                                "
                                class="offer-row"
                            >
                                <div class="offer-label">
                                    Addt'l Due at Signing
                                    <tooltip-disclaimer
                                        title="This value includes additional fees due at signing."
                                    />
                                </div>
                                <div class="offer-value text-right">
                                    {{
                                        (selectedOffer.quote.upfront -
                                            selectedOffer.quote.downPayment)
                                            | numeral("$0,0")
                                    }}
                                </div>
                            </div>

                            <hr
                                v-if="
                                    selectedOffer.outOfPocket ||
                                        selectedOffer.cashBack
                                "
                            />

                            <div
                                v-if="selectedOffer.outOfPocket"
                                class="offer-row"
                            >
                                <div class="offer-label">
                                    <strong>Out of Pocket</strong>
                                </div>
                                <div class="offer-value text-right">
                                    <strong>{{
                                        selectedOffer.outOfPocket
                                            | numeral("$0,0")
                                    }}</strong>
                                </div>
                            </div>

                            <div
                                v-if="selectedOffer.cashBack"
                                class="offer-row"
                            >
                                <div class="offer-label">
                                    <strong>Cash Back</strong>
                                </div>
                                <div class="offer-value text-right">
                                    <strong>{{
                                        selectedOffer.cashBack | numeral("$0,0")
                                    }}</strong>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="selectedOffer.quote.type === 'Closed End'"
                            class="offer-details"
                        >
                            <div class="offer-row">
                                <div class="offer-label">
                                    Term
                                </div>
                                <div class="offer-value text-right">
                                    {{ selectedOffer.quote.term }} Months
                                </div>
                            </div>
                            <div class="offer-row">
                                <div class="offer-label">
                                    Miles Per Year
                                </div>
                                <div class="offer-value text-right">
                                    {{
                                        selectedOffer.quote.mileageAllowed
                                            | numeral("0,0")
                                    }}
                                </div>
                            </div>
                            <div class="offer-row">
                                <div class="offer-label">
                                    Additional Miles
                                </div>
                                <div class="offer-value text-right">
                                    {{
                                        selectedOffer.quote.milePenalty
                                            | numeral("$0.00")
                                    }}/mile
                                </div>
                            </div>

                            <div v-if="!downPaymentEdit" class="offer-row">
                                <div class="offer-label">
                                    Down Payment
                                </div>
                                <div class="offer-value text-right">
                                    {{
                                        selectedOffer.quote.downPayment
                                            | numeral("$0,0")
                                    }}
                                </div>
                            </div>

                            <div
                                v-if="
                                    selectedOffer.quote.upfront -
                                        selectedOffer.quote.downPayment >
                                        0
                                "
                                class="offer-row"
                            >
                                <div class="offer-label">
                                    Addt'l Due at Signing
                                    <tooltip-disclaimer
                                        title="This value includes additional fees due at signing."
                                    />
                                </div>
                                <div class="offer-value text-right">
                                    {{
                                        (selectedOffer.quote.upfront -
                                            selectedOffer.quote.downPayment)
                                            | numeral("$0,0")
                                    }}
                                </div>
                            </div>

                            <hr
                                v-if="
                                    selectedOffer.outOfPocket ||
                                        selectedOffer.cashBack
                                "
                            />

                            <div
                                v-if="selectedOffer.outOfPocket"
                                class="offer-row"
                            >
                                <div class="offer-label">
                                    <strong>Out of Pocket </strong>
                                    <tooltip-disclaimer
                                        title="This value includes the first month's payment."
                                    />
                                </div>
                                <div class="offer-value text-right">
                                    <strong>{{
                                        selectedOffer.outOfPocket
                                            | numeral("$0,0")
                                    }}</strong>
                                </div>
                            </div>
                            <div
                                v-if="selectedOffer.cashBack"
                                class="offer-row"
                            >
                                <div class="offer-label">
                                    <strong>Cash Back</strong>
                                </div>
                                <div class="offer-value text-right">
                                    <strong>{{
                                        selectedOffer.cashBack | numeral("$0,0")
                                    }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="hasFinancingAvailable" class="offer-details">
                        <div class="d-flex button-block mt-2">
                            <b-button
                                class="w-100"
                                variant="primary"
                                size="lg"
                                :href="financeHref"
                            >
                                Apply Now
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="offers-scroller">
                        <b-row>
                            <b-col class="text-center payment-selector">
                                <a
                                    :class="{
                                        selected: paymentType === 'BEST'
                                    }"
                                    @click="choose('BEST')"
                                >
                                    BEST
                                </a>
                                |
                                <a
                                    :class="{
                                        selected: paymentType === 'FINANCE'
                                    }"
                                    @click="choose('FINANCE')"
                                >
                                    FINANCE </a
                                ><span v-if="showLease">
                                    |
                                    <a
                                        :class="{
                                            selected: paymentType === 'LEASE'
                                        }"
                                        @click="choose('LEASE')"
                                    >
                                        LEASE
                                    </a>
                                    |
                                    <a
                                        :class="{
                                            selected: paymentType === 'ALL'
                                        }"
                                        @click="choose('ALL')"
                                    >
                                        ALL
                                    </a>
                                </span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="scroller">
                                    <div class="arrow-left">
                                        <a @click="slideLeft">
                                            <i
                                                aria-hidden="true"
                                                class="icon ion-chevron-left"
                                            />
                                        </a>
                                    </div>
                                    <div
                                        v-if="showSliderLoader"
                                        class="loading pt-2 text-center"
                                    >
                                        <loading-spinner />
                                    </div>
                                    <slider
                                        v-else
                                        ref="paymentcards"
                                        :options="sliderOptions"
                                        css-class="payment-cards"
                                    >
                                        <payment-card
                                            v-for="(offer, index) in offers"
                                            :key="index"
                                            :offer="offer"
                                            :selected="isSelected(offer)"
                                            :index="index"
                                            @click="onPaymentCardClick"
                                        />
                                    </slider>
                                    <div class="arrow-right">
                                        <a @click="slideRight">
                                            <i
                                                aria-hidden="true"
                                                class="icon ion-chevron-right"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-center disclaimer">
                                Payments shown exclude taxes &amp; fees.
                            </b-col>
                        </b-row>
                        <b-row class="out-of-pocket-wrapper">
                            <b-col
                                class="downpayment-editor-wrapper d-flex px-5 justify-content-between flex-column flex-lg-row"
                            >
                                <b-form-group
                                    id="downpayment-editor"
                                    label="Down Payment"
                                    label-for="downpayment-field"
                                    class="mb-lg-0"
                                >
                                    <b-form-input
                                        ref="downPaymentEditor"
                                        v-model.lazy="downPaymentInput"
                                        v-money="{
                                            precision: 0,
                                            prefix: '$',
                                            masked: false
                                        }"
                                        type="text"
                                        class="form-control form-control-sm"
                                        style="max-width: 100%"
                                        tabindex="1"
                                        placeholder="Down Payment"
                                        :state="downPaymentValidState"
                                        :maxlength="downPaymentMaxLength"
                                        @keyup.enter="updateDownPayment"
                                    />
                                    <b-form-invalid-feedback
                                        id="downPaymentFeedback"
                                    >
                                        Lower down payment
                                    </b-form-invalid-feedback>
                                </b-form-group>

                                <div
                                    class="save-btn-wrapper d-flex align-items-end justify-content-between px-lg-3"
                                >
                                    <b-button
                                        variant="outline-primary"
                                        size="sm"
                                        tabindex="2"
                                        @click="updateDownPayment"
                                    >
                                        Recalculate
                                    </b-button>
                                    <b-button
                                        variant="orange"
                                        size="sm"
                                        tabindex="3"
                                        @click="saveAndClose"
                                    >
                                        Save
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="payment-disclaimer">
                    Payments shown exclude taxes & fees. With Approved Credit.
                    Not all applicants will qualify. Lease and loan quoting is a
                    dynamic process so payments and terms are subject to change
                    prior to contract execution by all parties.
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>
<script>
import numeral from "numeral";
import { dispatch, get, sync } from "vuex-pathify";
import EventBus from "../../../event-bus";
import _ from "lodash";
import VehicleImage from "../VehicleImage/index";
import PaymentCard from "./components/PaymentCard";
import SelectedPaymentCard from "./components/SelectedPaymentCard";
import Slider from "@carsaver/slider";
import LoadingSpinner from "Components/LoadingSpinner";
import { trackPageView } from "@/lib/GoogleTagManager";
import TooltipDisclaimer from "Components/TooltipDisclaimer/index";

export default {
    name: "PaymentMatrix",
    components: {
        TooltipDisclaimer,
        LoadingSpinner,
        PaymentCard,
        VehicleImage,
        Slider,
        SelectedPaymentCard
    },

    props: {
        showLease: {
            required: false,
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            paymentType: "BEST",
            currentlySelectedOffer: null,
            sliderOptions: {
                prevNextButtons: false,
                pageDots: false,
                cellSelector: ".payment-card",
                cellAlign: "center",
                contain: true
            },
            saving: false,
            downPaymentEdit: false,
            downPaymentInput: null,
            closePaymentOptions: false
        };
    },

    computed: {
        bestDeals: get("paymentMatrix/best"),
        allDeals: get("paymentMatrix/all"),
        loadingStep: get("paymentMatrix/loadingStep"),
        changingDownPayment: get("paymentMatrix/changingDownPayment"),
        inventoryVehicle: get("vehicleDetails/inventoryVehicle"),
        vehicle: get("vehicleDetails/vehicle"),
        media: get("vehicleDetails/media"),
        zipCode: get("user/user@zipCode"),
        certificateQuote: sync("vehicleDetails/certificate@baseQuote"),
        certificateId: sync("vehicleDetails/certificate@id"),
        hasFinancingAvailable: get("vehicleDetails/vehicle@financingAvailable"),

        financeHref() {
            return `/certificate/${this.certificateId}/finance/app`;
        },
        downPaymentMaxLength() {
            return this.selectedOffer.quote.purchase.toString().length + 2;
        },

        isConfigured() {
            return this.inventoryVehicle === null;
        },

        isLoading() {
            return this.changingDownPayment || this.loadingStep === 0;
        },
        showSliderLoader() {
            return this.paymentType !== "BEST" && this.loadingStep === 1;
        },

        offers() {
            let offers;

            switch (this.paymentType) {
                case "BEST":
                    offers = this.getBestDeals();
                    break;
                case "FINANCE":
                    offers = this.getFinanceDeals();
                    break;
                case "LEASE":
                    offers = this.getLeaseDeals();
                    break;
                default:
                    offers = this.allDeals;
            }

            return offers;
        },

        vehicleImageUrl() {
            return _.get(this.media, "carouselImageUrls[0]", null);
        },

        vehicleBackUpImageUrl() {
            return _.get(this.media, "front34.small", null);
        },

        /**
         * The offer that is visually selected.
         * Clicked offer takes precedence. If one hasn't been clicked,
         * it will either be the certificate's offer or
         * best available from the choices available.
         * */
        selectedOffer() {
            const certificateQuoteOffer = _.find(this.offers, offer => {
                return this.quoteMatches(offer.quote, this.certificateQuote);
            });

            const bestAvailableOffer = _.head(this.bestDeals);

            return (
                this.currentlySelectedOffer ||
                certificateQuoteOffer ||
                bestAvailableOffer ||
                null
            );
        },
        selectedOfferQuotePrice() {
            return this.selectedOffer.quote.purchase;
        },
        downPaymentValidState() {
            let downPaymentInput = _.toString(this.downPaymentInput);

            if (downPaymentInput === "") {
                return false;
            }

            downPaymentInput = downPaymentInput.replace(/\D/g, "");

            if (isNaN(downPaymentInput)) {
                return false;
            }

            if (_.toNumber(downPaymentInput) >= this.selectedOfferQuotePrice) {
                return false;
            }

            return true;
        }
    },

    watch: {
        changingDownPayment: function(newVal, oldVal) {
            if (newVal === false && this.closePaymentOptions) {
                this.closePaymentOptions = false;
                this.saveOfferToCertificate(this.selectedOffer);
                this.$refs.modal.hide();
            }

            if (newVal === false) {
                this.downPaymentInput = _.get(
                    this.selectedOffer,
                    "quote.downPayment"
                );
            }
        },

        currentlySelectedOffer(newVal) {
            this.downPaymentInput = numeral(
                _.get(newVal, "quote.downPayment")
            ).format("$0,0");
        }
    },

    created() {
        EventBus.$on("payment-matrix-modal-open", this.show);
    },

    mounted() {
        this.downPaymentInput = _.get(this.selectedOffer, "quote.downPayment");
    },

    methods: {
        fetchQuoteTaxes() {
            dispatch("paymentMatrix/fetchQuoteTaxes", {
                certificateId: this.certificateId,
                quote: this.selectedOffer.quote
            });
        },
        updateDownPayment() {
            if (!this.downPaymentValidState) {
                return;
            }

            this.downPaymentEdit = false;
            dispatch("paymentMatrix/changeDownPayment", {
                certificateId: this.certificateId,
                downPayment: this.downPaymentInput.replace(/\D/g, "")
            });
            this.resetToDefaultValues();
        },
        resetToDefaultValues() {
            this.currentlySelectedOffer = null; // reset the currently selected offer
        },

        /**
         * Compare 1 quote to another. Quotes "match" if true.
         */
        quoteMatches(quote, other) {
            if (_.isNil(other)) {
                return _.isNil(quote);
            }

            return (
                quote.term === other.term &&
                quote.interestRate === other.interestRate &&
                quote.type === other.type &&
                quote.lenderNumber === other.lenderNumber &&
                quote.monthlyPayment.withFeesAndTaxes ===
                    other.monthlyPayment.withFeesAndTaxes
            );
        },

        saveOfferToCertificate(offer) {
            dispatch("vehicleDetails/saveOfferToCertificate", { offer });
        },

        onPaymentCardClick(key) {
            const offers = this.offers;
            this.currentlySelectedOffer = offers[key];
        },

        isSelected(currentOffer) {
            if (_.isNil(this.selectedOffer) || _.isNil(currentOffer)) {
                return false;
            }

            return this.quoteMatches(
                currentOffer.quote,
                this.selectedOffer.quote
            );
        },

        getBestDeals() {
            return _.sortBy(this.bestDeals, ["quote.monthlyPayment"]);
        },

        getFinanceDeals() {
            return _.filter(this.allDeals, d => d.quote.type === "Loan");
        },

        getLeaseDeals() {
            return _.filter(this.allDeals, d => d.quote.type === "Closed End");
        },

        slideLeft() {
            if (!_.isNil(this.$refs.paymentcards)) {
                this.$refs.paymentcards.previous();
            }
        },

        slideRight() {
            if (!_.isNil(this.$refs.paymentcards)) {
                this.$refs.paymentcards.next();
            }
        },

        choose(paymentType) {
            this.$refs.paymentcards.destroy();
            this.paymentType = paymentType;

            this.rerender();
        },

        rerender() {
            if (_.get(this.offers, "length", 0) >= 7) {
                this.sliderOptions.cellAlign = "left";
            } else {
                this.sliderOptions.cellAlign = "center";
            }

            setTimeout(this.$refs.paymentcards.init, 1);
        },

        show() {
            this.$refs.modal.show();
        },

        string2number(str) {
            let newVal = 0;

            newVal = _.filter(str, function(char) {
                return /[0-9]/.test(char);
            });

            newVal = Number(newVal.join(""));

            return newVal;
        },

        saveAndClose() {
            let selectedDownPayment = this.downPaymentInput;
            let currentDownPayment = this.selectedOffer.quote.downPayment;

            if (_.isString(selectedDownPayment)) {
                selectedDownPayment = this.string2number(selectedDownPayment);
            }

            if (_.isString(currentDownPayment)) {
                currentDownPayment = this.string2number(currentDownPayment);
            }

            if (selectedDownPayment !== currentDownPayment) {
                this.updateDownPayment();
                this.closePaymentOptions = true;
            } else {
                this.saveOfferToCertificate(this.selectedOffer);
                this.$refs.modal.hide();
            }
        },

        onShow() {
            trackPageView(this, "/payment-matrix");
            this.resetToDefaultValues();

            if (this.loadingStep === 0) {
                dispatch("paymentMatrix/loadBest", {
                    certificateId: this.certificateId
                });
            }

            if (this.loadingStep < 2) {
                dispatch("paymentMatrix/loadAll", {
                    certificateId: this.certificateId
                });
            }

            this.downPaymentInput = _.get(
                this.selectedOffer,
                "quote.downPayment"
            );

            setTimeout(() => {
                if (this.$refs.paymentcards) {
                    this.$refs.paymentcards.destroy();
                }
                this.rerender();
            }, 1);
        }
    }
};
</script>
<style lang="scss">
#payment-matrix-wrapper {
    .btn:focus {
        box-shadow: 0px 0px 15px $primary-blue;
    }

    .downpayment-editor-wrapper {
        #downpayment-editor {
            min-width: 65%;
        }

        .save-btn-wrapper {
            min-width: 35%;

            .btn {
                min-width: 48%;
            }
        }
    }

    .price-block {
        > .value {
            font-size: 46px;
            line-height: 53px;
        }

        > .per-month {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
        }
    }

    .vehicle-image-wrapper {
        width: 200px;
    }

    .loading {
        color: $primary-blue;
        margin: 25px;

        > .half-circle-spinner {
            margin: 0 auto;
        }
    }

    .offer-details-column {
        /*padding-left: 0;*/
        margin-top: 0;
    }

    h3 {
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
        color: $dark-gray;
    }

    > a {
        font-size: 10px;
        color: $dark-gray;
        cursor: pointer;
    }

    .selected-payment-info {
        border: 1px solid $light-medium-gray;
        background-color: $very-light-gray;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
        padding: 10px;
        justify-content: space-around;
        width: 100%;

        .vehicle-image-wrapper {
            margin-right: 10px;

            img {
                height: 130px;
                width: auto;
            }
        }
    }

    .offer-details-wrapper {
        border: 1px solid $light-medium-gray;
        background-color: $very-light-gray;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);

        > h4 {
            background-color: $light-medium-gray;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
            color: $medium-gray;
            font-size: 12px;
            line-height: 15px;
            font-weight: normal;
            margin: 0;
            padding: 10px;
            text-transform: uppercase;
        }

        .offer-details {
            display: flex;
            flex-direction: column;
            margin: 10px;
            font-size: 14px;
            line-height: 15px;

            > .offer-row {
                display: flex;
                justify-content: space-between;

                .offer-value {
                    a.disabled {
                        cursor: not-allowed;
                        color: $muted-dark-gray;
                    }
                }
            }

            > hr {
                height: 1px;
                width: 100%;
                color: #d8d8d8;
                margin: 10px 0;
            }
        }
    }

    .offers-scroller {
        margin-top: 10px;
        border: 1px solid $light-medium-gray;
        background-color: $very-light-gray;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
        padding: 10px;

        .payment-selector {
            text-transform: uppercase;
            font-weight: normal;
            margin: 5px 0 11px;

            a {
                color: $muted-dark-gray;
                margin: 0 5px;
                cursor: pointer;

                &.selected {
                    color: $primary-blue;
                    font-weight: bold;
                }
            }
        }

        .scroller {
            display: flex;
            justify-content: space-between;

            > .payment-cards {
                width: calc(100% - 50px);
                height: 85px;
                overflow: hidden;

                &:focus {
                    outline: none !important;
                }
            }

            > .arrow-right,
            > .arrow-left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: $muted-gray;
                font-size: 28px;
                line-height: 28px;

                &:hover {
                    color: $muted-dark-gray;
                }
            }
        }

        .disclaimer {
            color: $muted-dark-gray;
            font-size: 12px;
            line-height: 15px;
            font-style: italic;
            padding: 10px;
        }
        .out-of-pocket-wrapper {
        }
    }

    .payment-disclaimer {
        color: $muted-dark-gray;
        font-size: 12px;
        font-style: italic;
        line-height: 15px;
        margin-top: 10px;
    }
}

@include media-breakpoint-down(sm) {
    #payment-matrix-wrapper {
        .price-block {
            > .value {
                font-size: 28px;
                line-height: 32px;
            }

            > .per-month {
                top: 15px;
            }
        }

        .vehicle-image-wrapper {
            width: 115px;
            height: auto;
        }

        .container-fluid {
            padding: 0;
        }

        .offer-details-column {
            padding-left: 15px;
            margin-top: 10px;
        }

        .selected-payment-info {
            .vehicle-image-wrapper {
                margin-right: 5px;
            }
        }
    }
}
</style>
