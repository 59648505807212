import { get, dispatch } from "vuex-pathify";
import _ from "lodash";
import { trackGAEvent } from "@/lib/GoogleTagManager";

const taxesAndFees = {
    computed: {
        taxesLoading: get("vehicleDetails/taxes@loading"),
        taxes: get("vehicleDetails/taxes"),
        hasFinanceTaxes() {
            return !_.isNil(_.get(this.taxes, "finance.salesTax"));
        },

        hasLeaseTaxes() {
            return !_.isNil(_.get(this.taxes, "lease.salesTax"));
        }
    },
    methods: {
        loadFinanceTaxes() {
            trackGAEvent(this, {
                category: "pricing-card",
                action: "fetch-finance-taxes",
                label: "View with Taxes &amp; Fees"
            });
            return dispatch("vehicleDetails/loadFinanceTaxes");
        },
        loadLeaseTaxes() {
            trackGAEvent(this, {
                category: "pricing-card",
                action: "fetch-lease-taxes",
                label: "View with Taxes &amp; Fees"
            });
            return dispatch("vehicleDetails/loadLeaseTaxes");
        }
    }
};

export default taxesAndFees;
