var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.warrantyEligible
    ? _c("div", { staticClass: "warranty-disclaimer" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.disclaimer) } })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }