var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative", attrs: { id: "vehicle360View" } },
    [
      _c("div", {
        ref: "spin",
        staticClass: "three-sixty",
        style: {
          display: _vm.exteriorOrInterior === "EXTERIOR" ? "block" : "none"
        },
        attrs: { id: "spin" }
      }),
      _vm._v(" "),
      _vm.interiorCubeMapExists
        ? _c(
            "div",
            {
              style: {
                display:
                  _vm.exteriorOrInterior === "INTERIOR" ? "block" : "none"
              },
              attrs: { id: "interior-360" }
            },
            [
              _c("iframe", {
                staticClass: "interior-iframe",
                attrs: {
                  title: "interior 360",
                  src: _vm.interior360Url,
                  width: "512",
                  height: "512"
                },
                on: { load: _vm.iframeLoad }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.threeSixtyTypes.length > 1
        ? _c(
            "div",
            { staticClass: "mt-2 d-flex justify-content-center" },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      id: "exterior_interior",
                      buttons: "",
                      "button-variant": "outline-primary",
                      options: _vm.threeSixtyTypes
                    },
                    model: {
                      value: _vm.exteriorOrInterior,
                      callback: function($$v) {
                        _vm.exteriorOrInterior = $$v
                      },
                      expression: "exteriorOrInterior"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column justify-content-center container text-center spin-drag"
        },
        [_vm._v("\n        Click and drag to spin vehicle\n    ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }