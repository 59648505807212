<template>
    <flickity
        v-if="vehicleGallery"
        id="vehicleImageSlider"
        ref="flickity"
        :options="flickityOptions"
    >
        <template v-if="vehicleGallery.length > 3">
            <div v-for="image in vehicleGallery" :key="image" class="carousel-cell">
                <vehicle-image :image-url="image" :alt="vehicleFullName" />
            </div>
        </template>
        <template v-else-if="vehicleGallery.length < 3 && stockImages != null && stockImages.length > 0">
            <div v-for="image in stockImages" :key="image" class="carousel-cell">
                <vehicle-image :image-url="image" :alt="vehicleFullName" />
            </div>
        </template>
        <div
            v-else-if="vehicleGallery.length < 3 && (stockImages === null || stockImages.length === 0)"
            class="carousel-cell"
        >
            <div class="missing-vehicle-image d-flex justify-content-center">
                <div class="d-flex justify-content-center flex-column">
                    <body-style-icon :id="bodyStyle" /><br />
                    <p class="mt-3 text-center">
                        Image Coming Soon
                    </p>
                </div>
            </div>
        </div>
    </flickity>
</template>

<style lang="scss">
#vehicleImageSlider {
    .carousel-cell {
        width: 640px;
        height: 420px;
        margin-right: 0;

        @include media-breakpoint-down(sm) {
            width: 427px;
            height: 280px;
        }
    }

    .flickity-viewport {
        touch-action: pan-y pinch-zoom !important;
    }

    .missing-vehicle-image {
        width: 640px;
        height: 420px;
        overflow: hidden;
        color: $inactive-gray;
        font-size: 1.5rem;

        i {
            font-size: 5em;
        }

        p {
            font-size: 1em;
            font-family: $helvetica-65-medium;
            text-transform: uppercase;
        }
    }
}
</style>

<script>
import { get } from "vuex-pathify";
import VehicleImage from "../VehicleImage/index";
import Flickity from "vue-flickity";
import BodyStyleIcon from "Modules/ShopBy/BodyStyle/components/BodyStyleIcon/index";

export default {
    name: "VehicleImageSlider",

    components: {
        BodyStyleIcon,
        VehicleImage,
        Flickity
    },

    data() {
        return {
            flickityOptions: {
                initialIndex: 0,
                pageDots: false,
                wrapAround: true,
                imagesLoaded: true,
                lazyLoad: 2
            }
        };
    },

    computed: {
        vehicleGallery: get("vehicleDetails/media@carouselImageUrls"),
        vehicleFullName: get("vehicleDetails/vehicleModelFullName"),
        bodyStyle: get("vehicleDetails/vehicle@bodyStyle"),
        stockImages: get("vehicleDetails/media@galleryImageUrls")
    },

    methods: {
        next() {
            this.$refs.flickity.next();
        },

        previous() {
            this.$refs.flickity.previous();
        }
    }
};
</script>
