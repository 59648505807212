<template>
    <div class="title-with-price d-flex justify-content-between">
        <div class="title d-flex align-items-center">
            <slot name="title" />
        </div>
        <div class="price">
            <slot name="price" />
        </div>
    </div>
</template>

<style lang="scss">
.title-with-price {
    font-size: 16px;
    color: $muted-dark-gray;

    > .title {
        line-height: 16px;

        i {
            margin-left: 5px;
        }

        > strong {
            color: $dark-gray;
        }
    }

    .blue-price {
        font-size: 24px;
        font-style: italic;
        font-weight: bold;
        font-family: Times, Serif;
        color: $primary-blue;
    }
}
</style>

<script>
export default {
    name: "TitleWithPrice"
};
</script>
