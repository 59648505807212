<template>
    <div class="row p-4">
        <div v-if="resubmitRequired" class="finance-offer-overlay">
            <div class="content">
                Please request a new offer or speak with a certified CarSaver
                dealership for information on additional financing options that
                may be available. (800) 217-4800
            </div>
            <b-link
                class="accept-offer btn btn-primary"
                @click="resubmitOffer()"
            >
                Request New Offer
            </b-link>
        </div>

        <div class="lender-logo col">
            <b-img :src="lenderLogo" height="40" />
        </div>

        <div class="offer-detail d-flex flex-column justify-content-center col">
            <div class="detail">
                {{ offerRate | numeral("0.00%") }}
            </div>
            <div class="label">
                APR
            </div>
        </div>

        <div class="offer-detail d-flex flex-column justify-content-center col">
            <div class="detail">
                {{ offer.term }}
            </div>
            <div class="label">
                Months
            </div>
        </div>

        <div class="offer-detail d-flex flex-column justify-content-center col">
            <div class="detail">
                <strong>{{ offer.monthlyPayment | numeral("$0.00") }}</strong
                >/mo
            </div>
            <div class="label">
                Starting at
            </div>
        </div>

        <div
            class="offer-actions d-flex flex-column justify-content-center col-md mt-3 mt-md-0"
        >
            <b-link
                v-if="accepted"
                :disabled="true"
                class="accept-offer btn btn-primary d-flex justify-content-center align-items-center"
            >
                Offer Accepted
            </b-link>
            <b-link
                v-else
                :disabled="resubmitRequired"
                class="accept-offer btn btn-primary d-flex justify-content-center align-items-center"
                @click="acceptOffer()"
            >
                Accept Offer
            </b-link>
            <b-link v-b-modal="'viewDetailsModal'" class="view-details">
                View Details
            </b-link>
        </div>

        <finance-view-details-modal
            id="viewDetailsModal"
            :offer="offer"
            :request="request"
        />
    </div>
</template>

<style lang="scss" scoped>
.finance-offer-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    background: hsla(0, 0%, 98%, 0.9);
    z-index: 1;
    display: flex;
    height: calc(100% - 50px);
    align-items: center;
    justify-content: space-around;

    > .content {
        max-width: 350px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
    }

    > a {
        max-height: 35px;
    }
}

.lender-logo {
    img {
        max-height: 48px;
    }
}

.accept-offer {
    border-radius: 22.5px;
    height: 40px;
    min-width: 200px;
}

.view-details {
    text-align: center;
    color: $muted-dark-gray;
    font-size: 12px;
    text-decoration: underline;
}

.offer-detail {
    text-align: center;

    > .detail {
        color: $primary-blue;
        font-size: 18px;
        line-height: 21px;
    }

    > .label {
        margin-top: 7px;
    }
}
</style>

<script>
import _ from "lodash";
import FinanceViewDetailsModal from "./ViewDetailsModal";
import { get } from "vuex-pathify";

export default {
    name: "OfferRow",
    components: {
        FinanceViewDetailsModal
    },
    props: {
        offer: {
            required: true,
            type: Object
        },
        request: {
            required: true,
            type: Object
        },
        accepted: {
            required: false,
            type: Boolean,
            default: false
        },
        resubmitRequired: {
            required: false,
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            showDetails: false
        };
    },

    computed: {
        lang: get("translated/lang"),
        lenderLogo() {
            return `https://app.myautoloan.com${this.offer.lenderLogoUrl}`;
        },
        offerRate() {
            return _.get(this.offer, "rate", 0) / 100;
        }
    },

    methods: {
        acceptOffer() {
            this.$gtm.trackEvent({
                event: "Event",
                category: "financing-offer",
                action: `accept-offer-${this.offer.lenderId}`,
                label: "Accept Offer"
            });

            this.$emit("accept", { responseId: this.offer.responseId });
        },
        resubmitOffer() {
            this.$gtm.trackEvent({
                event: "Event",
                category: "financing-offer",
                action: "resubmit-offer",
                label: "Request New Offer"
            });

            this.$emit("resubmit", {
                responseId: this.offer.responseId,
                lenderId: this.offer.lenderId
            });
        },
        onShowDetailsClick() {
            this.$gtm.trackEvent({
                event: "Event",
                category: "financing-offer",
                action: "show-details",
                label: "View Details"
            });

            this.showDetails = true;
        }
    }
};
</script>
