var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-row align-items-center order-1 order-md-2",
      attrs: { id: "vehicleTitleBar" }
    },
    [
      _c("div", { staticClass: "card-title w-100" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-8 col-md-12" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row align-items-center" },
                  [
                    _vm.stockImageUrl
                      ? _c(
                          "div",
                          { staticClass: "d-flex d-lg-none vehicle-image" },
                          [
                            _c("vehicle-image", {
                              attrs: { "image-url": _vm.stockImageUrl }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-nowrap align-items-center vehicle mr-auto"
                      },
                      [
                        _c("h1", [
                          _c("span", { staticClass: "title mr-2" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.fullName) +
                                "\n                                "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "trim align-self-md-end" },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.trim) +
                                  "\n                                "
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("heart-icon", { staticClass: "d-flex d-lg-none" })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("overlay-icons")
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }