var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", attrs: { id: "vr-banner-block" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { sm: "5" } },
            [_c("b-img", { attrs: { src: require("./images/vr_head.png") } })],
            1
          ),
          _vm._v(" "),
          _c("b-col", { attrs: { sm: "7" } }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column h-100 justify-content-around"
              },
              [
                _c(
                  "div",
                  { staticClass: "vr-tour-header mx-auto my-3 px-md-4" },
                  [
                    _c("span", { staticClass: "take-a d-block" }, [
                      _vm._v("Take a")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "vr d-block" }, [
                      _vm._v("Virtual Reality")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "tour d-block text-right" }, [
                      _vm._v("Tour")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "actions d-flex flex-sm-row flex-column justify-content-around"
                  },
                  [
                    _c(
                      "b-btn",
                      {
                        staticClass: "m-2 m-sm-0",
                        attrs: { variant: "outline-primary" },
                        on: { click: _vm.openDownloadModal }
                      },
                      [
                        _vm._v(
                          "\n                        Download the App\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.vrAvailable
                      ? _c(
                          "b-btn",
                          {
                            staticClass: "m-2 m-sm-0",
                            attrs: { variant: "orange" },
                            on: { click: _vm.openTourModal }
                          },
                          [
                            _vm._v(
                              "\n                        Tour This Car\n                    "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "powered-by d-flex m-3" },
                  [
                    _c(
                      "b-link",
                      { on: { click: _vm.gotoRelayCars } },
                      [
                        _c(
                          "svg-fallback",
                          { attrs: { src: _vm.relaycarsLogo.svg } },
                          [
                            _c("b-img", {
                              attrs: { src: _vm.relaycarsLogo.jpg }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("The new way to experience cars.")])
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }