var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vehicle-panel" }, [
    _c(
      "div",
      { staticClass: "panel-header" },
      [
        _vm.splashImage
          ? _c("b-img", {
              staticClass: "image",
              attrs: { src: _vm.splashImage, alt: _vm.carTitle, fluid: "" }
            })
          : _c("body-style-icon", { attrs: { id: _vm.vehicle.bodyStyle } }),
        _vm._v(" "),
        _c("div", { staticClass: "heading" }, [
          _vm._v("\n            " + _vm._s(_vm.carTitle) + "\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "subheading" }, [
          _vm._v("\n            " + _vm._s(_vm.carSubtitle) + "\n        ")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "panel-content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }