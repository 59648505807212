<template>
    <b-modal
        id="tour-modal"
        v-model="show"
        ok-title="Close"
        :ok-only="true"
        :hide-footer="true"
        title="Tour This Car"
        size="xl"
        :lazy="true"
    >
        <div slot="modal-header-close">
            <i aria-hidden="true" class="ion-ios-close-outline" />
        </div>

        <iframe title="Tour This Car" :src="tourUrl" />
    </b-modal>
</template>

<style lang="scss">
#tour-modal {
    .modal-dialog {
        max-width: 100%;
        height: 100%;
        margin-top: 0;

        .modal-content {
            height: 100%;

            .modal-body {
                display: flex;
                flex-direction: column;

                iframe {
                    border: none;
                    width: 100%;
                    height: 100%;
                    flex: 1;
                }
            }
        }
    }
}
</style>

<script>
import { sync, get } from "vuex-pathify";

export default {
    computed: {
        show: sync("vehicleDetails/vr@tourModal"),
        styleId: get("vehicleDetails/vehicle@styleId"),
        tourUrl() {
            const height = window.innerHeight;
            const width = window.innerWidth;
            const screenSize = height > width ? height : width;

            return `/evox/tour?styleId=${this.styleId}&screenSize=${screenSize}&notopbar=true`;
        }
    }
};
</script>
