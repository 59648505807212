<template>
    <div
        id="vehicleTitleBar"
        class="d-flex flex-row align-items-center order-1 order-md-2"
    >
        <div class="card-title w-100">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="d-flex flex-row align-items-center">
                            <div
                                v-if="stockImageUrl"
                                class="d-flex d-lg-none vehicle-image"
                            >
                                <vehicle-image :image-url="stockImageUrl" />
                            </div>
                            <div
                                class="d-flex flex-nowrap align-items-center vehicle mr-auto"
                            >
                                <h1>
                                    <span class="title mr-2">
                                        {{ fullName }}
                                    </span>
                                    <span class="trim align-self-md-end">
                                        {{ trim }}
                                    </span>
                                </h1>
                            </div>
                            <heart-icon class="d-flex d-lg-none" />
                        </div>
                        <overlay-icons />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { get } from "vuex-pathify";
import VehicleImage from "Modules/Vehicle/VehicleImage";
import OverlayIcons from "Modules/Vehicle/OverlayIcons";
import HeartIcon from "Modules/Vehicle/OverlayIcons/HeartIcon";

export default {
    name: "VehicleTitleBar",
    components: { HeartIcon, OverlayIcons, VehicleImage },
    data() {
        return {
            isMobile: false
        };
    },
    computed: {
        fullName: get("vehicleDetails/vehicleModelFullName"),
        trim: get("vehicleDetails/vehicle@trim"),
        stockImageUrl: get("vehicleDetails/media@front34/small")
    },

    created() {
        // initial check
        this.checkIfMobile();

        window.addEventListener("resize", this.checkIfMobile);
    },

    methods: {
        checkIfMobile() {
            this.isMobile = window.matchMedia(
                "only screen and (max-width: 767px)"
            ).matches;
        }
    }
};
</script>
<style lang="scss">
#vehicleTitleBar {
    height: 50px;
    background-color: $primary-dark-blue;

    .card-title {
        margin-bottom: 0 !important;

        .vehicle {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 480px;
            color: $white;

            .title {
                font-size: px2rem(22);
                line-height: 28px;
            }
            .trim {
                font-size: px2rem(16);
            }
        }
    }

    @include media-breakpoint-down(md) {
        background-color: white;
        height: 40px;
        width: 100%;
        top: 50px;
        z-index: 35;

        .card-title {
            .vehicle-image {
                max-height: 50px;
                width: 50px;
                padding-right: 10px;
            }

            .vehicle {
                color: #444;

                .title {
                    font-size: px2rem(14) !important;
                    max-width: 330px !important;
                    line-height: 16px;
                    font-weight: bold;
                }
                .trim {
                    font-size: px2rem(14) !important;
                }
            }
        }
    }
}
</style>
