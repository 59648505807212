var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasVehicleSpecs
    ? _c(
        "detail-block",
        {
          attrs: {
            id: "features-container",
            title: "Specs & Features",
            name: "specsAndFeatures"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "card-body specs",
              attrs: { id: "specsAndFeatures" }
            },
            [
              _c("fuel-consumption-block"),
              _vm._v(" "),
              _c("hr", { staticClass: "fuel-divider" }),
              _vm._v(" "),
              _c(
                "b-container",
                { staticClass: "p-0", attrs: { fluid: "" } },
                [
                  _c(
                    "b-tabs",
                    _vm._l(_vm.vehicleSpecs, function(
                      options,
                      category,
                      index
                    ) {
                      return _c(
                        "b-tab",
                        { key: index, attrs: { title: category } },
                        [
                          _c(
                            "b-list-group",
                            _vm._l(options, function(option, optionIndex) {
                              return _c(
                                "b-list-group-item",
                                { key: optionIndex, staticClass: "spec-row" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(option) +
                                      "\n                        "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }