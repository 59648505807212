<template>
    <div>
        <div v-if="isSignedIn">
            <slot />
        </div>
        <div v-if="!isSignedIn">
            <event-link event-name="sign-in-required-modal-open">
                <slot />
            </event-link>
        </div>
    </div>
</template>

<script>
import EventLink from "../EventLink/";
import { get } from "vuex-pathify";

export default {
    name: "IsUserSignedIn",
    components: {
        EventLink
    },
    computed: {
        isSignedIn: get("user/isSignedIn")
    }
};
</script>
