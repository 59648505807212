<template>
    <view-details-modal :id="id" class="finance-view-details-modal">
        <div slot="title">
            Congratulations!
        </div>

        <div slot="summary">
            <h4>
                You received a financing offer from
                <strong>{{ offer.lenderName }}</strong>
            </h4>

            <offer-info :offer="offer" />
        </div>

        <div slot="offerTab">
            <down-payment-matrix :offer="offer" />

            <div class="disclaimer">
                *Payments shown above are based on an estimated purchase price
                of
                {{
                    request.requestedAmountFinanced | numeral("$0,0.00")
                }}
                (CarSaver Price of
                {{ request.carsaverPrice | numeral("$0,0") }}, plus Dealer Fees
                of {{ request.dealerFees | numeral("$0,0") }}, plus taxes and
                fees totaling {{ taxTitleAndLicense | numeral("$0,0.00") }}).
                Down Payment of {{ offer.downPayment | numeral("$0,0") }}. APR
                of {{ offer.rate }}% and {{ offer.term }} month term. Approval
                and terms are subject to credit approval and collateral
                verification. All loan program rates, terms and conditions are
                subject to change at any time without notice. CarSaver is not
                responsible for information provided by third parties. Please
                see Terms of Service for more information.<br />
                **Additional Stipulations, Terms and Conditions: Approval is
                specific to vehicle identified and is subject to proof of
                income, verification of employment, proof of residence and proof
                of phone.
            </div>
        </div>

        <div slot="detailsTab">
            <finance-vehicle-panel
                :offer="offer"
                :request="request"
                :pricing="pricing"
                :vehicle="vehicle"
            />
        </div>
    </view-details-modal>
</template>

<style lang="scss">
.finance-view-details-modal {
    .disclaimer {
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;
        font-style: italic;
        color: $muted-dark-gray;
    }
}
</style>

<script>
import OfferInfo from "./OfferInfo";
import DownPaymentMatrix from "./DownPaymentMatrix";
import { get } from "vuex-pathify";
import FinanceVehiclePanel from "./VehiclePanel";
import ViewDetailsModal from "../../../ViewDetailsModal/index";

export default {
    name: "FinanceViewDetailsModal",

    components: {
        ViewDetailsModal,
        FinanceVehiclePanel,
        DownPaymentMatrix,
        OfferInfo
    },

    props: {
        id: {
            required: true,
            type: String
        },
        offer: {
            required: true,
            type: Object
        },
        request: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            selectedTab: "offer"
        };
    },

    computed: {
        vehicle: get("vehicleDetails/vehicle"),
        pricing: get("vehicleDetails/pricing"),

        taxTitleAndLicense() {
            return this.request.salesTax + this.request.titleLicense;
        },

        offerTabClasses() {
            return {
                "d-none": this.isMobile() && this.isSelectedTab("details")
            };
        },
        detailsTabClasses() {
            return {
                "col-md-4": true,
                "d-none": this.isMobile() && this.isSelectedTab("offer"),
                "d-sm-none": this.isMobile() && this.isSelectedTab("offer"),
                "d-md-flex": true,
                "flex-column": true
            };
        }
    },

    methods: {
        isSelectedTab(tabName) {
            return this.selectedTab === tabName;
        },

        getButtonVariant(tabName) {
            return this.isSelectedTab(tabName) ? "primary" : "";
        },

        isMobile() {
            return window.innerWidth < 768;
        },

        showOffer() {
            this.selectedTab = "offer";
        },

        showDetails() {
            this.selectedTab = "details";
        }
    }
};
</script>
