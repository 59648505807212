var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tooltip-disclaimer", {
    attrs: {
      id: "ltw-tooltip-disclaimer",
      title: _vm.lifetimeWarrantyDisclaimer
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }