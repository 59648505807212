<template>
    <section-box class="down-payment-matrix">
        <table aria-describedby="Down payment matrix Table">
            <thead>
                <tr>
                    <th scope="col">
                        Term
                    </th>
                    <th scope="col">
                        APR
                    </th>
                    <th scope="col">
                        Payment
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <strong>{{ offer.term }} months</strong>
                    </td>
                    <td>
                        <strong>{{ offer.rate }}%</strong>
                    </td>
                    <td class="payment">
                        <strong>{{
                            offer.monthlyPayment | numeral("$0,0.00")
                        }}</strong
                        >/mo
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        &nbsp;
                    </td>
                </tr>
            </tbody>
        </table>
    </section-box>
</template>

<style lang="scss" scoped>
.down-payment-matrix {
    &.section-box {
        border: 1px solid $muted-gray;
        border-radius: 2px;
        background-color: $very-light-gray;
        min-height: 70px;
        margin-top: 10px;
        padding: 0;
    }

    table {
        width: 100%;

        th,
        td {
            text-align: center;
            line-height: 30px;

            &.payment {
                color: $primary-blue;

                > strong {
                    font-style: italic;
                }
            }
        }

        th {
            background-color: $light-gray;
            font-weight: normal;
        }

        td {
            background-color: #fff;
        }

        tr:nth-of-type(2n) {
            td {
                background-color: $very-light-gray;
            }
        }
    }
}
</style>

<script>
import SectionBox from "../../../ViewDetailsModal/components/SectionBox";
export default {
    name: "DownPaymentMatrix",
    components: { SectionBox },
    props: {
        offer: {
            type: Object,
            required: true
        }
    }
};
</script>
