<template>
    <div class="selected-payment-wrapper text-center w-100">
        <h4 class="text-center">
            {{ selectedOffer.quote.type === "Loan" ? "FINANCE" : "LEASE" }}
        </h4>
        <div class="price-block-wrapper">
            <price-block :payment="selectedOffer.quote.monthlyPayment" />
            <b-link v-b-tooltip.focus.bottom :title="selectedOffer.disclaimer">
                <i
                    aria-hidden="true"
                    class="icon ion-ios-information-outline"
                />
            </b-link>
        </div>
        <div class="before-taxes">
            <div v-if="taxesLoading" class="d-flex justify-content-center">
                <loading-spinner />
            </div>
            <div
                v-else-if="selectedOffer.quoteWithTaxes"
                class="taxes-and-fees"
            >
                <strong
                    >{{
                        selectedOffer.quoteWithTaxes.quote.monthlyPayment
                            | numeral("$0")
                    }}/mo</strong
                >
                with taxes &amp; fees
                <b-link
                    v-b-tooltip.focus.bottom
                    :title="selectedOffer.quoteWithTaxes.disclaimer"
                >
                    <i
                        aria-hidden="true"
                        class="icon ion-ios-information-outline"
                    />
                </b-link>
            </div>
            <b-link v-else @click="fetchQuoteTaxes">
                Include Taxes &amp; Fees
            </b-link>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.selected-payment-wrapper {
    width: 220px;
    border: 1px solid $light-medium-gray;
    background-color: #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;

    > h4 {
        color: $muted-dark-gray;
        font-size: 12px;
        line-height: 15px;
        font-weight: normal;
        text-transform: uppercase;
    }

    .price-block-wrapper {
        display: flex;
        justify-content: center;
    }

    .before-taxes {
        font-size: 14px;
        color: $medium-gray;

        > strong {
            color: $primary-blue;
        }
    }
}

@include media-breakpoint-down(sm) {
    .selected-payment-wrapper {
        padding: 5px;

        > h4 {
            margin: 0;
        }

        .before-taxes {
            font-size: 12px;
        }
    }
}
</style>

<script>
import { get } from "vuex-pathify";
import PriceBlock from "./PriceBlock";
import LoadingSpinner from "Components/LoadingSpinner";
import { trackGAEvent } from "@/lib/GoogleTagManager";

export default {
    components: { LoadingSpinner, PriceBlock },

    props: {
        selectedOffer: {
            type: Object,
            required: true
        }
    },

    computed: {
        taxesLoading: get("paymentMatrix/taxesLoading")
    },

    methods: {
        fetchQuoteTaxes() {
            trackGAEvent(this, {
                category: "payment-matrix",
                action: "fetch-taxes",
                label: "Include Taxes & Fees"
            });
            this.$emit("fetch-taxes");
        }
    }
};
</script>
