var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.fuelDataIsNull
    ? _c(
        "div",
        {
          staticClass:
            "fuel-consumption d-flex flex-row flex-nowrap align-items-center justify-content-around"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mpg-block d-flex flex-column align-items-center" },
            [
              _c("span", { staticClass: "label text-uppercase" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("page.vdp.city")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("emptyDash")(_vm.vehicle.mpgCity)) +
                    "\n        "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mpg-block d-flex flex-column align-items-center" },
            [
              _c("span", { staticClass: "label text-uppercase" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("page.vdp.hwy")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("emptyDash")(_vm.vehicle.mpgHighway)) +
                    "\n        "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mpg-block d-flex flex-column align-items-center" },
            [
              _c("span", { staticClass: "label text-uppercase" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("page.vdp.avg")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("emptyDash")(_vm.vehicle.averageMpg)) +
                    "\n        "
                )
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "gas-icon-wrapper d-flex justify-content-start align-items-center"
      },
      [
        _c("i", {
          staticClass: "fa icon-gas_pump",
          attrs: { "aria-hidden": "true" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }