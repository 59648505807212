<template>
    <div
        id="vdp-hero"
        class="d-flex flex-column header"
        :class="{ '-with-background': splashImage }"
    >
        <vehicle-title-bar />

        <div
            class="card-image order-2 order-md-1 position-relative pt-5 pt-sm-0"
        >
            <div class="container-fluid">
                <new-search-link />
            </div>

            <b-img v-if="splashImage" :src="splashImage" fluid />

            <missing-image v-else :body-style="bodyStyle" />
        </div>
    </div>
</template>

<style lang="scss">
#vdp-hero {
    &.-with-background {
        background: #fff url("images/gallery_background.jpg") repeat-x bottom
            center;
        background-size: cover;
    }

    .card-image {
        min-height: 300px;
        text-align: center;
    }
}
</style>

<script>
import VehicleTitleBar from "Modules/Vehicle/VehicleTitleBar";
import NewSearchLink from "Modules/Vehicle/NewSearchLink";
import { get } from "vuex-pathify";
import MissingImage from "Modules/Vehicle/VdpHero/components/MissingImage";

export default {
    components: { MissingImage, NewSearchLink, VehicleTitleBar },

    computed: {
        splashImage: get("vehicleDetails/media@splashImage"),
        bodyStyle: get("vehicleDetails/vehicle@bodyStyle")
    }
};
</script>
