<template>
    <detail-block
        id="insurance-tab"
        :title="$t('component.insuranceBlock.title')"
        name="insurance"
    >
        <get-insurance-quote v-if="quotes.length === 0" />
        <insurance-quote-list v-else :quotes="quotes" />
    </detail-block>
</template>

<script>
import GetInsuranceQuote from "./components/GetInsuranceQuote";
import { get } from "vuex-pathify";
import api from "../../../api";
import InsuranceQuoteList from "./components/QuoteList";
import DetailBlock from "Modules/Vehicle/DetailBlock";

export default {
    name: "VehicleInsuranceBlock",
    components: {
        DetailBlock,
        InsuranceQuoteList,
        GetInsuranceQuote
    },

    data() {
        return {
            quotes: []
        };
    },

    computed: {
        certificate: get("vehicleDetails/certificate")
    },

    mounted() {
        if (this.certificate !== null && this.certificate.id !== null) {
            api.get(
                `/certificates/${this.certificate.id}/insurance/offers`
            ).then(response => {
                this.quotes = response.data;
            })
            .catch(error => {
                console.error(error);
            });
        }
    }
};
</script>
