<template>
    <vehicle-panel :vehicle="vehicle" class="insurance-vehicle-panel">
        <title-with-price>
            <template slot="title">
                <strong>Total Premium</strong>
            </template>
            <div slot="price" class="blue-price">
                {{ quote | emptyDash("paymentPlan.premium") | numeral("$0,0") }}
            </div>
        </title-with-price>
        <hr />
        <title-with-price>
            <div slot="title">Average<br /><strong>Payment</strong></div>
            <div slot="price" class="blue-price">
                {{
                    quote
                        | emptyDash("paymentPlan.avgMonthlyPayment")
                        | numeral("$0,0.00")
                }}
            </div>
        </title-with-price>
    </vehicle-panel>
</template>

<script>
import VehiclePanel from "../../../ViewDetailsModal/components/VehiclePanel";
import TitleWithPrice from "../../../ViewDetailsModal/components/TitleWithPrice";

export default {
    name: "InsuranceVehiclePanel",

    components: { TitleWithPrice, VehiclePanel },

    props: {
        vehicle: {
            required: true,
            type: Object
        },
        quote: {
            required: true,
            type: Object
        }
    }
};
</script>
