<template>
    <div v-if="warrantyEligible" class="warranty-disclaimer">
        <span v-html="disclaimer" />
    </div>
</template>
<script>
import { get } from "vuex-pathify";
export default {
    name: "LifetimeWarrantyDisclaimer",
    computed: {
        warrantyEligible: get("vehicleDetails/vehicle@warrantyEligible"),
        campaignNumber: get('campaign/phoneNumberFormatted'),
        disclaimer() {
            return this.$t("disclaimer.warranty", {telephoneNumber: this.campaignNumber});
        }
    }
};
</script>
<style lang="scss">
.warranty-disclaimer {
    font-size: px2rem(12);
    line-height: 15px;
    font-style: italic;
    color: $muted-dark-gray;
    margin-bottom: 10px;

    a {
        color: $muted-dark-gray;
    }
}
</style>
