<template>
    <cs-modal id="lifetimeWarrantyModal" ref="modal">
        <div slot="modal-title">
            This Vehicle Comes With...
        </div>
        <b-container class="py-sm-3 py-5">
            <div
                class="d-flex flex-column justify-content-center align-items-center"
            >
                <div class="d-flex flex-column mb-md-4 mb-0">
                    <img
                        src="/assets/images/lifetime-warranty-logo.png"
                        alt="lifetime_warranty_logo"
                    />
                    <div
                        class="d-flex align-items-center align-self-end mr-3 powered-block"
                    >
                        <small class="font-italic powered-by mr-1">
                            {{
                                $t("component.lifetimeWarrantyModal.poweredBy")
                            }}
                        </small>
                        <span class="font-weight-bold">
                            CarSaver
                        </span>
                    </div>
                </div>

                <div class="d-flex flex-column flex-md-row py-4 mb-0 mb-md-4">
                    <div
                        class="d-flex flex-column align-items-center text-center p-3 p-md-0 iconblock mx-2"
                    >
                        <img
                            class="icon"
                            src="/assets/images/shield.svg"
                            alt="shield_icon_no_additional_cost"
                        />
                        <span class="text">
                            {{ $t("component.lifetimeWarrantyModal.itemOne") }}
                        </span>
                    </div>

                    <div
                        class="d-flex flex-column align-items-center text-center p-3 p-md-0 iconblock mx-2"
                    >
                        <img
                            class="icon"
                            src="/assets/images/engine.svg"
                            alt="engine_icon_covers_drivetrain"
                        />
                        <span class="text">
                            {{ $t("component.lifetimeWarrantyModal.itemTwo") }}
                        </span>
                    </div>

                    <div
                        class="d-flex flex-column align-items-center text-center p-3 p-md-0 iconblock mx-2"
                    >
                        <img
                            class="icon"
                            src="/assets/images/time.svg"
                            alt="clock_icon_covers_for_life"
                        />
                        <span class="text">
                            {{
                                $t("component.lifetimeWarrantyModal.itemThree")
                            }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="disclaimer" v-html="warrantyDisclaimer" />
        </b-container>
        <div slot="modal-footer">
            <a class="btn btn-primary" href="/warranty">
                {{ $t("component.lifetimeWarrantyModal.btn") }}
            </a>
        </div>
    </cs-modal>
</template>

<script>
import CSModal from "../CSModal/index";
import EventBus from "../../event-bus";

export default {
    name: "LifetimeWarrantyModal",

    components: {
        "cs-modal": CSModal
    },

    data() {
        return {
            warrantyDisclaimer: this.$t("disclaimer.warranty")
        };
    },

    created() {
        EventBus.$on("lifetime-warranty-modal-open", () => {
            this.show();
        });
    },

    methods: {
        show() {
            this.$refs.modal.show();
        }
    }
};
</script>

<style lang="scss" scoped>
#lifetimeWarrantyModal {
    .powered-block {
        color: $muted-dark-gray;
        line-height: 8px;
    }
    .iconblock {
        .icon {
            height: 35px;
            width: 50px;
            margin-bottom: 15px;
        }
        .text {
            max-width: 165px;
            line-height: 15px;
            font-size: px2rem(14);
            color: $dark-gray;
        }
    }

    img {
        width: 265px;
    }

    .btn-primary {
        width: 200px;
    }

    .disclaimer {
        font-size: px2rem(10);
        font-style: italic;
        line-height: 10px;
        color: $muted-dark-gray;
    }
}
</style>
