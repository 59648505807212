var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isSignedIn ? _c("div", [_vm._t("default")], 2) : _vm._e(),
    _vm._v(" "),
    !_vm.isSignedIn
      ? _c(
          "div",
          [
            _c(
              "event-link",
              { attrs: { "event-name": "sign-in-required-modal-open" } },
              [_vm._t("default")],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }