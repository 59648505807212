<template>
    <div class="email-updater">
        <div v-if="editEmail" class="edit d-flex">
            <b-input v-model="emailInput" name="email" />
            <b-button variant="secondary" @click="saveEmail">
                {{ $t("component.pricingCard.save") }}
            </b-button>
        </div>
        <div v-else class="label d-flex align-items-center">
            <div class="email-label">
                {{ user.email }}
            </div>
            <b-button variant="secondary" @click="editEmail = true">
                {{ $t("component.pricingCard.edit") }}
            </b-button>
        </div>
    </div>
</template>

<style lang="scss">
.email-updater {
    margin: 10px 0;

    .edit {
        max-width: 300px;
    }

    button {
        margin-left: 10px;
    }
}
</style>

<script>
import { get, dispatch } from "vuex-pathify";

export default {
    name: "EmailUpdater",

    data() {
        return {
            emailInput: "",
            editEmail: false
        };
    },

    computed: {
        user: get("user/user")
    },

    mounted() {
        this.emailInput = this.user.email;
    },

    methods: {
        saveEmail() {
            dispatch("user/updateEmailPhone", {
                email: this.emailInput,
                userId: this.user.id,
                phoneNumber: this.user.phoneNumber
            });
            this.editEmail = false;
        }
    }
};
</script>
