var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "email-updater" }, [
    _vm.editEmail
      ? _c(
          "div",
          { staticClass: "edit d-flex" },
          [
            _c("b-input", {
              attrs: { name: "email" },
              model: {
                value: _vm.emailInput,
                callback: function($$v) {
                  _vm.emailInput = $$v
                },
                expression: "emailInput"
              }
            }),
            _vm._v(" "),
            _c(
              "b-button",
              { attrs: { variant: "secondary" }, on: { click: _vm.saveEmail } },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("component.pricingCard.save")) +
                    "\n        "
                )
              ]
            )
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "label d-flex align-items-center" },
          [
            _c("div", { staticClass: "email-label" }, [
              _vm._v("\n            " + _vm._s(_vm.user.email) + "\n        ")
            ]),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: { variant: "secondary" },
                on: {
                  click: function($event) {
                    _vm.editEmail = true
                  }
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("component.pricingCard.edit")) +
                    "\n        "
                )
              ]
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }