var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c(
        "div",
        {
          staticClass: "card-header d-flex justify-content-between",
          class: _vm.showCollapse ? "collapsed" : null,
          attrs: {
            "aria-controls": `${_vm.name}-collapse`,
            "aria-expanded": _vm.showCollapse ? "true" : "false"
          },
          on: {
            click: function($event) {
              _vm.showCollapse = !_vm.showCollapse
            }
          }
        },
        [
          _c("h2", { staticClass: "mb-0" }, [
            _vm._v("\n            " + _vm._s(_vm.title) + "\n        ")
          ]),
          _vm._v(" "),
          _c("i", {
            class: _vm.showCollapse ? "ion-chevron-up" : "ion-chevron-down",
            attrs: { "aria-hidden": "true" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { id: `${_vm.name}-collapse` },
          model: {
            value: _vm.showCollapse,
            callback: function($$v) {
              _vm.showCollapse = $$v
            },
            expression: "showCollapse"
          }
        },
        [
          _vm.loading
            ? _c("loading-spinner", { staticClass: "my-5 mx-auto" })
            : _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }