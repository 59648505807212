var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tab-pane active pb-3",
      attrs: { id: "price", role: "tabpanel", "aria-labelledby": "price-tab" }
    },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center line-item",
                  attrs: { cols: "12" }
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _vm.canUnlockPricing && _vm.isUsed
                      ? _c("span", [
                          _c(
                            "span",
                            { staticClass: "title blue font-weight-bold" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$t("component.pricingCard.salePrice")
                                  ) +
                                  "\n                            "
                              ),
                              _vm.salePriceDisclaimer !== null
                                ? _c("tooltip-disclaimer", {
                                    attrs: { title: _vm.salePriceDisclaimer }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("small", { staticClass: "font-italic d-block" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "component.pricingCard.excludesTaxesAndFees"
                                  )
                                ) +
                                "\n                        "
                            )
                          ])
                        ])
                      : _vm.isNew
                      ? _c(
                          "span",
                          { staticClass: "title font-weight-bold" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t("component.pricingCard.msrp")) +
                                "\n                        "
                            ),
                            _c("tooltip-disclaimer", {
                              attrs: { title: _vm.retailPriceDisclaimer }
                            })
                          ],
                          1
                        )
                      : _c(
                          "span",
                          { staticClass: "title font-weight-bold" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t("component.pricingCard.retailPrice")
                                ) +
                                "\n                        "
                            ),
                            _c("tooltip-disclaimer", {
                              attrs: { title: _vm.retailPriceDisclaimer }
                            })
                          ],
                          1
                        )
                  ]),
                  _vm._v(" "),
                  _vm.canUnlockPricing && _vm.isUsed
                    ? _c("span", { staticClass: "value blue" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("numeral")(
                                _vm._f("emptyDash")(
                                  _vm.pricing,
                                  "salePrice.value"
                                ),
                                "$0,0"
                              )
                            ) +
                            "\n                "
                        )
                      ])
                    : (_vm.isUsed && !_vm.canUnlockPricing) ||
                      _vm.isNew ||
                      _vm.isConfigured
                    ? _c("span", { staticClass: "value" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("numeral")(
                                _vm._f("emptyDash")(_vm.retailPrice),
                                "$0,0"
                              )
                            ) +
                            "\n                "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.shouldAverageMarketPriceShow
            ? _c(
                "b-row",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center line-item",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "title font-weight-bold" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t("component.pricingCard.marketAverage")
                              ) +
                              "\n                    "
                          ),
                          _vm.averageMarketPrice
                            ? _c("tooltip-disclaimer", {
                                attrs: {
                                  title: _vm.averageMarketPrice.disclaimer
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("numeral")(
                                _vm.averageMarketPrice.value,
                                "$0,0"
                              )
                            ) +
                            "\n                "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.certifiedDealer && !_vm.isConfigured
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "mt-2 d-flex justify-content-between align-items-center line-item",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("span", { staticClass: "title font-weight-bold" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$t("component.pricingCard.dealerPrice")
                            ) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-link",
                        {
                          staticClass: "font-italic",
                          on: { click: _vm.contactDealer }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t("component.pricingCard.contactDealer")
                              ) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          (_vm.showLifetimeWarranty && !_vm.isUsed) ||
          (_vm.showLifetimeWarranty && !_vm.canUnlockPricing && !_vm.isNew)
            ? _c(
                "b-row",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center line-item",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "title font-weight-bold mr-1" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t("component.pricingCard.lifetimeWarranty")
                              ) +
                              "\n                    "
                          ),
                          _c("lifetime-warranty-tooltip-disclaimer")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c(
                            "b-link",
                            {
                              staticClass: "warranty-value font-italic",
                              on: {
                                click: function($event) {
                                  return _vm.showLifetimeWarrantyModal()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t("component.pricingCard.included")
                                  ) +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isCertifiedDealer && _vm.isNew
            ? _c(
                "b-row",
                { staticClass: "mt-3 mb-2" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center line-item",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c(
                          "span",
                          { staticClass: "title font-weight-bold" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t("component.pricingCard.salePrice")
                                ) +
                                "\n                        "
                            ),
                            _vm.salePriceDisclaimer !== null
                              ? _c("tooltip-disclaimer", {
                                  attrs: { title: _vm.salePriceDisclaimer }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.canUnlockPricing
                          ? _c("small", { staticClass: "font-italic" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t(
                                      "component.pricingCard.excludesTaxesAndFees"
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.canUnlockPricing &&
                      (!_vm.showRestrictedMake || _vm.sentPriceByEmail)
                        ? _c(
                            "div",
                            [
                              _vm.showRestrictedMake
                                ? _c(
                                    "div",
                                    { staticClass: "restricted-make-price" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.pricingCard.seeEmail"
                                            )
                                          ) +
                                          "\n                    "
                                      )
                                    ]
                                  )
                                : _vm.showDealerProvidedPrice
                                ? _c("dealer-provided-price")
                                : _c("div", { staticClass: "blue-value" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm._f("numeral")(
                                            _vm._f("emptyDash")(
                                              _vm.pricing,
                                              "salePrice.value"
                                            ),
                                            "$0,0"
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ])
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _vm.isUserSignedIn
                                ? _c(
                                    "b-link",
                                    { on: { click: _vm.requestDealerLink } },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.pricingCard.getSalePrice"
                                            )
                                          ) +
                                          "\n                    "
                                      )
                                    ]
                                  )
                                : _c(
                                    "b-link",
                                    {
                                      directives: [
                                        {
                                          name: "href-return-url",
                                          rawName: "v-href-return-url",
                                          value: { modal: "dealer-link" },
                                          expression: "{ modal: 'dealer-link' }"
                                        }
                                      ],
                                      attrs: { href: _vm.signUpHref }
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "component.pricingCard.getSalePrice"
                                            )
                                          ) +
                                          "\n                    "
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                    ]
                  )
                ],
                1
              )
            : !_vm.showSupplierPricing &&
              _vm.isCertifiedDealer &&
              _vm.canUnlockPricing &&
              _vm.hasIncentives
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "text-center additional-incentives mt-3" },
                      [
                        _vm._v(
                          "\n                    Additional Incentives\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "incentives-box p-3" },
                      [
                        _vm._l(_vm.pricing.purchaseIncentives, function(
                          incentive,
                          key
                        ) {
                          return _c(
                            "b-row",
                            { key: key },
                            [
                              _c("b-col", { attrs: { cols: "8" } }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(incentive.name) +
                                    "\n                            "
                                ),
                                incentive.providedBy
                                  ? _c("small", [
                                      _vm._v(
                                        "from " + _vm._s(incentive.providedBy)
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              incentive.cashValue
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "text-right value",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("numeral")(
                                              incentive.cashValue,
                                              "$0,0"
                                            )
                                          ) +
                                          "\n                        "
                                      )
                                    ]
                                  )
                                : _c(
                                    "b-col",
                                    {
                                      staticClass: "text-right value",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            included\n                        "
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _vm.showLifetimeWarranty
                          ? _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { cols: "8" } }, [
                                  _vm._v(
                                    "\n                            Lifetime Warranty\n                            "
                                  ),
                                  _c("small", [_vm._v("from CarSaver")])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right value",
                                    attrs: { cols: "4" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            included\n                        "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            : _vm.canUnlockPricing && _vm.isUsed && _vm.isCertifiedDealer
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "text-center additional-incentives mt-3" },
                      [
                        _vm._v(
                          "\n                    Valid only at:\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "certified-box px-3 py-2 text-center" },
                      [
                        _c("strong", { staticClass: "blue" }, [
                          _vm._v(_vm._s(_vm.dealerName))
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n                    Please ask for your dedicated CarSaver Program Manager\n                    when you visit this dealership.\n                "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSupplierPricing ? _c("get-supplier-pricing-block") : _vm._e(),
      _vm._v(" "),
      _vm.isCertifiedDealer
        ? _c("get-sales-price-block")
        : _c("contact-dealer-block"),
      _vm._v(" "),
      _vm.isConfigured ? _c("search-local-listings") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }