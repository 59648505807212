<template>
    <div class="restricted-make-alert">
        <div class="title">
            <i aria-hidden="true" class="ion ion-android-warning" />
            {{ $t("component.pricingCard.displayRestricted") }}
        </div>

        <div class="body">
            {{ $t("component.pricingCard.checkYourEmail") }}
            <b-link ref="icon" tabindex="0">
                <i
                    aria-hidden="true"
                    class="icon ion-ios-information-outline"
                />
            </b-link>
            <b-popover :target="() => $refs.icon" triggers="focus">
                {{ $t("component.pricingCard.minimumAdvertisedPricePolicy") }}
                <br />
                {{ $t("component.pricingCard.yourGauranteedSavings") }}
                {{ savings | numeral("$0,0") }}
                {{ $t("component.pricingCard.onAnyInStock") }}
                {{ vehicleName }} {{ $t("component.pricingCard.at") }}
                {{ dealerName }}.
                <br />
                {{ $t("component.pricingCard.eachCertifiedDealer") }}
            </b-popover>
        </div>
    </div>
</template>

<style lang="scss">
.restricted-make-alert {
    border: 1px solid $primary-blue;
    border-radius: 2px;
    background-color: $very-light-gray;
    color: $primary-blue;
    text-align: center;

    > .title {
        font-size: 12px;
        color: #fff;
        min-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 30px;
        background-color: $primary-blue;

        > i {
            font-size: 20px;
            line-height: 23px;
            margin-right: 5px;
        }
    }

    > .body {
        font-size: 14px;
        line-height: 15px;
        padding: 15px;
    }
}
</style>

<script>
export default {
    name: "RestrictedMakeAlert",

    props: {
        dealerName: {
            type: String,
            required: true
        },
        vehicleName: {
            type: String,
            required: true
        },
        savings: {
            type: Number,
            required: true
        }
    }
};
</script>
