<template>
    <detail-block id="overview" title="Overview" name="overview">
        <div class="card-body">
            <div class="color-block d-flex flex-row">
                <div
                    v-if="vehicle.exteriorColor"
                    class="d-flex flex-column mr-5"
                >
                    <span v-if="isConfigured" class="label mb-1">
                        <span class="d-none d-sm-inline">
                            {{ $t("page.vdp.extColor") }}
                        </span>
                        <b-link class="d-sm-none" @click="onExteriorEditClick">
                            {{ $t("page.vdp.extColor") }}
                        </b-link>
                    </span>
                    <span v-else class="label mb-1">
                        {{ $t("page.vdp.extColor") }}
                    </span>
                    <div class="value d-flex flex-row align-items-center">
                        <span
                            class="color-swatch mx-2"
                            :style="{
                                'background-color':
                                    vehicle.exteriorColorHexCode != null
                                        ? '#' + vehicle.exteriorColorHexCode
                                        : ''
                            }"
                        />
                        <span class="color-text">
                            {{ vehicle.exteriorColor }}
                        </span>
                        <span
                            v-if="isConfigured"
                            class="d-none d-sm-inline color-edit pl-2"
                        >
                            <b-link
                                class="font-italic"
                                @click="onExteriorEditClick"
                            >
                                edit
                            </b-link>
                        </span>
                    </div>
                </div>

                <div v-if="vehicle.interiorColor" class="d-flex flex-column">
                    <span v-if="isConfigured" class="label mb-1">
                        <span class="d-none d-sm-inline">
                            {{ $t("page.vdp.intColor") }}
                        </span>
                        <b-link class="d-sm-none" @click="onInteriorEditClick">
                            {{ $t("page.vdp.intColor") }}
                        </b-link>
                    </span>
                    <span v-else class="label mb-1">
                        {{ $t("page.vdp.intColor") }}
                    </span>
                    <div class="value d-flex flex-row align-items-center">
                        <span class="color-text">
                            {{ vehicle.interiorColor }}
                        </span>
                        <span
                            v-if="isConfigured"
                            class="d-none d-sm-inline color-edit pl-2"
                        >
                            <b-link
                                class="font-italic"
                                @click="onInteriorEditClick"
                            >
                                edit
                            </b-link>
                        </span>
                    </div>
                </div>
            </div>

            <hr class="divider" />

            <div class="overview-icon-wrapper">
                <div v-if="vehicle.passengerCapacity" class="block">
                    <div class="icon-wrapper">
                        <i aria-hidden="true" class="icon icon-seat" />
                    </div>
                    <div class="label">
                        <span>{{ vehicle.passengerCapacity }} Seats</span>
                    </div>
                </div>

                <div v-if="vehicle.bodyStyle" class="block">
                    <div class="icon-wrapper">
                        <i aria-hidden="true" class="icon icon-bodystyle" />
                    </div>
                    <div class="label">
                        <span>{{ vehicle.bodyStyle | capitalize }}</span>
                    </div>
                </div>

                <div v-if="vehicle.transmission" class="block">
                    <div class="icon-wrapper">
                        <i aria-hidden="true" class="icon icon-automatic" />
                    </div>
                    <div class="label">
                        <span>{{ vehicle.transmission }}</span>
                    </div>
                </div>

                <div v-if="vehicle.drivetrain" class="block">
                    <div class="icon-wrapper">
                        <i aria-hidden="true" class="icon icon-front_wheel" />
                    </div>
                    <div class="label">
                        <span>{{ vehicle.drivetrain }}</span>
                    </div>
                </div>

                <div v-if="vehicle.fuelType" class="block">
                    <div class="icon-wrapper">
                        <i aria-hidden="true" class="icon icon-gas_pump" />
                    </div>
                    <div class="label">
                        <span>{{ vehicle.fuelType }}</span>
                    </div>
                </div>

                <div v-if="vehicle.horsePower" class="block">
                    <div class="icon-wrapper">
                        <i
                            aria-hidden="true"
                            class="icon icon-lightning_engine"
                        />
                    </div>
                    <div class="label">
                        <span>{{ vehicle.horsePower }} hp</span>
                    </div>
                </div>

                <div v-if="vehicle.engineDisplacement" class="block">
                    <div class="icon-wrapper">
                        <i aria-hidden="true" class="icon icon-E_engine" />
                    </div>
                    <div class="label">
                        <span>{{ vehicle.engineDisplacement }}</span>
                    </div>
                </div>

                <div v-if="vehicle.features.fuelType" class="block">
                    <div class="icon-wrapper">
                        <i aria-hidden="true" class="icon icon-fuel_can" />
                    </div>
                    <div class="label">
                        <span>{{ vehicle.features.fuelType }}</span>
                    </div>
                </div>
            </div>

            <hr v-if="vehicle.specs" class="divider" />

            <installed-options-block />

            <div
                v-if="vehicle.specs"
                class="show-all-block d-flex justify-content-center align-items-center mb-3"
            >
                <b-link
                    href="#specsAndFeatures"
                    class="btn btn-secondary"
                    smooth-scrolling
                >
                    show all specs & features
                </b-link>
            </div>

            <div v-if="hasSellerNotes" class="seller-notes mb-3">
                <h3>Seller's Notes</h3>
                <div class="notes">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span
                        v-if="truncateSellerNotes"
                        v-html="truncatedSellerNotes"
                    />
                    <b-link
                        v-if="truncateSellerNotes"
                        @click="sellerNotesReadMore"
                    >
                        Read More
                    </b-link>
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <div v-if="!truncateSellerNotes" v-html="sellerNotes" />
                </div>
            </div>
        </div>
    </detail-block>
</template>

<style lang="scss">
#overview {
    h3 {
        font-size: 14px;
        font-weight: bold;
    }

    .color-block {
        .label {
            font-weight: bold;
            font-size: 12px;
            color: #444444;
            line-height: 15px;
            margin-bottom: 7px;
        }

        .value {
            .color-swatch {
                border-radius: 50px;
                height: 15px;
                width: 15px;
                border: 1px solid #dddddd;
                box-sizing: border-box;
                min-width: 15px;
            }
            .color-text {
                font-size: 16px;
            }
        }
    }

    .overview-icon-wrapper {
        color: $muted-dark-gray;
        display: flex;
        flex-wrap: wrap;
        margin: 25px 0;

        .block {
            align-items: center;
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            width: 25%;

            @include media-breakpoint-down(sm) {
                width: 50%;
            }

            .icon-wrapper {
                height: 45px;
                min-width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                overflow: hidden;

                > i {
                    font-size: px2rem(30);
                    color: $primary-blue;
                }
            }

            .label {
                display: flex;

                span {
                    font-size: px2rem(14);
                    line-height: 18px;
                }
            }
        }
    }

    .show-all-block {
        text-align: center;

        .btn-secondary {
            color: $primary-blue;
            height: 40px;
            width: 200px;
            font-size: 14px;
            font-style: italic;
        }
    }
}
</style>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import DetailBlock from "./DetailBlock";
import InstalledOptionsBlock from "./InstalledOptionsBlock";

export default {
    components: { InstalledOptionsBlock, DetailBlock },

    data() {
        return {
            truncateSellerNotes: true
        };
    },

    computed: {
        vehicle: get("vehicleDetails/vehicle"),
        sellerNotes: get("vehicleDetails/inventoryVehicle@sellerNotes"),
        certificateId: get("vehicleDetails/certificate@id"),
        inventoryVehicle: get("vehicleDetails/inventoryVehicle"),
        isConfigured() {
            return _.isNil(this.inventoryVehicle);
        },

        hasSellerNotes() {
            return !_.isNil(this.sellerNotes) && this.sellerNotes.length > 0;
        },

        truncatedSellerNotes() {
            if (_.isNil(this.sellerNotes) || this.sellerNotes.length <= 230) {
                return this.sellerNotes;
            }

            return this.sellerNotes.substring(0, 230);
        }
    },

    methods: {
        sellerNotesReadMore() {
            this.truncateSellerNotes = false;
        },
        onExteriorEditClick() {
            document.location = `/configure/style/${
                this.vehicle.styleId
            }/color?configuration_state=${encodeURIComponent(
                this.vehicle.configurationState
            )}&cid=${this.certificateId}`;
        },

        onInteriorEditClick() {
            document.location = `/configure/style/${
                this.vehicle.styleId
            }/options?configuration_state=${encodeURIComponent(
                this.vehicle.configurationState
            )}&cid=${this.certificateId}`;
        }
    }
};
</script>
