var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "detail-block",
    { attrs: { title: "Payment Options", name: "paymentOptions" } },
    [
      _c(
        "div",
        {
          staticClass: "card-body pb-4",
          attrs: { id: "payment-options-card" }
        },
        [
          _c("b-row", [
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-md-2 mb-3 mb-md-0 d-flex justify-content-center"
              },
              [
                _c("i", {
                  staticClass: "icon icon-payment p-3",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-6" }, [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("span", { staticClass: "text font-weight-bold" }, [
                  _vm._v(
                    "\n                        Planning to lease or finance this vehicle?\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "sub-text" }, [
                  _vm._v(
                    "\n                        Seeing all your different payment options allows you\n                        to be more informed about your payment\n                        "
                  ),
                  _c("strong", [_vm._v("BEFORE")]),
                  _vm._v(" you get to the dealership.\n                    ")
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-md-4 d-flex justify-content-center align-items-center button-block py-3 py-md-0"
              },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: { click: _vm.openPaymentOptions }
                  },
                  [
                    _vm._v(
                      "\n                    View Payment Options\n                "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }