<template>
    <detail-block
        v-if="validCrashTestRating"
        id="safety"
        title="Safety Rating"
        name="safety"
    >
        <div class="card-body pb-4">
            <div class="block d-flex flex-column align-items-center">
                <div class="star-rating d-flex">
                    <i
                        v-for="star in stars"
                        :key="star"
                        class="ion-android-star mx-2"
                        :class="{ highlight: crashTestRating > star }"
                        aria-hidden="true"
                    />
                </div>
                <span id="ratings">
                    {{ crashTestRating }} Star Crash Test Rating
                </span>
            </div>
        </div>
    </detail-block>
</template>

<style lang="scss">
#safety {
    .card-body {
        background-color: $white;
        padding: 0;

        .block {
            text-align: center;

            .ion-android-star {
                font-size: 65px;
                color: $muted-gray;

                &.highlight {
                    color: $active-star;
                }
            }

            @include media-breakpoint-down(xs) {
                .ion-android-star {
                    font-size: 50px;
                }
            }

            #ratings {
                font-size: px2em(18);
            }
        }
    }
}
</style>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import DetailBlock from "./DetailBlock";

export default {
    components: { DetailBlock },

    computed: {
        crashTestRating: get("vehicleDetails/vehicle@features/crashTestRating"),
        validCrashTestRating() {
            return (
                !_.isNil(this.crashTestRating) &&
                _.isNumber(this.crashTestRating) &&
                this.crashTestRating > 0 &&
                this.crashTestRating <= 5
            );
        },
        stars() {
            return _.range(0, 5);
        }
    }
};
</script>
