<template>
    <b-modal
        id="dealerLinkThankYouModal"
        ref="modal"
        v-model="isOpen"
        :hide-header="true"
        :hide-footer="true"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
    >
        <restricted-make-content
            v-if="restrictedMake"
            @see-pricing="startAutoSend"
            @still-shopping="stillShopping"
            @close="closeModal"
        />
        <default-content
            v-else
            @see-pricing="startAutoSend"
            @still-shopping="stillShopping"
        />
    </b-modal>
</template>

<style lang="scss">
#dealerLinkThankYouModal {
    color: $medium-gray;
}
</style>

<script>
import _ from "lodash";
import { dispatch, get, sync } from "vuex-pathify";
import { trackGAEvent } from "@/lib/GoogleTagManager";
import RestrictedMakeContent from "Components/DealerLinkRequiredModal/RestrictedMakeContent";
import DefaultContent from "Components/DealerLinkRequiredModal/DefaultContent";

export default {
    components: { DefaultContent, RestrictedMakeContent },
    computed: {
        isOpen: sync("dealerLink/thankYouOpen"),
        dealer: get("dealerLink/selectedDealer"),
        restrictedMake: get("dealerLink/isRestrictedMake"),
        email: get("user/user@email"),

        dealerName() {
            return _.get(this.dealer, "name", "a CarSaver Certified Dealer");
        }
    },

    methods: {
        startAutoSend() {
            trackGAEvent(this, {
                category: "dealer-link-modal",
                action: "auto-connection",
                label: "Okay. See Pricing"
            });

            dispatch("dealerLink/startAutoSend");
        },
        stillShopping() {
            trackGAEvent(this, {
                category: "dealer-link-modal",
                action: "no-auto-connection",
                label: "I'm still shopping"
            });

            this.isOpen = false;
        },
        closeModal() {
            trackGAEvent(this, {
                category: "dealer-link-modal",
                action: "no-auto-connection",
                label: "Close"
            });

            this.isOpen = false;
        }
    }
};
</script>
