var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-3 w-100", attrs: { id: "contact-dealer-block" } },
    [
      _c(
        "div",
        {
          staticClass:
            "col-12 text-center d-flex flex-column align-items-center"
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mb-2",
              attrs: { variant: "orange", block: "" },
              on: { click: _vm.contactDealer }
            },
            [_vm._v("\n            Contact Dealer\n        ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }