var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "start-new-search d-none d-md-flex flex-column justify-content-center"
    },
    [
      _c("b-link", { on: { click: _vm.goToListings } }, [
        _c("i", {
          staticClass: "icon ion-ios-search",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v("\n        " + _vm._s(_vm.text) + "\n    ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }