<template>
    <div class="section-box">
        <slot />
    </div>
</template>

<style lang="scss">
.section-box {
    border: 1px solid $muted-gray;
    border-radius: 2px;
    background-color: $very-light-gray;
    min-height: 70px;
    padding: 5px;
    margin-top: 10px;
}
</style>

<script>
export default {
    name: "SectionBox"
};
</script>
