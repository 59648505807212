var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.warrantyEligible
    ? _c(
        "div",
        {
          staticClass:
            "d-flex flex-column flex-md-row justify-content-around align-items-center pt-4 py-md-2 px-md-3 position-relative",
          attrs: { id: "lifetimeWarrantyBlock" }
        },
        [
          _c("div", { staticClass: "d-flex flex-column logo-block" }, [
            _c("span", { staticClass: "text-center" }, [
              _vm._v("This vehicle comes with…")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-column lifetime-warranty-logo" },
              [
                _c("b-img", {
                  attrs: {
                    src: require("./images/lifetime-warranty-logo.png"),
                    alt: "Lifetime Warranty - powered by CarSaver"
                  }
                }),
                _vm._v(" "),
                _vm._m(0)
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column flex-md-row align-items-center p-3 p-md-0 icon-block"
              },
              [
                _c("svg-fallback", {
                  staticClass: "icon m-2",
                  attrs: { src: _vm.ShieldImage }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "d-flex align-items-center" }, [
                  _vm._v("No additional cost to you.")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column flex-md-row align-items-center p-3 p-md-0 icon-block"
              },
              [
                _c("svg-fallback", {
                  staticClass: "icon m-2",
                  attrs: { src: _vm.EngineImage }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "d-flex align-items-center" }, [
                  _vm._v("Covers engine & drivetrain.")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column flex-md-row align-items-center p-3 p-md-0 icon-block"
              },
              [
                _c("svg-fallback", {
                  staticClass: "icon m-2",
                  attrs: { src: _vm.TimeImage }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "d-flex align-items-center" }, [
                  _vm._v("As long as you own the vehicle.")
                ])
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex align-items-center align-self-end mr-3" },
      [
        _c("span", { staticClass: "font-italic powered-by" }, [
          _vm._v("powered by")
        ]),
        _vm._v(" "),
        _c("small", { staticClass: "font-weight-bold" }, [_vm._v("CarSaver")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }