<template>
    <div id="vinStockMilesWrapper">
        <div
            id="vinStockMiles"
            class="d-flex flex-wrap justify-content-between align-items-center content-box px-3"
        >
            <div class="text-nowrap">
                <span class="label">
                    VIN:
                </span>
                <span class="value">
                    <b-link
                        v-if="showCarfax"
                        :href="inventoryVehicle.carfaxUrl"
                        target="_blank"
                    >
                        {{ vin | emptyDash }}
                        <img src="/assets/images/carfax.png" alt="error_car" />
                    </b-link>
                    <span v-else>
                        {{ vin | emptyDash }}
                    </span>
                </span>
            </div>

            <div v-if="inventoryVehicle">
                <span class="label"> {{ $t("page.vdp.stock") }}: </span>
                <span class="value">
                    {{ inventoryVehicle.stockNumber | emptyDash }}
                </span>
            </div>

            <div v-if="vehicleIsUsed">
                <span class="label">
                    {{ $t("page.vdp.miles") }}
                </span>
                <span class="value">
                    {{ inventoryVehicle.miles | numeral | emptyDash }}
                </span>
            </div>

            <div v-if="showDistanceAway">
                <span class="label">
                    {{ $t("page.vdp.distance") }}
                </span>
                <span class="value">
                    {{ distance | numeral("0,0") }} miles
                </span>
            </div>
        </div>

        <div
            v-if="!inventoryVehicle"
            id="configuredDisclaimer"
            class="d-flex flex-wrap justify-content-between align-items-center content-box pb-3 px-3"
        >
            {{ $t("page.vdp.configuredMsg") }}
        </div>
    </div>
</template>
<script>
import { get } from "vuex-pathify";
import _ from "lodash";

export default {
    name: "VinStockMilesBlock",

    computed: {
        inventoryVehicle: get("vehicleDetails/inventoryVehicle"),
        distance: get("vehicleDetails/dealer@distance"),
        deliveryAvailability: get(
            "vehicleDetails/dealer@preferences.deliveryAvailability"
        ),

        isDelivery: function() {
            return _.eq(this.deliveryAvailability, "DELIVERY_ONLY");
        },
        vin() {
            const vin = _.get(this.inventoryVehicle, "vin");

            return _.isNil(vin) ? "Configured Vehicle" : vin;
        },

        showDistanceAway: function() {
            return !this.isDelivery && this.isFarAway;
        },

        isFarAway: function() {
            return _.gt(this.distance, 60);
        },

        vehicleIsUsed() {
            const stockType = _.get(this.inventoryVehicle, "stockType", null);
            return _.lowerCase(stockType) === "used";
        },

        showCarfax() {
            const vehicleHasCarfaxUrl = !_.isEmpty(
                _.get(this.inventoryVehicle, "carfaxUrl")
            );
            return vehicleHasCarfaxUrl && this.vehicleIsUsed;
        }
    }
};
</script>
<style lang="scss">
#vinStockMilesWrapper {
    margin-bottom: 10px;

    .content-box {
        border-radius: 2px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        min-height: 30px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: px2rem(14);
    }

    img {
        opacity: 1;
        margin-left: 7px;
        align-self: center;
        width: 66px;
        height: 17px;
    }

    .label {
        color: $dark-gray;
        font-size: px2rem(14);
        font-weight: bold;
    }

    .value {
        color: $dark-gray;
        font-size: px2rem(14);
    }

    @include media-breakpoint-down(md) {
        line-height: 20px;

        img {
            margin-left: 0;
        }

        .label {
            font-weight: bold;
            font-size: px2rem(13);
        }
        .value {
            font-size: px2rem(13);
        }
    }
}
</style>
