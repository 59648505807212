var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "download-modal",
        "ok-title": "Close",
        "ok-only": true,
        "hide-footer": true,
        title: "Download the App",
        size: "lg"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "div",
        { attrs: { slot: "modal-header-close" }, slot: "modal-header-close" },
        [
          _c("i", {
            staticClass: "ion-ios-close-outline",
            attrs: { "aria-hidden": "true" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex flex-wrap apps justify-content-center mt-3 mb-5"
        },
        _vm._l(_vm.apps, function(app) {
          return _c(
            "div",
            {
              key: app.name,
              staticClass: "d-flex flex-row align-items-center m-3 app"
            },
            [
              _c(
                "b-link",
                {
                  attrs: {
                    href: app.href,
                    target: "_blank",
                    rel: "noopener noreferrer"
                  },
                  on: { click: () => _vm.trackClick(app.name) }
                },
                [
                  _c("b-img", {
                    attrs: { src: app.image, alt: app.name, fluid: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }