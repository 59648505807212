<template>
    <detail-block
        v-if="activeDealerLink && coordinatesAvailable"
        :title="$t('component.dealerMap.title')"
        name="dealerMap"
    >
        <div id="dealerMap" class="card-body map">
            <div class="container-fluid px-0 px-md-3">
                <div class="row d-flex">
                    <div class="col-12 col-md-8 order-2 order-md-1 pl-md-0">
                        <marketing-image-slider
                            v-if="marketingImagesAvailable"
                            :marketing-images="dealer.marketingImages"
                        />

                        <gmap-map
                            v-else
                            :center="{ lat: dealer.lat, lng: dealer.lng }"
                            :zoom="11"
                            style="width: 100%; height: 400px"
                        >
                            <gmap-info-window
                                :options="infoOptions"
                                :opened="infoWinOpen"
                                :position="{ lat: dealer.lat, lng: dealer.lng }"
                                @closeclick="infoWinOpen = false"
                            >
                                <div id="content">
                                    <h5
                                        id="firstHeading"
                                        class="firstHeading"
                                        style="max-width:300px;"
                                    >
                                        {{ dealer.name }}
                                    </h5>
                                    <div id="bodyContent">
                                        <p>
                                            <strong>{{
                                                dealer.address1
                                            }}</strong
                                            ><br />
                                            {{ dealer.city }},
                                            {{ dealer.state }}
                                            {{ dealer.zipCode }}
                                        </p>
                                    </div>
                                </div>
                            </gmap-info-window>
                            <gmap-marker
                                :position="{ lat: dealer.lat, lng: dealer.lng }"
                                :clickable="true"
                                @click="toggleInfoWindow()"
                            />
                        </gmap-map>
                    </div>
                    <div class="col-12 col-md-4 order-1 order-md-2 pr-md-0">
                        <div
                            class="d-flex flex-column justify-content-between h-100"
                            style="padding-bottom: 60px;"
                        >
                            <div class="dealer my-2 my-md-0">
                                <div v-if="isDeliveryOnly">
                                    <span class="map-label">
                                        {{ dealer.name }}
                                    </span>
                                    <br />
                                    <span class="address map-value">
                                        Nationwide shipping to<br />
                                        {{ user.city }}, {{ user.stateCode }}
                                        {{ user.zipCode }}
                                    </span>
                                </div>
                                <div v-else class="d-flex flex-column">
                                    <span class="map-label">
                                        {{ dealer.name }}
                                    </span>
                                    <span class="address map-value">
                                        {{ dealer.address1 }}
                                    </span>
                                    <span class="address map-value">
                                        {{ dealer.city }} {{ dealer.state }}
                                        {{ dealer.zipCode }}
                                    </span>
                                </div>
                            </div>

                            <div class="d-flex flex-column my-2 my-md-0">
                                <span class="map-label">
                                    {{ $t("component.dealerMap.phoneNumber") }}
                                </span>
                                <b-link :href="'tel:' + dealer.phoneNumber">
                                    {{ dealer.phoneNumber | phone }}
                                </b-link>
                            </div>

                            <div class="d-flex flex-column my-2 my-md-0">
                                <span class="map-label">Time Zone:</span>
                                <span class="map-value">{{
                                    dealer.timeZone
                                }}</span>
                            </div>

                            <div class="d-flex flex-column my-2 my-md-0">
                                <span class="map-label">
                                    {{ $t("component.dealerMap.storeHours") }}
                                </span>
                                <div
                                    v-for="(hour, index) in hoursOfOperations"
                                    :key="index"
                                    class="d-flex justify-content-between dealer-hours"
                                >
                                    <span v-if="hour">
                                        {{ index | indexToDayOfWeek }}
                                    </span>
                                    <div>
                                        <span>{{ hour.openHours }}</span>
                                        <span>-</span>
                                        <span>{{ hour.closeHours }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="padding: 25px 0;">
                    <div
                        class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end"
                    >
                        <div class="my-2 my-md-0">
                            <b-button
                                variant="outline-primary"
                                size="lg"
                                class="p-0"
                                @click="openContactDealer"
                            >
                                {{ $t("component.dealerMap.contactDealer") }}
                            </b-button>
                        </div>
                    </div>
                    <div
                        class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start"
                    >
                        <div class="my-2 my-md-0">
                            <b-button
                                v-if="marketingImagesAvailable"
                                variant="outline-primary"
                                size="lg"
                                @click="viewInventory()"
                            >
                                See Dealer Inventory
                            </b-button>
                            <b-button
                                v-else
                                variant="outline-primary"
                                size="lg"
                                @click="getDirections()"
                            >
                                {{ $t("component.dealerMap.directions") }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </detail-block>
</template>

<script>
import { get, dispatch } from "vuex-pathify";
import DetailBlock from "Modules/Vehicle/DetailBlock";
import _ from "lodash";
import MarketingImageSlider from "Modules/Vehicle/DealerMap/MarketingImageSlider";

export default {
    name: "DealerMap",
    components: { MarketingImageSlider, DetailBlock },
    filters: {
        indexToDayOfWeek(index) {
            if (index === 0) {
                return "Monday";
            }
            if (index === 1) {
                return "Tuesday";
            }
            if (index === 2) {
                return "Wednesday";
            }
            if (index === 3) {
                return "Thursday";
            }
            if (index === 4) {
                return "Friday";
            }
            if (index === 5) {
                return "Saturday";
            }
            if (index === 6) {
                return "Sunday";
            }
            return "--";
        }
    },
    data() {
        return {
            infoWinOpen: true,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            }
        };
    },
    computed: {
        activeDealerLink: get("dealerLink/active"),
        dealer: get("vehicleDetails/dealer"),
        certificateId: get("vehicleDetails/certificate@id"),
        user: get("user/user"),
        hoursOfOperations() {
            return _.get(this.dealer, "hoursOfOperations");
        },
        coordinatesAvailable() {
            return (
                !_.isNil(_.get(this.dealer, "lat")) &&
                !_.isNil(_.get(this.dealer, "lng"))
            );
        },
        isDeliveryOnly() {
            return _.get(this.dealer, "deliveryOnly", true);
        },
        marketingImagesAvailable() {
            const marketingImages = _.get(this.dealer, "marketingImages", null);

            return marketingImages !== null;
        }
    },
    methods: {
        openContactDealer() {
            dispatch("contactDealer/openModal", {
                certificateId: this.certificateId
            });
        },
        getDirections() {
            const basePath =
                "https://www.google.com/maps/dir/?api=1&destination=";
            const latitude = this.dealer.lat;
            const longitude = this.dealer.lng;

            const directionUrl = basePath + latitude + "," + longitude;

            window.open(directionUrl);
        },
        viewInventory() {
            window.location = `/dealer/${this.dealer.id}/inventory`;
        },

        toggleInfoWindow() {
            this.infoWinOpen = true;
        }
    }
};
</script>
<style lang="scss">
#dealerMap {
    .dealer {
        line-height: 16px;

        .address {
            color: $muted-dark-gray;
        }
    }

    .dealer-hours {
        line-height: 16px;
        color: $muted-dark-gray;
        font-family: $helvetica-55-Roman;
    }

    .btn-outline-primary {
        width: 200px;
        font-size: 14px;
    }

    div {
        line-height: 17px;
        .map-label {
            font-family: $helvetica-75-bold;
        }
        .map-value {
            font-family: $helvetica-55-Roman;
            color: $muted-dark-gray;
            font-size: px2rem(14);
        }
    }
}
</style>
