<template>
    <div id="vehicle360View" class="position-relative">
        <div
            id="spin"
            ref="spin"
            :style="{
                display: exteriorOrInterior === 'EXTERIOR' ? 'block' : 'none'
            }"
            class="three-sixty"
        />
        <div
            v-if="interiorCubeMapExists"
            id="interior-360"
            :style="{
                display: exteriorOrInterior === 'INTERIOR' ? 'block' : 'none'
            }"
        >
            <iframe
                title="interior 360"
                class="interior-iframe"
                :src="interior360Url"
                width="512"
                height="512"
                @load="iframeLoad"
            />
        </div>
        <div
            v-if="threeSixtyTypes.length > 1"
            class="mt-2 d-flex justify-content-center"
        >
            <b-form-group>
                <b-form-radio-group
                    id="exterior_interior"
                    v-model="exteriorOrInterior"
                    buttons
                    button-variant="outline-primary"
                    :options="threeSixtyTypes"
                />
            </b-form-group>
        </div>
        <div
            class="d-flex flex-column justify-content-center container text-center spin-drag"
        >
            Click and drag to spin vehicle
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { get, sync } from "vuex-pathify";
import threesixty from "threesixty/dist/threesixty.min";

export default {
    name: "Vehicle360View",

    data() {
        return {
            exteriorOrInterior: "EXTERIOR",
            threeSixtyTypes: []
        };
    },

    computed: {
        styleId: get("vehicleDetails/vehicle@styleId"),
        exteriorThreeSixtyBaseUrl: get(
            "vehicleDetails/media@exteriorThreeSixtyBaseUrl"
        ),
        interiorCubeMapExists: sync(
            "vehicleDetails/media@interiorCubeMapExists"
        ),
        interior360Url() {
            return `/evox/360/interior?styleId=${this.styleId}&screenSize=512&notopbar=true`;
        },
        validExteriorBaseUrl() {
            return (
                !_.isNil(this.exteriorThreeSixtyBaseUrl) &&
                !_.isEmpty(this.exteriorThreeSixtyBaseUrl)
            );
        }
    },

    watch: {
        exteriorOrInterior(newVal) {
            if (newVal === "EXTERIOR") {
                this.update360Container(this.exteriorThreeSixtyBaseUrl);
            } else if (newVal === "INTERIOR") {
                this.exteriorOrInterior = "INTERIOR";
            }
        }
    },

    mounted() {
        if (this.validExteriorBaseUrl) {
            this.update360Container(this.exteriorThreeSixtyBaseUrl);
        } else if (this.interiorCubeMapExists) {
            this.exteriorOrInterior = "INTERIOR";
        }

        if (this.validExteriorBaseUrl) {
            this.threeSixtyTypes.push({
                text: "Exterior",
                value: "EXTERIOR"
            });
        }

        if (this.interiorCubeMapExists) {
            this.threeSixtyTypes.push({
                text: "Interior",
                value: "INTERIOR"
            });
        }
    },

    methods: {
        update360Container(baseUrl) {
            const basePath = baseUrl;
            const container = this.$refs.spin;
            const endOfPath = ".jpg";
            const array = [];

            for (let i = 1; i <= 36; i++) {
                array.push(
                    basePath.concat(("" + i).padStart(3, "0") + endOfPath)
                );
            }

            this.threesixty = threesixty(container, array, {
                interactive: true,
                currentImage: 0,
                reverse: true
            });

            this.threesixty.init();
        },

        iframeLoad(event) {
            const iframeUrl = _.get(
                event,
                "target.contentWindow.location.pathname"
            );

            // If iframe redirected and is not on the expected path possibly due to an error.
            if (iframeUrl !== "/evox/360/interior") {
                this.interiorCubeMapExists = false;
                _.remove(this.threeSixtyTypes, ["value", "INTERIOR"]);
            }
        }
    }
};
</script>

<style lang="scss">
#vehicle360View {
    #interior-360 {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 51%;

        .interior-iframe {
            position: absolute;
            border: none;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }

    .three-sixty {
        cursor: grab;
    }

    .spin-drag {
        font-family: $helvetica-46-light-italic;
    }

    @include media-breakpoint-down(md) {
        .three-sixty {
            img {
                max-width: 100%;
                object-fit: contain;
                max-height: 350px;
                height: calc((100vw - 25px) * 3 / 5);
            }
        }
    }
}
</style>
