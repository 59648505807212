<template>
    <div
        class="vdp-navigation-affix"
        relative-element-selector=".vdp-content"
        :enabled="false"
        :style="{ zIndex: isMobile ? 30 : 10 }"
        :offset="{ top: isMobile ? 139 : 99, bottom: 0 }"
    >
        <div
            class="corner-shadowing-left"
            :class="{ 'show-left': showLeftCornerShadow }"
        />
        <div
            class="corner-shadowing-right"
            :class="{ 'show-right': showRightCornerShadow }"
        />
        <nav id="vdp-navigation" class="navbar">
            <div
                ref="linkContainer"
                class="container-fluid text-center justify-content-md-start d-inline d-lg-flex"
            >
                <a
                    v-for="link in activeLinks"
                    :key="link.id"
                    class="nav-link text-uppercase"
                    :class="{ 'all-links-available': allNavLinksAvailable }"
                    :href="link.href"
                    @click="scrollTo(link.scrollTo)"
                >
                    {{ link.title }}
                </a>
            </div>
        </nav>
    </div>
</template>

<script>
import _ from "lodash";
import { get } from "vuex-pathify";
import smoothScroll from "@/mixins/smoothScroll";

export default {
    name: "VdpNavigation",

    mixins: [smoothScroll],

    data() {
        return {
            isMobile: false,
            windowWidth: window.innerWidth,
            navNode: {},
            showRightCornerShadow: false,
            showLeftCornerShadow: false
        };
    },

    computed: {
        financingAvailable: get("vehicleDetails/vehicle@financingAvailable"),
        vehicleSpecs: get("vehicleDetails/vehicle@specs"),
        reviewText: get("vehicleDetails/reviewText"),
        crashTestRating: get("vehicleDetails/vehicle@features/crashTestRating"),
        campaignType: get("campaign/type"),
        vrAvailable: get("vehicleDetails/media@interiorCubeMapExists"),
        isKiosk() {
            return this.campaignType === "kiosk";
        },
        showVrBlock() {
            return !this.isKiosk && this.vrAvailable;
        },
        showMainGallery() {
            return this.showVrBlock || this.mediaAvailable;
        },
        navWidth() {
            const width = this.navNode.scrollWidth;

            return width || 0;
        },
        scrollOffset() {
            return this.isMobile ? 115 : 90;
        },
        showOverview() {
            return true;
        },
        showFinance() {
            return this.financingAvailable;
        },
        showInsurance() {
            return true;
        },
        showGallery() {
            return true;
        },
        showReviews() {
            return !_.isNil(this.reviewText);
        },
        showVehicleSpecs() {
            return !_.isEmpty(this.vehicleSpecs);
        },
        showSafety() {
            return !_.isNil(this.crashTestRating);
        },
        allNavLinksAvailable() {
            return (
                this.financingAvailable && this.showSafety && this.showReviews
            );
        },
        hasVehicleSpecs() {
            return !_.isEmpty(this.vehicleSpecs);
        },
        scrollToOptions: function() {
            return {
                easing: "ease-in",
                offset: 0 - this.scrollOffset
            };
        },
        links() {
            return [
                {
                    id: "overview",
                    title: this.$i18n.t("page.vdp.overview"),
                    enabled: this.showOverview,
                    href: "#overview",
                    scrollTo: "overview"
                },
                {
                    id: "finance",
                    title: this.$i18n.t("page.vdp.finance"),
                    enabled: this.showFinance,
                    href: "#finance-tab",
                    scrollTo: "finance-tab"
                },
                {
                    id: "insurance",
                    title: this.$i18n.t("page.vdp.insurance"),
                    enabled: this.showInsurance,
                    href: "#insurance-tab",
                    scrollTo: "insurance-tab"
                },
                {
                    id: "gallery",
                    title: this.$i18n.t("page.vdp.gallery"),
                    enabled: this.showGallery,
                    href: "#main-gallery",
                    scrollTo: "main-gallery"
                },
                {
                    id: "reviews",
                    title: this.$i18n.t("page.vdp.research"),
                    enabled: this.showReviews,
                    href: "#about-this-vehicle",
                    scrollTo: "about-this-vehicle"
                },
                {
                    id: "vehiclesSpecs",
                    title: this.$i18n.t("page.vdp.features"),
                    enabled: this.showVehicleSpecs,
                    href: "#features-container",
                    scrollTo: "features-container"
                },
                {
                    id: "safety",
                    title: this.$i18n.t("page.vdp.safety"),
                    enabled: this.showSafety,
                    href: "#safety",
                    scrollTo: "safety"
                }
            ];
        },
        activeLinks() {
            let filteredLinks = [];

            filteredLinks = _.filter(this.links, function(link) {
                return link.enabled === true;
            });

            return filteredLinks;
        }
    },

    mounted() {
        const isMobile = window.matchMedia(
            "only screen and (max-width: 990px)"
        );
        this.isMobile = isMobile.matches;
        this.navNode = this.$refs.linkContainer;

        window.onresize = this.navWidthHandler();
        this.navNode.addEventListener("scroll", this.navScrollHandler);
    },

    methods: {
        navWidthHandler() {
            const windowWidth = window.innerWidth;

            if (this.navWidth > windowWidth) {
                this.showRightCornerShadow = true;
            } else {
                this.showRightCornerShadow = false;
            }
        },

        navScrollHandler() {
            const navScrollPosition = this.navNode.scrollLeft || 0;

            if (navScrollPosition > 5) {
                this.showLeftCornerShadow = true;
            } else {
                this.showLeftCornerShadow = false;
            }
        }
    }
};
</script>

<style lang="scss">
.vdp-navigation-affix {
    width: 100%;
    position: relative;

    nav {
        div {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
        }
        a.nav-link {
            display: inline;

            &:first-child {
                padding-left: 0;
            }
        }
    }

    &.affix {
        z-index: 21 !important;
    }

    @include media-breakpoint-down(xs) {
        nav > div {
            height: 100%;
            line-height: 35px;
        }
        a.nav-link {
            padding-left: 10px;
            padding-right: 10px;
        }
        .all-links-container {
            padding-right: 0;
        }

        .corner-shadowing-left,
        .corner-shadowing-right {
            width: 20px;
            height: 100%;
            position: absolute;
            z-index: 1;
            display: none;

            &.show-left,
            &.show-right {
                display: block;
            }
        }

        .corner-shadowing {
            &-left {
                left: 0px;
                display: none;
                background-image: linear-gradient(
                    to right,
                    rgba(0, 0, 0, 0.15),
                    rgba(255, 0, 0, 0)
                );
            }
            &-right {
                right: 0px;
                display: none;
                background-image: linear-gradient(
                    to left,
                    rgba(0, 0, 0, 0.15),
                    rgba(255, 0, 0, 0)
                );
            }
        }
    }
}
</style>
