<template>
    <div v-if="!notAvailable && financingAvailable" id="finance-tab" class="card">
        <div
            id="headingFinance"
            class="card-header d-flex justify-content-between"
            role="tab"
            data-toggle="collapse"
            href="#collapseFinance"
            aria-expanded="true"
        >
            <h2 class="mb-0">
                Finance Rates & Payment Plans
            </h2>
            <i aria-hidden="true" class="ion-chevron-up" />
        </div>
        <div
            id="collapseFinance"
            class="collapse show"
            aria-labelledby="headingFinance"
            data-target="#collapseFinance"
        >
            <application-in-progress v-if="inProgress" />
            <unable-to-process
                v-else-if="unableToProcess"
                :error-code="errorCode"
            />
            <offers-list
                v-else-if="financeAppRequest"
                :request="financeAppRequest"
                :accepted-response-id="acceptedResponseId"
                :offers="decisions"
                @accept="acceptOffer"
                @resubmit="resubmitOffer"
            />
            <planning-to-finance-block
                v-else
                :on-get-pre-approved-click="getPreApprovedClick"
            />
        </div>

        <get-financing-modal :certificate-id="certificateId" />
    </div>
</template>

<script>
import { get, dispatch, call } from "vuex-pathify";
import PlanningToFinanceBlock from "./components/PlanningToFinance";
import _ from "lodash";
import UnableToProcess from "./components/UnableToProcess";
import EventBus from "@/event-bus";
import ApplicationInProgress from "./components/ApplicationInProgress";
import OffersList from "./components/OffersList";
import GetFinancingModal from "./components/GetFinancingModal";

export default {
    name: "VehicleFinanceBlock",

    components: {
        GetFinancingModal,
        OffersList,
        ApplicationInProgress,
        UnableToProcess,
        PlanningToFinanceBlock
    },

    data() {
        return {
            errorCode: null
        };
    },

    computed: {
        isSignedIn: get("user/isSignedIn"),
        dealer: get("vehicleDetails/dealer"),
        certificate: get("vehicleDetails/certificate"),
        certificateId: get("vehicleDetails/certificate@id"),
        canUnlockPricing: get("dealerLink/canUnlockPricing"),
        offers: get("vehicleDetails/finance@offers"),
        financeAppRequest: get("vehicleDetails/finance@appRequest"),
        financingAvailable: get("vehicleDetails/vehicle@financingAvailable"),

        decisions() {
            return _.get(this.offers, "decisions", []);
        },

        acceptedResponseId() {
            return _.get(this.certificate, "finance.responseId", null);
        },

        notAvailable() {
            return _.get(this.offers, "state") === "NOT_AVAILABLE";
        },

        inProgress() {
            return _.get(this.offers, "state") === "IN_PROGRESS";
        },

        unableToProcess() {
            return this.errorCode !== null;
        }
    },

    watch: {
        offers(newOffers) {
            const decisions = _.get(newOffers, "decisions", []);
            const unableToProceedDecisions = _.filter(
                decisions,
                decision => decision.status === "UNABLE_TO_PROCEED"
            );

            if (unableToProceedDecisions.length >= 1) {
                this.errorCode = "FA001";
                return;
            }

            this.errorCode = null;
        }
    },

    mounted() {
        const shouldLoadOffers =
            this.certificate !== null &&
            this.certificateId !== null &&
            this.canUnlockPricing;
        if (shouldLoadOffers) {
            dispatch("vehicleDetails/loadFinanceOffers");
        }
    },

    methods: {
        openContactModal: call("contactDealer/openModal"),
        getPreApprovedClick() {
            if (!this.isSignedIn) {
                EventBus.$emit("sign-in-required-modal-open");
            } else if (this.financingAvailable) {
                EventBus.$emit("financing-modal-open");
            } else {
                this.openContactModal({
                    certificateId: this.certificateId,
                    questionType: "Financing"
                });
            }
        },

        acceptOffer({ responseId }) {
            dispatch("vehicleDetails/acceptFinanceOffer", { responseId });
        },

        resubmitOffer({ responseId, lenderId }) {
            dispatch("vehicleDetails/resubmitFinanceOffer", {
                responseId,
                lenderId
            });
        }
    }
};
</script>
