var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "c-s-modal",
    {
      ref: "getFinancingModal",
      attrs: { id: "getFinancingModal", "hide-footer": true }
    },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _vm._v("\n        " + _vm._s(_vm.modalTitle) + "\n    ")
      ]),
      _vm._v(" "),
      _vm.showHowItWorks
        ? _c("div", { staticClass: "container-fluid" }, [
            _c("ol", [
              _c("li", [
                _vm._v(
                  "\n                Your application is submitted to CarSaver’s network of banks\n                and finance companies\n            "
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n                We will notify you when offers are received (this usually\n                only takes a few minutes)\n            "
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n                Pick the offer that is right for you and schedule an\n                appointment with a Certified CarSaver Dealership\n            "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "actions" }, [
              _vm.certificateId
                ? _c(
                    "div",
                    { staticClass: "my-2" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-auto buttons",
                          attrs: { variant: "primary" },
                          on: { click: _vm.continueFinancingClick }
                        },
                        [
                          _vm._v(
                            "\n                    Continue\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "my-2 buttons d-flex" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-2",
                          attrs: { variant: "primary", href: _vm.signInUrl }
                        },
                        [
                          _vm._v(
                            "\n                    Sign In\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-2",
                          attrs: { variant: "orange", href: _vm.signUpUrl }
                        },
                        [
                          _vm._v(
                            "\n                    Sign Up\n                "
                          )
                        ]
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c("b-link", { on: { click: _vm.viewBenefitsClick } }, [
                    _vm._v(
                      "\n                    View benefits\n                "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c("b-link", { on: { click: _vm.closeModal } }, [
                    _vm._v(
                      "\n                    No thanks, take me back to my vehicle\n                "
                    )
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "disclaimer" }, [
              _vm._v(
                "\n            * If you are approved, the finance source may require\n            verification of certain items, prior to extending financing\n            including - proof of income, verification of employment, proof\n            of residence and proof of phone.\n        "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showBenefits
        ? _c("div", { staticClass: "container-fluid" }, [
            _c("p", [
              _vm._v("Benefits of getting pre-approved for financing.")
            ]),
            _vm._v(" "),
            _c("p", [_c("strong", [_vm._v("Benefits:")])]),
            _vm._v(" "),
            _c("ol", [
              _c("li", [
                _vm._v(
                  "\n                Know your rate and payment before you go to the dealership.*\n            "
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n                Save on finance charges by eliminating dealer markup on your\n                finance rate.\n            "
                )
              ]),
              _vm._v(" "),
              _c("li", [_vm._v("Save time at the dealership.")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "actions" }, [
              _vm.certificateId
                ? _c(
                    "div",
                    { staticClass: "my-2" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-auto",
                          attrs: { variant: "primary" },
                          on: { click: _vm.continueFinancingClick }
                        },
                        [
                          _vm._v(
                            "\n                    Get Payment Offers\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "my-2 buttons d-flex" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-2",
                          attrs: { variant: "primary", href: _vm.signInUrl }
                        },
                        [
                          _vm._v(
                            "\n                    Sign In\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-2",
                          attrs: { variant: "orange", href: _vm.signUpUrl }
                        },
                        [
                          _vm._v(
                            "\n                    Sign Up\n                "
                          )
                        ]
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c("b-link", { on: { click: _vm.closeModal } }, [
                    _vm._v(
                      "\n                    No thanks, take me back to my vehicle\n                "
                    )
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "disclaimer" }, [
              _vm._v(
                "\n            * Finance offers are subject to approved credit. See terms and\n            conditions for details.\n        "
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }