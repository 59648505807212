<template>
    <div
        class="start-new-search d-none d-md-flex flex-column justify-content-center"
    >
        <b-link @click="goToListings">
            <i aria-hidden="true" class="icon ion-ios-search" />
            {{ text }}
        </b-link>
    </div>
</template>

<script>
import listingsUrlStorage from "@/lib/listingsUrlStorage";
import _ from "lodash";

export default {
    name: "NewSearchLink",

    data() {
        return {
            alternativeUrl: "/pick-your-flow",
            alternativeText: "New Search",
            clientStorageListings: null,
            url: null,
            text: ""
        };
    },

    created() {
        this.clientStorageListings = listingsUrlStorage.get();
        this.url = _.get(
            this.clientStorageListings,
            "url",
            this.alternativeUrl
        );
        this.text = _.get(
            this.clientStorageListings,
            "text",
            this.alternativeText
        );
    },

    methods: {
        goToListings() {
            window.location = this.url || this.alternativeUrl;
        }
    }
};
</script>

<style lang="scss" scoped>
.start-new-search {
    position: absolute;
    top: 10px;
    height: 35px;
    min-width: 130px;
    border-radius: 20px;
    background-color: $very-light-gray;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    color: $primary-blue;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    padding: 7px 14px;
    z-index: 30;

    i {
        padding-right: 6px;
        font-size: 18px;
        vertical-align: sub;
    }

    a:hover {
        text-decoration: none;
        opacity: 0.8;
    }

    &:focus,
    &:active,
    &:focus-within {
        background-color: $light-gray;

        &,
        a {
            cursor: not-allowed;
            color: $dark-gray;
        }
    }
}
</style>
