<template>
    <b-modal
        id="download-modal"
        v-model="show"
        ok-title="Close"
        :ok-only="true"
        :hide-footer="true"
        title="Download the App"
        size="lg"
    >
        <div slot="modal-header-close">
            <i aria-hidden="true" class="ion-ios-close-outline" />
        </div>

        <div class="d-flex flex-wrap apps justify-content-center mt-3 mb-5">
            <div
                v-for="app in apps"
                :key="app.name"
                class="d-flex flex-row align-items-center m-3 app"
            >
                <b-link
                    :href="app.href"
                    target="_blank"
                    rel="noopener noreferrer"
                    @click="() => trackClick(app.name)"
                >
                    <b-img :src="app.image" :alt="app.name" fluid />
                </b-link>
            </div>
        </div>
    </b-modal>
</template>

<style lang="scss">
#download-modal {
    @include media-breakpoint-down(xs) {
        .modal-title {
            font-size: 28px;
            line-height: 2.7;
        }
    }

    .apps {
        .app {
            @include media-breakpoint-down(xs) {
                height: 50px;
                width: 115px;
            }

            img {
                max-width: 132px;
            }
        }
    }
}
</style>

<script>
import Steam from "./images/Steam.png";
import OculusGo from "./images/OculusGo.png";
import SamsungGear from "./images/SamsungGear.png";
import Apple from "./images/Apple.png";
import Android from "./images/Android.png";
import Daydream from "./images/Daydream.png";
import Microsoft from "./images/Mircosoft.png";
import { sync } from "vuex-pathify";
import { trackGAEvent } from "@/lib/GoogleTagManager";

export default {
    data() {
        return {
            apps: [
                {
                    href:
                        "https://store.steampowered.com/app/994610/RelayCars/",
                    image: Steam,
                    name: "Steam"
                },
                {
                    href:
                        "https://www.oculus.com/experiences/go/959775037421164/",
                    image: OculusGo,
                    name: "oculus Go"
                },
                {
                    href:
                        "https://www.oculus.com/experiences/app/959775037421164/",
                    image: SamsungGear,
                    name: "Gear VR - Powered by oculus"
                },
                {
                    href:
                        "https://itunes.apple.com/us/app/relaycars/id1153872921?mt=8",
                    image: Apple,
                    name: "Download on the App Store"
                },
                {
                    href:
                        "https://play.google.com/store/apps/details?id=com.evox.relaycarscb",
                    image: Android,
                    name: "Get it on Google play"
                },
                {
                    href:
                        "https://play.google.com/store/apps/details?id=com.evox.relaycarsdd",
                    image: Daydream,
                    name: "Daydream"
                },
                {
                    href:
                        "https://www.microsoft.com/en-us/p/relaycars/9pfd0gc92s1b?activetab=pivot:overviewtab",
                    image: Microsoft,
                    name: "Get it on Microsoft"
                }
            ]
        };
    },

    computed: {
        show: sync("vehicleDetails/vr@downloadModal")
    },

    methods: {
        trackClick(appName) {
            trackGAEvent(this, {
                category: "evox",
                action: "vr-app-download",
                label: appName
            });

            return true;
        }
    }
};
</script>
