var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vdp-navigation-affix",
      style: { zIndex: _vm.isMobile ? 30 : 10 },
      attrs: {
        "relative-element-selector": ".vdp-content",
        enabled: false,
        offset: { top: _vm.isMobile ? 139 : 99, bottom: 0 }
      }
    },
    [
      _c("div", {
        staticClass: "corner-shadowing-left",
        class: { "show-left": _vm.showLeftCornerShadow }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "corner-shadowing-right",
        class: { "show-right": _vm.showRightCornerShadow }
      }),
      _vm._v(" "),
      _c("nav", { staticClass: "navbar", attrs: { id: "vdp-navigation" } }, [
        _c(
          "div",
          {
            ref: "linkContainer",
            staticClass:
              "container-fluid text-center justify-content-md-start d-inline d-lg-flex"
          },
          _vm._l(_vm.activeLinks, function(link) {
            return _c(
              "a",
              {
                key: link.id,
                staticClass: "nav-link text-uppercase",
                class: { "all-links-available": _vm.allNavLinksAvailable },
                attrs: { href: link.href },
                on: {
                  click: function($event) {
                    return _vm.scrollTo(link.scrollTo)
                  }
                }
              },
              [
                _vm._v(
                  "\n                " + _vm._s(link.title) + "\n            "
                )
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }