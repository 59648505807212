<template>
    <view-details-modal :id="id" class="insure-view-details-modal">
        <div slot="title">
            Congratulations!
        </div>

        <div slot="summary">
            <h4>
                You received an insurance offer from Liberty Mutual Insurance.
            </h4>

            <quote-info :quote="quote" />
        </div>

        <div slot="offerTab">
            <coverage-details
                :coverages="quote.policyCoverage"
                title="Policy Coverages"
            />

            <coverage-details
                v-for="(vehicleCoverage, key) in quote.vehicleCoverage"
                :key="key"
                :title="vehicleTitle(vehicleCoverage.vehicle)"
                :coverages="vehicleCoverage.coverages"
            />
        </div>

        <div slot="detailsTab">
            <insurance-vehicle-panel :quote="quote" :vehicle="vehicle" />

            <div class="call-action">
                Call
                <a href="tel:8887272146">
                    888-727-2146
                </a>
                to accept this offer.
            </div>

            <div class="quote-id">Quote ID: {{ quote.quoteId }}</div>
        </div>

        <div slot="footer" class="disclaimer">
            <p>
                This example of some of the available coverages and limits is
                not a contract, binder, or recommendation of coverage. All
                coverages are subject to the term and conditions contained in
                the policy and endorsements. Because the rate charged must be in
                compliance with the Company's rules and rates, rate quotes are
                subject to revision if different rates are effective at the time
                of the policy insurance. This rate quote may be revised if any
                of the information used for rating is changed. Coverage provided
                and underwritten by Liberty Mutual Insurance and its affiliates,
                175 Berkeley Street, Boston, MA, 02116 USA. Equal Housing
                Insurer. (c) 2018 Liberty Mutual Insurance.
            </p>
            <p>{{ quote.disclaimer }}</p>
        </div>
    </view-details-modal>
</template>

<style lang="scss">
.insure-view-details-modal {
    .call-action {
        background-color: #f9f9f9;
        border: 1px solid #007dc6;
        color: #007dc6;
        padding: 10px;
        font-size: 16px;
        margin: 10px 0;

        > a {
            font-weight: bold;
            text-decoration: none;
        }
    }

    .quote-id {
        margin: 10px 0;
        font-size: 16px;
        text-align: center;
    }
}
</style>

<script>
import { get } from "vuex-pathify";
import ViewDetailsModal from "../../../ViewDetailsModal";
import QuoteInfo from "./QuoteInfo";
import CoverageDetails from "./CoverageDetails";
import InsuranceVehiclePanel from "./VehiclePanel";

export default {
    name: "InsuranceViewDetailsModal",

    components: {
        InsuranceVehiclePanel,
        CoverageDetails,
        QuoteInfo,
        ViewDetailsModal
    },

    props: {
        id: {
            required: true,
            type: String
        },
        quote: {
            required: true,
            type: Object
        }
    },

    computed: {
        vehicle: get("vehicleDetails/vehicle")
    },

    methods: {
        vehicleTitle(vehicle) {
            return `${vehicle.year} ${vehicle.makeName} ${vehicle.modelName} Coverages`;
        }
    }
};
</script>
