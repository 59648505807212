<template>
    <detail-block
        v-if="showSimilarBlock"
        name="similar"
        title="Similar Vehicles"
        :loading="loading"
    >
        <div class="container-fluid mb-3 mx-3">
            <p>
                Customers looking at the
                <strong>{{ vehicle.make }} {{ vehicle.model }}</strong> also
                looked at these vehicles.
            </p>
            <slider
                ref="similarVehicles"
                :options="flickityOptions"
                css-class="similarVehicles"
            >
                <div
                    v-for="similarVehicle in vehicles"
                    :key="similarVehicle.id"
                    class="mb-2 carousel-cell similarVehicle"
                >
                    <b-link
                        :href="vehicleUrl(similarVehicle)"
                        class="similarVehicleLink"
                        @click="similarVehicleClick(similarVehicle)"
                    >
                        <b-img
                            :src="similarVehicle.image"
                            :alt="
                                `${similarVehicle.year} ${similarVehicle.make} ${similarVehicle.model}`
                            "
                            fluid
                        />
                    </b-link>
                    <h3 class="text-left ml-2">
                        {{ similarVehicle.year }} {{ similarVehicle.make }}
                        {{ similarVehicle.model }}
                    </h3>
                    <div class="text-left ml-2">
                        {{ similarVehicle.trim }}<br />
                        {{ price(similarVehicle) | numeral("$0,0") }}
                    </div>
                </div>
            </slider>
        </div>
    </detail-block>
</template>

<style lang="scss" scoped>
h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
}

.similarVehicles {
    width: calc(100% - 20px);
    height: 235px;
    overflow: hidden;

    .carousel-cell {
        width: 33%;
        min-width: 200px;
        margin-right: 10px;
        text-align: center;
    }
}
</style>

<script>
import api from "@/api";
import { get } from "vuex-pathify";
import _ from "lodash";
import DetailBlock from "Modules/Vehicle/DetailBlock";
import Slider from "@carsaver/slider";
import { trackGAEvent } from "@/lib/GoogleTagManager";

export default {
    components: {
        Slider,
        DetailBlock
    },
    data() {
        return {
            vehicles: [],
            loading: true,
            showSimilarBlock: true,
            flickityOptions: {
                wrapAround: true,
                pageDots: false
            }
        };
    },

    computed: {
        vehicleId: get("vehicleDetails/inventoryVehicle@id"),
        vehicle: get("vehicleDetails/vehicle"),
        zipCode: get("location/selectedLocation@zipCode")
    },

    created() {
        // If it's an inventory vehicle
        if (!_.isNil(this.vehicleId)) {
            this.fetchRecommendations().then(() => {
                this.loading = false;
            });
        } else {
            this.showSimilarBlock = false;
        }
    },

    methods: {
        similarVehicleClick(vehicle) {
            trackGAEvent(this, {
                category: "vdp",
                action: "similar-vehicle-click",
                label: `${vehicle.year} ${vehicle.make} ${vehicle.model}`
            });
        },

        fetchRecommendations() {
            return api
                .get(`/vehicles/${this.vehicleId}/similar`, {
                    zipCode: this.zipCode,
                    size: 3
                })
                .then(response => {
                    this.vehicles = _.get(response, "data");
                    if (_.size(this.vehicles) === 0) {
                        this.showSimilarBlock = false;
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },

        price(vehicle) {
            if (vehicle.stockType === "NEW") {
                return vehicle.msrp ? vehicle.msrp : vehicle.internetPrice;
            }

            return vehicle.retailPrice
                ? vehicle.retailPrice
                : vehicle.internetPrice;
        },

        vehicleUrl(vehicle) {
            if (vehicle.stockType === "NEW") {
                return `/shop/new/${vehicle.id}`;
            }

            return `/shop/used/${vehicle.id}`;
        }
    }
};
</script>
