var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "cs-modal",
    { ref: "modal", attrs: { id: "vehicleReviewModal", "hide-footer": true } },
    [
      _c("div", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _vm._v("\n        Reviews\n    ")
      ]),
      _vm._v(" "),
      _c("b-container", [
        _c("div", { staticClass: "my-2 w-100 text-center" }, [
          _c("h3", { staticClass: "vehicle-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.vehicleFullName) +
                "\n            "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "review-wrapper" }, [
          _c(
            "div",
            { staticClass: "review" },
            [
              _c(
                "div",
                { staticClass: "review-image" },
                [
                  _c("fixed-vehicle-image", {
                    attrs: {
                      "image-url": _vm.vehicleSplashImage,
                      "backup-image-url": _vm.vehicleBackUpImage
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.reviews, function(review, key) {
                return _c("p", { key: key, staticClass: "review-text" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(review) +
                      "\n                "
                  )
                ])
              })
            ],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }