<template>
    <div class="card-body planning-to-finance">
        <div class="row py-md-4">
            <div class="col-12 col-md-2 mb-3 mb-md-0">
                <div class="icon-block">
                    <i aria-hidden="true" class="icon-calendar" />
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="d-flex flex-column question-block">
                    <h3 class="mb-1 mb-md-0">
                        Are you planning to finance this vehicle?
                    </h3>
                    <p>
                        Financing through CarSaver allows you to lock-in your
                        payment <strong>BEFORE</strong> you get to the
                        dealership.
                    </p>
                </div>
            </div>

            <div
                class="col-12 col-md-4 d-flex justify-content-center align-items-center button-block pt-3 pb-4 py-md-0"
            >
                <b-button variant="primary" @click="onGetPreApprovedClick()">
                    Get Pre-approved
                </b-button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.planning-to-finance {
    .icon-block {
        text-align: center;

        i {
            font-size: px2em(80);
            color: $primary-blue;
        }
    }

    .question-block {
        margin: 0 10px;

        > h3 {
            color: $dark-gray;
            font-weight: bold;
            font-size: px2em(16);
            margin: 0;
        }

        > p {
            color: $muted-dark-gray;
            font-size: px2em(14);
            margin: 0;
        }
    }

    .button-block {
        button {
            min-width: 200px;
            border-radius: 22.5px;
            height: 40px;
        }
    }

    @include media-breakpoint-down(sm) {
        .icon-block {
            i {
                font-size: px2em(75);
            }
        }
    }
}
</style>

<script>
export default {
    name: "PlanningToFinanceBlock",

    props: {
        onGetPreApprovedClick: {
            required: true,
            type: Function
        }
    }
};
</script>
