<template>
    <div id="vr-banner-block" class="card">
        <b-row>
            <b-col sm="5" class="text-center">
                <b-img src="./images/vr_head.png" />
            </b-col>
            <b-col sm="7">
                <div class="d-flex flex-column h-100 justify-content-around">
                    <div class="vr-tour-header mx-auto my-3 px-md-4">
                        <span class="take-a d-block">Take a</span>
                        <span class="vr d-block">Virtual Reality</span>
                        <span class="tour d-block text-right">Tour</span>
                    </div>
                    <div
                        class="actions d-flex flex-sm-row flex-column justify-content-around"
                    >
                        <b-btn
                            variant="outline-primary"
                            class="m-2 m-sm-0"
                            @click="openDownloadModal"
                        >
                            Download the App
                        </b-btn>
                        <b-btn
                            v-if="vrAvailable"
                            variant="orange"
                            class="m-2 m-sm-0"
                            @click="openTourModal"
                        >
                            Tour This Car
                        </b-btn>
                    </div>
                    <div class="powered-by d-flex m-3">
                        <b-link @click="gotoRelayCars">
                            <svg-fallback :src="relaycarsLogo.svg">
                                <b-img :src="relaycarsLogo.jpg" />
                            </svg-fallback>
                        </b-link>
                        <span>The new way to experience cars.</span>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss">
#vr-banner-block {
    .vr-tour-header {
        .take-a,
        .tour {
            font-family: $helvetica-46-light-italic;
            font-size: 28px;
            line-height: 34px;
        }
        .vr {
            font-family: $helvetica-65-medium;
            font-size: 43px;
            text-transform: uppercase;
            line-height: 34px;

            @include media-breakpoint-down(sm) {
                font-size: 34px;
            }
        }
    }

    .actions {
        .btn {
            min-width: 170px;
        }
    }

    .powered-by {
        font-family: $helvetica-46-light-italic;
        font-size: 14px;
    }
}
</style>

<script>
import relaycarsLogoSvg from "../Gallery/images/relaycars_logo.svg";
import relaycarsLogoJpg from "../Gallery/images/relaycars_logo.jpg";
import SvgFallback from "Components/SvgFallback";
import { sync, get } from "vuex-pathify";
import { trackPageView } from "@/lib/GoogleTagManager";
import EventBus from "@/event-bus";

export default {
    components: { SvgFallback },

    data() {
        return {
            relaycarsLogo: {
                svg: relaycarsLogoSvg,
                jpg: relaycarsLogoJpg,
                text: "Powered by RELAYCARS"
            },
            downloadAppModal: false
        };
    },

    computed: {
        downloadModal: sync("vehicleDetails/vr@downloadModal"),
        tourModal: sync("vehicleDetails/vr@tourModal"),
        vrAvailable: get("vehicleDetails/media@interiorCubeMapExists")
    },

    methods: {
        gotoRelayCars() {
            EventBus.$emit("leaving-modal", "https://vr.relaycars.com");
        },
        openDownloadModal() {
            trackPageView(this, `${window.location.pathname}/vr/download`);
            this.downloadModal = true;
        },
        openTourModal() {
            trackPageView(this, `${window.location.pathname}/vr/tour`);
            this.tourModal = true;
        }
    }
};
</script>
