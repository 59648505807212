var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "tour-modal",
        "ok-title": "Close",
        "ok-only": true,
        "hide-footer": true,
        title: "Tour This Car",
        size: "xl",
        lazy: true
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "div",
        { attrs: { slot: "modal-header-close" }, slot: "modal-header-close" },
        [
          _c("i", {
            staticClass: "ion-ios-close-outline",
            attrs: { "aria-hidden": "true" }
          })
        ]
      ),
      _vm._v(" "),
      _c("iframe", { attrs: { title: "Tour This Car", src: _vm.tourUrl } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }