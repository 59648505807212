<template>
    <b-alert show variant="primary" class="mb-2 d-flex align-items-center">
        <i aria-hidden="true" class="icon ion-ios-heart mr-1" />
        &nbsp;
        <strong>Dealer Delivery Available.</strong>&nbsp;
        <span>CarSaver cares about your well being. Stay Home. Stay Safe.</span>
    </b-alert>
</template>
<script>
export default {
    name: "DealerDeliveryResponse"
};
</script>
<style lang="scss">
.ion-ios-heart {
    font-size: 16px;
}
</style>
