var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100", attrs: { id: "cashTab" } }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "block border-bottom py-1" }, [
        _c("div", { staticClass: "d-flex justify-content-between line-item" }, [
          _c("span", { staticClass: "label" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("component.pricingCard.retailPrice")) +
                "\n                "
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [
            _vm._v(
              "\n                    " +
                _vm._s(
                  _vm._f("numeral")(
                    _vm._f("emptyDash")(_vm.retailPrice),
                    "$0,0"
                  )
                ) +
                "\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _vm.vehicleIsNew
          ? _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.rebate")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex value" }, [
                  _vm.pricing.rebatesValue > 0
                    ? _c("span", [
                        _vm._v(
                          "\n                        -\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("numeral")(
                          _vm._f("emptyDash")(_vm.pricing, "rebatesValue"),
                          "$0,0"
                        )
                      ) +
                      "\n                "
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasDealerDiscount
          ? _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.discount")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n                    - " +
                      _vm._s(
                        _vm._f("numeral")(
                          _vm._f("emptyDash")(_vm.dealerDiscount),
                          "$0,0"
                        )
                      ) +
                      "\n                "
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.vehicle.warrantyEligible
          ? _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "label mr-1",
                        on: {
                          click: function($event) {
                            return _vm.showLifetimeWarrantyModal()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t("component.pricingCard.lifetimeWarranty")
                            ) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("lifetime-warranty-tooltip-disclaimer")
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.included")) +
                      "\n                "
                  )
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "block border-bottom py-1" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between align-items-center line-item"
          },
          [
            _c(
              "div",
              { staticClass: "sale-label font-weight-bold" },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("component.pricingCard.salePrice")) +
                    "\n                    "
                ),
                _vm.salePriceDisclaimer !== null
                  ? _c("tooltip-disclaimer", {
                      attrs: { title: _vm.salePriceDisclaimer }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "font-italic excludes-taxes" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.$t("component.pricingCard.excludesTaxesAndFees")
                      ) +
                      "\n                    "
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _vm.showRestrictedMake
              ? _c("div", { staticClass: "restricted-make-price" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.seeEmail")) +
                      "\n                "
                  )
                ])
              : _vm.showDealerProvidedPrice
              ? _c("dealer-provided-price")
              : _c("div", { staticClass: "blue-value" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("numeral")(
                          _vm._f("emptyDash")(_vm.pricing, "salePrice.value"),
                          "$0,0"
                        )
                      ) +
                      "\n                "
                  )
                ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.hasFinanceTaxes
        ? _c("div", { staticClass: "block border-bottom py-1" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.tax")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("numeral")(
                          _vm._f("emptyDash")(_vm.taxes, "finance.salesTax"),
                          "+ $0,0"
                        )
                      ) +
                      "\n                "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t("component.pricingCard.licenseRegistration")
                      ) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("numeral")(
                          _vm._f("emptyDash")(
                            _vm.taxes,
                            "finance.titleLicenseFees"
                          ),
                          "+ $0,0"
                        )
                      ) +
                      "\n                "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.dealerFee")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("numeral")(
                          _vm._f("emptyDash")(_vm.pricing, "dealerFees"),
                          "+ $0,0"
                        )
                      ) +
                      "\n                "
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasFinanceTaxes
        ? _c("div", { staticClass: "block py-1" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.total")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value font-weight-bold" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("numeral")(_vm.totalWithTaxes, "$0,0")) +
                      "\n                "
                  )
                ])
              ]
            )
          ])
        : _c(
            "div",
            { staticClass: "block py-1 text-center taxes-link" },
            [
              _vm.taxesLoading
                ? _c("loading-spinner", { attrs: { size: 30 } })
                : _c("b-link", { on: { click: _vm.loadFinanceTaxes } }, [
                    _vm._v(
                      "\n                View with Taxes & Fees\n            "
                    )
                  ])
            ],
            1
          )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }