var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "title-with-price d-flex justify-content-between" },
    [
      _c(
        "div",
        { staticClass: "title d-flex align-items-center" },
        [_vm._t("title")],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "price" }, [_vm._t("price")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }