<template>
    <div
        class="d-flex flex-column justify-content-center align-items-center estimated-payment"
    >
        <span class="starting-at">
            {{ $t("component.pricingCard.startingAt") }}
        </span>
        <div class="d-flex flex-row justify-content-between">
            <div v-if="loading" class="d-flex flex-row flex-nowrap my-2">
                <loading-spinner />
            </div>

            <div
                v-if="!loading"
                class="d-flex flex-row align-items-center flex-nowrap dollar-value-block"
            >
                <span
                    :class="{
                        'blue-value': !strikeout,
                        value: strikeout,
                        strikeout: strikeout
                    }"
                >
                    {{ monthlyPayment | emptyDash | numeral("$0,0") }}
                </span>
                <span class="align-self-start month">
                    {{ $t("component.pricingCard.perMonth") }}
                    <tooltip-disclaimer v-if="disclaimer" :title="disclaimer" />
                </span>
            </div>
        </div>

        <small class="sub-text">
            {{ $t("component.pricingCard.excludesTaxesAndFees") }}
        </small>

        <div
            v-if="showPaymentsAvailable && !strikeout"
            class="text-center w-100 payment-options"
        >
            <payment-matrix ref="paymentMatrix" :show-lease="showLease" />
            <b-link class="color-payment-options" @click="viewPaymentOptions">
                {{ $t("component.pricingCard.viewPaymentOptions") }}
            </b-link>
            <i v-if="!canUnlockPricing" class="ion-locked" aria-hidden="true" />
        </div>
    </div>
</template>

<script>
import TooltipDisclaimer from "Components/TooltipDisclaimer";
import LoadingSpinner from "Components/LoadingSpinner";
import PaymentMatrix from "Modules/Vehicle/PaymentMatrix";
import { dispatch, get } from "vuex-pathify";
import EventBus from "@/event-bus";
import _ from "lodash";

export default {
    name: "EstimatedPayment",
    components: { PaymentMatrix, LoadingSpinner, TooltipDisclaimer },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        showLease: {
            type: Boolean,
            default: true
        },
        monthlyPayment: {
            type: Number,
            default: null
        },
        disclaimer: {
            type: String,
            default: null
        },
        strikeout: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        isUserSignedIn: get("user/isSignedIn"),
        dealerLinkExists: get("dealerLink/active"),
        canUnlockPricing: get("dealerLink/canUnlockPricing"),
        certificateId: get("vehicleDetails/certificate@id"),
        showPaymentsAvailable() {
            return !_.isNil(this.monthlyPayment) && this.monthlyPayment > 0;
        },
        showViewPaymentOptions() {
            return this.showPaymentsAvailable && !this.strikeout;
        }
    },

    methods: {
        viewPaymentOptions() {
            if (!this.isUserSignedIn) {
                EventBus.$emit("sign-in-modal-open", {
                    modalToOpen: "dealer-link"
                });
            } else if (!this.canUnlockPricing) {
                dispatch("dealerLink/openModal", this.certificateId);
            } else {
                this.$refs.paymentMatrix.show();
            }
        }
    }
};
</script>

<style lang="scss">
.estimated-payment {
    > span {
        font-size: px2em(14);
    }

    small {
        color: $muted-dark-gray;
    }

    .starting-at {
        font-style: italic;
        font-size: px2rem(10);
    }
    .dollar-value-block {
        font-family: $helvetica-45-light, sans-serif;
        color: $dark-gray;

        .blue-value {
            font-size: px2rem(31);
            line-height: 30px;
            color: $primary-blue;
        }
        .value {
            font-size: px2rem(31);
            line-height: 30px;
        }
        .month {
            margin-top: 10px;
            font-weight: 400;
            line-height: 30px;
            font-size: px2rem(12);
        }
    }

    .payment-options {
        font-size: px2rem(12);

        a {
            font-size: 10px;
            color: $dark-gray;
            cursor: pointer;
        }
    }
    a.color-payment-options {
        color: $primary;
        font-size: px2rem(12);
    }

    .sub-text {
        color: $muted-dark-gray;
        font-size: px2em(10);
        margin-bottom: 18px;
    }

    .strikeout {
        font-size: 4em;
        line-height: 1em;
        position: relative;
    }
    .strikeout::after {
        border-bottom: 2px solid $muted-dark-gray;
        opacity: 0.7;
        content: "";
        left: 0;
        margin-top: calc(0.125em / 2 * -1);
        position: absolute;
        right: 0;
        top: 55%;
    }
}
</style>
