<template>
    <detail-block
        :title="$t('component.betterWayToBuy.title')"
        name="betterWayToBuy"
    >
        <div id="better-way-to-buy" class="card-body p-0">
            <div class="position-relative">
                <img
                    class="img-fluid"
                    src="/assets/images/made_easy.jpg"
                    alt="a better way to buy cars"
                />

                <div class="position-absolute d-flex flex-column logo-block">
                    <img
                        class="crsv-logo"
                        src="/assets/images/white_crsv_logo.svg"
                        alt="a better way to buy cars"
                    />
                    <span class="align-self-end mr-2">
                        {{ $t("component.betterWayToBuy.enjoyTheRide") }}
                    </span>
                </div>
            </div>

            <div
                class="our-goal d-flex flex-column justify-content-center align-items-center text-center"
            >
                <span>{{ $t("component.betterWayToBuy.ourGoal") }}</span>
                <b-link class="phone" :href="'tel:' + phoneNumber">
                    {{ phoneNumberFormatted }}
                </b-link>
            </div>
        </div>
    </detail-block>
</template>

<script>
import { get } from "vuex-pathify";
import DetailBlock from "Modules/Vehicle/DetailBlock";

export default {
    name: "BetterWayToBuy",
    components: { DetailBlock },
    computed: {
        phoneNumber: get("campaign/phoneNumber"),
        phoneNumberFormatted: get("campaign/phoneNumberFormatted")
    }
};
</script>

<style lang="scss">
#better-way-to-buy {
    padding: 0;

    .img-fluid {
        width: 100%;
        max-height: 305px;
    }

    .logo-block {
        top: 30px;
        right: 40px;

        .crsv-logo {
            width: 280px;
        }

        span {
            font-size: px2em(24);
            font-weight: bold;
            color: white;
        }
    }

    .our-goal {
        color: $dark-gray;
        padding: 30px 0;
        line-height: 25px;

        span {
            max-width: 495px;
            margin-bottom: 20px;
            font-size: px2em(16);
        }

        .phone {
            font-weight: bold;
            font-size: 18px;
        }
    }

    @include media-breakpoint-down(sm) {
        .logo-block {
            top: 15px !important;
            right: 15px !important;

            .crsv-logo {
                width: 150px !important;
            }

            span {
                font-size: px2em(14) !important;
            }
        }

        .our-goal {
            padding: 20px !important;
            span {
                font-size: px2em(14) !important;
            }
        }
    }
}
</style>
