var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 mt-md-0" },
    [
      _c(
        "flickity",
        {
          ref: "mainCarousel",
          staticClass: "carousel-main",
          attrs: { options: _vm.mainOptions }
        },
        _vm._l(_vm.marketingImages, function(image, key) {
          return _c(
            "div",
            { key: "main-" + key, staticClass: "carousel-cell" },
            [_c("b-img", { attrs: { src: image, fluid: "" } })],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "flickity",
        {
          ref: "thumbnailCarousel",
          staticClass: "carousel-nav mt-1",
          attrs: { options: _vm.thumbnailOptions }
        },
        _vm._l(_vm.marketingImages, function(image, key) {
          return _c(
            "div",
            { key: "thumbnail-" + key, staticClass: "carousel-cell" },
            [_c("b-img", { attrs: { src: image, fluid: "" } })],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }