var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "missing-image d-flex align-items-center text-center" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column align-items-center mx-auto my-0" },
        [
          _c("body-style-icon", { attrs: { id: _vm.bodyStyle } }),
          _vm._v(" "),
          _c("p", { staticClass: "mt-3" }, [
            _vm._v("\n            Image Coming Soon\n        ")
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }