<template>
    <div id="special-offers-block" class="p-3">
        <div class="title">
            {{ $t("component.specialOffersBlock.header") }}
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="m-0" v-html="$t('component.specialOffersBlock.body')" />
    </div>
</template>
<script>
export default {
    name: "SpecialOffersBlock"
};
</script>
<style lang="scss">
#special-offers-block {
    margin-bottom: 10px;

    // .content-box
    border-radius: 2px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: px2rem(14);

    .title {
        font-size: px2rem(12);
        font-weight: bold;
    }

    .primary-blue {
        color: $primary-blue;
    }
}
</style>
