<template>
    <div class="card-body insurance-block">
        <div class="row py-md-4">
            <div class="col-12 col-md-2 mb-3 mb-md-0">
                <div class="icon-block">
                    <i aria-hidden="true" class="icon-shield_car" />
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="d-flex flex-column question-block">
                    <h3 class="mb-1 mb-md-0">
                        {{ $t("component.getInsuranceQuote.doYou") }}
                    </h3>
                    <p>{{ $t("component.getInsuranceQuote.weHave") }}</p>
                </div>
            </div>
            <div
                class="col-12 col-md-4 d-flex justify-content-center align-items-center button-block py-3 py-md-0"
            >
                <b-button variant="primary" @click="openInsuranceModal()">
                    {{ $t("component.getInsuranceQuote.getQuotes") }}
                </b-button>
            </div>
        </div>

        <div class="disclaimer">
            {{ $t("component.getInsuranceQuote.disclaimer") }}
        </div>
        <sign-in-required-modal ref="signInRequiredModal" />
    </div>
</template>

<style lang="scss">
.insurance-block {
    .disclaimer {
        margin: 0 0 10px 0;
        font-size: 12px;
        font-style: italic;
        line-height: 13px;
        color: $muted-dark-gray;
    }

    .icon-block {
        text-align: center;

        i {
            font-size: px2em(90);
            color: $primary-blue;
        }
    }

    .question-block {
        margin: 0 10px;

        h3 {
            font-size: px2em(16);
            font-weight: 700;
            margin: 0;
            color: $dark-gray;
        }
        p {
            font-size: px2em(14);
            color: $muted-dark-gray;
            margin: 0;
        }
    }

    .button-block {
        button {
            min-width: 200px;
            border-radius: 22.5px;
            height: 40px;
        }
    }

    @include media-breakpoint-down(sm) {
        .icon-block {
            i {
                font-size: px2em(80);
            }
        }
    }
}
</style>

<script>
import EventBus from "../../../../event-bus";
import { get } from "vuex-pathify";
import SignInRequiredModal from "Components/SignInRequiredModal";

export default {
    name: "GetInsuranceQuote",
    components: {
        "sign-in-required-modal": SignInRequiredModal
    },
    computed: {
        isSignedIn: get("user/isSignedIn")
    },

    methods: {
        showSignInRequiredModal() {
            this.$refs.signInRequiredModal.show();
        },
        openInsuranceModal() {
            if (!this.isSignedIn) {
                this.showSignInRequiredModal();
            } else {
                EventBus.$emit("insurance-modal-open");
            }
        }
    }
};
</script>
