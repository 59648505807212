var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column header", attrs: { id: "vdp-slider" } },
    [
      _c("vehicle-title-bar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-image order-2 order-md-1 position-relative" },
        [
          _c(
            "div",
            { staticClass: "container-fluid" },
            [_c("new-search-link")],
            1
          ),
          _vm._v(" "),
          _vm.vehicleGallery || _vm.vehicleMediumImage
            ? _c("vehicle-image-slider")
            : _c("missing-image", { attrs: { "body-style": _vm.bodyStyle } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }