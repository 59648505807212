var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section-box", { staticClass: "down-payment-matrix" }, [
    _c(
      "table",
      { attrs: { "aria-describedby": "Down payment matrix Table" } },
      [
        _c("thead", [
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("\n                    Term\n                ")
            ]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("\n                    APR\n                ")
            ]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("\n                    Payment\n                ")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td", [
              _c("strong", [_vm._v(_vm._s(_vm.offer.term) + " months")])
            ]),
            _vm._v(" "),
            _c("td", [_c("strong", [_vm._v(_vm._s(_vm.offer.rate) + "%")])]),
            _vm._v(" "),
            _c("td", { staticClass: "payment" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(_vm._f("numeral")(_vm.offer.monthlyPayment, "$0,0.00"))
                )
              ]),
              _vm._v("/mo\n                ")
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "3" } }, [
              _vm._v("\n                     \n                ")
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "3" } }, [
              _vm._v("\n                     \n                ")
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "3" } }, [
              _vm._v("\n                     \n                ")
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }