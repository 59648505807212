var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showNotification
    ? _c(
        "b-alert",
        {
          staticClass: "distance-notification text-center",
          attrs: { show: "", variant: "warning" }
        },
        [
          _vm._v(
            "\n    This vehicle is " +
              _vm._s(_vm._f("numeral")(_vm.distance, "0,0")) +
              " miles away from\n    " +
              _vm._s(_vm._f("capitalize")(_vm.userCity)) +
              ", " +
              _vm._s(_vm._f("upperCase")(_vm.userStateCode)) +
              "\n    "
          ),
          _c("i", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true }
              }
            ],
            staticClass: "ion-ios-information-outline",
            attrs: { title: _vm.locationTooltipMsg, "aria-hidden": "true" }
          }),
          _vm._v(". \n\n    "),
          _c("b-link", { on: { click: _vm.searchLocalListingsHandler } }, [
            _vm._v("\n        Search Local Listings\n    ")
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }