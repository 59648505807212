var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "coverage-row d-flex flex-row justify-content-between" },
    [
      _c("span", { staticClass: "coverage-label" }, [
        _vm._v("\n        " + _vm._s(_vm.label) + "\n    ")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "coverage-value" }, [
        _vm._v("\n        " + _vm._s(_vm.value) + "\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }