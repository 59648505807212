<template>
    <vehicle-panel :vehicle="vehicle" class="finance-offers-vehicle-panel">
        <title-with-price>
            <template slot="title">
                Market Average
                <tooltip-disclaimer
                    :title="pricing.averageMarketPrice.disclaimer"
                />
            </template>
            <template slot="price">
                {{
                    pricing
                        | emptyDash("averageMarketPrice.value")
                        | numeral("$0,0")
                }}
            </template>
        </title-with-price>
        <hr />
        <title-with-price>
            <template slot="title">
                <strong>Sale Price</strong>
                <tooltip-disclaimer :title="pricing.salePrice.disclaimer" />
            </template>
            <div slot="price" class="blue-price">
                {{ pricing | emptyDash("salePrice.value") | numeral("$0,0") }}
            </div>
        </title-with-price>
        <hr />
        <title-with-price>
            <template slot="title">
                Taxes
            </template>
            <template slot="price">
                +{{ request | emptyDash("salesTax") | numeral("$0,0") }}
            </template>
        </title-with-price>
        <title-with-price>
            <template slot="title">
                Title/Registration
            </template>
            <template slot="price">
                +{{ request | emptyDash("titleLicense") | numeral("$0,0") }}
            </template>
        </title-with-price>
        <title-with-price>
            <template slot="title">
                Dealer Fees
            </template>
            <template slot="price">
                +{{ request | emptyDash("dealerFees") | numeral("$0,0") }}
            </template>
        </title-with-price>
        <hr />
        <title-with-price>
            <template slot="title">
                Subtotal
            </template>
            <template slot="price">
                {{
                    request
                        | emptyDash("requestedAmountFinanced")
                        | numeral("$0,0.00")
                }}
            </template>
        </title-with-price>
        <title-with-price>
            <template slot="title">
                Down Payment
            </template>
            <template slot="price">
                -{{ offer | emptyDash("downPayment") | numeral("$0,0") }}
            </template>
        </title-with-price>
        <hr />
        <title-with-price>
            <template slot="title">
                <strong>Total Financed</strong>
            </template>
            <template slot="price">
                <strong>{{ totalFinanced | numeral("$0,0.00") }}</strong>
            </template>
        </title-with-price>
    </vehicle-panel>
</template>

<script>
import _ from "lodash";
import TooltipDisclaimer from "Components/TooltipDisclaimer";
import VehiclePanel from "Modules/Vehicle/ViewDetailsModal/components/VehiclePanel";
import TitleWithPrice from "Modules/Vehicle/ViewDetailsModal/components/TitleWithPrice";

export default {
    name: "FinanceVehiclePanel",

    components: { TitleWithPrice, VehiclePanel, TooltipDisclaimer },

    props: {
        vehicle: {
            required: true,
            type: Object
        },
        pricing: {
            required: true,
            type: Object
        },
        request: {
            required: true,
            type: Object
        },
        offer: {
            required: true,
            type: Object
        }
    },

    computed: {
        mainImage() {
            return _.get(this.vehicle, "images[0]", null);
        },
        backupImage() {
            return _.get(this.vehicle, "stockPhoto.medium", null);
        },
        carTitle() {
            return `${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}`;
        },
        carSubtitle() {
            return this.vehicle.trim;
        },
        totalFinanced() {
            const downPayment = _.get(this.offer, "downPayment", 0);
            return this.request.requestedAmountFinanced - downPayment;
        }
    }
};
</script>
