var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.activeDealerLink
    ? _c("div", { staticClass: "mb-3", attrs: { id: "expressPass" } }, [
        _c("div", { staticClass: "express-pass-block py-3" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between express-pass-header"
            },
            [
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c("img", {
                  staticClass: "cs-logo-black",
                  attrs: {
                    src: "/assets/images/black_CRSV_logo.svg",
                    alt: "CarSaver_logo_express_pass"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "font-weight-bold font-italic ml-2" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("component.expressPass.title")) +
                        "\n                "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-between align-self-start"
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex mr-2" },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "print-link",
                          attrs: { href: "javascript:window.print()" }
                        },
                        [
                          _c("i", {
                            staticClass: "ion ion-printer mr-1",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("component.expressPass.print")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "container-fluid mb-3 mb-md-0" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-md-4 border-right qr-block" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column justify-content-center align-items-center"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mb-1 qr-code" },
                        [
                          _c("qrcode-vue", {
                            attrs: {
                              value: _vm.qrCodeUrl,
                              size: 115,
                              level: "H"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "savings-code" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t("component.expressPass.savingsCode")
                            ) +
                            "\n                            " +
                            _vm._s(_vm.certificate.id) +
                            "\n                        "
                        )
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-8 user-vehicle-block" }, [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("span", { staticClass: "user" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.user.lastName) +
                        ", " +
                        _vm._s(_vm.user.firstName) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.inventoryVehicle
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row flex-wrap justify-content-between vehicle"
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "express-label" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("component.expressPass.vin")) +
                                  "\n                                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "express-value" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.inventoryVehicle.vin) +
                                  "\n                                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "express-label" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$t("component.expressPass.stockNumber")
                                  ) +
                                  "\n                                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "express-value" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.inventoryVehicle.stockNumber) +
                                  "\n                                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "express-label" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$t("component.expressPass.miles")
                                  ) +
                                  "\n                                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "express-value" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm._f("numeral")(
                                      _vm.inventoryVehicle.miles
                                    )
                                  ) +
                                  "\n                                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "express-label" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$t("component.expressPass.expiration")
                                  ) +
                                  "\n                                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "express-value" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.certificate.expirationDate) +
                                  "\n                                "
                              )
                            ])
                          ])
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row flex-wrap justify-content-between vehicle"
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "express-label" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("component.expressPass.vin")) +
                                  "\n                                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "express-value" }, [
                              _vm._v(
                                "\n                                    Configured Vehicle*\n                                "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "express-label" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$t("component.expressPass.expiration")
                                  ) +
                                  "\n                                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "express-value" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.certificate.expirationDate) +
                                  "\n                                "
                              )
                            ])
                          ])
                        ]
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-items-center p-2 dealer-block"
                    },
                    [
                      _c("span", { staticClass: "valid" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("component.expressPass.validOnly")) +
                            "\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "dealer" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.dealer.name) +
                            "\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "msg" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t("component.expressPass.msg")) +
                            "\n                            "
                        )
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }