var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("div", { staticClass: "loading-wrapper" }, [_c("loading-spinner")], 1)
    : _vm.noIncentives
    ? _c("div", { staticClass: "mt-3" }, [_c("no-incentives-found")], 1)
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("div", { staticClass: "mb-2" }, [
            _vm._v(
              "\n        The following incentive(s) are available for this vehicle.\n    "
            )
          ]),
          _vm._v(" "),
          _c("cash-programs", {
            attrs: { "consumer-cash": _vm.cash.standard.consumerCash }
          }),
          _vm._v(" "),
          _c("special-programs", {
            attrs: {
              id: "cash-special-incentives",
              "special-incentives": _vm.cash.consumerSpecials
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }