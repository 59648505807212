<template>
    <h3>Sorry, no rebates or incentives found for this vehicle.</h3>
</template>

<style lang="scss" scoped>
h3 {
    color: $primary-blue;
    opacity: 0.5;
    text-align: center;
    padding: 20px;
}
</style>

<script>
export default {
    name: "NoIncentivesFound"
};
</script>
