<template>
    <dealer-sticky-footer v-if="dealerLinkExists" :dealer="dealer">
        <div
            slot="cta"
            class="d-flex align-items-center justify-content-between button-wrapper"
        >
            <div class="mx-2">
                <b-button
                    variant="primary"
                    class="px-4"
                    block
                    @click="onViewInventoryClick()"
                >
                    {{ $t("component.dealerStickyFooter.view") }}
                </b-button>
            </div>
            <div class="mx-2">
                <b-button
                    variant="dark-blue"
                    class="px-4"
                    block
                    @click="onConfirmAvailabilityClick()"
                >
                    {{ $t("component.dealerStickyFooter.confirm") }}
                </b-button>
            </div>
            <div v-if="supportsTestDrive" class="mx-2">
                <b-button
                    variant="orange"
                    class="px-4"
                    block
                    @click="onScheduleTestDriveClick()"
                >
                    {{ $t("component.dealerStickyFooter.schedule") }}
                </b-button>
            </div>
        </div>
    </dealer-sticky-footer>
</template>

<style lang="scss" scoped>
button {
    min-width: 165px;
}
</style>

<script>
import { get, dispatch } from "vuex-pathify";
import DealerStickyFooter from "../../../components/DealerStickyFooter";
import _ from "lodash";

export default {
    name: "VehicleDealerStickyFooter",
    components: {
        DealerStickyFooter
    },
    computed: {
        dealerLinkExists: get("dealerLink/active"),
        canUnlockPricing: get("dealerLink/canUnlockPricing"),
        dealer: get("vehicleDetails/dealer"),
        certificate: get("vehicleDetails/certificate"),
        certificateId: get("vehicleDetails/certificate@id"),
        supportsTestDrive() {
            return _.get(this.dealer, "supportsTestDrive", true);
        }
    },
    methods: {
        onConfirmAvailabilityClick() {
            dispatch("contactDealer/openModal", {
                certificateId: this.certificateId,
                questionType: "Vehicle Availability"
            });
        },
        onScheduleTestDriveClick() {
            dispatch("scheduleAppointment/openScheduleModal", {
                certificateId: this.certificateId
            });
        },
        onViewInventoryClick() {
            window.location = `/dealer/${this.dealer.id}/inventory`;
        }
    }
};
</script>
