<template>
    <div class="missing-image d-flex align-items-center text-center">
        <div class="d-flex flex-column align-items-center mx-auto my-0">
            <body-style-icon :id="bodyStyle" />
            <p class="mt-3">
                Image Coming Soon
            </p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.missing-image {
    height: 300px;
    color: $inactive-gray;
    font-size: 1.5rem;

    i {
        font-size: 5em;
    }

    p {
        font-family: $helvetica-65-medium;
        font-size: 1em;
        text-transform: uppercase;
    }

    @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
        margin-bottom: -50px;
    }
}
</style>

<script>
import BodyStyleIcon from "Modules/ShopBy/BodyStyle/components/BodyStyleIcon";

export default {
    components: { BodyStyleIcon },
    props: {
        bodyStyle: {
            type: String,
            required: true
        }
    }
};
</script>
