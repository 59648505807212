<template>
    <div
        :class="{ 'mobile-sticky-footer': true, show: show }"
        class="d-flex d-md-none"
    >
        <div
            class="d-flex justify-content-around align-items-center w-100 footer-block"
        >
            <b-button variant="primary" @click="goToListings">
                {{ text }}
            </b-button>
            <b-button
                v-if="showSupplierPricing"
                variant="orange"
                @click="getSupplierPricing"
            >
                <span class="d-none d-sm-inline">Get Supplier Pricing</span>
                <span class="d-sm-none">Supplier Pricing</span>
            </b-button>

            <b-button
                v-else-if="
                    isCertifiedDealer &&
                        dealerLinkExists &&
                        !showSupplierPricing &&
                        supportsTestDrive
                "
                variant="orange"
                @click="scheduleTestDriveClick()"
            >
                <span class="d-none d-sm-inline">Schedule Test Drive</span>
                <span class="d-sm-none">Test Drive</span>
            </b-button>
            <b-button
                v-else-if="
                    isCertifiedDealer &&
                        !showSupplierPricing &&
                        !dealerLinkExists
                "
                variant="orange"
                @click="getSalePriceClick"
            >
                Get Sale Price
            </b-button>

            <b-button
                v-else-if="
                    (!isCertifiedDealer || !supportsTestDrive) &&
                        !showSupplierPricing
                "
                variant="orange"
                @click="contactDealer"
            >
                Contact Dealer
            </b-button>
        </div>
        <sign-in-required-modal ref="signInRequiredModal" />
    </div>
</template>

<script>
import URL from "url-parse";
import { get, dispatch, call, sync } from "vuex-pathify";
import _ from "lodash";
import listingsUrlStorage from "@/lib/listingsUrlStorage";
import SignInRequiredModal from "Components/SignInRequiredModal";

export default {
    name: "MobileStickyFooter",
    components: {
        "sign-in-required-modal": SignInRequiredModal
    },

    data() {
        return {
            show: false,
            alternativeUrl: "/pick-your-flow",
            alternativeText: "New Search",
            clientStorageListings: null,
            url: null,
            text: "",
            bodyElm: null
        };
    },

    computed: {
        dealer: get("vehicleDetails/dealer"),
        dealerLinkExists: get("dealerLink/active"),
        isSignedIn: get("user/isSignedIn"),
        certificate: get("vehicleDetails/certificate"),
        pricing: get("vehicleDetails/pricing"),
        vehicle: get("vehicleDetails/vehicle"),
        splashImage: get("vehicleDetails/media@splashImage"),
        inventoryVehicle: get("vehicleDetails/inventoryVehicle"),
        vin: sync("vehicleDetails/inventoryVehicle@vin"),

        supportsTestDrive() {
            return _.get(this.dealer, "supportsTestDrive", true);
        },
        isCertifiedDealer() {
            return _.get(this.dealer, "certified", null) || this.isConfigured;
        },
        isConfigured() {
            return _.isNil(this.inventoryVehicle);
        },
        showSupplierPricing() {
            return _.get(this.pricing, "supplierPricingAvailable", false);
        },
        certificateId() {
            return _.get(this.certificate, "id", null);
        }
    },

    created() {
        window.addEventListener("scroll", _.throttle(this.handleScroll, 200));
        this.clientStorageListings = listingsUrlStorage.get();
        this.url = _.get(
            this.clientStorageListings,
            "url",
            this.alternativeUrl
        );
        this.text = _.get(
            this.clientStorageListings,
            "text",
            this.alternativeText
        );
    },
    mount() {
        // handles freshchat offset
        this.initFreshChatPlacementAdjuster();
    },
    watch: {
        show(val) {
            if (val) {
                if (!this.bodyElm) {
                    this.bodyElm = document.querySelector("body");
                }

                this.bodyElm.classList.add("freshchat_offset");
            } else {
                if (!this.bodyElm) {
                    this.bodyElm = document.querySelector("body");
                }
                this.bodyElm.classList.remove("freshchat_offset");
            }
        }
    },
    methods: {
        // For adjusting the Fresh Chat icon
        initFreshChatPlacementAdjuster() {
            this.bodyElm = document.querySelector("body");
        },
        goToListings() {
            window.location = this.url || this.alternativeUrl;
        },
        openContactModal: call("contactDealer/openModal"),
        handleScroll() {
            const isMobile = window.matchMedia(
                "only screen and (max-width: 990px)"
            );

            const topPadding = 5;

            this.show = isMobile.matches && window.scrollY > topPadding;
        },

        scheduleTestDriveClick() {
            dispatch("scheduleAppointment/openScheduleModal", {
                certificateId: this.certificateId
            });
        },

        getSalePriceClick() {
            if (this.isSignedIn) {
                dispatch("dealerLink/openModal", this.certificateId);
            } else {
                this.showSignInRequiredModal();
            }
        },
        showSignInRequiredModal() {
            this.$refs.signInRequiredModal.show();
        },
        getSupplierPricing() {
            dispatch("supplierPricing/openSupplierPricingModal", {
                vehicle: this.vehicle,
                splashImage: this.splashImage
            });
        },
        contactDealer() {
            if (this.isSignedIn) {
                return this.openContactModal({
                    certificateId: this.certificateId
                });
            }

            const returnUrl = new URL(window.location.href, true);
            returnUrl.query.modal = "contact-dealer";

            document.location = "/sign-up?returnUrl=" + returnUrl;
        }
    }
};
</script>

<style lang="scss" scoped>
.mobile-sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 21;
    transition-timing-function: ease-in;
    transition-duration: 0.5s;
    transform: translateY(130%);

    @include media-breakpoint-down(sm) {
        .btn {
            width: 45% !important;
        }
    }

    .footer-block {
        flex-flow: row nowrap;
        height: 70px;
        background-color: #f9f9f9;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);

        .btn-primary,
        .btn-orange {
            width: 225px;
            font-size: px2rem(16);
            height: 40px;
        }
    }

    &.show {
        transition-timing-function: ease-out;
        transition-duration: 0.5s;
        transform: translateY(0);
    }
}
</style>

// freshdesk icon adjustment when footer is present
<style lang="scss">
body {
    &.freshchat_offset {
        .freshchat_btn {
            transition-timing-function: ease-in;
            transition-duration: 0.5s;
            bottom: 75px !important;
        }
    }
    .freshchat_btn {
        transition-timing-function: ease-out;
        transition-duration: 0.5s;
    }
}
</style>
