var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100", attrs: { id: "leaseTab" } }, [
    _vm.isLoading
      ? _c(
          "div",
          {
            staticClass: "loading-wrapper d-flex align-items-center flex-column"
          },
          [_c("loading-spinner")],
          1
        )
      : _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "block border-bottom py-1" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.retailPrice")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("numeral")(
                          _vm._f("emptyDash")(_vm.pricing, "msrp"),
                          "$0,0"
                        )
                      ) +
                      "\n                "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.rebate")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex value" }, [
                  _vm.bestLeaseDeal && _vm.bestLeaseDeal.quote.rebates > 0
                    ? _c("span", [
                        _vm._v(
                          "\n                        -\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("numeral")(
                          _vm._f("emptyDash")(
                            _vm.bestLeaseDeal,
                            "quote.rebates"
                          ),
                          "$0,0"
                        )
                      ) +
                      "\n                "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _vm.hasDealerDiscount
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-between line-item" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("component.pricingCard.discount")) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                    -\n                    " +
                          _vm._s(
                            _vm._f("numeral")(
                              _vm._f("emptyDash")(
                                _vm.pricing,
                                "dealerDiscount"
                              ),
                              "$0,0"
                            )
                          ) +
                          "\n                "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.vehicle.warrantyEligible
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-between line-item" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c(
                          "b-link",
                          {
                            staticClass: "label mr-1",
                            on: {
                              click: function($event) {
                                return _vm.showLifetimeWarrantyModal()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t(
                                    "component.pricingCard.lifetimeWarranty"
                                  )
                                ) +
                                "\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("lifetime-warranty-tooltip-disclaimer")
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("component.pricingCard.included")) +
                          "\n                "
                      )
                    ])
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "block border-bottom py-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center line-item"
              },
              [
                _c("span", { staticClass: "sale-label font-weight-bold" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.salePrice")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _vm.showRestrictedMake
                  ? _c("div", { staticClass: "restricted-make-price" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("component.pricingCard.seeEmail")) +
                          "\n                "
                      )
                    ])
                  : _c("div", { staticClass: "blue-value" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("numeral")(_vm.salePrice, "$0,0")) +
                          "\n                "
                      )
                    ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "block border-bottom py-1" }, [
            _vm.hasLeaseTaxes && _vm.bestLeaseDeal.quote.monthlyTaxes > 0
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-between line-item" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("component.pricingCard.tax")) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("numeral")(
                              _vm._f("emptyDash")(
                                _vm.bestLeaseDeal,
                                "quote.monthlyTaxes"
                              ),
                              "+ $0,0"
                            )
                          ) +
                          "/mo\n                "
                      )
                    ])
                  ]
                )
              : _vm.hasLeaseTaxes
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-between line-item" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("component.pricingCard.tax")) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("numeral")(
                              _vm._f("emptyDash")(_vm.taxes, "lease.salesTax"),
                              "+ $0,0"
                            )
                          ) +
                          "\n                "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasLeaseTaxes
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-between line-item" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t("component.pricingCard.licenseRegistration")
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("numeral")(
                              _vm._f("emptyDash")(
                                _vm.taxes,
                                "lease.titleLicenseFees"
                              ),
                              "+ $0,0"
                            )
                          ) +
                          "\n                "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasLeaseTaxes
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-between line-item" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("component.pricingCard.dealerFee")) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("numeral")(
                              _vm._f("emptyDash")(_vm.pricing, "dealerFees"),
                              "+ $0,0"
                            )
                          ) +
                          "\n                "
                      )
                    ])
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "line-item text-center" },
                  [
                    _vm.taxesLoading
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [_c("loading-spinner", { attrs: { size: 30 } })],
                          1
                        )
                      : _c("b-link", { on: { click: _vm.loadLeaseTaxes } }, [
                          _vm._v(
                            "\n                    View with Taxes & Fees\n                "
                          )
                        ])
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("component.pricingCard.downPayment")) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm.bestLeaseDeal
                    ? _c("span", [
                        _vm._v(
                          "\n                        -\n                    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("numeral")(
                          _vm._f("emptyDash")(
                            _vm.bestLeaseDeal,
                            "quote.downPayment"
                          ),
                          "$0,0"
                        )
                      ) +
                      "\n                "
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "block py-1" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between line-item" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$t("component.pricingCard.totalAmountFinanced")
                      ) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value font-weight-bold" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("numeral")(_vm.totalAmountFinanced, "$0,0")
                      ) +
                      "\n                "
                  )
                ])
              ]
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }