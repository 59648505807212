var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "getInsuranceModal",
      attrs: {
        id: "get-insurance-modal",
        size: "lg",
        "body-class": "m-0 p-0",
        "hide-header": "",
        "hide-footer": ""
      }
    },
    [
      _c("div", { staticClass: "m-0 p-0" }, [
        _c("div", { staticClass: "insure-hero m-0 p-0 d-flex flex-column" }, [
          _c(
            "span",
            { staticClass: "align-self-end", on: { click: _vm.close } },
            [
              _c(
                "i",
                {
                  staticClass: "ion-ios-close-outline",
                  attrs: { "aria-hidden": "true" }
                },
                [_vm._v("\n                     \n                ")]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "container d-table" }, [
            _c("div", { staticClass: "lib-m-logo" }),
            _vm._v(" "),
            _c("div", { staticClass: "special-discount" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("component.getInsuranceQuote.title")))
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-and-phone-block d-flex flex-column" },
              [
                _c(
                  "b-link",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center p-0 m-0 text-center btn btn-outline-white mb-2",
                    on: {
                      click: function($event) {
                        return _vm.gotoInsurance()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t("component.getInsuranceQuote.getQuoteBtn")
                        ) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "phone-number" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("component.getInsuranceQuote.orCall")) +
                      "\n                        "
                  ),
                  _c("a", { attrs: { href: "tel:888-727-2146" } }, [
                    _vm._v(
                      "\n                            888-727-2146\n                        "
                    )
                  ])
                ])
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }