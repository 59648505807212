import _ from "lodash";
import api from "../../api";
import { make } from "vuex-pathify";

const initialState = {
    selectedDealType: "CASH",
    dealer: _.get(window, "_CS_VEHICLE_DETAILS.dealer", null),
    vehicle: _.get(window, "_CS_VEHICLE_DETAILS.vehicleInfo", null),
    inventoryVehicle: _.get(
        window,
        "_CS_VEHICLE_DETAILS.inventoryVehicleInfo",
        null
    ),
    pricing: _.get(window, "_CS_VEHICLE_DETAILS.pricing", null),
    certificate: _.get(window, "_CS_VEHICLE_DETAILS.certificate", null),
    media: _.get(window, "_CS_VEHICLE_DETAILS.media", null),
    reviewText: null,
    taxes: {
        loading: false,
        finance: null,
        lease: null
    },
    estimatedPayment: {
        loading: false,
        loaded: false,
        disclaimer: null,
        monthlyPayment: null
    },
    finance: {
        offers: {
            decisions: [],
            state: "NO_APPLICATION"
        },
        appRequest: null
    },
    vr: {
        downloadModal: false,
        tourModal: false
    },
    ui: {
        gallery: {
            photos: {
                show: false
            },
            video: {
                show: false
            },
            vehicle360: {
                show: false
            }
        }
    }
};

const mutations = {
    ...make.mutations(initialState),

    SET_DEALER_DISTANCE: (state, distance) => {
        _.set(state.dealer, "distance", distance);
    },

    SET_CERTIFICATE_FINANCE: (state, finance) => {
        _.set(state.certificate, "finance", finance);
    },

    SET_CERTIFICATE_QUOTE: (state, offer) => {
        _.set(state.certificate, "quote", offer.quoteWithTaxes);
        _.set(state.certificate, "baseQuote", offer.quote);
    },

    SET_TAXES_FINANCE: (state, taxes) => {
        _.set(state.taxes, "finance", taxes);
    },

    SET_TAXES_LEASE: (state, taxes) => {
        _.set(state.taxes, "lease", taxes);
    },

    SET_TAXES_LOADING: (state, loading) => {
        _.set(state.taxes, "loading", loading);
    },

    SET_SENT_PRICE_BY_EMAIL: (state, value) => {
        _.set(state.certificate, "sentPriceByEmail", value);
    },

    SET_FINANCING_AVAILABLE: (state, value) => {
        _.set(state.vehicle, "financingAvailable", value);
    }
};

const getters = {
    ...make.getters(initialState),
    isFinancingAvailable: state => {
        return _.get(state, "finance.offers.state") !== "NOT_AVAILABLE";
    },

    vehicleModelFullName: state => {
        return `${state.vehicle.year} ${state.vehicle.make} ${state.vehicle.model}`;
    }
};

const actions = {
    ...make.actions(initialState),
    /*
        This function is non-reentrant if distance is already set
     */
    loadDistance: ({ commit, state }, { zipCode, lat, lng }) => {
        if (_.isNil(state.distance)) {
            api.get("/location/distance", {
                zipCode: zipCode,
                lat: lat,
                lng: lng
            })
                .then(response => {
                    commit("SET_DEALER_DISTANCE", response.data.distance);
                })
                .catch(() => {
                    commit("SET_DEALER_DISTANCE", 0);
                });
        }
    },

    loadFinanceTaxes: ({ state, commit }) => {
        commit("SET_TAXES_LOADING", true);
        api.get(
            `/pricing-card/certificates/${state.certificate.id}/taxes/finance`
        )
            .then(response => {
                commit("SET_TAXES_FINANCE", response.data);
                commit("SET_TAXES_LOADING", false);
            })
            .catch(error => {
                commit("SET_TAXES_LOADING", false);
                console.error(error);
            });
    },

    loadLeaseTaxes: ({ state, commit }) => {
        commit("SET_TAXES_LOADING", true);
        api.get(
            `/pricing-card/certificates/${state.certificate.id}/taxes/lease`
        )
            .then(response => {
                commit("SET_TAXES_LEASE", response.data);
                commit("SET_TAXES_LOADING", false);
            })
            .catch(error => {
                commit("SET_TAXES_LOADING", false);
                console.error(error);
            });
    },

    loadEstimatedPayment: ({ commit }, { id, styleId, zipCode, price }) => {
        commit("SET_ESTIMATED_PAYMENT", {
            disclaimer: null,
            monthlyPayment: null,
            loading: true,
            loaded: false
        });

        const url = _.isNil(id)
            ? `/styles/${styleId}/estimatedPayment`
            : `/vehicle/${id}/estimatedPayment`;

        api.get(url, { zipCode, price })
            .then(response => {
                if (response.status === 200) {
                    commit("SET_ESTIMATED_PAYMENT", {
                        ...response.data,
                        loading: false,
                        loaded: true
                    });
                } else {
                    commit("SET_ESTIMATED_PAYMENT", {
                        disclaimer: null,
                        monthlyPayment: null,
                        loading: false,
                        loaded: true
                    });
                }
            })
            .catch(() => {
                commit("SET_ESTIMATED_PAYMENT", {
                    disclaimer: null,
                    monthlyPayment: null,
                    loading: false,
                    loaded: true
                });
            });
    },

    acceptFinanceOffer({ commit, state }, { responseId }) {
        api.post(`/certificates/${state.certificate.id}/finance/accept`, {
            responseId
        })
            .then(response => {
                commit("SET_CERTIFICATE_FINANCE", response.data);
            })
            .catch(error => {
                console.error(error);
            });
    },

    resubmitFinanceOffer({ commit, state }, { responseId, lenderId }) {
        api.post(`/certificates/${state.certificate.id}/finance/resubmit`, {
            responseId,
            lenderId
        })
            .then(() => {
                commit("SET_FINANCE", {
                    offers: {
                        decisions: [],
                        state: "IN_PROGRESS"
                    },
                    appRequest: state.finance.appRequest
                });
            })
            .catch(error => {
                console.error(error);
            });
    },

    loadFinanceOffers({ commit, state }) {
        api.get(`/certificates/${state.certificate.id}/finance/offers`)
            .then(response => {
                commit("SET_FINANCE", {
                    offers: response.data.offers,
                    appRequest: response.data.financeAppRequest
                });
            })
            .catch(error => {
                console.error(error);
            });
    },

    saveOfferToCertificate({ commit, state }, { offer }) {
        api.post(`/certificates/${state.certificate.id}/update-quote`, offer)
            .then(() => {
                commit("SET_CERTIFICATE_QUOTE", offer);
            })
            .catch(error => {
                console.error(error);
            });
    },

    setDealer({ commit }, dealer) {
        commit("SET_DEALER", dealer);
    },

    loadReview({ commit, state }) {
        const styleId = _.get(state, "vehicle.styleId");

        if (!_.isNil(styleId)) {
            api.get(`/vehicle-data/styles/${state.vehicle.styleId}/review`)
                .then(response => {
                    commit("SET_REVIEW_TEXT", _.get(response, "data"));
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};
