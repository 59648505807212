<template>
    <div>
        <b-modal
            id="dealerLinkRequiredModal"
            ref="modal"
            v-model="isOpen"
            size="lg"
            :hide-footer="true"
            @shown="shown"
        >
            <div slot="modal-header-close">
                <i class="ion-ios-close-outline" aria-hidden="true">
                    &nbsp;
                </i>
            </div>

            <div slot="modal-title">
                {{ $t("component.dealerLinkRequiredModal.title") }}
            </div>

            <dealer-link-modal-loader v-if="isLoading" />

            <b-container v-else class="h-auto my-0">
                <b-row v-if="selectedDealer" class="mb-2">
                    <div class="col-12">
                        <div class="sub-title-block d-flex flex-column">
                            <span class="sub-title-one">
                                {{
                                    $t(
                                        "component.dealerLinkRequiredModal.subTitleOne"
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                </b-row>

                <b-row>
                    <b-col
                        md="12"
                        lg="7"
                        class="right-border  order-2 order-lg-1"
                    >
                        <div
                            v-if="selectedDealer"
                            class="left-section px-0 px-md-3"
                        >
                            <div
                                class="d-flex flex-column justify-content-center"
                            >
                                <div
                                    v-if="user"
                                    class="user-info-block d-flex flex-column justify-content-center"
                                >
                                    <span class="confirm-contact-info mb-2">
                                        {{
                                            $t(
                                                "component.dealerLinkRequiredModal.confirmContactInfo"
                                            )
                                        }}
                                    </span>
                                    <edit-contact-info />
                                </div>

                                <div class="d-flex align-items-center">
                                    <div
                                        class="dealer-info d-flex flex-column mr-auto"
                                    >
                                        <span class="certified-dealer">
                                            {{
                                                $t(
                                                    "component.dealerLinkRequiredModal.certifiedDealer"
                                                )
                                            }}
                                        </span>

                                        <span class="dealer-name">
                                            {{ selectedDealer.name }}
                                        </span>

                                        <div
                                            v-if="!isDelivery"
                                            class="d-flex flex-row dealer-distance"
                                        >
                                            <span class="font-italic mr-2">
                                                {{
                                                    $t(
                                                        "component.dealerLinkRequiredModal.distance"
                                                    )
                                                }}
                                            </span>
                                            <span class="font-italic mr-1">
                                                {{
                                                    selectedDealer.distance
                                                        | numeral("0,0")
                                                }}
                                            </span>
                                            <span class="font-italic">
                                                {{
                                                    $t(
                                                        "component.dealerLinkRequiredModal.miles"
                                                    )
                                                }}
                                            </span>
                                        </div>
                                    </div>

                                    <b-dropdown
                                        v-if="hasMultipleDealers"
                                        text="edit"
                                        size="sm"
                                        class="mt-2"
                                    >
                                        <b-dropdown-item
                                            v-for="d in availableDealers"
                                            :key="d.id"
                                            @click="selectDealer(d)"
                                        >
                                            {{ d.name }} ({{
                                                d.distance | numeral("0,0")
                                            }}
                                            miles)
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                        </div>
                        <div v-else class="left-section px-0 px-md-3">
                            <div class="sub-title-block d-flex flex-column">
                                <span class="sub-title-one">
                                    No Dealers Found
                                </span>
                            </div>
                            <p>
                                Sorry. There are no CarSaver Certified Dealers
                                in your area.
                            </p>
                        </div>
                    </b-col>

                    <b-col md="12" lg="5" class="order-1 order-lg-2">
                        <div
                            class="right-section d-flex flex-column px-0 px-lg-4"
                        >
                            <div v-if="vehicle" class="w-100 text-center">
                                <b-img
                                    v-if="vehicle.imageUrl"
                                    :src="vehicle.imageUrl"
                                    :alt="vehicleName"
                                    fluid
                                />
                                <body-style-icon
                                    v-else
                                    :id="vehicle.bodyStyle"
                                />
                            </div>

                            <div
                                v-if="vehicle"
                                class="vehicle d-flex flex-column text-center my-3"
                            >
                                <span class="font-weight-bold">
                                    {{ vehicle.year }} {{ vehicle.make }}
                                    {{ vehicle.model }}
                                </span>
                                <span class="trim">
                                    {{ vehicle.trim }}
                                </span>
                            </div>
                        </div>
                    </b-col>
                </b-row>

                <distance-notification
                    v-if="!isDelivery"
                    class=" mt-3 mb-0"
                    :dealer-link-required="false"
                />

                <b-row>
                    <div
                        v-if="selectedDealer"
                        class="col-12 order-2 order-lg-1"
                    >
                        <div class="terms-row">
                            <div class="terms-and-condition-block">
                                <span class="terms font-weight-bold">
                                    {{
                                        $t(
                                            "component.dealerLinkRequiredModal.boldTerms"
                                        )
                                    }}
                                </span>
                                <span class="terms font-italic">
                                    {{
                                        $t(
                                            "component.dealerLinkRequiredModal.terms"
                                        )
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-12 order-1 order-lg-2 p-0 d-flex justify-content-center align-items-center my-3"
                    >
                        <ladda-b-btn
                            v-if="selectedDealer || isLoading"
                            :loading="submitting"
                            class="btn-orange"
                            :disabled="!canGetSalePrice"
                            @click="onGetSalePrice()"
                        >
                            {{
                                $t(
                                    "component.dealerLinkRequiredModal.getSalePrice"
                                )
                            }}
                        </ladda-b-btn>

                        <div v-else class="d-flex actions">
                            <b-btn variant="primary" @click="isOpen = false">
                                <span class="d-none d-sm-inline"
                                    >Continue Shopping</span
                                >
                                <span class="d-sm-none">Continue</span>
                            </b-btn>
                            <b-btn
                                variant="orange"
                                :href="localListingsUrl"
                                class="ml-2"
                            >
                                <span class="d-none d-sm-inline"
                                    >Search Local Listings</span
                                >
                                <span class="d-sm-none">Local Listings</span>
                            </b-btn>
                        </div>
                    </div>
                </b-row>
            </b-container>
        </b-modal>

        <thank-you-modal />
        <carvana-modal />
    </div>
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import EditContactInfo from "../EditContactInfo";
import LaddaBBtn from "../LaddaBBtn";
import { trackGAEvent, trackPageView } from "../../lib/GoogleTagManager";
import _ from "lodash";
import DealerLinkModalLoader from "Components/DealerLinkRequiredModal/DealerLinkModalLoader";
import shouldAutoLoadModal from "@/mixins/shouldAutoLoadModal";
import BodyStyleIcon from "Modules/ShopBy/BodyStyle/components/BodyStyleIcon/index";
import ThankYouModal from "Components/DealerLinkRequiredModal/ThankYouModal";
import DistanceNotification from "Modules/Vehicle/DistanceNotification";
import CarvanaModal from "Modules/Vehicle/CarvanaModal/index";

export default {
    name: "DealerLinkRequiredModal",

    components: {
        CarvanaModal,
        DistanceNotification,
        ThankYouModal,
        BodyStyleIcon,
        DealerLinkModalLoader,
        EditContactInfo,
        LaddaBBtn
    },

    mixins: [shouldAutoLoadModal],

    data() {
        return {
            submitting: false,
            locationTooltipMsg:
                "Based on your home address. To update, please visit your user profile."
        };
    },

    computed: {
        user: get("user/user"),
        isLoading: get("dealerLink/isLoading"),
        certificateId: get("vehicleDetails/certificate@id"),
        isOpen: sync("dealerLink/isOpen"),
        vehicle: get("dealerLink/vehicle"),
        availableDealers: get("dealerLink/availableDealers"),
        selectedDealer: sync("dealerLink/selectedDealer"),
        ambassadorPin: get("sessionReset/ambassadorId"),
        userCity: get("user/user@city"),
        userStateCode: get("user/user@stateCode"),
        deliveryAvailability: get(
            "vehicleDetails/dealer@preferences.deliveryAvailability"
        ),

        localListingsUrl() {
            const make = _.get(this.vehicle, "make");
            const model = _.get(this.vehicle, "model");
            const year = _.get(this.vehicle, "year");
            return `/shop/new?makes=${make}&models=${model}&minYear=${year}&maxYear=${year}&distance=100`;
        },

        vehicleName() {
            return `${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}`;
        },

        isFarAway() {
            return _.gt(_.get(this.selectedDealer, "distance", 0), 60);
        },

        hasMultipleDealers() {
            return _.size(this.availableDealers) > 1;
        },

        canGetSalePrice() {
            return this.selectedDealer;
        },

        isDelivery() {
            return _.eq(this.deliveryAvailability, "DELIVERY_ONLY");
        }
    },

    mounted() {
        this.shouldAutoLoadModal("dealer-link", () => {
            this.openModal(this.certificateId);
        });
    },

    methods: {
        openModal: call("dealerLink/openModal"),
        createLink: call("dealerLink/createLink"),
        selectDealer(dealer) {
            this.selectedDealer = dealer;
        },
        shown() {
            trackPageView(this, `${window.location.pathname}/dealer-connector`);
        },
        onGetSalePrice() {
            this.submitting = true;

            trackGAEvent(this, {
                category: "dealer-link-modal",
                action: "create-link",
                label: "Get Sale Price"
            });

            this.createLink();
        }
    }
};
</script>

<style lang="scss">
#dealerLinkRequiredModal {
    .distance-disclaimer {
        font-size: 0.9rem;
    }

    @include media-breakpoint-up(lg) {
        .right-border {
            border-right: 1px solid $light-gray !important;
        }

        .sub-title-block {
            .sub-title {
                font-size: px2rem(16);
            }
        }
    }
    .right-border {
        border-right: none;
    }

    .sub-title-block {
        line-height: 24px;

        .sub-title-one {
            font-size: px2rem(14);
            color: $dark-gray;
            margin-bottom: 20px;
            font-family: $helvetica-45-light;
        }
    }

    .left-section {
        .user-info-block {
            background: #f9f9f9;
            border-radius: 5px;
            padding: 20px 25px;

            .confirm-contact-info {
                color: $dark-gray;
                font-size: px2rem(14);
                font-family: $helvetica-45-light;
                font-style: italic;
            }
        }
    }

    .right-section {
        img {
            height: auto;
            max-height: 225px;
        }
        .vehicle {
            font-size: px2rem(16);

            .trim {
                font-size: px2rem(14);
                line-height: 18px;
            }
        }
        i {
            font-size: 60px;
            margin: 40px 0;
            display: block;
        }
    }

    .dealer-info {
        padding: 20px 25px;

        .certified-dealer {
            color: $dark-gray;
            font-size: px2rem(12);
            font-family: $helvetica-65-medium;
            font-style: italic;
        }

        .dealer-name {
            font-family: $helvetica-45-light;
            font-size: 18px;
        }
    }

    .terms-row {
        margin-top: 20px;
        margin-bottom: 5px;
        .terms-and-condition-block {
            line-height: 15px;
            .terms {
                font-size: px2rem(12);
                color: $dark-gray;
                margin-top: 5px;
            }
        }
    }

    .communication-block {
        height: 30px;
        line-height: 15px;

        a {
            font-size: px2rem(12);
        }
    }

    .btn {
        width: 220px;
        height: 40px;
    }
    .btn.dropdown-toggle {
        height: 40px;
        width: 70px;
        font-family: $helvetica-65-medium;
    }

    @include media-breakpoint-down(sm) {
        .actions {
            width: 100%;
        }
    }
}
</style>
