<template>
    <div id="contact-dealer-block" class="pt-3 w-100">
        <div class="col-12 text-center d-flex flex-column align-items-center">
            <b-button
                variant="orange"
                block
                class="mb-2"
                @click="contactDealer"
            >
                Contact Dealer
            </b-button>
        </div>
    </div>
</template>
<script>
import URL from "url-parse";
import { call, get } from "vuex-pathify";

export default {
    name: "ContactDealerBlock",

    computed: {
        isUserSignedIn: get("user/isSignedIn"),
        certificateId: get("vehicleDetails/certificate@id")
    },

    methods: {
        openModal: call("contactDealer/openModal"),

        contactDealer() {
            if (this.isUserSignedIn) {
                return this.openModal({ certificateId: this.certificateId });
            }

            const returnUrl = new URL(window.location.href, true);
            returnUrl.query.modal = "contact-dealer";

            document.location = "/sign-up?returnUrl=" + returnUrl;
        }
    }
};
</script>
<style lang="scss">
#get-sales-price-block {
    button.btn {
        max-width: 200px;
    }
}
</style>
