import api from "@/api";
import { make } from "vuex-pathify";
import _ from "lodash";

const CONFIGURED_VEHICLE_OUT_OF_AREA_DISTANCE = 60;
const PRICING_REQUESTS_OUT_OF_AREA_DISTANCE = 100;

const initialState = {
    certificateId: null,
    isLoading: true,
    isOpen: false,
    thankYouOpen: false,
    carvanaModalOpen: false,
    vehicle: null,
    selectedDealer: null,
    availableDealers: [],
    active: _.get(window, "_CS_VEHICLE_DETAILS.hasDealerLink", false),
    sentToDealer: _.get(window, "_CS_VEHICLE_DETAILS.leadSentToDealer", false),
    hasLead: _.get(window, "_CS_VEHICLE_DETAILS.hasLead", false),
    leadId: null
};

const mutations = {
    ...make.mutations(initialState)
};

const storeGetters = {
    canUnlockPricing: (state, getters, rootState) => {
        const certificateShownPricing = _.get(
            rootState,
            "vehicleDetails.certificate.shownPricing",
            false
        );
        return state.active && (state.hasLead || certificateShownPricing);
    },
    // vehicleDetails.dealer.distance may either be distance from the user's address or distance from the zipCode in context (via search or browser/ip location)
    dealerDistance: (state, getters, rootState) => {
        return _.get(rootState, "vehicleDetails.dealer.distance", 0);
    },
    dealerDistanceFromUserAddress: (state, getters, rootState) => {
        return _.get(
            rootState,
            "vehicleDetails.dealer.distanceFromUserAddress",
            null
        );
    },
    isRestrictedMake: state => {
        return _.get(state, "vehicle.restrictedMake", false);
    },
    isConfiguredVehicle: state => {
        return _.get(state, "vehicle.configured", false);
    },
    // favor distance from user's address for calculation, else fallback to userLocationService resolved distance
    isOutOfAreaForConfigured: (state, getters) => {
        return !_.isNil(getters.dealerDistanceFromUserAddress)
            ? getters.dealerDistanceFromUserAddress >
                  CONFIGURED_VEHICLE_OUT_OF_AREA_DISTANCE
            : getters.dealerDistance > CONFIGURED_VEHICLE_OUT_OF_AREA_DISTANCE;
    },
    // favor distance from user's address for calculation, else fallback to userLocationService resolved distance
    isOutOfArea: (state, getters) => {
        return !_.isNil(getters.dealerDistanceFromUserAddress)
            ? getters.dealerDistanceFromUserAddress >
                  PRICING_REQUESTS_OUT_OF_AREA_DISTANCE
            : getters.dealerDistance > PRICING_REQUESTS_OUT_OF_AREA_DISTANCE;
    },
    canSendLead: (state, getters, rootState) => {
        const priceRequestsEnabled = _.get(
            rootState,
            "vehicleDetails.dealer.preferences.enablePriceRequests",
            true
        );
        const isOutOfArea =
            getters.isOutOfArea ||
            (getters.isConfiguredVehicle && getters.isOutOfAreaForConfigured);

        return priceRequestsEnabled && !isOutOfArea;
    }
};

const actions = {
    ...make.actions(initialState),

    openModal({ commit, getters }, certificateId) {
        if (certificateId) {
            const skipOpen = getters.canUnlockPricing;
            if (skipOpen) {
                // Do nothing, already has sale price
                return;
            }

            commit("SET_CERTIFICATE_ID", certificateId);
            commit("SET_SELECTED_DEALER", null);
            commit("SET_IS_LOADING", true);
            commit("SET_IS_OPEN", true);
            api.get(`/certificates/${certificateId}/dealer-link`)
                .then(response => {
                    const availableDealers = _.get(
                        response,
                        "data.availableDealers",
                        []
                    );
                    commit("SET_AVAILABLE_DEALERS", availableDealers);

                    if (_.size(availableDealers) > 0) {
                        commit("SET_SELECTED_DEALER", availableDealers[0]);
                    }

                    commit(
                        "SET_VEHICLE",
                        _.get(response, "data.vehicle", null)
                    );
                    commit("SET_IS_LOADING", false);
                })
                .catch(error => {
                    console.error(error);
                    commit("SET_IS_LOADING", false);
                    commit("SET_IS_OPEN", false);
                });
        } else {
            commit("SET_IS_OPEN", false);
        }
    },

    createLink({ commit, state, getters, dispatch }) {
        api.get(`/certificates/${state.certificateId}/dealer-link`, {
            dealerId: state.selectedDealer.id
        })
            .then(response => {
                const dealer = _.get(response, "data.dealer", null);
                commit("vehicleDetails/SET_DEALER", dealer, { root: true });
                commit(
                    "vehicleDetails/SET_PRICING",
                    _.get(response, "data.pricing", null),
                    { root: true }
                );
                commit("vehicleDetails/SET_SENT_PRICE_BY_EMAIL", true, {
                    root: true
                });
                commit(
                    "vehicleDetails/SET_CERTIFICATE",
                    _.get(response, "data.certificate", null),
                    { root: true }
                );
                commit(
                    "vehicleDetails/SET_FINANCING_AVAILABLE",
                    _.get(response, "data.financingAvailable", false),
                    { root: true }
                );
                commit("SET_ACTIVE", true);
                commit("SET_IS_OPEN", false);

                if (_.get(state.vehicle, "source") === "CV") {
                    commit("SET_CARVANA_MODAL_OPEN", true);
                    dispatch("startAutoSend");
                    return;
                }

                if (getters.canSendLead || getters.isRestrictedMake) {
                    commit("SET_THANK_YOU_OPEN", true);
                }
            })
            .catch(error => {
                console.error(error);
            });
    },

    startAutoSend({ commit, state, rootState }) {
        // Close thank you first
        commit("SET_THANK_YOU_OPEN", false);

        const ambassadorId = _.get(rootState, "sessionReset.ambassadorId");
        api.get(`/certificates/${state.certificateId}/dealer-link/send`, {
            ambassadorId
        })
            .then(response => {
                commit("SET_LEAD_ID", _.get(response, "data.id"));
            })
            .catch(error => {
                console.error(error);
            });
    }
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    getters: storeGetters,
    mutations
};
