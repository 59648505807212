<template>
    <c-s-modal
        id="getFinancingModal"
        ref="getFinancingModal"
        :hide-footer="true"
    >
        <div slot="modal-title">
            {{ modalTitle }}
        </div>

        <div v-if="showHowItWorks" class="container-fluid">
            <ol>
                <li>
                    Your application is submitted to CarSaver’s network of banks
                    and finance companies
                </li>
                <li>
                    We will notify you when offers are received (this usually
                    only takes a few minutes)
                </li>
                <li>
                    Pick the offer that is right for you and schedule an
                    appointment with a Certified CarSaver Dealership
                </li>
            </ol>

            <div class="actions">
                <div v-if="certificateId" class="my-2">
                    <b-button
                        class="mx-auto buttons"
                        variant="primary"
                        @click="continueFinancingClick"
                    >
                        Continue
                    </b-button>
                </div>
                <div v-else class="my-2 buttons d-flex">
                    <b-button class="mx-2" variant="primary" :href="signInUrl">
                        Sign In
                    </b-button>
                    <b-button class="mx-2" variant="orange" :href="signUpUrl">
                        Sign Up
                    </b-button>
                </div>
                <div class="my-2">
                    <b-link @click="viewBenefitsClick">
                        View benefits
                    </b-link>
                </div>
                <div class="my-2">
                    <b-link @click="closeModal">
                        No thanks, take me back to my vehicle
                    </b-link>
                </div>
            </div>

            <p class="disclaimer">
                * If you are approved, the finance source may require
                verification of certain items, prior to extending financing
                including - proof of income, verification of employment, proof
                of residence and proof of phone.
            </p>
        </div>

        <div v-if="showBenefits" class="container-fluid">
            <p>Benefits of getting pre-approved for financing.</p>
            <p><strong>Benefits:</strong></p>
            <ol>
                <li>
                    Know your rate and payment before you go to the dealership.*
                </li>
                <li>
                    Save on finance charges by eliminating dealer markup on your
                    finance rate.
                </li>
                <li>Save time at the dealership.</li>
            </ol>

            <div class="actions">
                <div v-if="certificateId" class="my-2">
                    <b-button
                        class="mx-auto"
                        variant="primary"
                        @click="continueFinancingClick"
                    >
                        Get Payment Offers
                    </b-button>
                </div>
                <div v-else class="my-2 buttons d-flex">
                    <b-button class="mx-2" variant="primary" :href="signInUrl">
                        Sign In
                    </b-button>
                    <b-button class="mx-2" variant="orange" :href="signUpUrl">
                        Sign Up
                    </b-button>
                </div>
                <div class="my-2">
                    <b-link @click="closeModal">
                        No thanks, take me back to my vehicle
                    </b-link>
                </div>
            </div>

            <p class="disclaimer">
                * Finance offers are subject to approved credit. See terms and
                conditions for details.
            </p>
        </div>
    </c-s-modal>
</template>

<style lang="scss">
#getFinancingModal {
    li {
        line-height: 18px;
        margin-top: 1em;
    }

    .actions {
        margin-top: 1em;
        text-align: center;

        .btn {
            width: 280px;
        }

        div:not(.buttons) {
            a {
                font-style: italic;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .disclaimer {
        font-size: 12px;
        font-style: italic;
        color: $muted-dark-gray;
    }
}
</style>

<script>
import CSModal from "Components/CSModal";
import EventBus from "@/event-bus";
import { get } from "vuex-pathify";
import { trackPageView } from "@/lib/GoogleTagManager";
import URL from "url-parse";

export default {
    name: "GetFinancingModal",

    components: {
        CSModal
    },

    props: {
        certificateId: {
            type: Number,
            required: false,
            default: null
        }
    },

    data() {
        return {
            step: 0
        };
    },

    computed: {
        isSignedIn: get("user/isSignedIn"),
        dealer: get("vehicleDetails/dealer"),
        modalTitle() {
            switch (this.step) {
                case 0:
                    return "How It Works";
                case 1:
                    return "Finance & Save";
                default:
                    return "How It Works";
            }
        },

        signInUrl() {
            const url = new URL(window.location.href, true);
            url.query.action = "finance-app";
            return `/sign-in?returnUrl=${encodeURIComponent(url.toString())}`;
        },

        signUpUrl() {
            const url = new URL(window.location.href, true);
            url.query.action = "finance-app";
            return `/sign-up?returnUrl=${encodeURIComponent(url.toString())}`;
        },

        showHowItWorks() {
            return this.step === 0;
        },

        showBenefits() {
            return this.step === 1;
        }
    },

    created() {
        EventBus.$on("financing-modal-open", this.openModal);
    },

    methods: {
        openModal() {
            trackPageView(
                this,
                `${window.location.pathname}/finance/how-it-works`
            );

            this.step = 0;
            this.$refs.getFinancingModal.show();
        },

        closeModal() {
            this.$refs.getFinancingModal.hide();
        },

        continueFinancingClick() {
            document.location = `/certificate/${this.certificateId}/finance/app`;
        },

        viewBenefitsClick() {
            trackPageView(this, `${window.location.pathname}/finance/benefits`);

            this.step = 1;
        }
    }
};
</script>
