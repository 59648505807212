var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pt-3 w-100 get-sales-price-outer-block" }, [
    _c(
      "div",
      {
        staticClass:
          "col-12 text-center d-flex flex-column align-items-center get-sales-price-block"
      },
      [
        _c("hr", { staticClass: "divider" }),
        _vm._v(" "),
        _vm.canUnlockPricing &&
        _vm.supportsTestDrive &&
        (!_vm.restrictedMake || _vm.sentPriceByEmail)
          ? _c(
              "b-button",
              {
                staticClass: "mb-2",
                attrs: { variant: "orange", block: "" },
                on: { click: _vm.scheduleTestDrive }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("component.pricingCard.testDrive")) +
                    "\n        "
                )
              ]
            )
          : _vm.canUnlockPricing &&
            (!_vm.restrictedMake || _vm.sentPriceByEmail)
          ? _c(
              "b-button",
              {
                staticClass: "mb-2",
                attrs: { variant: "orange", block: "" },
                on: { click: _vm.contactDealer }
              },
              [_vm._v("\n            Contact Dealer\n        ")]
            )
          : _c(
              "div",
              { staticClass: "d-flex justify-content-center w-100" },
              [
                _vm.isUserSignedIn
                  ? _c(
                      "b-button",
                      {
                        staticClass: "mb-2",
                        attrs: { variant: "orange", block: "" },
                        on: { click: _vm.requestDealerLink }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.text || _vm.defaultText) +
                            "\n            "
                        )
                      ]
                    )
                  : _c(
                      "b-button",
                      {
                        staticClass: "mb-2",
                        attrs: { variant: "orange", block: "" },
                        on: {
                          click: function($event) {
                            return _vm.showSignInRequiredModal()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.text || _vm.defaultText) +
                            "\n            "
                        )
                      ]
                    ),
                _vm._v(" "),
                _c("sign-in-required-modal", { ref: "signInRequiredModal" })
              ],
              1
            ),
        _vm._v(" "),
        !_vm.canUnlockPricing
          ? _c("span", { staticClass: "font-italic" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("component.pricingCard.additionalSavings")) +
                  "\n        "
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }