var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "is-user-signed-in",
    [
      _c(
        "b-link",
        { staticClass: "heart-icon", on: { click: _vm.toggleSavedVehicle } },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-center block"
            },
            [
              !_vm.saved
                ? _c("i", {
                    staticClass: "ion-ios-heart white",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.saved
                ? _c("i", {
                    staticClass: "ion-ios-heart red",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e()
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }