var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row no-gutters", attrs: { id: "gallery-container" } },
    [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          {
            staticClass: "card-header d-flex justify-content-between",
            attrs: {
              id: "headingGallery",
              role: "tab",
              "data-toggle": "collapse",
              href: "#collapseGallery",
              "aria-expanded": "true"
            }
          },
          [
            _c(
              "h2",
              { staticClass: "mb-0" },
              [
                _vm._v("\n                Virtual Showroom\n                "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "b-link",
                  { on: { click: _vm.gotoRelayCars } },
                  [
                    _c(
                      "svg-fallback",
                      {
                        staticClass: "float-right",
                        attrs: { src: _vm.relaycarsLogo.svg }
                      },
                      [_c("b-img", { attrs: { src: _vm.relaycarsLogo.jpg } })],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("i", {
              staticClass: "ion-chevron-up",
              attrs: { "aria-hidden": "true" }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse show",
            attrs: {
              id: "collapseGallery",
              "aria-labelledby": "headingGallery",
              "data-target": "#collapseGallery"
            }
          },
          [
            _c(
              "div",
              { staticClass: "body" },
              [
                _c("gallery-icons"),
                _vm._v(" "),
                _c("vehicle-gallery"),
                _vm._v(" "),
                _vm.show360 ? _c("vehicle360-view") : _vm._e()
              ],
              1
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }