<template>
    <div id="gallery-container" class="row no-gutters">
        <div class="col-12">
            <div
                id="headingGallery"
                class="card-header d-flex justify-content-between"
                role="tab"
                data-toggle="collapse"
                href="#collapseGallery"
                aria-expanded="true"
            >
                <h2 class="mb-0">
                    Virtual Showroom
                    <br />
                    <b-link @click="gotoRelayCars">
                        <svg-fallback
                            :src="relaycarsLogo.svg"
                            class="float-right"
                        >
                            <b-img :src="relaycarsLogo.jpg" />
                        </svg-fallback>
                    </b-link>
                </h2>
                <i aria-hidden="true" class="ion-chevron-up" />
            </div>
            <div
                id="collapseGallery"
                class="collapse show"
                aria-labelledby="headingGallery"
                data-target="#collapseGallery"
            >
                <div class="body">
                    <gallery-icons />
                    <vehicle-gallery />
                    <vehicle360-view v-if="show360" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { get } from "vuex-pathify";
import GalleryIcons from "./components/GalleryIcons";
import Vehicle360View from "./components/Vehicle360View";
import VehicleGallery from "./components/VehicleGallery";
import SvgFallback from "Components/SvgFallback";
import relaycarsLogoSvg from "./images/relaycars_logo.svg";
import relaycarsLogoJpg from "./images/relaycars_logo.jpg";
import EventBus from "@/event-bus";

export default {
    name: "Gallery",
    components: {
        SvgFallback,
        Vehicle360View,
        GalleryIcons,
        VehicleGallery
    },

    data() {
        return {
            relaycarsLogo: {
                svg: relaycarsLogoSvg,
                jpg: relaycarsLogoJpg,
                text: "Powered by RELAYCARS"
            }
        };
    },

    computed: {
        show360: get("vehicleDetails/ui@gallery.vehicle360.show")
    },

    methods: {
        gotoRelayCars() {
            EventBus.$emit("leaving-modal", "https://vr.relaycars.com");
        }
    }
};
</script>
<style lang="scss">
#gallery-container {
    background-color: $white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 20px 28px;

    .card-header {
        background-color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 2px;
        border-bottom: none;
        padding: 0;

        h5 {
            font-size: 2rem;
            color: #444;
            line-height: 30px;
        }
        i {
            font-size: px2em(16);
        }
    }
    #collapseGallery {
        .body {
            padding: 0 25px 25px;
        }
        i {
            font-size: px2em(16);
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 20px !important;
    }
}
</style>
