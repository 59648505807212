var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "selected-payment-wrapper text-center w-100" },
    [
      _c("h4", { staticClass: "text-center" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.selectedOffer.quote.type === "Loan" ? "FINANCE" : "LEASE"
            ) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "price-block-wrapper" },
        [
          _c("price-block", {
            attrs: { payment: _vm.selectedOffer.quote.monthlyPayment }
          }),
          _vm._v(" "),
          _c(
            "b-link",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.focus.bottom",
                  modifiers: { focus: true, bottom: true }
                }
              ],
              attrs: { title: _vm.selectedOffer.disclaimer }
            },
            [
              _c("i", {
                staticClass: "icon ion-ios-information-outline",
                attrs: { "aria-hidden": "true" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "before-taxes" },
        [
          _vm.taxesLoading
            ? _c(
                "div",
                { staticClass: "d-flex justify-content-center" },
                [_c("loading-spinner")],
                1
              )
            : _vm.selectedOffer.quoteWithTaxes
            ? _c(
                "div",
                { staticClass: "taxes-and-fees" },
                [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("numeral")(
                          _vm.selectedOffer.quoteWithTaxes.quote.monthlyPayment,
                          "$0"
                        )
                      ) + "/mo"
                    )
                  ]),
                  _vm._v("\n            with taxes & fees\n            "),
                  _c(
                    "b-link",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.focus.bottom",
                          modifiers: { focus: true, bottom: true }
                        }
                      ],
                      attrs: {
                        title: _vm.selectedOffer.quoteWithTaxes.disclaimer
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "icon ion-ios-information-outline",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                ],
                1
              )
            : _c("b-link", { on: { click: _vm.fetchQuoteTaxes } }, [
                _vm._v("\n            Include Taxes & Fees\n        ")
              ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }